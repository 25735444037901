import { city } from "ca-plateform-project-domain";
import i18n from "i18n";
import { http } from "api/http";
import { buildProjectApiUrl } from "api/configuration";

export interface DuplicatedCampaignEvent
  extends city.colisactiv.project.model.features.DuplicatedCampaignEvent {}

export interface RemoveCampaignCommand
  extends city.colisactiv.project.model.features.RemoveCampaignCommand {}

export const duplicateCampaignCommand = (
  projectId: string,
  campaignId: string
): Promise<DuplicatedCampaignEvent> => {
  return http.patch(
    buildProjectApiUrl(`/projects/${projectId}/campaigns/${campaignId}`),
    i18n.t("app_project_campaign_duplicate_success")
  );
};
