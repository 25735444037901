import * as R from "ramda";
import { VisitorFormState, VisitorFormValidity } from "./model";
import { NO_ERROR } from ".";

export const getValidation = (form: VisitorFormState): VisitorFormValidity => {
  return {
    territories: NO_ERROR,
    visitorRoles: NO_ERROR,
    watchedOperatorId: NO_ERROR,
    watchedCarrierId: NO_ERROR,
  };
};

export const isFormValid = (newValidity: VisitorFormValidity): boolean => {
  return R.values(newValidity)
    .map((value) => value == NO_ERROR)
    .reduce((previousValue, currentValue) => previousValue && currentValue);
};

export const defaultVisitorFormValidity: VisitorFormValidity = {
  territories: NO_ERROR,
  visitorRoles: NO_ERROR,
  watchedOperatorId: NO_ERROR,
  watchedCarrierId: NO_ERROR,
};

export const defaultVisitorFormState: VisitorFormState = {
  territories: [],
  roles: [],
  watchedOperatorId: undefined,
  watchedCarrierId: undefined,
  validity: defaultVisitorFormValidity,
};

export const VisitorValidation = {
  validation: getValidation,
  isFormValid: isFormValid,
  defaultVisitorFormValidity: defaultVisitorFormValidity,
  defaultVisitorFormState: defaultVisitorFormState,
};
