import { city } from "ca-plateform-bonus-domain";
import { buildBonusApiUrl } from "api/configuration";
import { http } from "api/http";
import { BonusManualData } from "..";
export interface CreateOrUpdateGroupOfBonusCommand
  extends city.colisactiv.plateform.bonus.model.features
    .CreateOrUpdateGroupOfBonusCommand {}

export interface CreateOrUpdateGroupOfBonusCommandDto
  extends city.colisactiv.plateform.bonus.model.features
    .CreateOrUpdateGroupOfBonusCommandDTO {
  bonuses: BonusManualData[];
}

export interface BonusUpdatedEvent
  extends city.colisactiv.plateform.bonus.model.features.BonusUpdatedEvent {}

export interface BonusCreatedEvent
  extends city.colisactiv.plateform.bonus.model.features.BonusCreatedEvent {}

const createOrUpdateGroupOfBonusCommand = (
  dto: CreateOrUpdateGroupOfBonusCommandDto
): CreateOrUpdateGroupOfBonusCommand => {
  return {
    type: "UPDATE_BONUS",
    payload: dto,
  };
};

export const createOrUpdateGroupOfBonus = (
  dto: CreateOrUpdateGroupOfBonusCommandDto
): Promise<(BonusCreatedEvent | BonusUpdatedEvent)[]> => {
  const url = buildBonusApiUrl(`/bonus`);
  const body = JSON.stringify(createOrUpdateGroupOfBonusCommand(dto));
  return http.post(url, body, "La prime a été sauvegardée");
};
