import { city } from "ca-plateform-geozone-domain";
import { buildProjectApiUrl } from "api/configuration";
import i18n from "i18n";
import { http } from "api/http";
import { GeoZoneFormDetailsState } from "app/components/GeoZoneForm/model";

export interface ArchiveGeoZoneCommand
  extends city.colisactiv.platform.geozone.model.features.ArchiveGeoZoneCommand {}


export interface GeoZoneArchivedEvent
  extends city.colisactiv.platform.geozone.model.features.GeoZoneArchivedEvent {}


export const GeoZoneArchiveCommand = (
  geoZoneId: string
): ArchiveGeoZoneCommand => {
  return {
    type: "ARCHIVE_GEOZONE",
    payload: {
      id: geoZoneId
    },
  };
};

export const archiveGeoZoneCommand = (
  geoZoneId: string
): Promise<GeoZoneArchivedEvent> => {
  return http.post(
    buildProjectApiUrl(`/geozones/archive`),
    JSON.stringify(GeoZoneArchiveCommand(geoZoneId)),
    i18n.t("app_geozone_archive_success")
  );
};
