import { connect } from "react-redux";
import { State } from "store";
import { title } from "store/title";
import { project } from "store/project";
import { router } from "store/router";
import { global } from "store/global";
import { profile } from "store/profile";

const mapStateToProps = (state: State) => ({
  project: project.selectors.project(state),
  funderRefs: global.selectors.funderRefs(state),
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  ...project.commands.campaignActions,
  setHeadBar: title.actions.setHeadBar,
  fetchProject: project.queries.fetchProject,
  gotoCreateCampaign: router.goto.project.campaignCreate,
  gotoProjectEdit: router.goto.project.projectEdit,
};

export default connect(mapStateToProps, mapDispatchToProps);
