import { buildBonusGroupStatsApiUrl } from "api/configuration";
import { http } from "../../http";
import { city } from "ca-plateform-delivery-export-domain";
import { DateInterval } from "components/src/Domain/Dashboard";

export interface GetDeliveryExportCommandBase
  extends city.colisactiv.plateform.delivery.export.domain.features
    .GetDeliveryExportCommandBase {}

export const exportDelivery = async (
  period: DateInterval,
  territoryCode?: string
): Promise<string | null> => {
  const query: GetDeliveryExportCommandBase = {
    payload: {
      period: period,
      territoryCode: territoryCode,
    },
  };
  return http.fetchDownloadableFile(
    buildBonusGroupStatsApiUrl(`/deliveryexport/delivery`),
    "Une erreur est survenue lors de l'export des points de livraison",
    "post",
    JSON.stringify(query)
  );
};

export const exportTrace = async (
  period: DateInterval,
  territoryCode?: string
): Promise<string | null> => {
  const query: GetDeliveryExportCommandBase = {
    payload: {
      period: period,
      territoryCode: territoryCode,
    },
  };
  return http.fetchDownloadableFile(
    buildBonusGroupStatsApiUrl(`/deliveryexport/trace`),
    "Une erreur est survenue lors de l'export des traces GPS",
    "post",
    JSON.stringify(query)
  );
};

export const exportAllDelivery = async () => {
  return http.get(
    buildBonusGroupStatsApiUrl("/deliveryexport/delivery/all"),
    "Une erreur est survenue lors de l'export des points de livraison",
    "La demande a été reçue"
  );
};

export const exportAllTrace = async () => {
  return http.get(
    buildBonusGroupStatsApiUrl("/deliveryexport/trace/all"),
    "Une erreur est survenue lors de l'export des traces GPS",
    "La demande a été reçue"
  );
};

export const downloadExport = async (path: string): Promise<string | null> => {
  return http.fetchDownloadableFile(
    buildBonusGroupStatsApiUrl("/deliveryexport/download" + path),
    "Une erreur est survenue lors du téléchargement de l'export",
    "get"
  );
};

export const downloadBonusGroupImports = async (
  bonusGroupId: string
): Promise<string | null> => {
  return http.fetchDownloadableFile(
    buildBonusGroupStatsApiUrl(
      `/deliveryexport/bonus/${bonusGroupId}/download/imports`
    ),
    "Une erreur est survenue lors du téléchargement des fichiers d'import",
    "get"
  );
};
