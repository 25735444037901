import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface VisitorAuthorizationUpdateCommandDTO
  extends city.colisactiv.organization.model.features
    .VisitorAuthorizationUpdateCommandDTO {}

export interface VisitorAuthorizationUpdatedEvent
  extends city.colisactiv.organization.model.features
    .VisitorAuthorizationUpdatedEvent {}

export interface VisitorAuthorization
  extends city.colisactiv.organization.model.VisitorAuthorization {}

export const updateVisitorAuthorization = (
  organizationId: string,
  command: VisitorAuthorizationUpdateCommandDTO
): Promise<VisitorAuthorizationUpdatedEvent | null> => {
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/visitorAuthorization`
    ),
    JSON.stringify(command)
  );
};
