import { Territory } from "api/geoZone";
import { Organization, VisitorRole } from "../../../api/organization";
import { defaultVisitorFormValidity } from "./validation";

export const buildVisitorForm = (
  organization: Organization
): VisitorFormState => ({
  territories: organization.territories || [],
  roles: organization.visitorAuthorization?.roles || [],
  watchedOperatorId:
    organization.visitorAuthorization?.watchedOperatorId || undefined,
  watchedCarrierId:
    organization.visitorAuthorization?.watchedCarrierId || undefined,
  validity: defaultVisitorFormValidity,
});

export interface VisitorFormState {
  territories: Array<Territory>;
  roles: Array<VisitorRole>;
  watchedOperatorId?: string;
  watchedCarrierId?: string;
  validity: VisitorFormValidity;
}

export interface VisitorFormValidity {}
