import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from '@material-ui/core'
import { GenericGroup, TransferList } from '../TransferList'
import { GeoZone, Territory } from './TerritorySelectorDialog'
import { ListHeader } from './ListHeader'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '1000px'
  },
  button: {
    margin: '0px 10px'
  },
  leftItem: {
    position: 'absolute',
    left: '20px',
    fontSize: '0.85em',
    color: '#007DCE',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

interface TerritorySelectorFilters {
  territory?: Territory
  name: string
}

interface transferListFilters {
  left: TerritorySelectorFilters
  right: TerritorySelectorFilters
}

export type GeoZoneWithTerritory = GeoZone & {
  territoryName: string
  territoryId: string
}

type Groups = {
  groups: GenericGroup<GeoZoneWithTerritory>[]
  zoneNumber: number
}

interface SelectorDialogProps {
  title: string
  territories: Territory[]
  selectedZones: Map<string, GeoZoneWithTerritory>
  zonesGroups: GenericGroup<GeoZoneWithTerritory>[]
  handleClose: () => void
  onValidate: (zones: Map<string, GeoZoneWithTerritory>) => void
  loading: boolean
  onChangeLoading: (value: boolean) => void
  open: boolean
  gotoGeoZoneCreate: () => void;
  isColisActivAdmin: boolean;
}

export const SelectorDialog = (props: SelectorDialogProps) => {
  const {
    title,
    territories,
    handleClose,
    open,
    onValidate,
    zonesGroups,
    loading,
    onChangeLoading,
    selectedZones,
    gotoGeoZoneCreate,
    isColisActivAdmin
  } = props
  const classes = useStyles()
  const [localSelectedZones, setLocalSelectedZones] = useState<
    Map<string, GeoZoneWithTerritory>
  >(new Map<string, GeoZoneWithTerritory>())
  const { t } = useTranslation()
  const [filters, setFilters] = useState<transferListFilters>({
    left: {
      name: ''
    },
    right: {
      name: ''
    }
  })

  useEffect(() => {
    setLocalSelectedZones(selectedZones)
  }, [selectedZones])

  const leftList: Groups = useMemo(() => {
    let zoneNumber = 0
    return {
      groups: zonesGroups.map((group) => {
        return {
          ...group,
          list: group.list.filter((zone) => {
            const filter = !localSelectedZones.has(zone.id)
            if (filter) zoneNumber++
            return filter
          })
        }
      }),
      zoneNumber: zoneNumber
    }
  }, [zonesGroups, localSelectedZones])

  const rightList: Groups = useMemo(() => {
    let zoneNumber = 0
    return {
      groups: zonesGroups.map((group) => {
        return {
          ...group,
          list: group.list.filter((zone) => {
            const filter = localSelectedZones.has(zone.id)
            if (filter) zoneNumber++
            return filter
          })
        }
      }),
      zoneNumber: zoneNumber
    }
  }, [zonesGroups, localSelectedZones])

  const rightFilteredList: Groups | undefined = useMemo(
    () =>
      filterList(
        rightList,
        filters.right.name,
        onChangeLoading,
        filters.right.territory
      ),
    [rightList, filters.right]
  )

  const leftFilteredList: Groups | undefined = useMemo(
    () =>
      filterList(
        leftList,
        filters.left.name,
        onChangeLoading,
        filters.left.territory
      ),
    [leftList, filters.left]
  )

  const getItemTitle = useCallback((zone: GeoZoneWithTerritory) => {
    return zone.name
  }, [])

  const onSwitchAllToLeft = useCallback(() => {
    setLocalSelectedZones((oldZones) => {
      const listToSwitch =
        rightFilteredList !== undefined ? rightFilteredList : rightList
      listToSwitch.groups.forEach((groups) =>
        groups.list.forEach((zone) => {
          oldZones.delete(zone.id)
        })
      )
      return new Map(oldZones)
    })
  }, [rightList, rightFilteredList])

  const onSwitchAllToRight = useCallback(() => {
    setLocalSelectedZones((oldZones) => {
      const listToSwitch =
        leftFilteredList !== undefined ? leftFilteredList : leftList
      listToSwitch.groups.forEach((groups) =>
        groups.list.forEach((zone) => {
          oldZones.set(zone.id, zone)
        })
      )
      return new Map(oldZones)
    })
  }, [leftList, leftFilteredList])

  const onSwitchToLeft = useCallback((item: GeoZoneWithTerritory) => {
    setLocalSelectedZones((oldZones) => {
      oldZones.delete(item.id)
      return new Map(oldZones)
    })
  }, [])

  const onSwitchToRight = useCallback((item: GeoZoneWithTerritory) => {
    setLocalSelectedZones((oldZones) => {
      oldZones.set(item.id, item)
      return new Map(oldZones)
    })
  }, [])

  const onChangeLeftName = useCallback((name: string) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        left: { name: name.trim(), territory: oldFilters.left.territory }
      }
    })
  }, [])

  const onChangeRightName = useCallback((name: string) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        right: { name: name.trim(), territory: oldFilters.right.territory }
      }
    })
  }, [])

  const onChangeRightTerritory = useCallback((territory?: Territory) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        right: { name: oldFilters.right.name, territory: territory }
      }
    })
  }, [])

  const onChangeLeftTerritory = useCallback((territory?: Territory) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        left: { name: oldFilters.left.name, territory: territory }
      }
    })
  }, [])

  const onValidateMemoized = useCallback(() => {
    handleClose()
    onValidate(localSelectedZones)
  }, [onValidate, handleClose, localSelectedZones])

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box display='flex' width='100%' flexDirection='column'>
          <TransferList<GeoZoneWithTerritory>
            rightGroupsList={
              rightFilteredList !== undefined
                ? rightFilteredList.groups
                : rightList.groups
            }
            leftGroupsList={
              leftFilteredList !== undefined
                ? leftFilteredList.groups
                : leftList.groups
            }
            getItemTitle={getItemTitle}
            isLoading={loading}
            onSwitchAllToLeft={onSwitchAllToLeft}
            onSwitchToLeft={onSwitchToLeft}
            onSwitchAllToRight={onSwitchAllToRight}
            onSwitchToRight={onSwitchToRight}
            switchAlltoRightLabel={t(
              'app_campaign_form_bonus_price_row_select_all'
            )}
            switchAlltoLeftLabel={t(
              'app_campaign_form_bonus_price_row_unselect_all'
            )}
            leftHeader={
              <ListHeader
                loading={loading}
                id='territory-selector-left'
                title={t('app_campaign_form_bonus_price_row_unSelected_zones')}
                itemsNumber={
                  leftFilteredList !== undefined
                    ? leftFilteredList.zoneNumber
                    : leftList.zoneNumber
                }
                territoryId={filters.left.territory?.id ?? ''}
                onChangeName={onChangeLeftName}
                onChangeTerritory={onChangeLeftTerritory}
                territories={territories}
              />
            }
            rightHeader={
              <ListHeader
                loading={loading}
                id='territory-selector-right'
                title={t('app_campaign_form_bonus_price_row_selected_zones')}
                itemsNumber={
                  rightFilteredList !== undefined
                    ? rightFilteredList.zoneNumber
                    : rightList.zoneNumber
                }
                territoryId={filters.right.territory?.id ?? ''}
                onChangeTerritory={onChangeRightTerritory}
                onChangeName={onChangeRightName}
                territories={territories}
              />
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {isColisActivAdmin && (
          <Box className={classes.leftItem} onClick={gotoGeoZoneCreate}>
            {t("app_campaign_form_bonus_price_row_create_geozone")}
          </Box>
        )}
        <Button className={classes.button} onClick={handleClose}>
          {t('app_campaign_form_bonus_price_row_cancel')}
        </Button>
        <Button className={classes.button} onClick={onValidateMemoized}>
          {t('app_campaign_form_bonus_price_row_validate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const filterList = (
  list: Groups,
  name: string,
  onChangeLoading: (value: React.SetStateAction<boolean>) => void,
  territory?: Territory
): Groups | undefined => {
  if (name === '' && territory === undefined) {
    onChangeLoading(false)
    return
  }
  onChangeLoading(true)
  let zoneNumber = 0
  if (name === '' && territory !== undefined) {
    const filtered = list.groups.filter((group) => {
      const filter = group.id === territory.id
      if (filter) zoneNumber += group.list.length
      return filter
    })
    onChangeLoading(false)
    return {
      groups: filtered,
      zoneNumber: zoneNumber
    }
  }
  const filtered = list.groups.map((group) => {
    return {
      ...group,
      list: group.list.filter((zone) => {
        if (territory && zone.territoryId !== territory.id) return false
        if (name && !zone.name.toLowerCase().startsWith(name.toLowerCase()))
          return false
        zoneNumber++
        return true
      })
    }
  })
  onChangeLoading(false)
  return {
    groups: filtered,
    zoneNumber: zoneNumber
  }
}
