import {
  BonusPrice,
  CampaignDetails,
  CampaignFunder,
} from "api/project/model";
import { CampaignStatus } from "api/project/model/CampaignStatus";
import {
  defaultCamapaignFunderValidity,
  defaultCampaignFormValidity,
  defaultRuleFormValidity,
} from "./validation";
import { getFirstDayOfTheMonthUTC, getLastDayOfTheMonthUTC } from "utils";
export interface CampaignFormState {
  id: string;
  projectId: string;
  budget?: number;
  status: CampaignStatus;
  details: CampaignDetails;
  bonusPrices: BonusPrice[];
  funders: CampaignFunder[];
  validation: CampaignFormValidation;
}

export interface CampaignFormValidation {
  validity: CampaignFormValidity;
  bonusPriceValidation: BonusPriceFormValidity[];
  campaignFundersValidation: CampaignFunderValidity[];
}

export interface RulesFormValidity {
  value: boolean;
  type: boolean;
}

export interface BonusPriceFormValidity {
  areas: boolean;
  value: boolean;
  operator: boolean;
  maxHourRate: boolean;
}

export interface CampaignFunderValidity {
  funderId: boolean;
  budgetPercentage: boolean;
}

export interface CampaignFormValidity {
  name: boolean;
  budget: boolean;
  startDate: boolean;
  endDate: boolean;
}

let date = new Date();

const defaultBonusPrice: BonusPrice = {
  // @ts-ignore
  value: undefined,
  areas: [],
  operator: [],
  carriers: [],
};

const defaultCampaignFunder: CampaignFunder = {
  // @ts-ignore
  budgetPercentage: undefined,
  // @ts-ignore
  funderId: undefined,
};


const defaultCampaignForm: CampaignFormState = {
  id: "",
  projectId: "",
  status: { value: "" },
  details: {
    name: "",
    period: {
      startDate: getFirstDayOfTheMonthUTC(date),
      endDate: getLastDayOfTheMonthUTC(date),
    },
  },
  bonusPrices: [defaultBonusPrice],
  funders: [defaultCampaignFunder],
  validation: {
    rulesValidation: [defaultRuleFormValidity],
    bonusPriceValidation: [{ areas: true, value: true, operator: true, maxHourRate: true }],
    campaignFundersValidation: [defaultCamapaignFunderValidity],
    validity: defaultCampaignFormValidity,
  },
};

export const CampaignFormModel = {
  defaultBonusPrice,
  defaultCampaignFunder,
  defaultCampaignForm,
};
