import {Box, makeStyles} from "@material-ui/core";
import {Organization} from "api/organization";
import {User} from "api/organization/users";
import {useAuth} from "auth/useAuth";
import {HeadBarProps, Section} from "components";
import {OrganizationRef} from "components/src/Domain/CampaignDelivery";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ApiKey} from "./ApiKey";
import {ApiOrganizations} from "./ApiOrganizations";

const useStyles = makeStyles({
  content: {
    margin: "0 50px 50px 50px",
  },
  container: {
    borderTop: "1px solid gray",
    padding: "30px 0",
  },
  title: {
    fontWeight: 700,
    marginBottom: 10,
  },
});

interface ApiPageProps {
  carrierRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  currentUser: User | null;
  organization: Organization | null;
  fetchOrganization: (organizationId: string) => void;
  setHeadBar: (headBar: HeadBarProps) => void;
}

export const ApiPage = (props: ApiPageProps) => {
  const {
    carrierRefs,
    operatorRefs,
    currentUser,
    organization,
    fetchOrganization,
    setHeadBar,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [authService, keycloak] = useAuth();

  const isOperator = useMemo(() => {
    return authService.isOperator(currentUser, keycloak) &&
        !authService.isColisActivAdmin(currentUser, keycloak)
  }, [currentUser, keycloak, authService])

  const isCarrier = useMemo(() => {
    return authService.isCarrier(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak)
  }, [currentUser, keycloak, authService])

  const isTms = useMemo(() => {
    return authService.isTms(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak)
  }, [currentUser, keycloak, authService])

  const isAdmin = useMemo(() => {
    return authService.isColisActivAdmin(currentUser, keycloak)
  }, [currentUser, keycloak, authService])

  useEffect(() => {
    setHeadBar({
      title: t("app_admin_layout_API"),
    });
  }, [setHeadBar, t]);

  return (
    <Section withGoBack>
      {(isOperator || isCarrier || isTms) && (
          <Box className={classes.content}>
            <p className={classes.title}>{t("app_api_keys")}</p>
            <Box className={classes.container}>
              <ApiKey
                currentUser={currentUser}
                organization={organization}
                fetchOrganization={fetchOrganization}
              />
            </Box>
          </Box>
        )}
        {isAdmin && (
          <>
            <Box className={classes.content}>
              <p className={classes.title}>{t("app_api_carrier_ids")}</p>
              <Box className={classes.container}>
                <ApiOrganizations organizationRefs={carrierRefs} />
              </Box>
            </Box>
            <Box className={classes.content}>
              <p className={classes.title}>{t("app_api_operator_ids")}</p>
              <Box className={classes.container}>
                <ApiOrganizations organizationRefs={operatorRefs} />
              </Box>
            </Box>
          </>
        )}
    </Section>
  );
};
