import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core'
import {themeContext} from '@smartb/archetypes-ui-components'
import React, {useContext} from 'react'
import {ArrowLeft} from '../../Icons'
import {CheckBox} from '../CheckBox'

const useStyles = makeStyles(() => ({
  root: {
    width: '500px',
    height: '60px',
    display: 'flex',
    padding: '5px',
    paddingLeft: '0px',
    position: 'fixed',
    transform: 'translate(-50%)',
    left: 'calc(50% + 75px)',
    bottom: '0px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  listContainer: {
    top: '-5px',
    height: 'calc(100% + 10px)',
    '&:hover .hoverList': {
      height: '180px'
    }
  },
  hoverList: {
    position: 'absolute',
    width: '100%',
    overflow: 'auto',
    zIndex: 0,
    height: '0px',
    bottom: '70px',
    background: 'white',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: '0.2s'
  },
  checkbox: {
    '& .MuiIconButton-root': {
      padding: '0px !important'
    }
  },
  listIcon: {
    minWidth: 'unset',
    marginRight: '10px'
  },
  listItem: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  icon: {
    width: '20px',
    height: '20px',
    transform: 'rotate(90deg)',
    marginRight: '10px'
  },
  muidivider: {
    marginTop: '0.5px'
  }
}))

export interface SelectedItem {
  id: string
  informations: React.ReactNode
}

interface SelectedItemsWithActions {
  items: SelectedItem[]
  onRemoveItem: (itemId: string) => void
  actions: React.ReactNode
  className?: string
  displayList: boolean
}

export const SelectedItemsWithActions = (props: SelectedItemsWithActions) => {
  const { items, actions, onRemoveItem, className, displayList } = props
  const theme = useContext(themeContext)
  const classes = useStyles()
  return (
    <Paper className={`${className} ${classes.root}`}>
      <Box
        flexGrow={1}
        display='flex'
        justifyContent='center'
        position='relative'
        alignItems='center'
        className={classes.listContainer}
      >
        {displayList &&
          <Box className={`${classes.hoverList} hoverList`}>
            <List>
              {items.map((item) => (
                <div key={`selectedItem-${item.id}`}>
                  <ListItem dense>
                    <ListItemIcon className={classes.listIcon}>
                      <CheckBox
                        checked={true}
                        onChange={() => onRemoveItem(item.id)}
                        checkedIconStyle={{color: theme.primaryColor}}
                        className={classes.checkbox}
                      />
                    </ListItemIcon>
                    <ListItemText>{item.informations}</ListItemText>
                  </ListItem>
                  <Divider
                    variant='middle'
                    component='div'
                    className={classes.muidivider}
                  />
                </div>
              ))}
            </List>
          </Box>
        }
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          style={{ background: 'white' }}
          zIndex={1}
        >
          {displayList &&
            <ArrowLeft className={classes.icon}/>
          }
          <Typography variant='body1'>{`${items.length} ligne${
            items.length <= 1 ? '' : 's'
          } sélectionnée${items.length <= 1 ? '' : 's'}`}</Typography>
        </Box>
      </Box>
      <Divider
        variant='middle'
        component='div'
        orientation='vertical'
        style={{ marginRight: '5px', marginLeft: '0px' }}
      />
      {actions}
    </Paper>
  )
}
