import {buildDashboardPoiApiUrl} from "api/configuration";
import {http} from "../../http";
import {PoiDashboardFilter} from "./fetchPoiDashboardStats";

export const getHeatmapData = (
  filter: PoiDashboardFilter
): Promise<Blob | null> => {
  const {
    territoryCode,
    carrierId,
    operatorId,
    startPeriod,
    endPeriod
  } = filter;
  return http.fetchBlob(
    buildDashboardPoiApiUrl("/poidashboard/heatmap"),
    "Une erreur est survenue lors de la récupération de la heatmap.",
    "post",
    JSON.stringify({
      payload: {
        operatorId: operatorId,
        carrierId: carrierId,
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        territoryCode: territoryCode
      }
    }),
    { Accept: "application/octet-stream", "Content-Type": "application/json" }
  )

  // return http.post(
  //   buildDashboardPoiApiUrl("/poidashboard/heatmap"),
  //   JSON.stringify({
  //     payload: {
  //       operatorId: operatorId,
  //       carrierId: carrierId,
  //       startPeriod: startPeriod,
  //       endPeriod: endPeriod,
  //       territoryCode: territoryCode
  //     }
  //   })
  // );
};
