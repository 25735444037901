import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryTypography, Table } from '../../Component/Table'
import { CheckBox } from '../../Component/CheckBox'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import { city as citygeo } from 'plateform-geozone-domain'
import { city as citydel } from 'plateform-campaign-delivery-domain'
import { city as cityDom } from 'plateform-organization-domain'
import { CampaignDeliveryCarrierTable } from './CampaignDeliveryCarriersTable'
import { Button } from '../../Component/Button'
import { CampaignDeliveryReasonTooltip } from './CampaignDeliveryReasonTooltip'

export interface OrganizationRef
  extends cityDom.colisactiv.organization.model.references.OrganizationRef {
  profileImgUrl: string
}
export interface OrganizationType
  extends cityDom.colisactiv.organization.model.OrganizationType {}
export interface BonusPaymentStatus {
  value: string
}

export interface GeoZone
  extends citygeo.colisactiv.platform.geozone.model.GeoZone {}

export interface DeliveryTour
  extends citydel.colisactiv.platform.campaign.delivery.model
    .RejectedDeliveryTour {
  bonusIds: number[]
}

export interface CarrierPackCount
  extends citydel.colisactiv.platform.campaign.delivery.model
    .CarrierPackCount {}

export interface CarrierInfo {
  organization: OrganizationRef
  packCount: number
}

export interface CampaignDeliveryRow {
  tourId: string
  date: string
  area: string
  operator: string
  carriers: CarrierInfo[]
  deliveryCount: number
  packagesCount: number
  duration: string
  bonusIds: number[]
  reason: string
  delivery: DeliveryTour
  bikeCarDistanceRate: number | undefined
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px !important',
      maxWidth: '120px !important'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px !important',
      maxWidth: '120px !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '130px !important',
      maxWidth: '130px !important'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '130px !important',
      maxWidth: '130px !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '160px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '160px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(7n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(7n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(8n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(8n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(9n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(9n)': {
      minWidth: '100px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(10n)': {
      minWidth: '200px !important',
      maxWidth: '250px !important'
    },
    '& .rdt_TableCol:nth-child(10n)': {
      whiteSpace: 'nowrap',
      minWidth: '200px !important',
      maxWidth: '250px !important'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '270px !important',
      maxWidth: '270px !important',
      paddingRight: '0px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '270px !important',
      maxWidth: '270px !important',
      paddingRight: '0px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '80px',
      maxWidth: '80px'
    }
  },
  button: {
    height: '35px !important',
    margin: '10px'
  }
}))

interface CampaignDeliveryTableProps {
  campaignDeliveries: DeliveryTour[]
  totalPages: number
  page: number
  handlePageChange?: (page: number) => void
  isLoading?: boolean
  checkedDeliveries: string[]
  onClickCheckBox: (check: boolean, row: CampaignDeliveryRow) => void
  onChangeGlobalCheck: (globalCheck: boolean) => void
  onClickCarrier: (carrierId: string) => void
  organizationsRef: Map<string, OrganizationRef>
  getDefaultOrganizationLogo: (
    type: OrganizationType | undefined,
    small: boolean
  ) => JSX.Element
  onValidateDelivery: (row: CampaignDeliveryRow) => void
  onRejectDelivery: (row: CampaignDeliveryRow) => void
}

export const toCampaignDeliveryRow = (
  deliveryTour: DeliveryTour,
  organizationsRef: Map<string, OrganizationRef>
): CampaignDeliveryRow => {
  let carriers: CarrierInfo[] = []
  let totalPackages = 0
  deliveryTour.packCountPerCarrier.forEach(
    (carrierPackCount: CarrierPackCount) => {
      const carrier = organizationsRef.get(carrierPackCount.carrierId)
      if (carrier) {
        carriers.push({
          organization: carrier,
          packCount: carrierPackCount.packCount
        })
        totalPackages += carrierPackCount.packCount
      }
    }
  )
  const operator = organizationsRef.get(deliveryTour.operatorId)
  let duration = deliveryTour.duration / (3600 * 1000)
  let stringDuration = ''
  if (duration < 1) {
    duration = Math.round(deliveryTour.duration / (60 * 1000))
    stringDuration = `${duration} min`
  } else {
    stringDuration = `${Math.round(duration)} h`
  }
  return {
    tourId: deliveryTour.tourId,
    date: new Date(deliveryTour.date).toLocaleDateString(),
    area: deliveryTour.area.name,
    operator: operator?.displayName ?? '',
    deliveryCount: deliveryTour.deliveryCount,
    packagesCount: totalPackages,
    carriers: carriers,
    duration: stringDuration,
    reason: deliveryTour.reason,
    bonusIds: deliveryTour.bonusIds,
    delivery: deliveryTour,
    bikeCarDistanceRate: deliveryTour.bikeCarDistanceRate
  }
}

export const CampaignDeliveryTable = (props: CampaignDeliveryTableProps) => {
  const {
    campaignDeliveries,
    checkedDeliveries,
    getDefaultOrganizationLogo,
    onChangeGlobalCheck,
    onClickCarrier,
    onClickCheckBox,
    organizationsRef,
    page,
    totalPages,
    handlePageChange,
    isLoading,
    onRejectDelivery,
    onValidateDelivery
  } = props

  const theme = useContext(themeContext)
  const classes = useStyles()

  const [globalCheck, setGlobalCheck] = useState<boolean>(false)
  const isCheckedDelivery = (
    tourId: string,
    checkedDeliveries: string[]
  ): boolean => {
    return checkedDeliveries.includes(tourId)
  }

  const { t } = useTranslation()
  const data: CampaignDeliveryRow[] = campaignDeliveries.map(
    (delivery): CampaignDeliveryRow => {
      return toCampaignDeliveryRow(delivery, organizationsRef)
    }
  )

  const getAllChecked = (
    campaignDeliveries: DeliveryTour[],
    checkedDeliveries: string[]
  ): boolean => {
    let allChecked = true
    let counter = 0
    if (
      checkedDeliveries.length === 0 ||
      checkedDeliveries.length < campaignDeliveries.length
    ) {
      return false
    }
    while (allChecked && counter < campaignDeliveries.length) {
      allChecked = checkedDeliveries.includes(
        campaignDeliveries[counter].tourId
      )
      counter++
    }
    return allChecked
  }

  useEffect(() => {
    if (campaignDeliveries.length > 0) {
      setGlobalCheck(getAllChecked(campaignDeliveries, checkedDeliveries))
    }
  }, [campaignDeliveries, checkedDeliveries])

  const columns = [
    {
      name: (
        <CheckBox
          checked={globalCheck}
          onChange={() => {
            onChangeGlobalCheck(!globalCheck)
            setGlobalCheck(!globalCheck)
          }}
          checkedIconStyle={{
            color: theme.primaryColor
          }}
        />
      ),
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography align='left'>
          <CheckBox
            onChange={(event) => onClickCheckBox(event.target.checked, row)}
            checked={isCheckedDelivery(row.tourId, checkedDeliveries)}
            checkedIconStyle={{
              color: theme.primaryColor
            }}
          />
        </PrimaryTypography>
      )
    },
    {
      name: 'Date',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.date}
        </PrimaryTypography>
      )
    },
    {
      name: 'Zone Géographique',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.area}
        </PrimaryTypography>
      )
    },
    {
      name: 'Opérateur',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.operator}
        </PrimaryTypography>
      )
    },
    {
      name: 'Nombre de Livraison',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.deliveryCount}
        </PrimaryTypography>
      )
    },
    {
      name: 'Nombre de Colis',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.packagesCount}
        </PrimaryTypography>
      )
    },
    {
      name: 'Durée',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.duration}
        </PrimaryTypography>
      )
    },
    {
      name: 'Ratio distance vélo/voiture',
      cell: (row: CampaignDeliveryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.bikeCarDistanceRate ? row.bikeCarDistanceRate : '-'}
        </PrimaryTypography>
      )
    },
    {
      name: '% de prédicition de l’IA par mode de transport',
      cell: (row: CampaignDeliveryRow) => (
        <CampaignDeliveryReasonTooltip campaignDeliveryRow={row} />
      )
    },
    {
      name: '',
      cell: (row: CampaignDeliveryRow) => (
        <Box display='flex' alignItems='center'>
          <Button
            fail
            variant='outlined'
            onClick={() => onRejectDelivery(row)}
            className={classes.button}
          >
            {t('app_domain_campaign_delivery_reject')}
          </Button>
          <Button
            onClick={() => onValidateDelivery(row)}
            className={classes.button}
          >
            {t('app_domain_campaign_delivery_validate')}
          </Button>
        </Box>
      )
    }
  ]

  return (
    <Table<CampaignDeliveryRow>
      data={data}
      columns={columns}
      className={classes.container}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      page={page}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_campaignDelivery_table')}
      expandableRows={true}
      ExpandableComponents={
        <CampaignDeliveryCarrierTable
          getDefaultOrganizationLogo={getDefaultOrganizationLogo}
          onClickCarrier={onClickCarrier}
        />
      }
      expandOnRowClicked={true}
    />
  )
}
