import React, { useCallback, useMemo, useState } from "react";
import withConnect from "./withConnect";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { BackButton, Button, GeoZoneMap, TerritoryTable } from "components";
import { geoZoneClient, Territory } from "api/geoZone";
import { usePaginedList } from "app/hooks/usePaginedList/usePaginedList";
import { GeoZoneFilters, GeoZoneFiltersModel } from "components/src/Domain/GeoZone";
import { nullToUndefined } from "utils";
import { useTranslation } from "react-i18next";
import { User } from "api/organization/users";
import { useAuth } from "auth/useAuth";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    float: "right",
    marginRight: "30px",
    height: "49.5px !important",
  },
  header: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  mapContainer: {
    paddingRight: '20px',
    position: 'sticky',
    top: 20,
  }
}));

interface GeoZonesProps {
  gotoGeoZoneCreate: () => void;
  gotoGeoZoneList: (
    territoryName?: string,
    territoryId?: string,
    territoryCode?: string,
    geoZoneName?: string,
  ) => void;
  archiveGeoZone: (geoZoneId: string) => void;
  currentUser: User | null;
}

const GeoZones = (props: GeoZonesProps) => {
  const {
    gotoGeoZoneCreate,
    gotoGeoZoneList,
    archiveGeoZone,
    currentUser
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [authService, keycloak] = useAuth();
  const headBar = useMemo(() => ({ title: "Liste des zones personalisées" }), []);
  const [geoJson, setGeoJson] = useState<string>("")
  const authResult = new URLSearchParams(window.location.search);
  const territoryName = nullToUndefined(authResult.get("territoryName"));
  const territoryId = nullToUndefined(authResult.get("territoryId"));
  const territoryCode = nullToUndefined(authResult.get("territoryCode"));
  const geoZoneName = nullToUndefined(authResult.get("geoZoneName"));
  const isColisActivAdmin = useMemo(() => 
    authService.isColisActivAdmin(currentUser, keycloak)
  ,[authService, currentUser, keycloak])

  const [filterValues, setFilterValues] = useState<GeoZoneFiltersModel>({
    territory:
      territoryName && territoryId && territoryCode
        ? {
            code: territoryCode,
            id: territoryId,
            name: territoryName,
            zones: []
          }
        : {
          code: "",
          id: "",
          name: "",
          zones: []
        },
    geoZoneName: geoZoneName ? geoZoneName : "",
  });
  
  const fetchPage = useCallback(
    (page, size) => {
      return geoZoneClient.query.getTerritories(
        page,
        size,
        filterValues.territory?.name ?? ""
      );
    },
    [filterValues]
  );

  const gotoTerritoriesListCallback = useCallback(
    (page, size) =>
      gotoGeoZoneList(page, size, ""),
    [gotoGeoZoneList]
  );

  const [items, isLoading, handlePageChange, fetchItems] = usePaginedList<Territory>(
    [],
    fetchPage,
    gotoTerritoriesListCallback,
    [fetchPage]
  );

  const onClickGeoZone = useCallback(async (geoZoneCode) => {
    const geoLocations = await geoZoneClient.query.getGeoZoneData(geoZoneCode)
    const geoJsonString = `{
      "type": "Feature",
      "properties": {},
      "geometry": {
          "type": "MultiPolygon",
          "coordinates": [${JSON.stringify(geoLocations)}]
      }
    }
    `
    setGeoJson(geoJsonString)
  }, [])

  const handleArchiveGeoZone = useCallback(async (geoZoneId) => {
    await archiveGeoZone(geoZoneId)
    fetchItems()
  }, [fetchItems])

  const onFiltersChange = useCallback(
    (values: GeoZoneFiltersModel) => {
      gotoGeoZoneList(
        values.territory?.name,
        values.territory?.id,
        values.territory?.code,
        values.geoZoneName,
      );
      setFilterValues(values);
    },
    [items.perPage, gotoGeoZoneList, setFilterValues]
  );

  return (
    <PageWithTitle
      headBar={headBar}
      header={
        <Box className={classes.header}>
          <BackButton />
          {isColisActivAdmin && (
            <Button
              className={classes.button}
              onClick={gotoGeoZoneCreate}
            >
              {t("app_geozone_list_new_geozone")}
            </Button>
          )}
        </Box>
      }
      columnSwitchWidth={800}
      switchedHeaderHeight={150}
    >
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GeoZoneFilters
              onFilterChange={onFiltersChange}
              getTerritories={geoZoneClient.query.getTerritories}
              filterValues={filterValues}
            />
            <TerritoryTable
              isLoading={isLoading}
              territories={items.list}
              onClickGeoZone={onClickGeoZone}
              archiveGeoZone={handleArchiveGeoZone}
              geoZoneFilterValue={filterValues.geoZoneName}
              isColisActivAdmin={isColisActivAdmin}
            />
          </Grid>
          <Grid item xs={6}>
            <GeoZoneMap
              geoJson={geoJson}
              mapContainerClassName={classes.mapContainer}
            />
          </Grid>
        </Grid>
      </Box>
    </PageWithTitle>
  );
};

export default withConnect(GeoZones);
