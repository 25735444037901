import { city } from "ca-plateform-plateform-commons";
import { DependencyList, useCallback, useEffect, useState } from "react";
import { getURLParameter } from "api/configuration";

interface PaginedState<T> {
  list: T[];
  totalRows: number;
  page: number;
  perPage: number;
}

type PageChangeHandler = (page: number) => Promise<void>;
type ItemFetcher = () => Promise<void>;

type PaginedList<T> = [
  PaginedState<T>,
  boolean,
  PageChangeHandler,
  ItemFetcher
];

export function usePaginedList<T>(
  arg: T[],
  fetch: (
    page: number,
    size: number
  ) => Promise<city.colisactiv.platform.commons.Page<T>> | null,
  setListURL: (page: number, size: number) => void,
  deps: DependencyList = []
): PaginedList<T> {
  const [state, setState] = useState<PaginedState<T>>({
    list: [],
    page: getURLParameter("page") !== "" ? Number(getURLParameter("page")) : 0,
    perPage:
      getURLParameter("size") !== "" ? Number(getURLParameter("size")) : 10,
    totalRows: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const pageNumber = getURLParameter("page")
      ? Number(getURLParameter("page"))
      : state.page;
    const fetched = fetch(pageNumber, state.perPage);
    if (fetched) {
      setIsLoading(true);
      fetched.then((result) => {
        if (result) {
          setState({
            list: result.list,
            page: pageNumber,
            perPage: result.size,
            totalRows: result.total,
          });
        }
        setIsLoading(false);
      });
    }
  }, [...deps]);

  const handlePageChange = useCallback(
    async (page: number) => {
      setIsLoading(true);
      setListURL(page - 1, state.perPage);
      const fetched = await fetch(page - 1, state.perPage);
      if (fetched) {
        setState({
          list: fetched.list,
          page: page - 1,
          perPage: fetched.size,
          totalRows: fetched.total,
        });
      }
      setIsLoading(false);
    },
    [fetch, state.perPage]
  );

  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    const fetched = await fetch(state.page, state.perPage);
    if (fetched) {
      setState({
        list: fetched.list,
        page: state.page,
        perPage: fetched.size,
        totalRows: fetched.total,
      });
    }
    setIsLoading(false);
  }, [fetch, state.page, state.perPage]);

  return [state, isLoading, handlePageChange, fetchItems];
}
