import {GetPolylineListQuery, GetPolylineListQueryDTO, Polyline} from "..";
import {buildPolylineApiUrl} from "../../configuration";
import {http} from "../../http";

const getPolylineListQuery = (
  dto: GetPolylineListQueryDTO
): GetPolylineListQuery => {
  return {
    type: "GET_POLYLINE_LIST",
    payload: dto,
  };
};

export const fetchPolylineListQuery = (
  query: GetPolylineListQueryDTO
): Promise<string[]> => {
  const url = buildPolylineApiUrl("/polyline");
  const body = JSON.stringify(getPolylineListQuery(query));
  return http.post(url, body);
};
