import { makeStyles } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useCallback, useContext } from 'react'
import { city as cityGeo } from 'ca-plateform-geozone-domain'
import { PrimaryTypography, Table } from '../../Component/Table'
import { useTranslation } from 'react-i18next'
import { GeoZone, GeoZoneTable } from './GeoZoneTable'


export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}

export interface TerritoryRow {
  id: string,
  code: string,
  name: string,
  zones: GeoZone[]
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '80px',
      maxWidth: '500px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '80px',
      maxWidth: '500px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '500px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '500px'
    }
  }
}))

interface TerritoryTableProps {
  isLoading?: boolean
  territories: Territory[]
  onClickGeoZone: (geoZoneCode: string) => void;
  archiveGeoZone: (geoZoneId: string) => void;
  geoZoneFilterValue: string;
  isColisActivAdmin: boolean;
}

export const TerritoryTable = (props: TerritoryTableProps) => {
  const {
    isLoading,
    territories,
    onClickGeoZone,
    archiveGeoZone,
    geoZoneFilterValue,
    isColisActivAdmin
  } = props

  const theme = useContext(themeContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const displayTerritory = useCallback((
    territory: Territory
  ): boolean => {
    const filteredZones = territory.zones.filter((zone: GeoZone) => 
      zone.isArchived == false 
      && zone.isCustom == true 
      && zone.name.includes(geoZoneFilterValue)
    )
    return filteredZones.length !== 0 
  }, [geoZoneFilterValue]) 

  const data: TerritoryRow[] = territories
    .filter(territory => displayTerritory(territory))
    .map((territory): TerritoryRow => {
      return {
        id: territory.id,
        name: territory.name,
        code: territory.code,
        zones: territory.zones
      }
    }
  )

  const handleClickGeoZone = useCallback((geozone: GeoZone) => {
    onClickGeoZone(geozone.code)
  }, [onClickGeoZone])

  const handleArchiveGeoZone = useCallback((geozone: GeoZone) => {
    archiveGeoZone(geozone.id)
  }, [onClickGeoZone])

  const columns = [
    {
      name: 'Nom du territoire',
      cell: (row: TerritoryRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.name}
        </PrimaryTypography>
      )
    }
  ]
  return (
    <Table<TerritoryRow>
      data={data}
      className={classes.container}
      columns={columns}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_territory_table')}
      expandableRows={true}
      ExpandableComponents={
        <GeoZoneTable
          onGeoZoneClicked={handleClickGeoZone}
          onGeoZoneArchiveButtonClicked={handleArchiveGeoZone}
          geoZoneFilterValue={geoZoneFilterValue}
          isColisActivAdmin={isColisActivAdmin}
        />
      }
    />
  )
}
