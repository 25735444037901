import { OrganizationDetailsFormValidity } from "../../../../components/OrganizationDetailsForm/model";
import { PaymentInfoFormValidity } from "../../../../components/PaymentInfoForm/model";
import { OrganizationFormState } from "./model";
import {
  OrganizationDetailsValidation,
  defaultOrganizationDetailsFormValidity,
} from "../../../../components/OrganizationDetailsForm/validation";
import {
  defaultPaymentInfoFormValidity,
  PaymentInfoValidation,
} from "../../../../components/PaymentInfoForm/validation";
import {
  ExportAuthorizationValidation,
  defaultExportAuthorizationFormValidity,
} from "app/components/ExportAuthorizationForm/validation";
import { ExportAuthorizationFormValidity } from "app/components/ExportAuthorizationForm/model";
import { VisitorFormValidity } from "app/components/VisitorForm/model";
import {
  VisitorValidation,
  defaultVisitorFormValidity,
} from "app/components/VisitorForm/validation";

const isOrganizationValid = (
  detailsValidation: OrganizationDetailsFormValidity,
  paymentValidation: PaymentInfoFormValidity,
  showPayment: boolean,
  exportAuthorizationValidation: ExportAuthorizationFormValidity,
  showExportAuthorization: boolean,
  visitorAuthorizationValidation: VisitorFormValidity,
  showVisitorAuthorization: boolean
) => {
  return (
    OrganizationDetailsValidation.isFormValid(detailsValidation) &&
    (PaymentInfoValidation.isFormValid(paymentValidation) || !showPayment) &&
    (ExportAuthorizationValidation.isFormValid(exportAuthorizationValidation) ||
      !showExportAuthorization) &&
    (VisitorValidation.isFormValid(visitorAuthorizationValidation) ||
      !showVisitorAuthorization)
  );
};

export const isOrganizationFormValid = (
  showPayment: boolean,
  showExportAuthorization: boolean,
  showVisitor: boolean,
  validDetails: boolean,
  organizationForm: OrganizationFormState
): OrganizationValidation => {
  const { details, type } = organizationForm.details;
  const { payment, exportAuthorization, visitor } = organizationForm;

  const detailsValidation = validDetails
    ? OrganizationDetailsValidation.validation(details, type)
    : defaultOrganizationDetailsFormValidity;
  const paymentValidation = showPayment
    ? PaymentInfoValidation.validation(payment)
    : defaultPaymentInfoFormValidity;
  const exportAuthorizationValidation = showExportAuthorization
    ? ExportAuthorizationValidation.validation(exportAuthorization)
    : defaultExportAuthorizationFormValidity;
  const visitorValidation = showVisitor
    ? VisitorValidation.validation(visitor)
    : defaultVisitorFormValidity;

  const isValid = isOrganizationValid(
    detailsValidation,
    paymentValidation,
    showPayment,
    exportAuthorizationValidation,
    showExportAuthorization,
    visitorValidation,
    showVisitor
  );

  return {
    details: detailsValidation,
    payment: paymentValidation,
    exportAuthorization: exportAuthorizationValidation,
    visitor: visitorValidation,
    isValid: isValid,
  };
};

export interface OrganizationValidation {
  details: OrganizationDetailsFormValidity;
  payment: PaymentInfoFormValidity;
  exportAuthorization: ExportAuthorizationFormValidity;
  visitor: VisitorFormValidity;
  isValid: boolean;
}
