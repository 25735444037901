import { basicSetup } from "@codemirror/basic-setup";
import { indentWithTab } from "@codemirror/commands";
import { EditorState, Extension } from "@codemirror/state";
import { EditorView, keymap, ViewUpdate } from "@codemirror/view";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { json as jsonLanguage } from "@codemirror/lang-json";
import { useInputStyles } from "../../Theme";
import { themeContext } from '@smartb/archetypes-ui-components'
import { Box, makeStyles } from "@material-ui/core";
import { PrimaryTypography } from "../..";
import { InfoBox } from "../InfoBox";

const useStyles = makeStyles(() => ({
  helperText: {
    fontSize: '0.75rem',
    color: '#f44336',
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    fontWeight: 400
  },
  errorBox: {
    border: "1px solid #f44336",
  },
  infoIcon: {
    width: '15px',
    height: '15px',
    margin: '5px'
  },
}))

interface JsonEditorProps {
  label: string;
  json?: string;
  onUpdate?: (geoJson: string) => void;
  className?: string;
  error: boolean;
  errorMessage: string;
  tooltipMessage: string;
}

export const JsonEditor = (props: JsonEditorProps) => {
  const { 
    error, 
    errorMessage, 
    label, 
    json, 
    onUpdate, 
    className,
    tooltipMessage
  } = props;

  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, false)()
  const localClasses = useStyles()
  const editorRef = useRef(null);

  const handleUpdate = useCallback((viewUpdate: ViewUpdate) => {
    if (viewUpdate.docChanged && onUpdate) {
      onUpdate(viewUpdate.state.doc.toString())
    }
  }, [onUpdate])

  useEffect(() => {
    const currentEditor = editorRef.current as Exclude<typeof editorRef["current"], null>;
    if (currentEditor) {
      const extensions: Extension[] = [basicSetup, keymap.of([indentWithTab]), jsonLanguage()];

      extensions.push(EditorView.updateListener.of(handleUpdate));

      const state = EditorState.create({
        doc: json,
        extensions,
      });

      const view = new EditorView({ state, parent: currentEditor });
      
      return () => view.destroy();
    }
  }, [editorRef.current, handleUpdate]);

  return (
  <>
    <Box className={classes.label}>
      {label}
      <InfoBox
        className={localClasses.infoIcon}
        content={
          <PrimaryTypography>
            {tooltipMessage}
          </PrimaryTypography>
        }
      />
    </Box>
    <div className={`${className} ${error?localClasses.errorBox:""}`} ref={editorRef} />
    {error && (
      <p className={localClasses.helperText}>{errorMessage}</p>
    )}
  </>
  );
};
