import { connect } from "react-redux";
import { router } from "store/router";
import { profile } from "store/profile";
import { organization } from "store/organization";
import { State } from "store";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  organizationRefs: global.selectors.organizationRefs(state),
  user: profile.selectors.User(state),
});

const mapDispatchToProps = {
  gotoUserEdit: router.goto.user.userEdit,
  gotoUserView: router.goto.user.usersView,
  fetchOrganization: organization.queries.fetchOrganization,
  fetchUser: profile.queries.fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps);
