import {archiveCampaignCommand} from "./command/archiveCampaignCommand";
import {createCampaignCommand} from "./command/createCampaignCommand";
import {createProjectCommand} from "./command/createProjectCommand";
import {pauseCampaignCommand} from "./command/pauseCampaignCommand";
import {publishCampaignCommand} from "./command/publishCampaignCommand";
import {removeCampaignCommand} from "./command/removeCampaign";
import {updateCampaignCommand} from "./command/updateCampaignCommand";
import {updateProjectCommand} from "./command/updateProjectCommand";
import {getTerritoriesOfAllProjects} from "./query/getTerritoriesOfAllProjects";
import {getProject} from "./query/getProjectQuery";
import {fetchProjectsPage} from "./query/getProjectsPageQuery";
import {fetchCampaigns} from "./query/getAllCampaignsQuery";
import {duplicateCampaignCommand} from "./command/duplicateCampaign";
import {fetchCampaignsFiltered} from "./query/getCampaignsFiltered";
import {getTerritoriesOfAllUnfinishedProjects} from "./query/getTerritoriesOfAllUnfinishedProjects";
import {startCampaignCommand} from "./command/startCampaignCommand";
import { updateCampaignNotificationCommand } from "./command/updateCampaignNotificationCommand";

export const projectClient = {
  query: {
    getProject: getProject,
    getProjectsPage: fetchProjectsPage,
    getTerritoriesOfAllProjects: getTerritoriesOfAllProjects,
    getTerritoriesOfAllUnfinishedProjects: getTerritoriesOfAllUnfinishedProjects,
    fetchCampaigns: fetchCampaigns,
    fetchCampaignsFiltered: fetchCampaignsFiltered,
  },
  createProject: createProjectCommand,
  updateProject: updateProjectCommand,
  createCampaign: createCampaignCommand,
  updateCampaign: updateCampaignCommand,
  removeCampaign: removeCampaignCommand,
  publishCampaign: publishCampaignCommand,
  archiveCampaign: archiveCampaignCommand,
  pauseCampaign: pauseCampaignCommand,
  startCampaign: startCampaignCommand,
  duplicateCampaign: duplicateCampaignCommand,
  updateCampaignNotification: updateCampaignNotificationCommand,
};
