import { Box, makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { InputForm, JsonEditor } from "components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import GeoZoneFormTerritoryInput from "./GeoZoneFormTerritoryInput";
import { GeoZoneFormDetailsValidity, GeoZoneFormDetailsState } from "./model";
import { ViewUpdate } from "@codemirror/view";

const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
    paddingBottom: 20
  },
  inputForm: {
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "start"
  },
  test: {
    width:'10px'
  },
  title: {
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
  },
  jsonEditor: {
    minHeight: "300px",
    maxHeight: "300px",
    overflowY: "auto",
  },
  territoryHelperText: {
    top: 50,
    marginLeft: 0
  }
}));

interface GeoZoneFormProps {
  geoZoneDetails: GeoZoneFormDetailsState;
  onChange: (property: keyof GeoZoneFormDetailsState, value: any) => void;
  validity: GeoZoneFormDetailsValidity;
}

const GeoZoneForm = (props: GeoZoneFormProps) => {
  const { t } = useTranslation();
  const { geoZoneDetails, onChange, validity } = props;

  const classes = useStyles();

  const handleGeoZoneNameChange = useCallback(
    (geoZoneName: string) => {
      onChange("geoZoneName", geoZoneName);
    },
    [onChange]
  );

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const newTerritory =
        Array.isArray(territory) && territory.length === 0
          ? undefined
          : territory;
      onChange("territory", newTerritory);
    },
    [onChange]
  );

  const handleGeoJsonChange = useCallback(
    (geoJson: string) => {
      onChange("geoJson", geoJson);
    },
    [onChange]
  );

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <InputForm
          value={geoZoneDetails.geoZoneName}
          onChange={handleGeoZoneNameChange}
          error={!validity.geoZoneName}
          inputClassName={classes.input}
          id="geozone-add-name"
          errorMessage={t(
            "app_create_geozone_invalid_name"
          )}
          inputType="textField"
          label={t("app_create_geozone_name")}
          className={classes.inputForm}
        />
        <GeoZoneFormTerritoryInput
          label={t("app_create_geozone_territory")}
          value={geoZoneDetails.territory}
          onChange={handleTerritoryChange}
          className={classes.input}
          inputClassName={classes.input}
          error={!validity.territory}
          errorMessage={t("app_create_geozone_invalid_territory")}
          helperTextClassName={classes.territoryHelperText}
        />
        <JsonEditor
          error={!validity.geoJson}
          errorMessage={t("app_create_geozone_invalid_geojson")}
          label={t("app_create_geozone_geojson")}
          json={geoZoneDetails.geoJson}
          onUpdate={handleGeoJsonChange}
          className={classes.jsonEditor}
          tooltipMessage={t("app_create_geozone_geojson_tooltip")}
        />
      </Box>
    </>
  );
};

export default GeoZoneForm;
