import i18n from "i18next";
import { BonusGroupLogType } from "..";
import { BonusGroupStatusUtils } from "./bonusStatus";
import { BonusGroupStatus } from "api/bonus";

const getLogTypeLabel = (
  logType: BonusGroupLogType,
  status: BonusGroupStatus | undefined = undefined
): string => {
  switch (logType) {
    case "IMPORT_DELIVERIES":
      return i18n.t("app_bonus_view_log_type_import_deliveries");
    case "CREATE_CARRIER_REPORT":
      return i18n.t("app_bonus_view_log_type_create_carrier_report");
    case "EDIT_CARRIER_REPORT":
      return i18n.t("app_bonus_view_log_type_edit_carrier_report");
    case "UPLOAD_INVOICE":
      return i18n.t("app_bonus_view_log_type_upload_invoice");
    case "EDIT_INVOICE":
      return i18n.t("app_bonus_view_log_type_edit_invoice");
    case "EDIT_STATUS":
      return (
        i18n.t("app_bonus_view_log_type_edit_status") +
        " : " +
        BonusGroupStatusUtils.getLabelStyleStatusTags(
          status as BonusGroupStatus
        ).label
      );
    default:
      return "";
  }
};

export const BonusGroupLogUtils = {
  getLogTypeLabel: getLogTypeLabel,
};
