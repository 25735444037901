import { Dispatch } from "redux";
import { actions } from "./global.actions";
import { State } from "store";
import { organizationClient } from "api/organization";
import { projectClient } from "api/project";
import {dashboardClient} from "../../api/dashboard";

export const fetchCampaigns = (force: boolean) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().global.campaigns === null || force) {
      dispatch(actions.fetchCampaigns());
      try {
        const campaigns = await projectClient.query.fetchCampaigns();
        dispatch(actions.fetchedCampaigns(campaigns || []));
      } catch (error) {
        console.log(error);
        // dispatch(addTodo.error(error))
      }
    }
  };
};

export const fetchProjectTerritories = (force: boolean) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().global.projectTerritories === null || force) {
      dispatch(actions.fetchProjectTerritories());
      try {
        const allTerritories = await projectClient.query.getTerritoriesOfAllProjects();
        dispatch(actions.fetchedProjectTerritories(allTerritories || []));
      } catch (error) {
        console.log(error);
        // dispatch(addTodo.error(error))
      }
    }
  };
};

export const fetchOrganizationRefs = (force: boolean) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().global.organizationRefs === null || force) {
      dispatch(actions.fetchOrganizationRefs());
      try {
        const organizationRefs = await organizationClient.query.fetchOrganizationRefs();

        dispatch(actions.fetchedOrganizationRefs(organizationRefs || null));
      } catch (error) {
        // dispatch(addTodo.error(error))
      }
    }
  };
};

export const fetchDashboards = (force: boolean) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().global.dashboards === null || force) {
      dispatch(actions.fetchDashboards());
      try {
        const dashboards = await dashboardClient.query.getDashboardsQuery();
        dispatch(actions.fetchedDashboards(dashboards || null));
      } catch (error) {
        // dispatch(addTodo.error(error))
      }
    }
  };
};

export const queries = {
  fetchOrganizationRefs: fetchOrganizationRefs,
  fetchCampaigns: fetchCampaigns,
  fetchProjectTerritories: fetchProjectTerritories,
  fetchDashboards: fetchDashboards
};
