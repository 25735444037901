import { connect } from "react-redux";
import { State } from "../../../store";
import { profile } from "store/profile";
import { router } from "store/router";
import { global } from "store/global";
import { organization } from "store/organization";

const mapStateToProps = (state: State) => ({
  operatorRefs: global.selectors.operatorRefs(state),
  currentUser: profile.selectors.User(state),
  organization: organization.selectors.organization(state),
});

const mapDispatchToProps = {
  goToFlux: router.goto.flux,
  fetchOrganization: organization.queries.fetchOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps);
