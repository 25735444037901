import { Box, makeStyles } from "@material-ui/core";
import { OrganizationRef } from "api/organization";
import { ProjectStats, CampaignFunder } from "api/project/model";
import { DeleteButton, InputForm, Option } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignFunderValidity } from "./Model";

const useStyles = makeStyles(() => ({
  inputForm: {
    flexDirection: "column",
    marginTop: "10px",
    justifyContent: "start",
    alignItems: "start",
    width: "100%",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: 50,
    maxWidth: "700px",
    minWidth: "500px",
  },
  zone: {
    display: "flex",
    flexDirection: "column",
  },
  containerSelectStyle: {
    display: "flex",
    flexDirection: "column",
  },
  selectStyle: {
    justifyContent: "center",
    width: "100% ",
    marginTop: 11,
    minHeight: "2.1rem",
  },
  itemsWidth: {
    width: "50%",
    marginRight: 20,
  },
  deletebutton: {
    margin: "auto",
    paddingTop: "36px",
  },
  zoneButton: {
    marginTop: "36px",
    height: "50px",
    width: "100%",
  },
}));

interface CampaignFunderRowProps {
  project: ProjectStats | null;
  campaignFunder: CampaignFunder;
  position: number;
  campaignFunderLength: number;
  campaignFunderValidation: CampaignFunderValidity;
  onCampaignFunderDeleted: (position: number) => void;
  onChangeCampaignFunder: (
    typeInput: keyof CampaignFunder,
    position: number,
    value: any
  ) => void;
  funderRefs: OrganizationRef[];
  alreadyAddedFunders: string[];
  readOnly: boolean;
}

const CampaignFunderRow = (props: CampaignFunderRowProps) => {
  const {
    project,
    campaignFunder,
    position,
    readOnly,
    funderRefs,
    campaignFunderLength,
    campaignFunderValidation,
    alreadyAddedFunders,
    onCampaignFunderDeleted,
    onChangeCampaignFunder,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [funderOption, setFunderOption] = useState<Option[]>([]);

  const onChangeFunderId = useCallback(
    (funderId: string) =>
      onChangeCampaignFunder("funderId", position, funderId),
    [position, onChangeCampaignFunder]
  );

  const onChangeFunderBudgetPercentage = useCallback(
    (budget: string) =>
      onChangeCampaignFunder("budgetPercentage", position, Number(budget)),
    [position, onChangeCampaignFunder]
  );

  useEffect(() => {
    if (project) {
      const allFunderOption: Option[] = funderRefs.map(
        (it: any) => ({
          label: it.displayName,
          value: it.organizationId,
        })
      );
      const projectFunders: string[] = project.funders.map(
        (funder: any) => funder.funderId
      );
      const fundersOption = allFunderOption.filter(
        (el) =>
          projectFunders.includes(el.value.toString()) &&
          !alreadyAddedFunders.includes(el.value.toString())
      );
      setFunderOption(fundersOption);
    }
  }, [project, funderRefs, alreadyAddedFunders]);

  return (
    <Box className={classes.box}>
      <div className={classes.itemsWidth}>
        <InputForm
          id={`camapginFunder-funderName-${position}`}
          value={campaignFunder.funderId}
          inputType="select"
          label={t("app_campaign_form_campaign_form_row_funder")}
          textFieldType="text"
          selectOptions={funderOption}
          onChange={onChangeFunderId}
          className={classes.inputForm}
          error={!campaignFunderValidation.funderId}
          errorMessage={t("app_campaign_form_campaign_form_row_funder_error")}
          readonly={readOnly}
        />
      </div>
      <div className={classes.itemsWidth}>
        <InputForm
          id={`camapginFunder-budgetPercentage-${position}`}
          value={campaignFunder.budgetPercentage}
          inputType="textField"
          label={t("app_campaign_form_campaign_form_row_budget_percantage")}
          textFieldType="number"
          onChange={onChangeFunderBudgetPercentage}
          className={classes.inputForm}
          error={!campaignFunderValidation.budgetPercentage}
          errorMessage={t(
            "app_campaign_form_campaign_form_row_budget_percantage_error"
          )}
          readonly={readOnly}
          inputIcon={"%"}
          iconPosition="end"
        />
      </div>
      {campaignFunderLength !== 1 && !readOnly && (
        <DeleteButton
          className={classes.deletebutton}
          onClick={() => onCampaignFunderDeleted(position)}
        />
      )}
    </Box>
  );
};

export default CampaignFunderRow;
