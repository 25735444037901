import i18n from "i18n";
import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../../configuration";
import { UserDetails } from "../index";
import { http } from "../../../http";

export interface OrganizationAddUserCommand<DETAILS>
  extends city.colisactiv.organization.model.features
    .OrganizationAddUserCommand {}

export interface LogoCreatedEvent
  extends city.colisactiv.organization.model.features.LogoCreatedEvent {}

export interface UserAddedEvent
  extends city.colisactiv.organization.model.features.UserAddedEvent {}

export const createUser = (
  organizationId: string,
  details: UserDetails
): Promise<UserAddedEvent> => {
  return http.post(
    buildOrganizationApiUrl(`/organizations/${organizationId}/users`),
    JSON.stringify(details),
    i18n.t("app_organization_add_user_success"),
    i18n.t("app_organization_add_user_error", { email: details.email }),
    false
  );
};
