import { city } from "ca-plateform-organization-domain";
import { UserDetails } from "../index";
import { buildOrganizationApiUrl } from "../../../configuration";
import { http } from "../../../http";

export interface OrganizationUpdateUserDetailsCommand
  extends city.colisactiv.organization.model.features
    .OrganizationUpdateUserDetailsCommand {}

export interface UserUpdatedEvent
  extends city.colisactiv.organization.model.features.UserAddedEvent {}

export const organizationUpdateUserDetailsCommand = (
  organizationId: string,
  details: UserDetails
): OrganizationUpdateUserDetailsCommand => {
  return {
    type: "UPDATE_USER",
    payload: {
      organizationId: organizationId,
      userDetails: details,
    },
  };
};

export const updateUser = (
  organizationId: string,
  details: UserDetails,
  userId: string
): Promise<UserUpdatedEvent> => {
  return http.put(
    buildOrganizationApiUrl(`/organizations/${organizationId}/users/${userId}`),
    JSON.stringify(
      organizationUpdateUserDetailsCommand(organizationId, details)
    )
  );
};
