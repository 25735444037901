import { city } from 'plateform-organization-domain'
import {
  Box,
  makeStyles,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  PrimaryTypography,
  SecondaryTypography,
  Table
} from '../../Component/Table'
import React, { ReactElement } from 'react'
import { MailOutline, Call, FileCopyOutlined } from '@material-ui/icons'
import { MoreOptions } from '../../Component/MoreOptions'
import * as R from 'ramda'
import { MenuItem } from '@smartb/archetypes-ui-layout'
import { StatusCheckbox } from '../../Component/StatusCheckbox'
import { useTranslation } from 'react-i18next'
import { Territory } from '../GeoZone'
import {
  Organization,
  OrganizationTypeValues
} from 'web-admin/src/api/organization'

export interface UserRow {
  id: string
  firstname: string
  lastname: string
  phonenumber: String
  email: String
  role: String
  status: string
  territories: Territory[]
  imgSource?: JSX.Element
  onCopy: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  options?: MenuItem[]
}
interface User extends city.colisactiv.organization.model.user.User {}

interface UserTableProps {
  organization: Organization | null
  users: User[]
  onRowClicked?: (row: UserRow) => void
  totalPages: number
  page: number
  handlePageChange: (page: number) => void
  isLoading?: boolean
  getUserImage?: (user: User) => JSX.Element
  onUserStatusChange?: (userId: string) => void
  handleSelectedOption?: (userId: string) => void
  getUserOptions?: (user: User) => MenuItem[]
}

const propsAreEqual = (
  prevProps: UserTableProps,
  nextProps: UserTableProps
) => {
  return (
    R.equals(prevProps.users, nextProps.users) &&
    R.equals(prevProps.handlePageChange, nextProps.handlePageChange) &&
    R.equals(prevProps.isLoading, nextProps.isLoading) &&
    R.equals(prevProps.totalPages, nextProps.totalPages) &&
    R.equals(prevProps.page, nextProps.page) &&
    R.equals(prevProps.onRowClicked, nextProps.onRowClicked) &&
    R.equals(prevProps.getUserImage, nextProps.getUserImage) &&
    R.equals(prevProps.onUserStatusChange, nextProps.onUserStatusChange) &&
    R.equals(prevProps.handleSelectedOption, nextProps.handleSelectedOption) &&
    R.equals(prevProps.getUserOptions, nextProps.getUserOptions)
  )
}

const useStyles = makeStyles(() => ({
  button: {
    marginBottom: '20px'
  },
  contactContainer: {
    minWidth: '200px',
    height: '100%'
  },
  icon: {
    color: '#9a9a9a',
    width: '18px',
    height: '18px'
  },
  mail: {
    maxWidth: '157px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: 5
  },
  contactIcon: {
    marginRight: '10px'
  },
  copyIcon: {
    position: 'absolute',
    top: '-12px',
    right: '-15px'
  },
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '240px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '240px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '110px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '110px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      maxWidth: '60px'
    },
    '& .rdt_TableCol:last-child': {
      maxWidth: '60px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '220px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '220px'
    }
  },
  tooltip: {
    background: 'white',
    border: 'solid 1px #C5C7D0',
    color: '#353945'
  },
  arrow: {
    color: '#C5C7D0'
  },
  internText: {
    fontSize: '12px',
    color: '#828282'
  }
}))

export const UserTable = React.memo((props: UserTableProps) => {
  const {
    organization,
    users,
    onRowClicked,
    handlePageChange,
    page,
    totalPages,
    isLoading = false,
    getUserImage,
    handleSelectedOption,
    getUserOptions
  } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const shouldShowTerritories = (): Boolean => {
    const type = organization?.organizationType.type
    return (
      type == OrganizationTypeValues.CARRIER.type ||
      type == OrganizationTypeValues.OPERATOR.type
    )
  }

  const data: UserRow[] = users.map((user): UserRow => {
    return {
      id: user.userId,
      firstname: user.details.firstname,
      lastname: user.details.lastname,
      email: user.details.email,
      phonenumber: user.details.phoneNumber,
      role: getFrenchRole(user.details.role),
      status: user.status.value,
      territories: user.details.territories,
      imgSource: getUserImage && getUserImage(user),
      onCopy: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        navigator.clipboard.writeText(user.details.email)
      },
      options: getUserOptions && getUserOptions(user)
    }
  })

  const getTerritories = (row: UserRow): ReactElement => {
    const territoryNames = row.territories.map((t) => t.name)
    const text =
      territoryNames.length > 0
        ? territoryNames.length > 1
          ? `${territoryNames[0]}, `
          : territoryNames[0]
        : t('app_user_default_territory')
    const extraText =
      territoryNames.length > 0
        ? territoryNames.length > 1
          ? `+${territoryNames.length - 1} `
          : ''
        : t('app_user_default_territory_detail')
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        placement='top'
        title={
          territoryNames.length > 1 ? (
            <Box display='flex' flexDirection='column'>
              {territoryNames.map((name) => (
                <Typography variant='body2' className={classes.internText}>
                  {name}
                </Typography>
              ))}
            </Box>
          ) : (
            ''
          )
        }
      >
        <Box>
          <PrimaryTypography style={{ display: 'inline' }}>
            {text}
          </PrimaryTypography>

          <Typography
            style={{ fontStyle: 'italic', display: 'inline', color: '#9a9a9a' }}
          >
            {extraText}
          </Typography>
        </Box>
      </Tooltip>
    )
  }

  const getStatus = (row: UserRow): JSX.Element => {
    const isValidStatus = row.status === 'enabled'
    const severity = isValidStatus ? 'success' : 'warning'
    const text =
      row.status == 'created'
        ? 'En attente'
        : row.status == 'enabled'
        ? 'Validé'
        : 'Désactivé'
    return (
      <StatusCheckbox
        data-tag='___react-data-table-allow-propagation___'
        checked={isValidStatus}
        severity={severity}
        text={text}
      />
    )
  }

  const columns = [
    {
      name: `${t('app_domain_user_first_name_last_name')} `,
      cell: (row: UserRow) => (
        <Box
          display='flex'
          data-tag='___react-data-table-allow-propagation___'
          justifyContent='space-around'
          alignItems='center'
        >
          <Box
            width='50px'
            data-tag='___react-data-table-allow-propagation___'
            marginRight='10px'
          >
            {row.imgSource}
          </Box>
          <PrimaryTypography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
            style={{ marginLeft: '10px' }}
          >
            {row.firstname + ' ' + row.lastname}
          </PrimaryTypography>
        </Box>
      )
    },
    {
      name: `${t('app_domain_user_info')} `,
      cell: (row: UserRow) => (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className={classes.contactContainer}
          data-tag='___react-data-table-allow-propagation___'
        >
          <Box
            data-tag='___react-data-table-allow-propagation___'
            display='flex'
            flexDirection='row'
            position='relative'
          >
            <MailOutline
              data-tag='___react-data-table-allow-propagation___'
              className={`${classes.icon} ${classes.contactIcon}`}
            />
            <SecondaryTypography
              data-tag='___react-data-table-allow-propagation___'
              align='left'
              className={classes.mail}
            >
              {row.email}
            </SecondaryTypography>
            <IconButton onClick={row.onCopy} className={classes.copyIcon}>
              <FileCopyOutlined className={classes.icon} />
            </IconButton>
          </Box>
          {row.phonenumber !== '' && (
            <Box
              data-tag='___react-data-table-allow-propagation___'
              display='flex'
              flexDirection='row'
            >
              <Call
                data-tag='___react-data-table-allow-propagation___'
                className={`${classes.icon} ${classes.contactIcon}`}
              />
              <SecondaryTypography
                data-tag='___react-data-table-allow-propagation___'
                align='left'
              >
                {row.phonenumber}
              </SecondaryTypography>
            </Box>
          )}
        </Box>
      )
    },
    {
      name: `${t('app_domain_user_role')} `,
      cell: (row: UserRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='center'
        >
          {row.role}
        </PrimaryTypography>
      )
    },
    ...(shouldShowTerritories()
      ? [
          {
            name: `${t('app_domain_user_territories')} `,
            cell: (row: UserRow) => getTerritories(row)
          }
        ]
      : []),
    {
      name: `${t('app_domain_status')} `,
      cell: (row: UserRow) => getStatus(row)
    },
    {
      name: `${t('app_domain_action')} `,
      cell: (row: UserRow) => (
        <Box>
          <MoreOptions
            handleClose={() => {
              handleSelectedOption && handleSelectedOption(row.id)
            }}
            options={row.options ? row.options : []}
          />
        </Box>
      )
    }
  ]
  return (
    <Table<UserRow>
      className={classes.container}
      data={data}
      columns={columns}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      page={page}
      totalPages={totalPages}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_user_table')}
    />
  )
}, propsAreEqual)

const getFrenchRole = (role: string): string => {
  switch (role) {
    case 'user':
      return 'Utilisateur'
    case 'admin':
      return 'administrateur'
    default:
      return 'Utilisateur'
  }
}
