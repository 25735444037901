import React, { useCallback, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PeriodPicker, PopUpConfirmation, PrimaryTypography } from '../..'
import { DateInterval } from '../../Domain/BonusPayment'

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 200,
    maxWidth: 200
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}))


export interface PeriodPickerPopupProps {
  open: boolean;
  title: string;
  body: string;
  onClose: () => void
  onConfirm: (period: DateInterval) => Promise<void>
}

export const PeriodPickerPopup = (props: PeriodPickerPopupProps) => {
  const { open, title, body, onClose, onConfirm } = props
  const classes = useStyles()
  const [selectedPeriod, setSelectedPeriod] = useState<DateInterval | undefined>(undefined)
  const [isLoading, setLoading] = useState(false)

  const handlePeriodChange = useCallback((period: DateInterval | undefined) => {
    setSelectedPeriod(period)
  }, [])

  const handleConfirm = useCallback(async () => {
    if (!selectedPeriod) return;
    setLoading(true)
    await onConfirm(selectedPeriod)
    setLoading(false)
  }, [selectedPeriod, onConfirm])

  return (
    <PopUpConfirmation
      title={title}
      onClose={onClose}
      onConfirm={handleConfirm}
      open={open}
      isConfirmLoading={isLoading}
    >
      <Box className={classes.container}>
        <PrimaryTypography>
          {body}
        </PrimaryTypography>
        <PeriodPicker
          id='filterPeriod'
          placeholder={'Période'}
          period={selectedPeriod}
          onDateChange={handlePeriodChange}
          inputClassName={classes.input}
          clearable
        />
      </Box>
    </PopUpConfirmation>
  )
}

export default PeriodPickerPopup
