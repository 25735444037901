import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Card, Typography, makeStyles } from "@material-ui/core";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { Button, HeadBarProps, InputForm, Section } from "components";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ReactDropzone, { FileRejection } from "react-dropzone";
import { Delete } from "@material-ui/icons";
import { tourClient } from "api/tour";
import withConnect from "./withConnect";
import DataTable from "react-data-table-component";

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    section: {
      padding: "30px",
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
    },
    form: {
      display: "flex",
      alignContent: "flex-start",
      marginBottom: "20px",
    },
    fileContainer: {
      marginLeft: "40px",
    },
    container: {
      borderTop: "1px solid gray",
      padding: "30px 0",
    },
    title: {
      fontSize: "17px",
      marginTop: "20px",
      marginBottom: "20px",
      marginLeft: "2px",
      lineHeight: 1.4,
      fontWeight: 600,
    },
    fileInput: {
      width: "300px",
    },
    file: {
      display: "flex",
      alignItems: "center",
    },
    addIcon: {
      width: "15px",
      height: "15px",
      color: theme.secondaryColor,
      marginRight: "5px",
    },
    error: {
      color: "#bd1313",
      fontSize: "12px",
    },
    success: {
      color: "#4caf50",
      fontSize: "12px",
    },
    fileButton: {
      width: "fit-content",
      height: "48px",
      padding: "5px 9px",
      marginRight: "10px",
      border: `1px solid ${theme.secondaryColor}`,
      fontSize: "12px",
      borderRadius: "4px",
      textTransform: "none",
      "& span": {
        color: theme.secondaryColor,
      },
    },
    errorMessage: {
      color: "#EC8A90",
      fontSize: "12px",
      marginBottom: "15px",
    },
    description: {
      color: "rgba(0,0,0,0.5)",
      fontStyle: "italic",
      fontSize: "11px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      marginBottom: "15px",
    },
    deleteIcon: {
      cursor: "pointer",
      width: "25px",
      height: "25px",
      marginLeft: "7px",
      stroke: "#9a9a9a",
      color: "#9a9a9a",
      "&:hover": {
        stroke: "#005ecc",
        color: "#005ecc",
      },
    },
    validateButton: {
      width: "fit-content",
    },
    resultContainer: {
      marginTop: "20px",
    },
    resultCard: {
      width: "fit-content",
    },
  }));

interface ToursImportPageProps {
  currentUser: User | null;
  organizationRefs: Map<string, OrganizationRef>;
  setHeadBar: (headBar: HeadBarProps) => void;
}

interface Result {
  originalFileName: string;
  organizationName: string;
  response: string;
}

const ImportTours = (props: ToursImportPageProps) => {
  const { currentUser, organizationRefs, setHeadBar } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();
  const { t } = useTranslation();
  const [organizationId, setOrganizationId] = useState<string | undefined>();
  const [file, setFile] = useState<File | undefined>();
  const [validity, setValidity] = useState(false);
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<Result>();

  const getOrganizations = useMemo(
    () =>
      Array.from(organizationRefs.values()).map((el) => {
        return {
          label: el.displayName,
          value: el.organizationId,
        };
      }),
    [organizationRefs]
  );

  useEffect(() => {
    setHeadBar({
      title: t("app_admin_layout_import"),
    });
  }, [setHeadBar, t]);

  useEffect(() => {
    setValidity(!(!file || !organizationId));
  }, [file, organizationId]);

  const handleOrganizationChange = useCallback((organizationId?: string) => {
    setOrganizationId(organizationId);
  }, []);

  const onFileAdded = useCallback((file: File) => {
    setFile(file)
    setError(undefined)
  }, []);

  const onReject = (files: FileRejection[]) => {
    setError("Une erreur est intervenue")
    files.forEach((file) => {
      let errors = []
      if (file.errors[0].code === "file-too-large") {
        errors.push("La taille de votre fichier ne doit pas dépasser 30 Mo")
      }
      if (file.errors[0].code === "too-many-files") {
        errors.push("Un seul fichier à la fois")
      }
      if (file.errors[0].code === "file-invalid-type") {
        errors.push("Seuls les fichiers au format csv sont acceptés")
      }
      setError(errors.join(", "))
    });
  };

  const onRemoveFile = useCallback(() => {
    setFile(undefined);
  }, []);

  const importFile = useCallback(() => {
    if (!file || !organizationId) return;
    file.arrayBuffer().then((arrayBuffer) => {
      tourClient
        .uploadToursAndGenerateEmptyTraceGps(organizationId, arrayBuffer)
        .then((response) => {
          setResult({
            originalFileName: file.name,
            organizationName:
              getOrganizations.find((o) => o.value === organizationId)?.label ||
              "",
            response: response,
          });
          setFile(undefined);
        });
    });
  }, [file]);

  const columns = useMemo(() => {
    return [
      {
        name: t("app_import_original_filename"),
        width: "200px",
        cell: (result: Result) => <p>{result.originalFileName}</p>,
      },
      {
        name: t("app_import_organization"),
        width: "200px",
        cell: (result: Result) => <p>{result.organizationName}</p>,
      },
      {
        name: t("app_import_response"),
        width: "400px",
        cell: (result: Result) => <p>{result.response}</p>,
      },
    ];
  }, []);

  return (
    <Section>
      <Box className={classes.section}>
        <Box className={classes.form}>
          <Box>
            <Typography variant="h5" className={classes.title}>
              {t("app_import_organization")}
            </Typography>
            <InputForm
              inputType={"select"}
              onChange={handleOrganizationChange}
              selectOptions={getOrganizations}
              value={organizationId}
              onRemoveValue={() => handleOrganizationChange(undefined)}
              label={""}
              placeHolder={"Organisation"}
              className={classes.fileInput}
              id={"importFileInput"}
            />
          </Box>
          <Box className={classes.fileContainer}>
            <Typography variant="h5" className={classes.title}>
              {t("app_import_csv")}
            </Typography>
            <ReactDropzone
              onDropAccepted={(files) => {
                onFileAdded(files[0]);
              }}
              onDropRejected={(fileRejections) => {
                onReject(fileRejections);
              }}
              multiple={false}
              accept={"text/csv"}
              maxSize={30000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={classes.buttonContainer} {...getRootProps()}>
                  <Button
                    onClick={() => {}}
                    className={classes.fileButton}
                    icon={<Add className={classes.addIcon} />}
                    variant="outlined"
                  >
                    {t("app_bonus_add")}
                    <input {...getInputProps()} />
                  </Button>
                  <Box>
                    {!validity && error && (
                      <Typography variant="body2" className={classes.error}>
                        {error}
                      </Typography>
                    )}
                    {file && (
                      <Box className={classes.file}>
                        {file.name}
                        <Delete
                          className={classes.deleteIcon}
                          onClick={(event) => {
                            event.stopPropagation();
                            onRemoveFile();
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </div>
              )}
            </ReactDropzone>
          </Box>
        </Box>
        <Button
          className={classes.validateButton}
          onClick={importFile}
          disabled={!validity}
        >
          Valider
        </Button>
        {result && (
          <Box className={classes.resultContainer}>
            <Typography variant="h5" className={classes.title}>
              {t("app_import_result")}
            </Typography>
            <Card className={classes.resultCard}>
              <DataTable
                columns={columns}
                data={[result]}
                noDataComponent={<p style={{ padding: "10px" }}></p>}
                noHeader
                theme="colisactiv"
              />
            </Card>
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default withConnect(ImportTours);
