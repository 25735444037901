import { connect } from "react-redux";
import { global } from "store/global";
import { State } from "../../../store";
import { profile } from "store/profile";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
  organizationRefs: global.selectors.organizationRefs(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
};

export default connect(mapStateToProps, mapDispatchToProps);
