import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "ca-plateform-bonus-group-domain";
import { http } from "../../http";

export interface BonusGroupDeletedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupDeletedEvent {
  type: string;
}

export interface DeleteBonusGroupCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .DeleteBonusGroupCommand {
  type: string;
}

export const deleteBonusGroupCommand = (
  bonusGroupId: string,
): DeleteBonusGroupCommand => ({
  type: "DELETE_BONUS_GROUP",
  payload: {
    bonusGroupId: bonusGroupId,
  },
});

export const deleteBonusGroup = async (
  bonusGroupId: string
): Promise<BonusGroupDeletedEvent> => {
  return http.put(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/delete`),
    JSON.stringify(deleteBonusGroupCommand(bonusGroupId)),
    "La prime a bien été supprimée"
  );
};
