import { createActionCreator } from "deox";
import { OrganizationRef } from "api/organization";
import { Campaign } from "api/project/model";
import { Territory } from "api/geoZone";
import { Dashboard } from "../../api/dashboard";

const fetchCampaigns = createActionCreator("GLOBAL_CAMPAIGNS_FETCH");
const fetchedCampaigns = createActionCreator(
  "GLOBAL_CAMPAIGNS_FETCHED",
  (resolve) => (campaigns: Campaign[]) => resolve({ campaigns: campaigns })
);

const fetchProjectTerritories = createActionCreator(
  "GLOBAL_PROJECT_TERRITORIES_FETCH"
);
const fetchedProjectTerritories = createActionCreator(
  "GLOBAL_PROJECT_TERRITORIES_FETCHED",
  (resolve) => (ProjectTerritories: Territory[]) =>
    resolve({ ProjectTerritories: ProjectTerritories })
);

const fetchOrganizationRefs = createActionCreator(
  "GLOBAL_ORGANIZATION_REFS_FETCH"
);
const fetchedOrganizationRefs = createActionCreator(
  "GLOBAL_ORGANIZATION_REFS_FETCHED",
  (resolve) => (organizationRefs: OrganizationRef[]) =>
    resolve({ organizationRefs: organizationRefs })
);

const fetchDashboards = createActionCreator(
    "GLOBAL_DASHBOARDS_FETCH"
);
const fetchedDashboards = createActionCreator(
    "GLOBAL_DASHBOARDS_FETCHED",
    (resolve) => (dashboards: Dashboard[]) =>
        resolve({ dashboards: dashboards })
);

export const actions = {
  fetchCampaigns: fetchCampaigns,
  fetchedCampaigns: fetchedCampaigns,
  fetchOrganizationRefs: fetchOrganizationRefs,
  fetchedOrganizationRefs: fetchedOrganizationRefs,
  fetchProjectTerritories: fetchProjectTerritories,
  fetchedProjectTerritories: fetchedProjectTerritories,
  fetchDashboards: fetchDashboards,
  fetchedDashboards: fetchedDashboards
};
