import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactMapboxGl, { Layer, Source } from "react-mapbox-gl";
import { GeoJSONSourceRaw, Map as MapBoxMap } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import { Box } from "@material-ui/core";
import { bbox } from "@turf/turf"

const Map = ReactMapboxGl({
  //@ts-ignore
  accessToken: window._env_.API_MAPBOX_ACCESS_TOKEN,
  attributionControl: false,
});

const mapStartProps: {
  containerStyle: object;
  center: [number, number];
  zoom: [number];
} = {
  containerStyle: { width: "100%", minHeight: 600 },
  center: [6, 18],
  zoom: [1],
};

interface GeoJsonData {
  string: string;
  obj: any;
}

export const EMPTY_GEO_JSON = `{
  "type": "Feature",
  "properties": {},
  "geometry": {
      "type": "Polygon",
      "coordinates": [
      ]
  }
}
`;

export interface GeoZoneMapProps {
  geoJson: string;
  mapContainerClassName?: string;
}

export const GeoZoneMap = (props: GeoZoneMapProps) => {
  const { geoJson, mapContainerClassName } = props;
 
  const [map, setmap] = useState<MapBoxMap | undefined>(undefined);

  const onStyleLoad = useCallback<MapEvent>((map: MapBoxMap) => {
    setmap(map);
  }, []);

  const currentGeoJson: GeoJsonData = useMemo(() => {
    try {
      return geoJson 
      ? { string: geoJson, obj: JSON.parse(geoJson) }
      : { string: EMPTY_GEO_JSON, obj: JSON.parse(EMPTY_GEO_JSON) }
    } catch {
      return { string: EMPTY_GEO_JSON, obj: JSON.parse(EMPTY_GEO_JSON) }
    }
  }, [geoJson])

  const highlightedArea: GeoJSONSourceRaw = useMemo(
    () => ({
      data: currentGeoJson.obj,
      type: "geojson",
    }),
    [currentGeoJson]
  );

  useEffect(() => {
    try {
      const bounds = bbox(currentGeoJson.obj)
      // @ts-ignore
      map?.fitBounds(bounds, {padding: 20})
    } catch {}
  }, [currentGeoJson.obj, map]);

  return (
    <>
      <Box className={mapContainerClassName}>
        <Map
          style="mapbox://styles/mapbox/outdoors-v11"
          {...mapStartProps}
          onStyleLoad={onStyleLoad}
        >
          <Source id="highlightedArea" geoJsonSource={highlightedArea} />
          <Layer
            type="fill"
            sourceId="highlightedArea"
            paint={{ "fill-color": "#ff0000", "fill-opacity": 0.3 }}
          />
          <Layer
            type="line"
            sourceId="highlightedArea"
            paint={{ "line-color": "#ff0000", "line-width": 1 }}
          />
        </Map>
      </Box>
    </>
  );
};
