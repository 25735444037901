import React, { useCallback, useMemo, useState } from 'react'
import { city as cityGeo } from 'plateform-geozone-domain'

import { SelectorDialog, GeoZoneWithTerritory } from './SelectorDialog'
import { MultiSelectBox } from '../MultiSelect'
import { Option } from '../Select'
import { GenericGroup } from '../TransferList'

export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}
export interface GeoZone
  extends cityGeo.colisactiv.platform.geozone.model.GeoZone {}

interface TerritorySelectorDialogProps {
  title: string
  territories: Territory[]
  selectedZones?: GeoZone[]
  onValidate: (zones: Map<string, GeoZoneWithTerritory>) => void
  readonly?: boolean
  className?: string
  error?: boolean
  errorMessage?: string
  gotoGeoZoneCreate: () => void
  isColisActivAdmin: boolean
}

export const TerritorySelectorDialog = (
  props: TerritorySelectorDialogProps
) => {
  const {
    title,
    territories,
    className,
    readonly,
    onValidate,
    selectedZones,
    error,
    errorMessage,
    gotoGeoZoneCreate,
    isColisActivAdmin
  } = props
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleClose = useCallback(() => setOpen(false), [])

  const [loading, setLoading] = useState(false)

  const onChangeLoading = useCallback(
    (loading: boolean) => setLoading(loading),
    []
  )

  const zones: {
    groups: GenericGroup<GeoZoneWithTerritory>[]
    selectedZonesMap: Map<string, GeoZoneWithTerritory>
  } = useMemo(() => {
    if (territories.length > 0) setLoading(true)
    const selectedZonesMap: Map<string, GeoZoneWithTerritory> = new Map<
      string,
      GeoZoneWithTerritory
    >()
    const groups = territories.map(
      (territory): GenericGroup<GeoZoneWithTerritory> => {
        return {
          list: territory.zones.map(
            (zone: GeoZone): GeoZoneWithTerritory => {
              const zoneWithTerritory = {
                ...zone,
                territoryName: territory.name,
                territoryId: territory.id
              }
              if (
                selectedZones?.find((zone) => zone.id === zoneWithTerritory.id)
              ) {
                selectedZonesMap.set(zoneWithTerritory.id, zoneWithTerritory)
              }
              return zoneWithTerritory
            }
          ),
          id: territory.id,
          name: territory.name
        }
      }
    )
    return {
      groups: groups,
      selectedZonesMap: selectedZonesMap
    }
  }, [territories, selectedZones])

  const selectedTerritories: {
    values: string[]
    options: Option[]
  } = useMemo(() => {
    if (!zones.selectedZonesMap) return { values: [], options: [] }
    const values: string[] = []
    const options: Option[] = []
    zones.selectedZonesMap.forEach((zone) => {
      if (!values.includes(zone.territoryId)) {
        values.push(zone.territoryId)
        options.push({ label: zone.territoryName, value: zone.territoryId })
      }
    })
    return { values: values, options: options }
  }, [zones.selectedZonesMap])

  return (
    <>
      <MultiSelectBox
        noMenu
        values={selectedTerritories.values}
        options={selectedTerritories.options}
        id='territory-Selector-input'
        label={title}
        className={className}
        onClick={handleOpen}
        error={error}
        errorMessage={errorMessage}
        readonly={readonly}
      />
      <SelectorDialog
        zonesGroups={zones.groups}
        selectedZones={zones.selectedZonesMap}
        title={title}
        loading={loading}
        onChangeLoading={onChangeLoading}
        onValidate={onValidate}
        handleClose={handleClose}
        open={open}
        territories={territories}
        gotoGeoZoneCreate={gotoGeoZoneCreate}
        isColisActivAdmin={isColisActivAdmin}
      />
    </>
  )
}
