import {successHandler} from "api/error";
import {projectClient} from "api/project";
import {CampaignDetails, ProjectDetails} from "api/project/model";
import {CampaignsActionProps} from "api/project/model/CampaignActions";
import {CampaignFormState} from "app/components/CampaignForm/Model";
import {ProjectFormState} from "app/components/ProjectForm/model";
import i18n from "i18n";
import {Dispatch} from "redux";
import {global} from "../global";
import {State} from "../index";
import {router} from "../router";
import {actions} from "./project.actions";

const createProject = (form: ProjectFormState) => {
  return async (dispatch: Dispatch) => {
    const { details, funders: projectFunder } = form;

    const projectDetails: ProjectDetails = {
      ...details,
      name: details.name.trim(),
    };
    const updateEvent = await projectClient.createProject(
      projectDetails,
      projectFunder
    );
    const fetched = await projectClient.query.getProject(
      updateEvent.payload.id
    );
    dispatch(actions.createdProject(fetched));
    dispatch(router.goto.project.projectView(fetched.id));
  };
};

const resetProject = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetProject());
  };
};

const createCampaign = (form: CampaignFormState, projectId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { details, bonusPrices, rules, budget, funders } = form;

    const campaignDetails: CampaignDetails = {
      ...details,
      name: details.name.trim(),
    };

    const updatedEvent = await projectClient.createCampaign({
      type: "ADD_CAMPAIGN",
      payload: {
        bonusPrices: bonusPrices,
        funders: funders,
        budget: budget ?? 0,
        campaignDetails: campaignDetails,
        projectId: projectId,
        rules: rules,
      },
    });
    const fetched = await projectClient.query.getProject(
      updatedEvent.payload.projectId!!
    );
    dispatch(actions.updatedProject(fetched));
    global.queries.fetchCampaigns(true)(dispatch, getState);
  };
};

const updateCampaign = (form: CampaignFormState) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const {
      details,
      bonusPrices,
      rules,
      projectId,
      id,
      funders,
      budget,
    } = form;

    const campaignDetails: CampaignDetails = {
      ...details,
      name: details.name.trim(),
    };

    const updatedEvent = await projectClient.updateCampaign(
      projectId,
      id,
      campaignDetails,
      bonusPrices,
      funders,
      budget ?? 0,
      rules
    );
    const fetched = await projectClient.query.getProject(
      updatedEvent.payload.projectId!!
    );
    dispatch(actions.updatedCampaign(fetched));
    global.queries.fetchCampaigns(true)(dispatch, getState);
  };
};

const updateProject = (form: ProjectFormState) => {
  return async (dispatch: Dispatch) => {
    const { details, funders: projectFunder, id } = form;

    const projectDetails: ProjectDetails = {
      ...details,
      name: details.name.trim(),
    };

    await projectClient.updateProject(projectDetails, projectFunder, id!!);
    successHandler("Projet modifié avec succès");
    const fetched = await projectClient.query.getProject(id!!);
    dispatch(actions.updatedProject(fetched));
  };
};

const deleteCampaign = (projectId: string, campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const deletedEvent = await projectClient.removeCampaign(
      projectId,
      campaignId
    );
    const fetched = await projectClient.query.getProject(
      deletedEvent.payload.projectId
    );
    dispatch(actions.deletedCampaign(fetched));
  };
};

const duplicateCampaign = (projectId: string, campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const event = await projectClient.duplicateCampaign(projectId, campaignId);
    const fetched = await projectClient.query.getProject(
      event.payload.projectId
    );
    dispatch(actions.duplicatedCampaign(fetched));
    dispatch(
      router.goto.project.campaignEdit(projectId, event.payload.newCampaignId)
    );
  };
};

const publishCampaign = (projectId: string, campaignId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const event = await projectClient.publishCampaign(projectId, campaignId);
    successHandler("La campagne a été publiée");
    const fetched = await projectClient.query.getProject(
      event.payload.projectId!!
    );
    dispatch(actions.publishedCampaign(fetched));
    global.queries.fetchCampaigns(true)(dispatch, getState);
  };
};

const archiveCampaign = (projectId: string, campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const event = await projectClient.archiveCampaign(projectId, campaignId);
    successHandler("La campagne a été archivé");
    const fetched = await projectClient.query.getProject(
      event.payload.projectId!!
    );
    dispatch(actions.archivedCampaign(fetched));
  };
};

const pauseCampaign = (projectId: string, campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const event = await projectClient.pauseCampaign(projectId, campaignId);
    successHandler(i18n.t("app_campaign_paused_succeed_message"));
    const fetched = await projectClient.query.getProject(
      event.payload.projectId!!
    );
    dispatch(actions.pausedCampaign(fetched));
  };
};

const startCampaign = (projectId: string, campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const event = await projectClient.startCampaign(projectId, campaignId);
    successHandler(i18n.t("app_campaign_started_succeed_message"));
    const fetched = await projectClient.query.getProject(
      event.payload.projectId!!
    );
    dispatch(actions.startedCampaign(fetched));
  };
};

const updateCampaignNotification = (projectId: string, campaignId: string, sendNotification: boolean) => {
  return async (dispatch: Dispatch) => {
    const event = await projectClient.updateCampaignNotification(projectId, campaignId, sendNotification);
    successHandler(i18n.t("app_campaign_notification_updated_succeed_message"));
    const fetched = await projectClient.query.getProject(
      event.payload.projectId!!
    );
    dispatch(actions.startedCampaign(fetched));
  };
};

const campaignActions: CampaignsActionProps = {
  gotoCampaignEdit: router.goto.project.campaignEdit,
  publishCampaign: publishCampaign,
  archiveCampaign: archiveCampaign,
  deleteCampaign: deleteCampaign,
  pauseCampaign: pauseCampaign,
  duplicateCampaign: duplicateCampaign,
  startCampaign: startCampaign,
  updateCampaignNotification: updateCampaignNotification,
  gotoCampaignView: router.goto.project.campaignView,
  gotoProjectView: router.goto.project.projectView,
};

export const commands = {
  createProject: createProject,
  resetProject: resetProject,
  updateProject: updateProject,

  creatCampaign: createCampaign,
  updateCampaign: updateCampaign,
  deleteCampaign: deleteCampaign,
  publishCampaign: publishCampaign,
  archiveCampaign: archiveCampaign,
  pauseCampaign: pauseCampaign,
  duplicateCampaign: duplicateCampaign,
  startCampaign: startCampaign,
  updateCampaignNotification: updateCampaignNotification,
  campaignActions: campaignActions,
};
