import { Box, makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { BonusPrice, ProjectFunder, ProjectStats } from "api/project/model";
import { DeleteButton, GeoZoneWithTerritory, InputForm, MultiSelectBox, Option, TerritorySelectorDialog, } from "components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BonusPriceFormValidity } from "./Model";

const useStyles = makeStyles(() => ({
  inputForm: {
    flexDirection: "column",
    marginTop: "10px",
    justifyContent: "start",
    alignItems: "start",
    width: "100%",
  },
  container: {
    display: "flex",
  },
  box: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: 50,
    gap: 20
  },
  zone: {
    display: "flex",
    flexDirection: "column",
  },
  containerSelectStyle: {
    display: "flex",
    flexDirection: "column",
  },
  selectStyle: {
    justifyContent: "center",
    width: "100%",
    marginTop: 11,
    minHeight: "2.1rem",
  },
  itemsWidth: {
    flex: '1 1 0px',
    minWidth: 0,
    alignSelf: 'flex-start'
  },
  deletebutton: {
    marginTop: "36px",
  },
  zoneButton: {
    marginTop: "36px",
    height: "50px",
    width: "100%",
  },
}));

function refToOption(refs: OrganizationRef[]) {
  return refs.map(ref => ({
    label: ref.displayName,
    value: ref.organizationId,
  }))
}

interface BonusPriceFormProps {
  project: ProjectStats | null;
  bonus: BonusPrice;
  position: number;
  bonusLength: number;
  bonusValidation: BonusPriceFormValidity;
  carrierRefs: OrganizationRef[];
  operatorRefs: OrganizationRef[];
  readOnly: boolean;
  onBonusDeleted: (position: number) => void;
  onChangeBonus: (
    typeInput: keyof BonusPrice,
    position: number,
    value: any
  ) => void;
  gotoGeoZoneCreate: () => void;
  isColisActivAdmin: boolean;
}

const BonusPriceRow = (props: BonusPriceFormProps) => {
  const {
    project,
    bonus,
    position,
    bonusLength,
    bonusValidation,
    carrierRefs,
    operatorRefs,
    readOnly,
    onChangeBonus,
    onBonusDeleted,
    gotoGeoZoneCreate,
    isColisActivAdmin
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const operatorOptions: Option[] = useMemo(() => refToOption(operatorRefs), [operatorRefs]);
  const carrierOptions: Option[] = useMemo(() => refToOption(carrierRefs), [carrierRefs]);

  const territories = useMemo(() => {
    if (!project) return [];
    return (project.funders as ProjectFunder[]).flatMap(
      (funder) => funder.territories as Territory[]
    );
  }, [project?.funders]);

  const onValidate = useCallback(
    (zones: Map<string, GeoZoneWithTerritory>) =>
      onChangeBonus("areas", position, Array.from(zones.values())),
    [position, onChangeBonus]
  );

  const handleValueChange = useCallback((value: string) => {
    onChangeBonus(
      "value",
      position,
      !!value ? Number(value) : undefined
    )
  }, [position, onChangeBonus])

  const handleOperatorChange = useCallback((operatorIds: string[]) => {
    onChangeBonus("operatorIds", position, operatorIds)
  }, [position, onChangeBonus])

  const handleCarrierChange = useCallback((carrierIds: string[]) => {
    onChangeBonus("carrierIds", position, carrierIds)
  }, [position, onChangeBonus])

  const handleMaxRateChange = useCallback((maxHourRate: string) => {
    onChangeBonus(
      "maxHourRate",
      position,
      !!maxHourRate ? Number(maxHourRate) : undefined
    )
  }, [position, onChangeBonus])
  
  return (
    <div className={classes.container}>
      <Box className={classes.box}>
        <div className={classes.itemsWidth}>
          <InputForm
            id={`bonus-value-${position}`}
            value={bonus.value}
            inputType="textField"
            label={t(
              "app_campaign_form_bonus_price_row_project_premium_delivery"
            )}
            textFieldType="number"
            onChange={handleValueChange}
            className={classes.inputForm}
            error={!bonusValidation.value}
            errorMessage={t(
              "app_campaign_form_bonus_price_row_please_enter_the_premium_value"
            )}
            readonly={readOnly}
            inputIcon={"€"}
            iconPosition="end"
          />
        </div>
        <div className={classes.itemsWidth}>
          <TerritorySelectorDialog
            onValidate={onValidate}
            selectedZones={bonus.areas}
            readonly={readOnly}
            title={t(
              "app_campaign_form_bonus_price_row_territory_selector_dialog"
            )}
            error={!bonusValidation.areas}
            errorMessage={t(
              "app_campaign_form_bonus_price_row_please_choose_area"
            )}
            className={classes.inputForm}
            territories={territories}
            gotoGeoZoneCreate={gotoGeoZoneCreate}
            isColisActivAdmin={isColisActivAdmin}
          />
        </div>
        <div className={classes.itemsWidth}>
          <MultiSelectBox
            label={t("app_campaign_form_bonus_price_row_operator")}
            id={`bonus-operator-${position}`}
            onChange={handleOperatorChange}
            options={operatorOptions}
            className={classes.selectStyle}
            values={bonus.operatorIds}
            error={!bonusValidation.operator}
            errorMessage={t(
              "app_campaign_form_bonus_price_row_please_select_at_least_one_operator"
            )}
            readonly={readOnly}
          />
        </div>
        <div className={classes.itemsWidth}>
          <MultiSelectBox
            label={t("app_campaign_form_bonus_price_row_carrier")}
            id={`bonus-carriers-${position}`}
            onChange={handleCarrierChange}
            options={carrierOptions}
            className={classes.selectStyle}
            values={bonus.carrierIds}
            readonly={readOnly}
          />
        </div>
        <div className={classes.itemsWidth}>
          <InputForm
            id={`bonus-max-${position}`}
            value={bonus.maxHourRate}
            inputType="textField"
            label={t(
              "app_campaign_form_bonus_price_row_max_rate"
            )}
            textFieldType="number"
            onChange={handleMaxRateChange}
            className={classes.inputForm}
            error={!bonusValidation.maxHourRate}
            errorMessage={t(
              "app_campaign_form_bonus_price_row_please_enter_max_rate"
            )}
            readonly={readOnly}
            inputIcon={"€"}
            iconPosition="end"
          />
        </div>
      </Box>
      {bonusLength > 1 && !readOnly && (
          <DeleteButton
            className={classes.deletebutton}
            onClick={() => onBonusDeleted(position)}
          />
        )}
    </div>
  );
};

export default BonusPriceRow;
