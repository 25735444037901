import { city } from "ca-plateform-organization-domain";
import { http } from "../../../http";
import { buildOrganizationApiUrl } from "../../../configuration";

export interface LogoCreatedEvent
  extends city.colisactiv.organization.model.features.LogoCreatedEvent {}

export const logoCreateCommand = (
  organizationId: string,
  userId: String,
  logo: Blob,
  name: string
): Promise<LogoCreatedEvent> => {
  const formData = new FormData();
  formData.append("logo", logo, name);
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/users/${userId}/logo`
    ),
    formData,
    undefined,
    true
  );
};
