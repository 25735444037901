import { makeStyles } from "@material-ui/core";
import { Campaign, ProjectFunder, ProjectStats } from "api/project/model";
import {
  Button,
  CheckBox,
  FormButtons,
  HeadBarProps,
  SectionForm,
} from "components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { OrganizationRef } from "api/organization";
import CampaignForm from "app/components/CampaignForm";
import {
  CampaignFormModel,
  CampaignFormState,
} from "app/components/CampaignForm/Model";
import { CampaignValidation } from "app/components/CampaignForm/validation";
import withConnect from "./withConnect";
import { User } from "api/organization/users";
import { useAuth } from "auth/useAuth";

const useStyles = makeStyles(() => ({
  CheckBox: {
    marginRight: "20px !important",
  },
}));

interface CampaignDetailsFormProps {
  setHeadBar: (title: HeadBarProps) => void;
  operatorRefs: Map<string, OrganizationRef>;
  carrierRefs: Map<string, OrganizationRef>;
  funderRefs: Map<string, OrganizationRef>;
  project: ProjectStats | null;
  gotoProjectGeneralView: (projectId: string) => void;
  createCampaign: (campaign: CampaignFormState, projectId: string) => void;
  fetchProject: (projectId: string) => void;
  gotoGeoZoneCreate: () => void;
  currentUser: User | null;
}

const CampaignCreatePage = (props: CampaignDetailsFormProps) => {
  const { t } = useTranslation();
  const {
    setHeadBar,
    operatorRefs,
    gotoProjectGeneralView,
    createCampaign,
    project,
    funderRefs,
    carrierRefs,
    fetchProject,
    gotoGeoZoneCreate,
    currentUser,
  } = props;
  useEffect(() => {
    setHeadBar({
      title: `${t("app_project_campaign_create_create_a_campaign")} `,
    });
  }, []);
  const [authService, keycloak] = useAuth();
  const { projectId } = useParams();
  const classes = useStyles();
  const [checkedCreate, setCheckedCreate] = useState<boolean>(false);
  const [campaign, setCampaign] = useState(
    CampaignFormModel.defaultCampaignForm
  );

  const carriers = useMemo(() => Array.from(carrierRefs.values()), [carrierRefs])
  const operators = useMemo(() => Array.from(operatorRefs.values()), [operatorRefs])
  const funders = useMemo(() => Array.from(funderRefs.values()), [funderRefs])
  const isColisActivAdmin = useMemo(() =>
    authService.isColisActivAdmin(currentUser, keycloak)
  ,[authService, currentUser, keycloak])

  useEffect(() => {
    fetchProject(projectId);
  }, [projectId]);

  const pageRef = useRef<HTMLDivElement>(null);
  const scrollTo = useCallback(() => {
    pageRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const projectBudget: number | undefined = useMemo(() => {
    if (!project) return;
    const totalBudget = project.funders.reduce(
      (accumulator: number, funder: ProjectFunder) => {
        if (funder.budget) return accumulator + funder.budget;
        return accumulator;
      },
      0
    );
    if (!project.campaigns) return totalBudget;
    const campaignsBudget = project.campaigns.reduce(
      (accumulator: number, campaign: Campaign) => {
        if (campaign.budget) return accumulator + campaign.budget;
        return accumulator;
      },
      0
    );
    return totalBudget - campaignsBudget;
  }, [project?.funders, project?.campaigns]);

  const handleCampaignCreation = useCallback(async () => {
    const [
      isFormValid,
      validationDetails,
    ] = CampaignValidation.campaignFormValidation(campaign, projectBudget);
    if (!isFormValid) {
      return setCampaign({
        ...campaign,
        validation: validationDetails,
      });
    }

    if (checkedCreate) {
      createCampaign(campaign, projectId);
      setCampaign(CampaignFormModel.defaultCampaignForm);
      setCheckedCreate(false);
      scrollTo();
      return;
    } else {
      createCampaign(campaign, projectId);
    }
    gotoProjectGeneralView(projectId);
  }, [
    campaign,
    checkedCreate,
    createCampaign,
    scrollTo,
    gotoProjectGeneralView,
    projectBudget,
  ]);

  return (
    <SectionForm withGoBack>
      <CampaignForm
        campaign={campaign}
        onCampaignChange={setCampaign}
        projectBudget={projectBudget}
        readonly={false}
        operatorRefs={operators}
        project={project}
        funderRefs={funders}
        carrierRefs={carriers}
        gotoGeoZoneCreate={gotoGeoZoneCreate}
        isColisActivAdmin={isColisActivAdmin}
      />
      <FormButtons>
        <CheckBox
          text={t("app_project_campaign_create_create_another")}
          checked={checkedCreate}
          className={classes.CheckBox}
          onChange={() => setCheckedCreate(!checkedCreate)}
        />
        <Button onClick={() => handleCampaignCreation()}>
          {" "}
          {t("app_project_campaign_create_create")}{" "}
        </Button>
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(CampaignCreatePage);
