import { BonusGroup } from "api/bonusGroup";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import BonusForm from "app/components/Bonus/BonusForm";
import {
  BonusFormDetailsState, toForm
} from "app/components/Bonus/BonusForm/model";
import {
  HeadBarProps,
  SectionForm
} from "components";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import BonusViewHeadBar from "../View/BonusViewHeadBar";
import withConnect from "./withConnect";

interface BonusDetailsPageFormProps {
  bonus: BonusGroup | null;
  carriers: Map<string, OrganizationRef>;
  currentUser: User | null;
  operators: Map<string, OrganizationRef>;
  fetchBonus: (bonusId: string, force?: boolean) => void;
  setHeadBar: (props: HeadBarProps) => void;
  updateBonus: (bonus: BonusFormDetailsState) => void;
  gotoBonusEdit: (bonusId: string) => void;
  gotoBonusView: (bonusId: string) => void;
}

const BonusDetailsPage = (props: BonusDetailsPageFormProps) => {
  const {
    bonus,
    carriers,
    currentUser,
    operators,
    fetchBonus,
    setHeadBar,
    gotoBonusEdit,
    gotoBonusView,
  } = props;
  const { bonusId } = useParams();

  useEffect(() => {
    fetchBonus(bonusId, false);
  }, [bonusId, fetchBonus]);

  useEffect(() => {
    if (bonus == null) {
      return
    }

    setHeadBar({
      title: `Prime n°${bonus.id}`,
      component: (
        <BonusViewHeadBar 
          bonus={bonus}
          currentUser={currentUser}
          gotoBonusDetails={gotoBonusView}
          gotoBonusEdit={gotoBonusEdit}
        />
      )
    });
  }, [bonus, currentUser, gotoBonusEdit, gotoBonusView, setHeadBar]);

  const bonusState = useMemo(() => {
    return bonus && toForm(bonus);
  }, [bonus]) 

  return bonusState && (
    <SectionForm withGoBack>
      <BonusForm
        carriers={carriers}
        operators={operators}
        bonusState={bonusState}
        onBonusChange={() => {}}
        mode="readonly"
      />
    </SectionForm>
  );
};

export default withConnect(BonusDetailsPage);
