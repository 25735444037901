import { city } from "ca-plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";
import { BonusGroupInvoiceType } from "..";

export interface CreateBonusGroupInvoiceCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .CreateBonusGroupInvoiceCommand {}

export interface CreateBonusGroupInvoiceCommandDetails
  extends city.colisactiv.plateform.bonus.group.model.features
    .CreateBonusGroupInvoiceCommandDetails {}

export interface BonusGroupInvoiceCreatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupInvoiceCreatedEvent {
  type: string;
}

export const createBonusGroupInvoiceCommand = (
  bonusGroupId: string,
  file: string,
  name: string,
  amount: number,
  creatorId: string,
  type: BonusGroupInvoiceType,
  targetId: string
): CreateBonusGroupInvoiceCommand => {
  return {
    type: "CREATE_BONUS_GROUP_INVOICE",
    payload: {
      bonusGroupId: bonusGroupId,
      file: file,
      name: name,
      amount: amount,
      creatorId: creatorId,
      type: type,
      targetId: targetId,
    },
  };
};

export const createInvoice = (
  bonusGroupId: string,
  file: string,
  name: string,
  amount: number,
  creatorId: string,
  type: BonusGroupInvoiceType,
  targetId: string
): Promise<BonusGroupInvoiceCreatedEvent> => {
  const url = buildBonusGroupApiUrl(`/bonusgroup/invoice`);
  const body = JSON.stringify(
    createBonusGroupInvoiceCommand(
      bonusGroupId,
      file,
      name,
      amount,
      creatorId,
      type,
      targetId
    )
  );
  return http.post(url, body, "La facture a bien été ajoutée");
};
