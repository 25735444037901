import { Organization } from "../../../api/organization";
import { defaultExportAuthorizationFormValidity } from "./validation";

export const buildExportAuthorizationForm = (
  organization: Organization
): ExportAuthorizationFormState => ({
  canExportDelivery:
    organization?.exportAuthorization?.deliveryPeriod?.startDate != null,
  deliveryPeriodStartMonth:
    organization?.exportAuthorization?.deliveryPeriod?.startDate,
  deliveryPeriodEndMonth:
    organization?.exportAuthorization?.deliveryPeriod?.endDate,
  canExportTrace: organization.exportAuthorization?.tracePeriod != null,
  tracePeriodStartMonth:
    organization?.exportAuthorization?.tracePeriod?.startDate,
  tracePeriodEndMonth: organization?.exportAuthorization?.tracePeriod?.endDate,
  validity: defaultExportAuthorizationFormValidity,
});

export interface ExportAuthorizationFormState {
  canExportDelivery: boolean;
  deliveryPeriodStartMonth?: number;
  deliveryPeriodEndMonth?: number;
  canExportTrace: boolean;
  tracePeriodStartMonth?: number;
  tracePeriodEndMonth?: number;
  validity: ExportAuthorizationFormValidity;
}

export interface ExportAuthorizationFormValidity {
  canExportDelivery?: boolean;
  deliveryPeriodStartMonth?: number;
  deliveryPeriodEndMonth?: number;
  canExportTrace?: boolean;
  tracePeriodStartMonth?: number;
  tracePeriodEndMonth?: number;
}
