import { buildTourApiUrl } from "api/configuration";
import { http } from "api/http";

export const uploadToursAndGenerateEmptyTraceGps = (
  organizationId: string,
  file: ArrayBuffer
): Promise<any> => {
  const url = buildTourApiUrl(
    `/deliverytours/organization/${organizationId}/uploadToursAndGenerateEmptyGps`
  );
  return http.postCsv(url, file);
};
