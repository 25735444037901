import { Theme, themeContext } from "@smartb/archetypes-ui-components"
import {
  FormField, useForm
} from "@smartb/archetypes-ui-forms"
import { highLevelStyles } from "@smartb/archetypes-ui-themes"
import {
  bonusGroupClient,
  BonusGroupFunderInvoiceDetails
} from "api/bonusGroup"
import { InputForm } from 'components'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { useTranslation } from "react-i18next"

const useStyles = highLevelStyles<Theme>()({
  textField: {
    "& .MuiFormLabel-root": {
      fontSize: 14,
      marginBottom: 8,
    },
    '& .MuiInputBase-root': {
      minHeight: '35px !important',
      paddingTop: 2,
      paddingRight: 5,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0
    },
    marginBottom: 30
  }
});

interface FunderInvoiceDetailsProps {
  isFocused: boolean;
  attachmentId: string;
  details?: BonusGroupFunderInvoiceDetails;
  bonusId: string;
  isValid?: boolean;
  readonly?: boolean;
  fetchBonus: (bonusId: string, force?: boolean) => void;
}

export const FunderInvoiceDetails = (props: FunderInvoiceDetailsProps) => {
  const {
    attachmentId,
    isFocused,
    details,
    bonusId,
    isValid = true,
    readonly = false,
    fetchBonus
  } = props;
  const { t } = useTranslation();
  const theme = useContext(themeContext);
  const classes = useStyles(theme);

  const [invoiceNumber, setInvoiceNumber] = useState(details?.invoiceNumber ?? "")
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!isValid && isFocused) {
      setTimeout(() => {
        const button = document.getElementById(
          `invoice-${attachmentId}-details-number`
        ) as HTMLButtonElement;
        if (button) {
          button.focus();
          button.click();
        }
      }, 300);
    }
  }, [isValid]);

  const handleInvoiceNumberChange = useCallback((invoiceNumber) => setInvoiceNumber(invoiceNumber), [])

  const handleSubmit = useCallback(async () => {
    setSubmitted(true)
    if (invoiceNumber !== details?.invoiceNumber) {
      await bonusGroupClient.updateInvoiceDetails(bonusId, attachmentId, { invoiceNumber });
      fetchBonus(bonusId);
    }
  }, [invoiceNumber, details?.invoiceNumber])

  return (
    <InputForm
      className={classes.textField}
      id={attachmentId}
      inputType='textField'
      textFieldType='text'
      label={t("app_bonus_view_invoices_details_number")}
      value={invoiceNumber}
      onChange={handleInvoiceNumberChange}
      onBlur={handleSubmit}
      readonly={readonly}
      error={!isValid || (submitted && invoiceNumber === "")}
      errorMessage={t("app_bonus_view_invoices_details_number_error")}
    />
  )
};
