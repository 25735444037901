import {addMinutes, addMonths, endOfMonth, startOfMonth} from 'date-fns'
import {DateInterval} from '../Domain/BonusPayment'

export const nullToUndefined = <T>(value: T | null): T | undefined => {
  return value ?? undefined
}

export const displayNumber = (
  value: number,
  fractionDigits?: number
): string => {
  const fixedValue = value.toFixed(fractionDigits)
  return parseFloat(fixedValue).toLocaleString('fr-FR')
}

export const removeTimeZoneOffset = (date: number): number => {
  const formatedDate = new Date(date)
  return addMinutes(formatedDate, -formatedDate.getTimezoneOffset()).getTime()
}

export const applyTimeZoneOffset = (date: number): number => {
  const formatedDate = new Date(date)
  return addMinutes(formatedDate, formatedDate.getTimezoneOffset()).getTime()
}

export const getFirstDayOfTheMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(startOfMonth(date).getTime())
}

export const getFirstDayOfLastMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(endOfMonth(addMonths(date, -1)).getTime())
}

export const getLastDayOfTheMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(endOfMonth(date).getTime())
}

export const UTCToTimedZone = (date: number): Date => {
  return new Date(applyTimeZoneOffset(date))
}

export const wholeMonthOf = (date: number): DateInterval => ({
  startDate: getFirstDayOfTheMonthUTC(date),
  endDate: getLastDayOfTheMonthUTC(date)
})

export const formatDate = (date: number): string => {
  const newDate = new Date(date)
  const month = newDate.toLocaleString('fr-fr', { month: 'long' })
  const year = newDate.getFullYear()

  return month[0].toUpperCase() + month.substr(1) + ' ' + year
}

export const getNumberOfDaysFromNow = (date: number): number => {
  const now = new Date().getTime()
  console.log(now)

  const milli = now - date
  console.log(milli)

  const days = milli / 1000 / 60 / 60 / 24
  console.log(days)

  return days
}
