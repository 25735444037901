import React, { useCallback, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {
  AutoComplete,
  CircleProfileIcon,
  DropPicture,
  InputForm,
  Option,
} from "components";
import {
  Organization,
  OrganizationRef,
  OrganizationTypeValues,
} from "../../../api/organization";
import { LogoType } from "api/organization/service";
import { User, UserDetails } from "api/organization/users";
import { useTranslation } from "react-i18next";
import { geoZoneClient, Territory } from "api/geoZone";
import { Roles } from "auth";

const useStyles = makeStyles(() => ({
  leftContainer: {
    width: "250px",
  },
  rightContainer: {
    width: "500px",
    paddingTop: "20px",
    marginBottom: "20px",
  },
  select: {
    width: "100%",
    marginTop: "30px",
  },
  input: {
    width: "50%",
  },
  inputForm: {
    marginTop: "20px",
  },
  reportingInput: {
    marginTop: 40,
    "& span": {
      fontWeight: 600,
    },
  },
  territoryInput: {
    width: "100%",
    marginTop: "20px",
  },
  autoComplete: {
    width: "250px",
  },
  text: {
    width: "35%",
  },
}));

export interface UserFormState {
  details: UserDetails;
  validity: UserFormValidity;
  logo?: LogoType;
}

export interface UserFormValidity {
  firstname?: string;
  lastname?: string;
  email?: string;
  organization?: string;
  phoneNumber?: string;
  fonction?: string;
}

export const NO_ERROR = undefined;

interface UserFormProps {
  currentUserHasRoles: (roles: Roles[]) => boolean;
  user: UserFormState;
  organization?: Organization;
  organizationRefs: Map<string, OrganizationRef>;
  onDetailsChange: (user: UserFormState) => void;
  readonly: boolean;
  // edition?: boolean;
}

const typeOptions: Option[] = [
  { value: "user", label: "Utilisateur" },
  { value: "admin", label: "Administrateur" },
];

const UserDetailsForm = (props: UserFormProps) => {
  const {
    currentUserHasRoles,
    user,
    organizationRefs,
    readonly,
    onDetailsChange,
    organization,
    // edition = false,
  } = props;
  const classes = useStyles();

  const onChangeEmail = (email: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, email: email },
          validity: { ...user.validity, email: NO_ERROR },
        });
    }
  };
  const onChangeFirstName = (firstname: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, firstname: firstname },
          validity: { ...user.validity, firstname: NO_ERROR },
        });
    }
  };
  const onChangeLastName = (lastname: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, lastname: lastname },
          validity: { ...user.validity, lastname: NO_ERROR },
        });
    }
  };
  const onChangeFonction = (fonction: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, fonction: fonction },
          validity: { ...user.validity, fonction: NO_ERROR },
        });
    }
  };
  const onChangePhoneNumber = (phoneNumber: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, phoneNumber: phoneNumber },
          validity: { ...user.validity, phoneNumber: NO_ERROR },
        });
    }
  };

  const onChangeRole = (role: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, role: role },
        });
    }
  };

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const territories = Array.isArray(territory) ? territory : [territory];
      if (!!user.details) {
        user.details &&
          onDetailsChange({
            ...user,
            details: { ...user.details, territories: territories },
          });
      }
    },
    [onDetailsChange, user]
  );

  const [autocompleteText, setAutocompleteText] = useState(
    "Rechercher un territoire"
  );
  const [autoCompleteOptions, setautoCompleteOptions] = useState<Territory[]>(
    []
  );

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setautoCompleteOptions([]);
      setAutocompleteText("Rechercher un territoire");
      return;
    }

    try {
      const response = await geoZoneClient.query.getTerritories(
        0,
        5,
        search.trim()
      );
      setautoCompleteOptions(response.list);
      if (response.list.length === 0) {
        setAutocompleteText("Aucun territoire trouvé");
      }
    } catch (err) {
      console.error(err);
      setautoCompleteOptions([]);
      setAutocompleteText("Une erreur est survenue, veuillez réessayer");
    }
  }, []);

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name;
  }, []);

  const isCarrierOrOperatorOrgUser = (): Boolean => {
    const type = organization
      ? organization?.organizationType.type
      : organizationRefs.get(user.details.organizationId)?.organizationType
          .type;
    return (
      type == OrganizationTypeValues.CARRIER.type ||
      type == OrganizationTypeValues.OPERATOR.type
    );
  };

  const setLogo = (logo?: File) => {
    onDetailsChange({
      ...user,
      logo: { file: logo },
    });
  };

  const onChangeOrganization = (organizationId: string) => {
    if (!!user.details) {
      user.details &&
        onDetailsChange({
          ...user,
          details: { ...user.details, organizationId: organizationId },
        });
    }
  };
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-around" flexWrap="wrap">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="40px"
        className={classes.leftContainer}
      >
        <DropPicture
          onPictureDroped={(picture) => setLogo(picture)}
          onRemovePicture={() => setLogo(undefined)}
          readonly={readonly}
          src={user.logo && user.logo.src ? user.logo.src : ""}
          defaultLogoComponent={<CircleProfileIcon />}
        />
        {!readonly &&
        currentUserHasRoles([
          "admin",
          "super_admin",
          "carrier_admin",
          "operator_admin",
        ]) ? (
          <InputForm
            id="user-add-type"
            label={t("app_user_form_role")}
            inputType="select"
            selectOptions={typeOptions}
            className={classes.select}
            value={user.details.role}
            onChange={(value) => {
              onChangeRole(value);
            }}
          />
        ) : (
          <InputForm
            id="user-add-type-readonly"
            label={t("app_user_form_role")}
            inputType="textField"
            readonly={true}
            className={classes.autoComplete}
            value={
              typeOptions.find((option) => option.value === user.details.role)
                ?.label
            }
          />
        )}
        {isCarrierOrOperatorOrgUser() ? (
          <Box className={classes.territoryInput}>
            {(readonly || !currentUserHasRoles(["admin", "super_admin"])) &&
            user.details.territories.length == 0 ? (
              <InputForm
                id="user-add-territorries-readonly"
                label={t("app_user_form_geographical_territory")}
                inputType="textField"
                readonly={true}
                className={classes.autoComplete}
                value={
                  t("app_user_default_territory") +
                  t("app_user_default_territory_detail")
                }
              />
            ) : (
              <AutoComplete<Territory>
                id="user-add-territorries"
                label={t("app_user_form_geographical_territory")}
                className={classes.autoComplete}
                multiple
                options={autoCompleteOptions}
                defaultValue={user.details.territories}
                noOptionsText={autocompleteText}
                onChangeSelectedElement={handleTerritoryChange}
                onSearch={handleSearchTerritory}
                getOptionLabel={getTerritoryName}
                readonly={
                  readonly || !currentUserHasRoles(["admin", "super_admin"])
                }
                error={!user.details.territories}
                errorMessage={t("app_report_form_please_define_the_city")}
              />
            )}
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.rightContainer}
        style={{ width: readonly ? "400px" : "500px" }}
      >
        {organization ? (
          <InputForm
            value={organization.details.name}
            id="organisation-add-selectOrganisationName"
            error={!organization.details.name}
            inputType="textField"
            label={t("app_user_form_name_of_the_organization")}
            inputClassName={readonly ? classes.text : classes.input}
            className={classes.inputForm}
            readonly={readonly}
          />
        ) : (
          <InputForm
            id="user-add-organization"
            inputType="textField"
            label={t("app_user_form_organization")}
            error={user.validity.organization !== NO_ERROR}
            errorMessage={user.validity.organization}
            inputClassName={readonly ? classes.text : classes.input}
            className={classes.inputForm}
            value={
              organizationRefs.get(user.details.organizationId)?.displayName
            }
            onChange={(value) => {
              onChangeOrganization(value);
            }}
            readonly={true}
          />
        )}
        <InputForm
          id="user-add-firstname"
          inputType="textField"
          label={t("app_user_form_first_name")}
          error={user.validity.firstname !== NO_ERROR}
          errorMessage={user.validity.firstname}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          value={user.details.firstname}
          onChange={(value) => {
            onChangeFirstName(value);
          }}
          readonly={readonly}
        />
        <InputForm
          id="user-add-lastname"
          inputType="textField"
          label={t("app_user_form_last_name")}
          error={user.validity.lastname !== NO_ERROR}
          errorMessage={user.validity.lastname}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          value={user.details.lastname}
          onChange={(value) => {
            onChangeLastName(value);
          }}
          readonly={readonly}
        />
        <InputForm
          id="user-add-fonction"
          inputType="textField"
          label={t("app_user_form_fonction")}
          error={user.validity.fonction !== NO_ERROR}
          errorMessage={user.validity.fonction}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          value={user.details.fonction}
          onChange={(value) => {
            onChangeFonction(value);
          }}
          readonly={readonly}
        />
        <InputForm
          id="user-add-email"
          inputType="textField"
          label={t("app_user_form_email")}
          textFieldType="email"
          error={user.validity.email !== NO_ERROR}
          errorMessage={user.validity.email}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          value={user.details.email}
          onChange={(value) => {
            onChangeEmail(value);
          }}
          readonly={readonly}
        />
        <InputForm
          id="user-add-phone"
          inputType="textField"
          label={t("app_user_form_phone")}
          error={user.validity.phoneNumber !== NO_ERROR}
          errorMessage={user.validity.phoneNumber}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          value={user.details.phoneNumber}
          onChange={(value) => {
            onChangePhoneNumber(value);
          }}
          readonly={readonly}
        />
      </Box>
    </Box>
  );
};

export default UserDetailsForm;
