import {CarrierReportStatus, DateInterval, Mode} from "../index";
import {city} from "ca-plateform-carrier-report-domain";
import {buildCarrierReportApiUrl} from "../../configuration";
import {http} from "../../http";

export interface CreateCarrierReportCommand
  extends city.colisactiv.platform.carrier.report.domain.features
    .CreateCarrierReportCommand {}

export interface CarrierReportCreatedEvent
  extends city.colisactiv.platform.carrier.report.domain.features
    .CarrierReportCreatedEvent {}

export const CarrierReportCreateCommand = (
  carrierId: string,
  period: DateInterval,
  territoryCode: string,
  activeMode: Mode,
  electricMode: Mode,
  thermalMode: Mode,
  status: CarrierReportStatus
): CreateCarrierReportCommand => {
  return {
    type: "CREATE-CARRIER-REPORT",
    payload: {
      activeMode: activeMode,
      carrierId: carrierId,
      period: period,
      territoryCode: territoryCode,
      electricMode: electricMode,
      thermalMode: thermalMode,
      status: status,
    },
  };
};

export const createCarrierReportCommand = (
  carrierId: string,
  period: DateInterval,
  territoryCode: string,
  activeMode: Mode,
  electricMode: Mode,
  thermalMode: Mode,
  status: CarrierReportStatus
): Promise<CarrierReportCreatedEvent> => {
  const statusMsg =
    status.value === "PUBLISHED"
      ? "Reporting créé avec succès"
      : "Le reporting a été enregistré comme brouillon";
  return http.post(
    buildCarrierReportApiUrl("/carrierReports"),
    JSON.stringify(
      CarrierReportCreateCommand(
        carrierId,
        period,
        territoryCode,
        activeMode,
        thermalMode,
        electricMode,
        status
      )
    ),
    statusMsg
  );
};
