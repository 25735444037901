import React, { useEffect, useMemo, useState } from "react";
import {
  CampaignActionButton,
  FormButtons,
  HeadBarProps,
  HeadBarView,
  MenuItemAction,
  SectionForm,
} from "components";
import { makeStyles } from "@material-ui/core";
import {
  CampaignValidation,
  defaultCampaignFormValidity,
} from "app/components/CampaignForm/validation";
import { useParams } from "react-router-dom";
import CampaignForm from "app/components/CampaignForm";
import { Campaign, ProjectStats } from "api/project/model";
import { OrganizationRef } from "api/organization";
import withConnect from "./withConnect";
import { CampaignStatusUtils } from "api/project/model/CampaignStatus";
import {
  CampaignActionsUtils,
  CampaignsActionProps,
} from "api/project/model/CampaignActions";
import { CampaignFormModel } from "app/components/CampaignForm/Model";
import { User } from "api/organization/users";
import { useAuth } from "auth/useAuth";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 8,
  },
}));

interface CampaignViewPageProps extends CampaignsActionProps {
  project: ProjectStats | null;
  campaign: Campaign | null;
  operatorRefs: Map<string, OrganizationRef>;
  funderRefs: Map<string, OrganizationRef>;
  carrierRefs: Map<string, OrganizationRef>;
  setHeadBar: (title: HeadBarProps) => void;
  fetchCampaign: (projectId: string, campaignId: string) => void;
  currentUser: User | null;
}

const CampaignViewPage = (props: CampaignViewPageProps) => {
  const {
    project,
    setHeadBar,
    operatorRefs,
    fetchCampaign,
    campaign,
    funderRefs,
    carrierRefs,
    currentUser,
  } = props;
  const { projectId, campaignId } = useParams();
  const [authService, keycloak] = useAuth();

  const carriers = useMemo(() => Array.from(carrierRefs.values()), [carrierRefs])
  const operators = useMemo(() => Array.from(operatorRefs.values()), [operatorRefs])
  const funders = useMemo(() => Array.from(funderRefs.values()), [funderRefs])
  const isColisActivAdmin = useMemo(() =>
    authService.isColisActivAdmin(currentUser, keycloak)
  ,[authService, currentUser, keycloak])

  useEffect(() => {
    project &&
      campaign &&
      setHeadBar({
        title: campaign.details.name,
        component: (
          <HeadBarView
            title={campaign.details.name}
            secondaryTitle={project.details.name}
            status={CampaignStatusUtils.getLabelStyleStatusTags(
              campaign.status
            )}
            startDate={campaign.details.period.startDate}
            endDate={campaign.details.period.endDate}
          />
        ),
      });
  }, [campaign, projectId, campaignId]);
  const classes = useStyles();
  const [campaignForm, setCampaignForm] = useState(
    CampaignFormModel.defaultCampaignForm
  );

  useEffect(() => {
    campaign &&
      setCampaignForm({
        ...campaignForm,
        ...campaign,
        projectId: projectId,
        validation: {
          validity: defaultCampaignFormValidity,
          campaignFundersValidation: [],
          bonusPriceValidation: [],
        },
      });
  }, [campaign, projectId, campaignId]);

  useEffect(() => {
    fetchCampaign(projectId, campaignId);
  }, [projectId, campaignId]);

  const actionsLink = !!campaign
    ? CampaignActionsUtils.getMenuItems(
        campaign,
        props,
        authService.isColisActivAdmin(currentUser, keycloak),
        ["VIEW"]
      )
    : [];
  return (
    <SectionForm withGoBack>
      <CampaignForm
        project={project}
        campaign={campaignForm}
        onCampaignChange={setCampaignForm}
        readonly={true}
        operatorRefs={operators}
        funderRefs={funders}
        carrierRefs={carriers}
        isColisActivAdmin={isColisActivAdmin}
      />
      <FormButtons>
        {actionsLink.map((action: MenuItemAction) => (
          <CampaignActionButton action={action} className={classes.button} />
        ))}
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(CampaignViewPage);
