import React from 'react'
import { ReactComponent } from './geozone.svg'
import { MergeReactElementProps } from '../types'

interface GeoZoneIconProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', GeoZoneIconProps>

export const GeoZoneIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color } = props
    return <ReactComponent stroke={color} fill='none' ref={ref} {...props} />
  }
)
