import { city } from "ca-plateform-project-domain";
import { StatusTagsFormProps } from "components";
import { Campaign } from "./index";

export interface CampaignStatus
  extends city.colisactiv.project.model.CampaignStatus {}

export const CampaignStatusValues = new city.colisactiv.project.model.CampaignStatusValues();

export type CampaignActions =
  | "PUBLISH"
  | "EDIT"
  | "DELETE"
  | "PAUSE"
  | "ARCHIVE";

export type CampaignStatusValues =
  | "CREATED"
  | "PUBLISHED"
  | "STARTED"
  | "PAUSED"
  | "DONE"
  | "ARCHIVED";

const getStatusFromRow = (row: Campaign): StatusTagsFormProps => {
  return getLabelStyleStatusTags(row.status);
};

const isCreated = (status: CampaignStatus) => {
  return hasStatus(status, "CREATED");
};
const isPublished = (status: CampaignStatus) => {
  return hasStatus(status, "PUBLISHED");
};
const isStarted = (status: CampaignStatus) => {
  return hasStatus(status, "STARTED");
};
const isPaused = (status: CampaignStatus) => {
  return hasStatus(status, "PAUSED");
};
const isDone = (status: CampaignStatus) => {
  return hasStatus(status, "DONE");
};
const isArchived = (status: CampaignStatus) => {
  return hasStatus(status, "ARCHIVED");
};

const hasStatus = (status: CampaignStatus, value: string) => {
  return status.value === value;
};

const getLabelStyleStatusTags = (
  campaignStatus: CampaignStatus
): StatusTagsFormProps => {
  switch (campaignStatus.value) {
    case "CREATED":
      return {
        label: "Créée",
        color: "purple",
      };
    case "PUBLISHED":
      return {
        label: "Publiée",
        color: "blue",
      };
    case "STARTED":
      return {
        label: "Démarrée",
        color: "green",
      };
    case "PAUSED":
      return {
        label: "En Pause",
        color: "yellow",
      };
    case "DONE":
      return {
        label: "Terminée",
        color: "grey",
      };
    case "ARCHIVED":
      return {
        label: "Archivée",
        color: "light_grey",
      };
    default:
      return {
        label: campaignStatus.value,
        color: "grey",
      };
  }
};

export const CampaignStatusUtils = {
  isCreated: isCreated,
  isPublished: isPublished,
  isStarted: isStarted,
  isPaused: isPaused,
  isDone: isDone,
  isArchived: isArchived,
  getLabelStyleStatusTags: getLabelStyleStatusTags,
  getStatusFromRow: getStatusFromRow,
};
