import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PeriodPicker } from "components";
import { useTranslation } from "react-i18next";
import { Info } from "@material-ui/icons";
import { DateInterval } from "components/src/Domain/BonusPayment";
import { ExportAuthorizationFormState } from "./model";
import { wholeMonthOfOrUndefined } from "utils";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    marginTop: "30px",
  },
  input: {
    width: "70%",
  },
  text: {
    width: "550px !important",
    alignContent: "center",
    marginTop: "5px",
  },
  title: {
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
    marginTop: "20px",
  },
  inputForm: {
    marginTop: "30px",
    width: "550px !important",
  },
  exportBox: {
    marginBottom: "30px",
  },
  authorization: {
    display: "flex",
    flexDirection: "column",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "left",
  },
  period: {
    display: "flex",
    justifyContent: "left",
  },
  leftPeriodInput: {
    paddingRight: "10px",
  },
  reminderRuleIcon: {
    color: "#007DCE",
    alignItems: "center",
    display: "flex",
  },
  reminderIcon: {
    alignItems: "center",
    display: "flex",
  },
  reminderMessage: {
    padding: "8px",
  },
  reminderContainer: {
    display: "flex",
    gap: "15px",
    background: "white",
    width: "100%",
    padding: "5px",
    marginBottom: "20px",
  },
  severityBar: {
    width: "3px",
    minWidth: "3px",
    borderRadius: "30px",
    marginRight: "5px",
  },
}));

interface DeliveryExportAuthorizationFormProps {
  exportAuthorization: ExportAuthorizationFormState;
  onDetailsChange: (exportAuthorization: ExportAuthorizationFormState) => void;
  readonly: boolean;
}

export const NO_ERROR = undefined;

export const DeliveryExportAuthorizationForm = (
  props: DeliveryExportAuthorizationFormProps
) => {
  const { exportAuthorization, onDetailsChange, readonly } = props;

  const onDeliveryAuthorizationChange = (_, value: string) => {
    onDetailsChange({
      ...exportAuthorization,
      canExportDelivery: value === "true",
      validity: {
        ...exportAuthorization.validity,
        canExportDelivery: NO_ERROR,
      },
    });
  };
  const onDeliveryPeriodStartMonthChange = (newPeriod?: DateInterval) => {
    onDetailsChange({
      ...exportAuthorization,
      deliveryPeriodStartMonth: newPeriod?.startDate,
      validity: {
        ...exportAuthorization.validity,
        deliveryPeriodStartMonth: NO_ERROR,
      },
    });
  };
  const onDeliveryPeriodEndMonthChange = (newPeriod?: DateInterval) => {
    onDetailsChange({
      ...exportAuthorization,
      deliveryPeriodEndMonth: newPeriod?.endDate,
      validity: {
        ...exportAuthorization.validity,
        deliveryPeriodEndMonth: NO_ERROR,
      },
    });
  };

  const onTraceAuthorizationChange = (_, value: string) => {
    onDetailsChange({
      ...exportAuthorization,
      canExportTrace: value === "true",
      validity: { ...exportAuthorization.validity, canExportTrace: NO_ERROR },
    });
  };
  const onTracePeriodStartMonthChange = (newPeriod?: DateInterval) => {
    onDetailsChange({
      ...exportAuthorization,
      tracePeriodStartMonth: newPeriod?.startDate,
      validity: {
        ...exportAuthorization.validity,
        tracePeriodStartMonth: NO_ERROR,
      },
    });
  };
  const onTracePeriodEndMonthChange = (newPeriod?: DateInterval) => {
    onDetailsChange({
      ...exportAuthorization,
      tracePeriodEndMonth: newPeriod?.endDate,
      validity: {
        ...exportAuthorization.validity,
        tracePeriodEndMonth: NO_ERROR,
      },
    });
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.reminderContainer}>
        <div
          className={classes.severityBar}
          style={{ background: "rgb(33, 150, 243)" }}
        />
        <div className={classes.reminderRuleIcon}>
          <Info className={classes.reminderRuleIcon} />
        </div>
        <div>{t("app_organizations_delivery_export_reminder")}</div>
      </Box>
      <Box className={classes.exportBox}>
        <div>
          <span>{t("app_organizations_delivery_export_authorization")}</span>
          <RadioGroup
            onChange={onDeliveryAuthorizationChange}
            className={classes.radioGroup}
            row
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Oui"
              checked={exportAuthorization.canExportDelivery}
              disabled={readonly}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Non"
              checked={!exportAuthorization.canExportDelivery}
              disabled={readonly}
            />
          </RadioGroup>
        </div>
        <div className={classes.title}>
          {t("app_organizations_delivery_export_period")}
        </div>
        <div className={classes.period}>
          <PeriodPicker
            id="organization-form-export-delivery-periodPicker-left"
            label={t("app_campaign_form_start_date")}
            readonly={readonly || !exportAuthorization.canExportDelivery}
            period={wholeMonthOfOrUndefined(
              exportAuthorization.deliveryPeriodStartMonth
            )}
            onDateChange={onDeliveryPeriodStartMonthChange}
            inputClassName={classes.leftPeriodInput}
            clearable
          />
          <PeriodPicker
            id="organization-form-export-delivery-periodPicker-right"
            label={t("app_campaign_form_end_date")}
            readonly={readonly || !exportAuthorization.canExportDelivery}
            period={wholeMonthOfOrUndefined(
              exportAuthorization.deliveryPeriodEndMonth
            )}
            displayEnd
            onDateChange={onDeliveryPeriodEndMonthChange}
            clearable
          />
        </div>
      </Box>
      <Box className={classes.exportBox}>
        <div>
          <span>
            {t("app_organizations_delivery_export_trace_authorization")}
          </span>
          <RadioGroup
            onChange={onTraceAuthorizationChange}
            className={classes.radioGroup}
            row
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Oui"
              checked={exportAuthorization.canExportTrace}
              disabled={readonly}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Non"
              checked={!exportAuthorization.canExportTrace}
              disabled={readonly}
            />
          </RadioGroup>
        </div>
        <div className={classes.title}>
          {t("app_organizations_delivery_export_trace_period")}
        </div>
        <div className={classes.period}>
          <PeriodPicker
            id="organization-form-export-trace-periodPicker-left"
            label={t("app_campaign_form_start_date")}
            readonly={readonly || !exportAuthorization.canExportTrace}
            period={wholeMonthOfOrUndefined(
              exportAuthorization.tracePeriodStartMonth
            )}
            onDateChange={onTracePeriodStartMonthChange}
            inputClassName={classes.leftPeriodInput}
            clearable
          />
          <PeriodPicker
            id="organization-form-export-trace-periodPicker-right"
            label={t("app_campaign_form_end_date")}
            readonly={readonly || !exportAuthorization.canExportTrace}
            period={wholeMonthOfOrUndefined(
              exportAuthorization.tracePeriodEndMonth
            )}
            displayEnd
            onDateChange={onTracePeriodEndMonthChange}
            clearable
          />
        </div>
      </Box>
    </Box>
  );
};
