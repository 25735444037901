import {
  Box,
  createStyles,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { BonusGroup, BonusGroupStateMachine } from "api/bonusGroup";
import { BonusGroupStatusUtils } from "api/bonusGroup/model/bonusStatus";
import { User } from "api/organization/users";
import { useAuth } from "auth/useAuth";
import { EditButton, StatusTags, TextButton, theme } from "components";
import { DateIntervalLabel } from "components/src/Component/DateInterval";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: theme.secondaryColor,
      fontWeight: 600,
      fontSize: 25,
    },
    icon: {
      "& .MuiButtonBase-root": {
        padding: 3,
      },
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    button: {
      marginLeft: 8,
      marginRight: 8,
      marginTop: "-25px",
      height: "32px",
      "& span": {
        color: theme.secondaryColor,
      },
    },
  })
);

interface BonusViewHeadBarProps {
  bonus: BonusGroup;
  currentUser: User | null;
  gotoBonusDetails: (bonusId: string) => void;
  gotoBonusEdit: (bonusId: string) => void;
}

const BonusViewHeadBar = (props: BonusViewHeadBarProps) => {
  const { bonus, currentUser, gotoBonusDetails, gotoBonusEdit } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const title = `Prime n°${bonus.id}`;
  const status = BonusGroupStatusUtils.getLabelStyleStatusTags(bonus.status);
  const isEditable = BonusGroupStateMachine.getAvailableActions(
    bonus.status.value,
    true
  ).canEdit;

  const [authService, keycloak] = useAuth();
  const isColisActivAdmin = authService.isColisActivAdmin(
    currentUser,
    keycloak
  );

  const handleDetailsClick = useCallback(() => {
    gotoBonusDetails(bonus.id);
  }, [gotoBonusDetails, bonus]);

  const handleEditClick = useCallback(() => {
    gotoBonusEdit(bonus.id);
  }, [gotoBonusEdit, bonus]);

  const isDisabled = useMemo(() => {
    return new Date().getTime() < bonus.creationDate + 1000 * 60 * 5;
  }, [bonus]);

  const disabledTooltipText = useMemo(() => {
    return isDisabled ? t("app_components_headbar_edit_disabled_tooltip") : "";
  }, [isDisabled, t]);

  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="flex-end" width="max-content">
          <Typography className={classes.title} component="h1">
            {title}
          </Typography>
        </Box>
        <StatusTags label={status.label} color={status.color} />
      </Box>
      {isColisActivAdmin &&
        (isEditable ? (
          <Tooltip title={disabledTooltipText} arrow>
            <div>
              <EditButton
                key={"bonus-edit-button"}
                className={classes.button}
                onClick={handleEditClick}
                label={t("app_components_headbar_edit")}
                disabled={isDisabled}
              />
            </div>
          </Tooltip>
        ) : (
          <TextButton
            key={"bonus-headbar-details-button"}
            className={classes.button}
            onClick={handleDetailsClick}
          >
            {t("app_bonus_headbar_details")}
          </TextButton>
        ))}
      <DateIntervalLabel
        start={bonus.period.startDate}
        end={bonus.period.endDate}
      />
    </Box>
  );
};

export default BonusViewHeadBar;
