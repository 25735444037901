import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "ca-plateform-bonus-group-domain";
import { http } from "../../http";

export interface BonusGroupStatusUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupStatusUpdatedEvent {
  type: string;
}

export const validateBonusGroup = async (
  bonusGroupId: string
): Promise<BonusGroupStatusUpdatedEvent> => {
  return http.put(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/validate`),
    null,
    "La prime a bien été validée"
  );
};
