import {connect} from "react-redux";
import {router} from "store/router";
import {State} from "../../../../store";
import {report} from "store/report";
import {profile} from "store/profile";
import {title} from "store/title";
import {global} from "store/global";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
  carriers: global.selectors.carrierRefs(state),
  currentReport: report.selectors.report(state),
});

const mapDispatchToProps = {
  fetchReport: report.queries.fetchReport,
  gotoReportEdit: router.goto.report.reportEdit,
  setHeadBar: title.actions.setHeadBar,
  updateCarrierReportStatus: report.commands.updateCarrierReportStatus
};

export default connect(mapStateToProps, mapDispatchToProps);
