import {CarrierReportStatus} from "../index";
import {buildCarrierReportApiUrl} from "../../configuration";
import {city} from "ca-plateform-carrier-report-domain";
import {http} from "../../http";

export interface UpdateCarrierReportStatusCommand
  extends city.colisactiv.platform.carrier.report.domain.features
    .UpdateCarrierReportStatusCommand {}

export interface CarrierReportStatusUpdatedEvent
  extends city.colisactiv.platform.carrier.report.domain.features
    .CarrierReportStatusUpdatedEvent {}

export const CarrierReportStatusUpdateCommand = (
  reportId: string,
  status: CarrierReportStatus
): UpdateCarrierReportStatusCommand => {
  return {
    type: "CREATE-CARRIER-REPORT",
    payload: {
      carrierReportId: reportId,
      status: status
    },
  };
};

export const updateCarrierReportStatusCommand = (
  reportId: string,
  status: CarrierReportStatus
): Promise<CarrierReportStatusUpdatedEvent> => {
  const statusMsg = "Le reporting a été mis à jour avec succès"
  return http.post(
    buildCarrierReportApiUrl(`/carrierReports/${reportId}/status`),
    JSON.stringify(
      CarrierReportStatusUpdateCommand(
        reportId,
        status
      )
    ),
    statusMsg
  );
};
