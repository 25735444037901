import { MenuItem } from '@smartb/archetypes-ui-layout'
import { city as cityBonus } from 'ca-plateform-bonus-domain'
import { city as cityBonusGroup } from 'ca-plateform-bonus-group-domain'
import { city as cityDom } from 'ca-plateform-organization-domain'
import React from 'react'
import { StatusTags, StatusTagsFormProps } from '../../Component/StatusTags'
import { PrimaryTypography, SecondaryTypography, Table } from '../../Component/Table'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { displayNumber, getNumberOfDaysFromNow } from '../../utils'
import { MonthDate } from '../../Component/MonthDate'
import { differenceInMinutesToString, getRelativeDate } from 'web-admin/src/utils'
import { differenceInMinutes } from 'date-fns'
interface Bonus extends cityBonus.colisactiv.plateform.bonus.model.Bonus {
  activeDeliveryTours: BonusDeliveryTour[]
  nonActiveDeliveryTours: BonusDeliveryTour[]
}
interface BonusGroup
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model.BonusGroup {
  bonuses: Bonus[]
}

class BonusGroupStatusValues extends cityBonus.colisactiv.plateform.bonus.model
  .BonusGroupStatusValues {}

interface BonusGroupIncident
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupIncident {}

class BonusGroupIncidentTypeValues extends cityBonusGroup.colisactiv.plateform
  .bonus.group.model.BonusGroupIncidentTypeValues {}

interface BonusDeliveryTour
  extends cityBonus.colisactiv.plateform.bonus.model.BonusDeliveryTour {}
class CarrierType extends cityDom.colisactiv.organization.model.CarrierType {}
class OperatorType extends cityDom.colisactiv.organization.model.OperatorType {}
interface OrganizationType
  extends cityDom.colisactiv.organization.model.OrganizationType {}

interface OrganizationRef
  extends cityDom.colisactiv.organization.model.references.OrganizationRef {
  profileImgUrl: string
}

export interface BonusRow {
  id: number
  period: number
  city: string
  operator: OrganizationRef
  carrier: OrganizationRef
  deliveries?: number
  deliveriesActiv?: number
  amount: number
  status: string
  unvalidData: boolean
  actions: MenuItem[]
  lastStatusModifiedDate: number
}

interface BonusTableProps {
  bonus: BonusGroup[]
  onRowClicked?: (row: BonusRow) => void
  totalPages: number
  page: number
  handlePageChange: (page: number) => void
  isLoading?: boolean
  getOrganizationRef: (id: string) => OrganizationRef | undefined
  getStatus: (row: BonusRow) => StatusTagsFormProps
  getActions?: (row: BonusGroup) => MenuItem[]
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: 100,
      maxWidth: 100
    },
    '& .rdt_TableCol:first-child': {
      minWidth: 100,
      maxWidth: 100
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      width: 160
    },
    '& .rdt_TableCol:nth-child(2n)': {
      width: 160
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '200px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '200px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '200px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '200px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(7n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(7n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(8n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(8n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '230px',
      maxWidth: '230px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '230px',
      maxWidth: '230px'
    }
  },
  redDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: '#E32628',
    marginRight: '10px'
  }
}))

export const BonusTable = (props: BonusTableProps) => {
  const {
    bonus,
    onRowClicked,
    totalPages,
    handlePageChange,
    page,
    isLoading,
    getStatus,
    getOrganizationRef,
    getActions
  } = props

  const unknown = (id: string, type: OrganizationType): OrganizationRef => ({
    displayName: 'Inconnue',
    organizationId: id,
    friendlyId: id,
    organizationType: type,
    profileImgUrl: '',
    hasLogo: false
  })

  const classes = useStyles()

  const { t } = useTranslation()

  const data: BonusRow[] = bonus.map(
    (bonusGroup): BonusRow => {
      const activeDeliveryCount = bonusGroup.bonuses
        .flatMap((bonus) => bonus.activeDeliveryTours)
        .reduce((deliveryCount, tour) => deliveryCount + tour.deliveryCount, 0)
      const nonActiveDeliveryCount = bonusGroup.bonuses
        .flatMap((bonus) => bonus.nonActiveDeliveryTours)
        .reduce((deliveryCount, tour) => deliveryCount + tour.deliveryCount, 0)
      const typeValues = new BonusGroupIncidentTypeValues()
      const bonusGroupStatusValues = new BonusGroupStatusValues()
      const unvalidData =
        bonusGroup.incidents &&
        bonusGroup.incidents.length > 0 &&
        bonusGroupStatusValues.isReported(bonusGroup.status.value) &&
        bonusGroup.incidents.some(
          (incident: BonusGroupIncident) =>
            typeValues.CARRIER_REPORT_INVALID_DATA.value === incident.type.value
        )
      return {
        id: bonusGroup.id,
        city: bonusGroup.territory.name,
        status: bonusGroup.status.value,
        carrier:
          getOrganizationRef(bonusGroup.carrierId) ||
          unknown(bonusGroup.carrierId, new CarrierType()),
        operator:
          getOrganizationRef(bonusGroup.operatorId) ||
          unknown(bonusGroup.carrierId, new OperatorType()),
        period: bonusGroup.period.startDate,
        actions: getActions ? getActions(bonusGroup) : [],
        deliveries: activeDeliveryCount + nonActiveDeliveryCount,
        deliveriesActiv: activeDeliveryCount,
        amount: bonusGroup.amount,
        unvalidData: unvalidData,
        lastStatusModifiedDate: bonusGroup.lastStatusModifiedDate
      }
    }
  )

  const columns = [
    {
      name: `${t('app_domain_bonus_id')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.id}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_period')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          <MonthDate date={row.period} />
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_territory')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.city}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_operateur')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.operator.displayName}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_chargeur')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.carrier.displayName}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_Livraison_effectuée')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.deliveries != null ? displayNumber(row.deliveries) : 0}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_active_delivery_detected')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.deliveriesActiv != null ? displayNumber(row.deliveriesActiv) : 0}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_amounts')} `,
      cell: (row: BonusRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {displayNumber(row.amount, 2)}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_bonus_status')} `,
      cell: (row: BonusRow) => {
        const status = getStatus(row)
        return (
          <Box
            display='flex'
            justifyContent='space-between'
            width='100%'
            alignItems='left'
            flexDirection='column'
          >
            <StatusTags {...status} />
            {row.unvalidData && <div className={classes.redDot} />}
            <SecondaryTypography
              data-tag='___react-data-table-allow-propagation___'
              align='left'
            >
              ({differenceInMinutesToString(differenceInMinutes(
                new Date(), row.lastStatusModifiedDate), t)
              })
            </SecondaryTypography>
          </Box>
        )
      }
    }
  ]
  return (
    <Table
      data={data}
      columns={columns}
      className={classes.container}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      isLoading={isLoading}
      page={page}
      noDataMessage={t('app_no_data_bonus_table')}
    />
  )
}
