import {
  Box,
  makeStyles,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";

import {
  BonusGroup,
  bonusGroupClient,
  BonusGroupFunder,
  BonusGroupFunderInvoiceDetails,
  BonusGroupInvoice,
  BonusGroupInvoiceType,
} from "api/bonusGroup";

import { BonusGroupStatusUtils } from "api/bonusGroup/model/bonusStatus";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { BonusActionsButtons } from "app/components/Bonus/BonusActionsButtons";
import { BonusIncidentBar } from "app/components/Bonus/BonusIncidentBar";
import BonusPanel from "app/components/Bonus/BonusPanel";
import BonusPdfViewer from "app/components/Bonus/BonusPdfViewer";
import { FunderInvoiceDetails } from "app/components/Bonus/FunderInvoiceDetails/FunderInvoiceDetails";
import { useAuth } from "auth/useAuth";
import {
  Attachment,
  Button,
  FileList as FileListComponent,
  HeadBarProps,
  Section,
  getMonthDate,
  theme,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayNumber, UTCToTimedZone } from "utils";
import Loading from "../../../components/Loading";
import BonusViewHeadBar from "./BonusViewHeadBar";
import withConnect from "./withConnect";
import { BonusLogTable } from "./BonusLogTable";
import { deliveryExportClient } from "api/deliveryExport";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  contentCenter: {},
  notificationContainer: {
    marginBottom: 8,
  },
  arrow: {
    marginLeft: "-8px",
    marginRight: "8px",
    marginTop: "-1px",
    width: "20px",
    height: "20px",
  },
  filesPanel: {
    marginBottom: "20px",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "20px",
  },
  title: {
    fontSize: "17px",
    marginTop: "-5px",
    marginBottom: "10px",
    marginLeft: "2px",
    lineHeight: 1.4,
    fontWeight: 600,
  },
  logButton: {
    width: "fit-content",
  },
  commentTextField: {
    minHeight: "100px",
    padding: "10px",
    fontSize: 12,
    fontFamily: '"Montserrat", Arial',
    display: "inline-block",
    verticalAlign: "middle",
    border: "none",
    resize: "none",
    borderRadius: "5px",
    boxShadow: "0px 9px 18px 0px rgba(0,0,0,0.05)",
    "&:focus": {
      outline: "ridge thin",
      outlineColor: theme.secondaryColor,
    },
  },
}));

interface Invoices {
  previous: {
    data: (BonusGroupInvoice & Attachment)[];
    validity: boolean;
  };
  carrier: {
    data: (BonusGroupInvoice & Attachment)[];
    validity: boolean;
  };
  funder: {
    data: Map<string, (BonusGroupInvoice & Attachment)[]>;
    validity: boolean;
  };
}

interface BonusViewProps {
  organizationRef: Map<string, OrganizationRef>;
  setHeadBar: (props: HeadBarProps) => void;
  bonus: BonusGroup | null;
  fetchBonus: (bonusId: string, force?: boolean) => void;
  currentUser: User | null;
  publishBonus: (bonusId: string) => void;
  rejectBonus: (bonusId: string, reasons: string) => void;
  validateBonus: (bonusId: string) => void;
  createBonusIncident: (bonusId: string, description: string) => void;
  resolveBonusIncident: (bonusId: string, incidentId: string) => void;
  deleteBonus: (bonusId: string) => Promise<void>;
  gotoBonusDetails: (bonusId: string) => void;
  gotoBonusEdit: (bonusId: string) => void;
}

const BonusView = (props: BonusViewProps) => {
  const {
    organizationRef,
    bonus,
    fetchBonus,
    setHeadBar,
    currentUser,
    publishBonus,
    rejectBonus,
    validateBonus,
    deleteBonus,
    createBonusIncident,
    resolveBonusIncident,
    gotoBonusDetails,
    gotoBonusEdit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [authService, keycloak] = useAuth();
  const { bonusId } = useParams();

  const [funders, setFunders] = useState<BonusGroupFunder[]>([]);
  const [currentPdf, setCurrentPdf] = useState<string>("");
  const [invoiceAddedId, setInvoiceAddedId] = useState<string>("");
  const [invoices, setInvoices] = useState<Invoices>({
    carrier: {
      data: [],
      validity: true,
    },
    previous: {
      data: [],
      validity: true,
    },
    funder: {
      data: new Map(),
      validity: true,
    },
  });
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [logPopUpIsOpen, setLogPopUpIsOpen] = useState<boolean>(false);

  const isColisActivAdmin = useMemo(
    () => authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const noInvoiceText = t("app_bonus_view_invoices_empty");

  const startDateString = useMemo(
    () => UTCToTimedZone(bonus?.period.startDate).toLocaleDateString(),
    [bonus]
  );
  const endDateString = useMemo(
    () => UTCToTimedZone(bonus?.period.endDate).toLocaleDateString(),
    [bonus]
  );

  useEffect(() => {
    fetchBonus(bonusId, true);
    bonusGroupClient.query.fetchBonusGroupFunders(bonusId).then(setFunders);
  }, [bonusId, fetchBonus]);

  useEffect(() => {
    if (bonus == null) {
      return;
    }

    const title = `Prime n°${bonus.id}`;
    setHeadBar({
      title: title,
      component: (
        <BonusViewHeadBar
          bonus={bonus}
          currentUser={currentUser}
          gotoBonusDetails={gotoBonusDetails}
          gotoBonusEdit={gotoBonusEdit}
        />
      ),
    });
  }, [bonus, currentUser, gotoBonusDetails, gotoBonusEdit, setHeadBar]);

  useEffect(() => {
    const input = document.getElementById("invoiceAdded");
    input && input.focus();
  }, [invoiceAddedId]);

  useEffect(() => {
    if (bonus?.invoices) {
      const previousInvoices: (BonusGroupInvoice & Attachment)[] = [];
      const carrierInvoices: (BonusGroupInvoice & Attachment)[] = [];
      const funderInvoices = new Map<
        string,
        (BonusGroupInvoice & Attachment)[]
      >();
      bonus.invoices.forEach((invoice: BonusGroupInvoice) => {
        switch (invoice.type.value) {
          case "PREVIOUS":
            previousInvoices.push({ ...invoice, cantBeRemoved: true });
            break;
          case "CUSTOM_PREVIOUS":
            previousInvoices.push(invoice);
            break;
          case "CARRIER":
            carrierInvoices.push(invoice);
            break;
          case "FUNDER":
            if (!funderInvoices.has(invoice.targetId)) {
              funderInvoices.set(invoice.targetId, []);
            }
            funderInvoices.get(invoice.targetId)!!.push(invoice);
            break;
        }
      });
      setInvoices((oldInvoices) => ({
        previous: {
          data: previousInvoices,
          validity: oldInvoices.previous.validity,
        },
        carrier: {
          data: carrierInvoices,
          validity: oldInvoices.carrier.validity,
        },
        funder: {
          data: funderInvoices,
          validity: oldInvoices.funder.validity,
        },
      }));
    }
  }, [bonus, fetchBonus]);

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(
        authService.hasRoles(currentUser, ["admin", "super_admin"], keycloak)
      );
    }
  }, [authService, currentUser, keycloak]);

  const memoizedCarrier = useMemo(() => {
    if (!bonus) return undefined;
    return organizationRef.get(bonus.carrierId);
  }, [organizationRef, bonus]);

  const memoizedOperator: OrganizationRef | undefined = useMemo(() => {
    if (!bonus) return undefined;
    return organizationRef.get(bonus.operatorId);
  }, [organizationRef, bonus]);

  const createInvoice = useCallback(
    async (file: File, type: BonusGroupInvoiceType, targetId?: string) => {
      if (currentUser) {
        const blobInvoice = new Blob([file], {
          type: file.type,
        });
        const reader = new FileReader();
        reader.readAsDataURL(blobInvoice);
        reader.onload = async () => {
          const event = await bonusGroupClient.createInvoice(
            bonusId,
            reader.result as string,
            file.name,
            0,
            currentUser.details.organizationId,
            type,
            targetId ?? ""
          );
          await fetchBonus(bonusId);
          event && event.payload && setInvoiceAddedId(event.payload.invoiceId);
        };
      }
    },
    [bonusId, fetchBonus, currentUser]
  );

  const createCarrierInvoice = useCallback(
    async (file: File) => {
      if (currentUser) {
        await createInvoice(file, { value: "CARRIER" });
        setInvoices((oldInvoices) => ({
          ...oldInvoices,
          carrier: { data: oldInvoices.carrier.data, validity: true },
        }));
      }
    },
    [currentUser, createInvoice]
  );

  const createHandleAddFunderInvoice = useCallback(
    (funderId: string) => {
      return async (file: File) => {
        if (currentUser) {
          await createInvoice(file, { value: "FUNDER" }, funderId);
          setInvoices((oldInvoices) => ({
            ...oldInvoices,
            funder: { data: oldInvoices.funder.data, validity: true },
          }));
        }
      };
    },
    [currentUser, createInvoice]
  );

  const createCustomPreviousInvoice = useCallback(
    async (file: File) => {
      if (currentUser) {
        await createInvoice(file, { value: "CUSTOM_PREVIOUS" });
      }
    },
    [currentUser, createInvoice]
  );

  const checkDocumentsValidity = useCallback(() => {
    const previousValidity = invoices.previous.validity;
    const carrierValidity =
      invoices.carrier.data.length > 0 &&
      invoices.carrier.data.every((invoice) => invoice.amount > 0);
    const funderValidity =
      invoices.funder.data.size > 0 &&
      Array.from(invoices.funder.data.values()).every((invoices) =>
        invoices.every(
          (invoice) => (invoice.details?.invoiceNumber ?? "") !== ""
        )
      );
    setInvoices({
      ...invoices,
      carrier: {
        data: invoices.carrier.data,
        validity: carrierValidity,
      },
      funder: {
        data: invoices.funder.data,
        validity: funderValidity,
      },
    });
    return carrierValidity && funderValidity && previousValidity;
  }, [invoices]);

  const handlePublishBonus = useCallback(
    (bonusId: string) => {
      if (!bonus) return;
      checkDocumentsValidity() && publishBonus(bonusId);
    },
    [checkDocumentsValidity, bonus, publishBonus]
  );

  const handleResolveIncidentBonus = useCallback(
    (bonusId: string, incidentId: string) => {
      checkDocumentsValidity() && resolveBonusIncident(bonusId, incidentId);
    },
    [checkDocumentsValidity, resolveBonusIncident]
  );

  const handleValidateBonus = useCallback(
    (bonusId: string) => {
      checkDocumentsValidity() && validateBonus(bonusId);
    },
    [validateBonus, checkDocumentsValidity]
  );

  const handleDeleteBonus = useCallback(
    async (bonusId: string) => deleteBonus(bonusId),
    [deleteBonus]
  );

  const deleteInvoice = useCallback(
    async (invoiceId: string) => {
      await bonusGroupClient.deleteInvoice(bonusId, invoiceId);
      await fetchBonus(bonusId);
    },
    [bonusId, fetchBonus]
  );

  const updateInvoice = useCallback(
    async (newAmount: number, invoice: Attachment) => {
      setInvoiceAddedId("");
      if (newAmount !== invoice.amount) {
        await bonusGroupClient.updateInvoice(bonusId, invoice.id, newAmount);
        await fetchBonus(bonusId);
      }
    },
    [bonusId, fetchBonus]
  );
  const onCLickAttachment = useCallback((attachment: Attachment) => {
    setCurrentPdf(attachment.file);
  }, []);

  const canAddAndRemoveCarrierInvoice = useMemo(() => {
    if (!bonus) return false;
    if (isColisActivAdmin && BonusGroupStatusUtils.isPublished(bonus.status)) {
      return true;
    }
    return (
      BonusGroupStatusUtils.isInvalid(bonus.status) ||
      BonusGroupStatusUtils.isDeducted(bonus.status) ||
      BonusGroupStatusUtils.isReported(bonus.status)
    );
  }, [bonus, isColisActivAdmin]);

  const canSeeFunderInvoice = useMemo(() => {
    if (!bonus) return true;
    return !BonusGroupStatusUtils.isReported(bonus.status);
  }, [bonus]);

  const funderBlockDescription = useMemo(() => {
    const carrier = bonus?.carrierId
      ? organizationRef.get(bonus?.carrierId)
      : undefined;
    const month = getMonthDate(bonus?.period.startDate, t, true).toLowerCase();
    return t("app_bonus_view_invoices_cofunder_description", {
      bonusId: bonus?.id,
      month: month,
      carrier: carrier?.displayName,
    });
  }, [bonus, organizationRef, t]);

  const funderSections = useMemo(() => {
    return funders.map((funder) => {
      const funderRef = organizationRef.get(funder.id);
      const funderInvoices = invoices.funder.data.get(funder.id) ?? [];
      return {
        title: `- ${
          funderRef && funderRef.organizationType.type === "FunderCee"
            ? t("app_bonus_view_invoices_sofub")
            : t("app_bonus_view_invoices_fundercee")
        } : ${displayNumber(funder.fundedAmount, 2)}€ (Nette de taxe)`,
        attachments: funderInvoices,
        noAttachmentText: noInvoiceText,
        canDownload: true,
        canAddFile:
          !!bonus &&
          canAddAndRemoveCarrierInvoice &&
          funderInvoices.length === 0,
        canChangePrice: false,
        fundedAmount: funder.fundedAmount,
        onRemoveFile: canAddAndRemoveCarrierInvoice ? deleteInvoice : undefined,
        onFileAdded: createHandleAddFunderInvoice(funder.id),
      };
    });
  }, [
    funders,
    organizationRef,
    invoices.funder.data,
    t,
    noInvoiceText,
    bonus,
    canAddAndRemoveCarrierInvoice,
    deleteInvoice,
    createHandleAddFunderInvoice,
  ]);

  const onDeduceBonus = useCallback(
    (bonusGroupId: string) =>
      bonusGroupClient.deduceBonusGroup(bonusGroupId).then(() => {
        fetchBonus(bonusGroupId);
      }),
    [fetchBonus]
  );

  const getAdditionnalNodesOfFunderInvoice = useCallback(
    (
      attachementId: string,
      fundedAmount?: number,
      details?: BonusGroupFunderInvoiceDetails,
      focusAttachementId?: string
    ) => {
      return (
        <FunderInvoiceDetails
          bonusId={bonusId}
          readonly={!canAddAndRemoveCarrierInvoice}
          fetchBonus={fetchBonus}
          isValid={invoices.funder.validity}
          details={details}
          isFocused={focusAttachementId === attachementId}
          attachmentId={attachementId}
        />
      );
    },
    [
      bonusId,
      invoices.funder.validity,
      fetchBonus,
      canAddAndRemoveCarrierInvoice,
    ]
  );

  const canChangePrice = (): boolean => {
    if (isColisActivAdmin) {
      return (
        canAddAndRemoveCarrierInvoice ||
        BonusGroupStatusUtils.isPublished(bonus.status)
      );
    }
    return canAddAndRemoveCarrierInvoice;
  };

  const onEditBonusGroupComment = useCallback(
    (comment: string) =>
      bonusGroupClient.editBonusGroupComment(bonusId, comment).then(() => {
        fetchBonus(bonusId);
      }),
    [bonusId, fetchBonus]
  );

  const handleDownloadImportFiles = useCallback(async () => {
    const objectUrl =
      await deliveryExportClient.query.downloadBonusGroupImports(bonusId);
    if (objectUrl == null) {
      return;
    }
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `Fichiers d'import de la prime ${bonusId}.zip`;
    a.click();
  }, [bonusId]);

  if (bonus === null) {
    return <Loading />;
  }

  return (
    <Section withGoBack>
      <Box className={classes.content}>
        <Box className={classes.contentCenter}>
          <BonusIncidentBar
            bonus={bonus}
            className={classes.notificationContainer}
            currentUser={currentUser}
          />
          <BonusPanel
            bonus={bonus}
            carrier={memoizedCarrier}
            operator={memoizedOperator}
            actions={
              <BonusActionsButtons
                deduceBonusGroup={onDeduceBonus}
                createBonusIncident={createBonusIncident}
                resolveBonusIncident={handleResolveIncidentBonus}
                publishBonus={handlePublishBonus}
                rejectBonus={rejectBonus}
                validateBonus={handleValidateBonus}
                deleteBonusGroup={handleDeleteBonus}
                checkDocumentsValidity={checkDocumentsValidity}
                bonus={bonus}
                carrier={memoizedCarrier}
                currentUser={currentUser}
              />
            }
          />
          <Box alignContent="flex-end" justifyContent="center" display="flex">
            <Box
              display="flex"
              width="420px"
              flexDirection="column"
              marginRight="20px"
            >
              <FileListComponent
                sections={[
                  {
                    title: `1- ${t("app_bonus_view_invoices_carrier")}`,
                    attachments: invoices.carrier.data,
                    noAttachmentText: noInvoiceText,
                    canAddFile: canAddAndRemoveCarrierInvoice,
                    canChangePrice: canChangePrice(),
                    description: t(
                      "app_bonus_view_invoices_carrier_description"
                    ),
                    canDownload: true,
                    withPrice: true,
                    onRemoveFile: canAddAndRemoveCarrierInvoice
                      ? deleteInvoice
                      : undefined,
                    onFileAdded: createCarrierInvoice,
                    onPriceChange: updateInvoice,
                  },
                ]}
                focusAttachementPriceId={invoiceAddedId}
                className={classes.filesPanel}
                onClickAttachement={onCLickAttachment}
                isValid={invoices.carrier.validity}
                errorMessage={t("app_bonus_view_invoices_carrier_error")}
              />
              {canSeeFunderInvoice && (
                <FileListComponent
                  description={funderBlockDescription}
                  title={`2- ${t("app_bonus_view_invoices_funder")}`}
                  sections={funderSections}
                  focusAttachementPriceId={invoiceAddedId}
                  className={classes.filesPanel}
                  getAdditionnalNodes={getAdditionnalNodesOfFunderInvoice}
                  onClickAttachement={onCLickAttachment}
                  isValid={invoices.funder.validity}
                  errorMessage={t("app_bonus_view_invoices_funder_error")}
                />
              )}
              <FileListComponent
                sections={[
                  {
                    title: `3- ${t(
                      "app_bonus_view_invoices_carrier_lastMonth",
                      {
                        currentMonth: new Date(
                          bonus.period.startDate
                        ).toLocaleDateString("fr-FR", { month: "long" }),
                      }
                    )}`,
                    description: t(
                      "app_bonus_view_invoices_carrier_lastMonth_description",
                      { startDate: startDateString, endDate: endDateString }
                    ),
                    attachments: invoices.previous.data,
                    noAttachmentText: noInvoiceText,
                    canAddFile: canAddAndRemoveCarrierInvoice,
                    withPrice: false,
                    canDownload: true,
                    onFileAdded: createCustomPreviousInvoice,
                    onRemoveFile: canAddAndRemoveCarrierInvoice
                      ? deleteInvoice
                      : undefined,
                  },
                ]}
                className={classes.filesPanel}
                onClickAttachement={onCLickAttachment}
              />
              {isAdmin && (
                <>
                  <Box className={classes.sectionContainer}>
                    <Typography variant="h5" className={classes.title}>
                      {t("app_bonus_view_comment_title")}
                    </Typography>
                    <TextareaAutosize
                      key={bonus.comment}
                      defaultValue={bonus.comment}
                      onBlur={(e) =>
                        e.target.value !== bonus.comment
                          ? onEditBonusGroupComment(e.target.value)
                          : ""
                      }
                      className={classes.commentTextField}
                    />
                  </Box>
                  <Box className={classes.sectionContainer}>
                    <Typography variant="h5" className={classes.title}>
                      {t("app_bonus_view_log_title")}
                    </Typography>
                    <Button
                      className={classes.logButton}
                      onClick={() => setLogPopUpIsOpen(true)}
                    >
                      {t("app_bonus_view_log_open_button")}
                    </Button>
                  </Box>
                  {isColisActivAdmin && (
                    <Box className={classes.sectionContainer}>
                      <Typography variant="h5" className={classes.title}>
                        {t("app_bonus_view_download_imports_title")}
                      </Typography>
                      <Button
                        className={classes.logButton}
                        onClick={() => handleDownloadImportFiles()}
                      >
                        {t("app_bonus_view_download_imports_open_button")}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <BonusPdfViewer
              pdf={currentPdf}
              bonusHasDocuments={bonus.invoices.length > 0}
            />
          </Box>
        </Box>
      </Box>
      {isAdmin && (
        <BonusLogTable
          bonus={bonus}
          logPopUpIsOpen={logPopUpIsOpen}
          setLogPopUpIsOpen={setLogPopUpIsOpen}
          fetchBonus={fetchBonus}
        />
      )}
    </Section>
  );
};

export default withConnect(BonusView);
