import React from 'react'
import { ReactComponent } from './down-arrow.svg'
import { MergeReactElementProps } from '../types'

interface DownloadProps {
  color?: string
}

type Props = MergeReactElementProps<'svg',DownloadProps>

export const Download = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#9a9a9a' } = props
    return <ReactComponent  ref={ref} {...props} fill={color}/>
  }
)
