import { fetchGeoZone } from "./query/fetchGeoZoneQuery";
import { city as cityPlat } from "ca-plateform-plateform-commons";
import { city as cityGeo } from "ca-plateform-geozone-domain";
import { fetchTerritories } from "./query/fetchTerritoriesQuery";
import {createGeoZoneCommand} from "./command/createGeoZoneCommand";
import {archiveGeoZoneCommand} from "./command/archiveGeoZoneCommand";
import { getGeoZoneData } from "./query/getGeoZoneDataQuery";

export interface GeoZonePage
  extends cityPlat.colisactiv.platform.commons.Page<GeoZone> {}

export interface GeoZone
  extends cityGeo.colisactiv.platform.geozone.model.GeoZone {}

export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}

export interface TerritoryPage
  extends cityPlat.colisactiv.platform.commons.Page<Territory> {}

export const geoZoneClient = {
  query: {
    getGeoZones: fetchGeoZone,
    getTerritories: fetchTerritories,
    getGeoZoneData: getGeoZoneData
  },
  createGeoZone: createGeoZoneCommand,
  archiveGeoZone: archiveGeoZoneCommand
};
