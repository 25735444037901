import {city as cityPolyline} from "ca-plateform-polyline-domain";
import {fetchPolylineListQuery} from "./query/fetchPolylineListQuery";

export interface Polyline
  extends cityPolyline.colisactiv.plateform.polyline.domain.Polyline {
}

export interface GetPolylineListQuery
  extends cityPolyline.colisactiv.plateform.polyline.domain.features
    .GetPolylineListQuery {}

export interface GetPolylineListQueryDTO
  extends cityPolyline.colisactiv.plateform.polyline.domain.features
    .GetPolylineListQueryDTO {}


export const polylineClient = {
  query: {
    fetchPolylineListQuery: fetchPolylineListQuery
  },
};
