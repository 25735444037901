import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { city as cityGeo } from 'ca-plateform-geozone-domain'
import { PrimaryTypography } from '../../Component/Table'
import { TerritoryRow } from './TerritoryTable'
import { ArchiveIcon, PopUpConfirmation } from '../..'
import { useTranslation } from "react-i18next";

export interface GeoZone
  extends cityGeo.colisactiv.platform.geozone.model.GeoZone {}

export interface GeoZoneRow {
  id: string,
  code: string,
  name: string
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '150px'
    }
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  line: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 15,
    marginTop: 5
  },
  lineItem: {
    alignItems: 'center',
    minHeight: '30px',
    cursor: 'pointer'
  }
}))

interface GeoZoneTableProps {
  data?: TerritoryRow
  onGeoZoneClicked: (row: GeoZone) => void
  onGeoZoneArchiveButtonClicked: (row: GeoZone) => void
  geoZoneFilterValue: string
  isColisActivAdmin: boolean
}

export const GeoZoneTable = (props: GeoZoneTableProps) => {
  const {
    data,
    onGeoZoneClicked,
    onGeoZoneArchiveButtonClicked,
    geoZoneFilterValue,
    isColisActivAdmin
  } = props

  const classes = useStyles()
  const { t } = useTranslation();
  const [geoZoneConcerned, setGeoZoneConcerned] = useState<GeoZone | undefined>(
    undefined
  )
  const [askArchiveConfirmation, setAskArchiveConfirmation] = useState<boolean>(
    false
  );

  const handleGeoZoneClick = useCallback((zone: GeoZone) => {
    onGeoZoneClicked(zone)
  }, [onGeoZoneClicked])

  const handleArchiveGeoZoneButtonClick = useCallback((zone: GeoZone) => {
    console.log(zone)
    setGeoZoneConcerned(zone)
    setAskArchiveConfirmation(true)
  }, [setAskArchiveConfirmation])

  const handleConfirmArchive = useCallback(() => {
    if (!geoZoneConcerned) return;
    onGeoZoneArchiveButtonClicked(geoZoneConcerned)
    setAskArchiveConfirmation(false)
  }, [onGeoZoneArchiveButtonClicked, geoZoneConcerned])

  const handleCloseArchiveConfirmation = useCallback(() => {
    setAskArchiveConfirmation(false)
  }, [onGeoZoneArchiveButtonClicked])

  const lines = useMemo(() => {
    return data?.zones.map((zone) => {
      if (zone.isCustom == true && zone.isArchived == false && zone.name.includes(geoZoneFilterValue)) {
        return (
          <Box key={`box-${zone.id}`} className={classes.line} >
            <Box
              key={zone.id}
              className={classes.lineItem}
              onClick={() => handleGeoZoneClick(zone)}

            >
              <PrimaryTypography>{zone.name}</PrimaryTypography>
            </Box>
            {isColisActivAdmin && (
              <Box
                key={`archive-button-${zone.id}`}
                className={classes.lineItem}
                onClick={() => handleArchiveGeoZoneButtonClick(zone)}
              >
                <ArchiveIcon
                  className={classes.icon}
                  color={"#98A5AE"}
                />
              </Box>
            )}
          </Box>
        )
      }
    })
  }, [data])

  return (
    <Box key='geozone-table' display='flex' flexDirection='column' alignItems='left'>
      {lines}
      <PopUpConfirmation
        title={t("app_geozone_archive_popup_confirmation_title")}
        onClose={handleCloseArchiveConfirmation}
        onConfirm={handleConfirmArchive}
        open={askArchiveConfirmation}
      >
        <Typography variant="body1">
          {t("app_geozone_archive_popup_confirmation_body")}
        </Typography>
      </PopUpConfirmation>
    </Box>
  )
}
