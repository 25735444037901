import { Box, Divider, makeStyles, Typography } from '@material-ui/core'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Panel } from '../Panel'
import { useTranslation } from 'react-i18next'
import { Add } from '@material-ui/icons'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import ReactDropzone, { FileRejection } from 'react-dropzone'
import { Button } from '../Button'
import { File as CAFile } from '../File'
import { city as cityBonusGroup } from 'plateform-bonus-group-domain'

export interface BonusGroupFunderInvoiceDetails
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupFunderInvoiceDetails {}

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    container: {
      padding: '15px',
      '& .ColisactivPanel-content,.ColisactivPanel-footer': {
        padding: '0px !important'
      },
      '& .ColisactivPanel-divider': {
        width: '100%',
        left: '0px'
      }
    },
    title: {
      fontSize: '17px',
      marginLeft: '-5px',
      marginTop: '-5px',
      marginBottom: '5px',
      lineHeight: 1.4,
      color: theme.secondaryColor,
      fontWeight: 600
    },
    sectionTitle: {
      fontSize: '15px',
      lineHeight: 1.4,
      color: theme.secondaryColor,
      fontWeight: 600
    },
    subtitle: {
      fontSize: '15px',
      color: theme.secondaryColor
    },
    addIcon: {
      width: '15px',
      height: '15px',
      color: theme.secondaryColor,
      marginRight: '5px'
    },
    error: {
      color: '#bd1313',
      fontSize: '12px'
    },
    button: {
      padding: '5px 9px',
      border: `1px solid ${theme.secondaryColor}`,
      fontSize: '12px',
      borderRadius: '4px',
      textTransform: 'none',
      '& span': {
        color: theme.secondaryColor
      }
    },
    errorMessage: {
      color: '#EC8A90',
      fontSize: '12px',
      marginBottom: '15px'
    },
    description: {
      color: 'rgba(0,0,0,0.5)',
      fontStyle: 'italic',
      fontSize: '11px'
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    divider: {
      marginBottom: '15px'
    }
  }))

export interface Attachment {
  file: string
  id: string
  name: string
  amount?: number
  details?: BonusGroupFunderInvoiceDetails
}

export interface FileSection {
  title: string
  subTitle?: string
  description?: string
  attachments: Attachment[]
  canAddFile?: boolean
  canDownload?: boolean
  canChangePrice?: boolean
  withPrice?: boolean
  noAttachmentText?: string
  fundedAmount?: number
  onRemoveFile?: (attachementId: string) => void
  onFileAdded?: (file: File) => void
  onPriceChange?: (newAmount: number, attachment: Attachment) => void
}

interface FileListProps {
  title?: string
  description?: string
  sections: FileSection[]
  onClickAttachement?: (attachment: Attachment) => void
  getAdditionnalNodes?: (
    attachementId: string,
    fundedAmount?: number,
    detaisl?: BonusGroupFunderInvoiceDetails,
    focusAttachementId?: string
  ) => React.ReactNode
  focusAttachementPriceId?: string
  errorMessage?: string
  isValid?: boolean
  className?: string
  style?: React.CSSProperties
}

export const FileList = (props: FileListProps) => {
  const {
    sections,
    title,
    className,
    style,
    onClickAttachement,
    focusAttachementPriceId,
    errorMessage = '',
    isValid = true,
    getAdditionnalNodes,
    description
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()
  const [error, setError] = useState<string>('Insérez une seule image valide')
  const [validity, setValidity] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (focusAttachementPriceId) {
      const input = document.getElementById(focusAttachementPriceId)
      input && input.focus()
    }
  }, [focusAttachementPriceId])

  const totalPrice = useMemo(() => {
    let total = 0
    sections.forEach((section) => {
      total += section.attachments.reduce((total, attachment) => {
        return total + (attachment.amount ?? 0)
      }, 0)
    })
    return total
  }, [sections])

  const onDownLoad = useCallback((attachment: Attachment) => {
    const base64data = attachment.file.replace(
      'data:application/pdf;base64,',
      ''
    )
    const bs = atob(base64data)
    const buffer = new ArrayBuffer(bs.length)
    const ba = new Uint8Array(buffer)
    for (var i = 0; i < bs.length; i++) {
      ba[i] = bs.charCodeAt(i)
    }
    const blob = new Blob([ba], { type: 'application/pdf' })
    const a = document.createElement('a')
    const url = URL.createObjectURL(blob)
    a.href = url
    a.download = attachment.name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, [])

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const element = document.activeElement as HTMLElement
      if (element && element.classList.contains('FileList-priceInput')) {
        element.blur()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  const getFooter = useCallback((): React.ReactNode | undefined => {
    let displayPrice = false
    sections.forEach((section) => {
      if (section.attachments.length !== 0 && section.withPrice)
        displayPrice = true
    })
    if (!displayPrice) return undefined
    return (
      <Box
        display='flex'
        paddingTop='15px'
        width='100%'
        justifyContent='flex-end'
        alignItems='center'
      >
        <Typography variant='body2'>{`${t('app_bonus_total')}:`}</Typography>
        <Typography
          variant='h6'
          className={classes.sectionTitle}
          style={{ marginLeft: '15px' }}
        >{`${totalPrice.toLocaleString()} €`}</Typography>
      </Box>
    )
  }, [sections, classes, totalPrice])

  const onReject = (files: FileRejection[]) => {
    setError('Insérez un seul fichier valide')
    files.forEach((file) => {
      if (file.errors[0].code === 'file-too-large')
        setError('La taille de votre fichier ne doit pas dépasser 10Mo')
      if (file.errors[0].code === 'too-many-files')
        setError("N'insérez qu'un seul fichier")
      if (file.errors[0].code === 'file-invalid-type')
        setError('Seul les fichiers au format pdf sont acceptés')
    })
    setValidity(false)
  }

  return (
    <Panel
      footer={getFooter()}
      className={`${classes.container} ${className}`}
      style={style}
    >
      {title && (
        <Typography variant='h5' className={classes.title}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant='body2' className={classes.description}>
          {description}
        </Typography>
      )}
      {sections.map((section, index) => (
        <div key={'fileListComponent' + section.title}>
          <Box
            display='flex'
            justifyContent='space-between'
            width='100%'
            marginBottom='10px'
            alignItems='center'
            key={section.title}
          >
            <Box flexDirection='column' justifyContent='center'>
              <Typography variant='h6' className={classes.sectionTitle}>
                {section.title}
              </Typography>
              {section.subTitle && (
                <Typography variant='body1' className={classes.subtitle}>
                  {section.subTitle}
                </Typography>
              )}
            </Box>
            {section.canAddFile && (
              <ReactDropzone
                onDropAccepted={(files) => {
                  section.onFileAdded && section.onFileAdded(files[0])
                  setValidity(true)
                }}
                onDropRejected={(files) => onReject(files)}
                multiple={false}
                accept={'application/pdf'}
                maxSize={11000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className={classes.buttonContainer} {...getRootProps()}>
                    <Button
                      onClick={() => {}}
                      className={classes.button}
                      icon={<Add className={classes.addIcon} />}
                      variant='outlined'
                    >
                      {t('app_bonus_add')}
                      <input {...getInputProps()} />
                    </Button>
                  </div>
                )}
              </ReactDropzone>
            )}
          </Box>
          {section.description && (
            <Typography variant='body2' className={classes.description}>
              {section.description}
            </Typography>
          )}
          {!validity && (
            <Typography variant='body2' className={classes.error}>
              {error}
            </Typography>
          )}
          <Box
            position='relative'
            display='flex'
            flexDirection='column'
            width='100%'
          >
            {section.attachments.map((attachment, index) => (
              <>
                <CAFile
                  key={attachment.id}
                  style={{
                    marginBottom:
                      index === section.attachments.length - 1 ? '15px' : ''
                  }}
                  attachment={attachment}
                  canChangePrice={section.canChangePrice}
                  onClickAttachement={onClickAttachement}
                  onPriceChange={section.onPriceChange}
                  onDownloadFile={section.canDownload ? onDownLoad : undefined}
                  onRemoveFile={section.onRemoveFile}
                  withPrice={section.withPrice}
                />
                {getAdditionnalNodes &&
                  getAdditionnalNodes(
                    attachment.id,
                    section.fundedAmount,
                    attachment.details,
                    focusAttachementPriceId
                  )}
              </>
            ))}
            {section.attachments.length === 0 && (
              <Typography variant='body2' align='center'>
                {section.noAttachmentText ?? 'Aucun fichier à afficher'}
              </Typography>
            )}
            {!isValid && (
              <Typography variant='body2' className={classes.errorMessage}>
                {errorMessage}
              </Typography>
            )}
          </Box>
          {index !== sections.length - 1 && (
            <Divider className={classes.divider} />
          )}
        </div>
      ))}
    </Panel>
  )
}
