import {buildBonusGroupStatsApiUrl} from "api/configuration";
import { DateInterval } from 'components/src/Domain/BonusPayment'
import { http } from "../../http";
import { city } from "ca-plateform-poi-dashboard-domain";

export interface GetBonusStatsCommandBase
  extends city.colisactiv.plateform.poi.dashboard.domain.features.GetBonusStatsCommandBase {}

export const exportBonusGroupStats = async (
  startPeriod?: DateInterval,
  endPeriod?: DateInterval
): Promise<string | null> => {
  const query: GetBonusStatsCommandBase = {
    payload: {
      startPeriod: startPeriod,
      endPeriod: endPeriod
    }
  }
  return http.fetchDownloadableFile(
    buildBonusGroupStatsApiUrl(`/bonusgroupstats/export`),
    "Une erreur est survenue lors de l'export des primes",
    "post",
    JSON.stringify(query)
  );
};
