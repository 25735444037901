import { buildOrganizationApiUrl } from "api/configuration";
import { DeliveryTourPage } from "..";
import { http } from "../../http";
import { city as citydel } from "ca-plateform-campaign-delivery-domain";

interface QueueForceAcceptDeliveryToursCommand
  extends citydel.colisactiv.platform.campaign.delivery.model.features
    .QueueDeliveryToursForceAcceptCommand { }

interface DeliveryToursForceAcceptQueuedEventBase
  extends citydel.colisactiv.platform.campaign.delivery.model.features
    .DeliveryTourForceAcceptQueuedEventBase { }

const queueForceAcceptDeliveryToursCommand = (
  tourIds: string[]
): QueueForceAcceptDeliveryToursCommand => {
  return {
    type: "QUEUE_ACCEPT_DELIVERY_TOURS",
    payload: {
      tourIds: tourIds,
    },
  };
};

export const queueForceAcceptDeliveryTours = (
  tourIds: string[]
): Promise<DeliveryToursForceAcceptQueuedEventBase[]> => {
  return http.post(
    buildOrganizationApiUrl(`/campaignDelivery/tours/queueForceAccept`),
    JSON.stringify(queueForceAcceptDeliveryToursCommand(tourIds)),
    `${tourIds.length > 1 ? "les" : "la"} livraison${tourIds.length > 1 ? "s" : ""} ${tourIds.length > 1 ? "ont" : "a"} bien été validée${tourIds.length > 1 ? "s" : ""}`
  );
};
