import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AutoComplete, InputForm, Option, MultiSelectBox } from "components";
import { useTranslation } from "react-i18next";
import { VisitorFormState } from "./model";
import { Territory } from "components/src/Domain/GeoZone";
import { geoZoneClient } from "api/geoZone";
import {
  VisitorRole,
  VisitorRoleBase,
  VisitorRoleValues,
} from "api/organization";

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
    marginTop: "20px",
  },
  input: {
    width: "100%",
    marginTop: "20px",
  },
  autoComplete: {
    width: "500px",
  },
  selectStyle: {
    justifyContent: "center",
    marginTop: 11,
    minHeight: "2.1rem",
    "& .MuiFormControl-root": {
      width: "500px",
    },
  },
}));

interface VisitorFormProps {
  visitorFormState: VisitorFormState;
  onDetailsChange: (visitorFormState: VisitorFormState) => void;
  operators: Option[];
  carriers: Option[];
  readonly: boolean;
}

export const NO_ERROR = undefined;

export const VisitorForm = (props: VisitorFormProps) => {
  const { visitorFormState, onDetailsChange, operators, carriers, readonly } =
    props;
  const [autocompleteText, setAutocompleteText] = useState(
    "Rechercher un territoire"
  );
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  );
  const [operatorId, setOperatorId] = useState<string | undefined>(
    visitorFormState.watchedOperatorId
  );
  const [carrierId, setCarrierId] = useState<string | undefined>(
    visitorFormState.watchedCarrierId
  );
  const defaultPlaceholder = "Tous par défaut";
  const carrierSelectedPlaceholder =
    "Tous ceux liés au transporteur selectionné";
  const operatorSelectedPlaceholder =
    "Tous ceux liés à l'opérateur selectionné";
  const [operatorPlaceholder, setOperatorPlaceholder] = useState<string>(
    visitorFormState.watchedCarrierId
      ? carrierSelectedPlaceholder
      : defaultPlaceholder
  );
  const [carrierPlaceholder, setCarrierPlaceholder] = useState<string>(
    visitorFormState.watchedOperatorId
      ? operatorSelectedPlaceholder
      : defaultPlaceholder
  );

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setOperatorId(visitorFormState.watchedOperatorId);
    setCarrierId(visitorFormState.watchedCarrierId);
    setOperatorPlaceholder(
      visitorFormState.watchedCarrierId
        ? carrierSelectedPlaceholder
        : defaultPlaceholder
    );
    setCarrierPlaceholder(
      visitorFormState.watchedOperatorId
        ? operatorSelectedPlaceholder
        : defaultPlaceholder
    );
  }, [visitorFormState]);

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const territories = Array.isArray(territory) ? territory : [territory];
      onDetailsChange({
        ...visitorFormState,
        territories: territories,
      });
    },
    [onDetailsChange, visitorFormState]
  );

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([]);
      setAutocompleteText("Rechercher un territoire");
      return;
    }

    try {
      const response = await geoZoneClient.query.getTerritories(
        0,
        5,
        search.trim()
      );
      setAutoCompleteOptions(response.list);
      if (response.list.length === 0) {
        setAutocompleteText("Aucun territoire trouvé");
      }
    } catch (err) {
      console.error(err);
      setAutoCompleteOptions([]);
      setAutocompleteText("Une erreur est survenue, veuillez réessayer");
    }
  }, []);

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name;
  }, []);

  const handleOperatorChange = useCallback(
    (operator?: string) => {
      setOperatorId(operator);
      setCarrierId(undefined);
      if (operator) {
        setCarrierPlaceholder(operatorSelectedPlaceholder);
      } else {
        setOperatorPlaceholder(defaultPlaceholder);
        setCarrierPlaceholder(defaultPlaceholder);
      }
      onDetailsChange({
        ...visitorFormState,
        watchedOperatorId: operator,
        watchedCarrierId: undefined,
      });
    },
    [onDetailsChange, visitorFormState]
  );

  const handleCarrierChange = useCallback(
    (carrier?: string) => {
      setOperatorId(undefined);
      setCarrierId(carrier);
      if (carrier) {
        setOperatorPlaceholder(carrierSelectedPlaceholder);
      } else {
        setOperatorPlaceholder(defaultPlaceholder);
        setCarrierPlaceholder(defaultPlaceholder);
      }
      onDetailsChange({
        ...visitorFormState,
        watchedOperatorId: undefined,
        watchedCarrierId: carrier,
      });
    },
    [onDetailsChange, visitorFormState]
  );

  const getRoleOption = (role: VisitorRole): Option => {
    return {
      label: t(`app_organizations_visitor_${role.role}`),
      value: role.role,
    };
  };

  const dashboardIndicatorRoles: Array<Option> = [
    VisitorRoleValues.READ_DASHBOARD_BONUS_TOTAL,
    VisitorRoleValues.READ_DASHBOARD_BONUS_PER_PACKAGE,
    VisitorRoleValues.READ_DASHBOARD_DELIVERY_TIME,
    VisitorRoleValues.READ_DASHBOARD_ACTIVE_DELIVERIES,
    VisitorRoleValues.READ_DASHBOARD_ACTIVE_PACK_COUNT,
    VisitorRoleValues.READ_DASHBOARD_HUB_COUNT,
    VisitorRoleValues.READ_DASHBOARD_AVERAGE_DISTANCE_PER_DELIVERY,
    VisitorRoleValues.READ_DASHBOARD_TOUR_AMOUNT,
    VisitorRoleValues.READ_DASHBOARD_DISTANCE_TOTAL,
    VisitorRoleValues.READ_DASHBOARD_THERMAL_KM_AVOIDED,
    VisitorRoleValues.READ_DASHBOARD_CO2_AVOIDED,
    VisitorRoleValues.READ_DASHBOARD_NOX_PPM_AVOIDED,
  ].map(getRoleOption);

  const dashboardIndicatorRoleValues = useMemo(() => {
    return visitorFormState.roles
      .filter((role: VisitorRole) => {
        return dashboardIndicatorRoles
          .map((option: Option) => option.value)
          .includes(role.role);
      })
      .map((role: VisitorRole) => role.role);
  }, [visitorFormState, dashboardIndicatorRoles]);

  const mapRoles: Array<Option> = [
    VisitorRoleValues.READ_HEAT_MAP,
    VisitorRoleValues.READ_FLUX_MAP,
  ].map(getRoleOption);

  const mapRoleValues = useMemo(() => {
    return visitorFormState.roles
      .filter((role: VisitorRole) => {
        return mapRoles
          .map((option: Option) => option.value)
          .includes(role.role);
      })
      .map((role: VisitorRole) => role.role);
  }, [visitorFormState, mapRoles]);

  const handleDashboardIndicatorRolesChange = useCallback(
    (roles: string[]) => {
      onDetailsChange({
        ...visitorFormState,
        roles: visitorFormState.roles
          .filter(
            (role: VisitorRole) =>
              !dashboardIndicatorRoleValues.includes(role.role)
          )
          .concat(
            roles.map((role: string): VisitorRoleBase => {
              return { role };
            })
          ),
      });
    },
    [onDetailsChange, visitorFormState, dashboardIndicatorRoleValues]
  );

  const handleMapRolesChange = useCallback(
    (roles: string[]) => {
      if (visitorFormState) {
        onDetailsChange({
          ...visitorFormState,
          roles: visitorFormState.roles
            .filter((role: VisitorRole) => !mapRoleValues.includes(role.role))
            .concat(
              roles.map((role: string): VisitorRoleBase => {
                return { role };
              })
            ),
        });
      }
    },
    [onDetailsChange, visitorFormState, mapRoleValues]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.input}>
        {readonly && visitorFormState.territories.length === 0 ? (
          <InputForm
            id="visitor-add-territorries-readonly"
            label={t("app_organizations_visitor_territories")}
            inputType="textField"
            readonly={true}
            className={classes.autoComplete}
            value={
              t("app_user_default_territory") +
              t("app_user_default_territory_detail")
            }
          />
        ) : (
          <AutoComplete<Territory>
            id="visitor-add-territorries"
            label={t("app_organizations_visitor_territories")}
            className={classes.autoComplete}
            multiple
            options={autoCompleteOptions}
            defaultValue={visitorFormState.territories}
            noOptionsText={autocompleteText}
            onChangeSelectedElement={handleTerritoryChange}
            onSearch={handleSearchTerritory}
            getOptionLabel={getTerritoryName}
            readonly={readonly}
            error={!visitorFormState.territories}
            errorMessage={t("app_report_form_please_define_the_city")}
          />
        )}
      </Box>
      <Box className={classes.input}>
        <InputForm
          inputType={"select"}
          onChange={handleOperatorChange}
          selectOptions={operators}
          value={operatorId}
          onRemoveValue={() => handleOperatorChange(undefined)}
          label={t("app_organizations_visitor_operator")}
          placeHolder={operatorPlaceholder}
          className={classes.selectStyle}
          id="operator"
          readonly={readonly}
        />
      </Box>
      <Box className={classes.input}>
        <InputForm
          inputType={"select"}
          onChange={handleCarrierChange}
          selectOptions={carriers}
          value={carrierId}
          onRemoveValue={() => handleCarrierChange(undefined)}
          label={t("app_organizations_visitor_carrier")}
          placeHolder={carrierPlaceholder}
          className={classes.selectStyle}
          id="carrier"
          readonly={readonly}
        />
      </Box>
      <Box className={classes.input}>
        <MultiSelectBox
          label={t("app_organizations_visitor_dashboard_indicator_roles")}
          id={"indicators"}
          onChange={handleDashboardIndicatorRolesChange}
          options={dashboardIndicatorRoles}
          className={classes.selectStyle}
          values={dashboardIndicatorRoleValues}
          readonly={readonly}
        />
      </Box>
      <Box className={classes.input}>
        <MultiSelectBox
          label={t("app_organizations_visitor_map_roles")}
          id={"maps"}
          onChange={handleMapRolesChange}
          options={mapRoles}
          className={classes.selectStyle}
          values={mapRoleValues}
          readonly={readonly}
        />
      </Box>
    </Box>
  );
};
