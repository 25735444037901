import { buildBonusGroupApiUrl } from "api/configuration";
import { BonusGroup } from "..";
import { http } from "../../http";

export const fetchOperatorsByTerritoriesQuery = (): Promise<{[territoryCode: string]: string[]}> => {
  return http.get(
    buildBonusGroupApiUrl(`/bonusgroup/territories`),
    "la récupération des operateurs n'a pas pu être effectuée"
  );
};
