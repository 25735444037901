import { makeStyles } from '@material-ui/core'
import 'date-fns'
import React, { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateInterval } from '.'
import { AutoComplete } from '../../Component/AutoComplete'
import { InputForm } from '../../Component/InputForm'
import { PeriodPicker } from '../../Component/PeriodPicker'
import { Option } from '../../Component/Select'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: '0px'
    },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  },
  container: {
    display: 'flex'
  }
}))

export interface BonusPaymentFiltersModel {
  startDate?: number | null | undefined
  endDate?: number | null | undefined
  status?: string
  operator?: OperatorOption
}
export type OperatorOption = {
  label: string
  value: string
}
interface BonusPaymentFiltersProps {
  operators: OperatorOption[]
  filterValues: BonusPaymentFiltersModel
  onFilterChange: (values: BonusPaymentFiltersModel) => void
}
export const BonusPaymentFilters = (props: BonusPaymentFiltersProps) => {
  const { operators, filterValues, onFilterChange } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedPeriod, setSelectedPeriod] = useState<DateInterval | undefined>(
    filterValues.startDate == null
      ? undefined
      : {
          startDate: filterValues.startDate!,
          endDate: filterValues.endDate!
        }
  )

  const [operator, setOperator] = useState<OperatorOption | undefined>(
    filterValues.operator
  )
  const BonusPaymentStatusOptions: Option[] = [
    {
      label: t('app_bonusPayment_valid_status') as string,
      value: 'CREATED'
    },
    {
      label: t('app_bonusPayment_paid_status') as string,
      value: 'PAID'
    }
  ]

  const handlePeriodChange = useCallback(
    (period: DateInterval | undefined) => {
      setSelectedPeriod(period)

      onFilterChange({
        startDate: period?.startDate,
        endDate: period?.endDate,
        operator: operator,
        status: filterValues.status
      })
    },
    [onFilterChange, filterValues]
  )

  return (
    <Fragment>
      <PeriodPicker
        id='filterPeriod'
        placeholder={'Période'}
        period={selectedPeriod ?? undefined}
        onDateChange={handlePeriodChange}
        inputClassName={classes.filter}
        clearable
      />
      <AutoComplete
        id='BonusPayment-filters-operator'
        options={operators}
        defaultValue={operator}
        label=''
        onSearch={() => {}}
        className={`${classes.filter}`}
        onChangeSelectedElement={(operator: OperatorOption) => {
          onFilterChange({
            startDate: selectedPeriod?.startDate,
            endDate: selectedPeriod?.endDate,
            operator: operator,
            status: filterValues.status
          })
          setOperator(operator)
        }}
        placeholder='Opérateur'
        noOptionsText={
          operators.length > 0 ? 'Aucun opérateur trouver' : 'Aucun opérateur'
        }
        getOptionLabel={(operator: OperatorOption) => operator.label}
      />
      <InputForm
        inputType={'select'}
        onChange={(status) =>
          onFilterChange({
            startDate: selectedPeriod?.startDate,
            endDate: selectedPeriod?.endDate,
            operator: operator,
            status: status
          })
        }
        selectOptions={BonusPaymentStatusOptions}
        label={''}
        value={filterValues.status}
        placeHolder={t('app_domain_project_project_status')}
        className={`${classes.filter} project-filters`}
        id='BonusPayment-filters-status'
        onRemoveValue={() =>
          onFilterChange({
            startDate: selectedPeriod?.startDate,
            endDate: selectedPeriod?.endDate,
            operator: operator,
            status: undefined
          })
        }
      />
    </Fragment>
  )
}
