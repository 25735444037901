import { push } from "connected-react-router";
import { gotoProject } from "./router.project.goto";
import { gotoOrganization } from "./router.organization.goto";
import { gotoReports } from "./router.reports.goto";
import { gotoUser } from "./router.user.goto";
import { gotoBonus } from "./router.bonus.goto";
import { gotoBonusPayment } from "./router.bonusPayment.goto";
import { gotoGeoZone } from "./router.geoZone.goto";
import { gotoImportTours } from "./router.import.goto";

const dashboard = (
  startDate?: number,
  endDate?: number,
  operatorId?: string,
  carrierId?: string,
  areaName?: string,
  areaId?: string,
  areaCode?: string
) => {
  let filters: string[] = [];
  if (startDate) filters.push(`startDate=${startDate}`);
  if (endDate) filters.push(`endDate=${endDate}`);
  if (operatorId) filters.push(`operatorId=${operatorId}`);
  if (carrierId) filters.push(`carrierId=${carrierId}`);
  if (areaName) filters.push(`areaName=${areaName}`);
  if (areaId) filters.push(`areaId=${areaId}`);
  if (areaCode) filters.push(`areaCode=${areaCode}`);
  return push(`/dashboard?${filters.join("&")}`);
};

const api = () => push(`/api`);

const bonusView = (bonusId: string) => push(`/bonus/${bonusId}/view`);

const notifications = (pinned?: boolean) =>
  push(
    `/notifications?${
      pinned !== undefined ? "pinned=" + pinned : "pinned=false"
    }`
  );

const campaignDelivery = (
  page?: number,
  size?: number,
  startDate?: number,
  endDate?: number,
  operatorId?: string,
  areaName?: string,
  areaId?: string,
  areaCode?: string,
  minPackage?: number,
  maxPackage?: number
) => {
  let path = `/campaignDelivery?page=${page ?? 0}&size=${size ?? 100}`;
  if (startDate) path += `&startDate=${startDate}`;
  if (endDate) path += `&endDate=${endDate}`;
  if (operatorId) path += `&operatorId=${operatorId}`;
  if (areaName) path += `&areaName=${areaName}`;
  if (areaId) path += `&areaId=${areaId}`;
  if (areaCode) path += `&areaCode=${areaCode}`;
  if (minPackage) path += `&minPackage=${minPackage}`;
  if (maxPackage) path += `&maxPackage=${maxPackage}`;
  return push(path);
};

const pushUrl = (url: string) => push(url);

const flux = (
  operatorIds: string[] = [],
  days: string[] = [],
  minDate?: number,
  maxDate?: number,
  minTime?: number,
  maxTime?: number,
  areaName?: string,
  areaId?: string,
  areaCode?: string,
  dashboardId?: string
) => {
  let filters: string[] = [];
  if (minDate) filters.push(`minDate=${minDate}`);
  if (maxDate) filters.push(`maxDate=${maxDate}`);
  if (minTime) filters.push(`minTime=${minTime}`);
  if (maxTime) filters.push(`maxTime=${maxTime}`);
  if (days.length !== 0) filters.push(`days=${days.toString()}`);
  if (operatorIds.length !== 0)
    filters.push(`operatorIds=${operatorIds.toString()}`);
  if (areaName) filters.push(`areaName=${areaName}`);
  if (areaId) filters.push(`areaId=${areaId}`);
  if (areaCode) filters.push(`areaCode=${areaCode}`);
  if (dashboardId) filters.push(`dashboardId=${dashboardId}`);
  return push(`/flux?${filters.join("&")}`);
};

export const goto = {
  push: pushUrl,
  organization: gotoOrganization,
  project: gotoProject,
  user: gotoUser,
  report: gotoReports,
  bonus: gotoBonus,
  bonusPayment: gotoBonusPayment,
  geoZone: gotoGeoZone,
  import: gotoImportTours,
  dashboard: dashboard,
  api: api,
  bonusView: bonusView,
  notifications: notifications,
  campaignDelivery: campaignDelivery,
  flux: flux,
};
