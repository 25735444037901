import { Grid, makeStyles } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { CarrierReport, carrierReportClient, CarrierReportStatus } from "api/report";
import {
  CarrierReportModeIncidentState,
  carrierReportModeIncidentStateValues,
} from "api/report/model/reportIncidentState";
import {
  carrierReportIncidentStatusValues,
  ReportStatusUtils,
} from "api/report/model/reportStatus";
import Loading from "app/components/Loading";
import { showCarrierInput } from "app/components/ReportForm";
import { useAuth } from "auth/useAuth";
import {
  Button,
  DownloadButton,
  HeadBarProps,
  HeadBarView,
  InputForm,
  Notification,
  SectionForm,
  WarningMessage,
} from "components";
import { city as cityReport } from "ca-plateform-carrier-report-domain";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayNumber, UTCToTimedZone } from "utils";
import withConnect from "./withConnect";
import { lastDayOfMonth, max } from "date-fns";
import {carrierReportStatusValues, ReportReminder} from "components/src/Domain/Report";

type CarrierReportIncident = cityReport.colisactiv.platform.carrier.report.domain.CarrierReportIncident;

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "15px 0",
  },
  selectStyle: {
    minWidth: 250,
    width: "100%",
  },
  title: {
    marginBottom: 10,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
    marginTop: "20px",
  },
  inputClassName: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    "&:first-child": {
      marginRight: "20px",
    },
  },
  bordredWarningInputStyle: {
    border: "1px solid #ff9900",
    borderRadius: 5,
  },
  bordredInputStyle: {
    border: "1px solid #D1DCE7",
    borderRadius: 5,
  },
  downloadButton: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: '-25px',
    height: '32px'
  },
  button: {
    margin: "0 10px",
  }
}));

const isInvalidValue = (field: string | undefined): boolean => {
  return field == null || field !== "VALID";
};
const isOneInvalidValue = (kmNumb: string, packNumb: string): boolean => {
  return kmNumb !== "VALID" || packNumb !== "VALID";
};

const incidentMessage = (
  kmState: CarrierReportModeIncidentState,
  packState: CarrierReportModeIncidentState,
  date: number,
  mode: string
): string => {
  const isGreater =
    carrierReportModeIncidentStateValues.isGreaterThanLimit(packState.value) ||
    carrierReportModeIncidentStateValues.isGreaterThanLimit(kmState.value);
  const isPackValid = carrierReportModeIncidentStateValues.isValid(
    packState.value
  );
  const isKmValid = carrierReportModeIncidentStateValues.isValid(kmState.value);

  const previousMonth = getPreviousMonthName(date);
  const variationText = isGreater ? "augmenté" : "diminué";

  const incidentedValues = [];
  if (!isKmValid) incidentedValues.push("kilomètres");
  if (!isPackValid) incidentedValues.push("colis");

  return `La proportion de livraisons ${mode} a ${variationText} de plus de 10% en nombre de ${incidentedValues.join(
    " et "
  )} par rapport au mois de ${previousMonth}.`;
};

const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
const getPreviousMonthName = (date: number): string => {
  const currentDate = new Date(date);

  if (currentDate.getMonth() === 0)
    return ` Décembre ${currentDate.getFullYear() - 1}`;

  return monthNames[currentDate.getMonth() - 1];
};
interface ReportViewFormProps {
  setHeadBar: (title: HeadBarProps) => void;
  fetchReport: (reportId: string) => void;
  currentReport: CarrierReport | null;
  currentUser: User | null;
  carriers: Map<string, OrganizationRef>;
  gotoReportEdit: (reportId: string) => void;
  updateCarrierReportStatus: (carrierReport: CarrierReport, newStatus: CarrierReportStatus) => void;
}

const ReportView = (props: ReportViewFormProps) => {
  const {
    fetchReport,
    currentReport,
    currentUser,
    carriers,
    gotoReportEdit,
    setHeadBar,
    updateCarrierReportStatus
  } = props;
  const { reportId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const [authService, keycloak] = useAuth();

  const isCarrier = useMemo(
    () =>
      authService.isCarrier(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const incident = useMemo(() => {
    if (currentReport == null) return null;

    const incidents = currentReport.incidents as CarrierReportIncident[];
    return incidents.find((incident) =>
      carrierReportIncidentStatusValues.isCreated(incident.status.value)
    );
  }, [currentReport?.incidents]);

  useEffect(() => {
    fetchReport(reportId);
  }, [reportId, fetchReport]);

  const ownsTerritory = (currentUser: User | null, territoryCode: string): boolean => {
    const userTerritoryCodes = currentUser ? currentUser.details.territories.map(territory => territory.code) : []
    return userTerritoryCodes.length > 0 ? userTerritoryCodes.includes(territoryCode) : true
  }

  const isReportPeriodOver = useMemo(() => {
    if (currentReport == null) return false
    if (currentReport.period.endDate < Date.now()) return true
    else return false
  }, [currentReport])

  const editable = useCallback((): boolean => {
    if (!currentReport) return false;

    if ((authService.isColisActivAdmin(currentUser, keycloak) ||
      (authService.isCarrier(currentUser, keycloak) && ownsTerritory(currentUser, currentReport.territory.code))) &&
      isReportPeriodOver
    ) {
      if (
        ReportStatusUtils.isCreated(currentReport.status.value) ||
        ReportStatusUtils.isInvalid(currentReport.status.value)
      )
        return true;
    }
    return false;
  }, [currentReport, keycloak, authService, currentUser]);

  const handleCarrierReportExport = useCallback(() => {
    if (currentReport == null) return;
    carrierReportClient.query.exportReport(currentReport.id).then((objectUrl) => {
      if (objectUrl == null) {
        return;
      }
      const a = document.createElement("a");
      a.href = objectUrl;
      a.download = `Rapport transporteur ${currentReport.id}.pdf`;
      a.click();
    });
  }, [currentReport])

  const handleCarrierReportValidation = useCallback(() => {
    if (currentReport == null) return;
    updateCarrierReportStatus(currentReport, {value: "APPROVED"})
  }, [currentReport])

  const handleCarrierReportInvalidation = useCallback(() => {
    if (currentReport == null) return;
    updateCarrierReportStatus(currentReport, {value: "CREATED"})
  }, [currentReport])

  const displayValidationButtons = useCallback(() => {
    if (currentReport == null) return false;
    if (!authService.isColisActivAdmin(currentUser, keycloak)) return false;
    return true;
  }, [currentReport])

  useEffect(() => {
    if (currentReport) {
      setHeadBar({
        title: `${t("app_reports_view_monthly_report")} `,
        component: (
          <HeadBarView
            title={t("app_reports_view_monthly_report")}
            status={ReportStatusUtils.getLabelStyleStatusTags(
              currentReport.status,
              isCarrier
            )}
            onEdit={
              editable() ? () => gotoReportEdit(currentReport.id) : undefined
            }
            children={!carrierReportStatusValues.isCreated(currentReport.status.value) && (
              <DownloadButton
                key={"reports-view-download-button"}
                className={classes.downloadButton}
                onClick={handleCarrierReportExport}
                label={t("app_reports_view_download_button")}
              />
            )}
          />
        ),
      });
    }
  }, [currentReport, setHeadBar, editable, isCarrier]);

  if (!currentReport) {
    return <Loading />;
  }

  return (
    <SectionForm withGoBack>
      {currentReport.status.value === "CREATED" &&
        <ReportReminder
          carrierReportCreationDate={currentReport.creationDate}
        />
      }
      <Grid container direction="row">
        <Grid item xs={6} className={classes.grid}>
          <InputForm
            value={carriers.get(currentReport.carrierId)?.displayName}
            id="carrier-select"
            label={t("app_reports_view_the_carrier")}
            inputType="textField"
            readonly={true}
            className={classes.selectStyle}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6} className={classes.grid}>
          <InputForm
            value={`Du ${UTCToTimedZone(
              currentReport.period.startDate
            ).toLocaleDateString()} au ${UTCToTimedZone(
              currentReport.period.endDate
            ).toLocaleDateString()} `}
            id="startDate"
            label={t("app_reports_view_the_period")}
            inputType="textField"
            readonly={true}
            className={classes.selectStyle}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6} className={classes.grid}>
          <InputForm
            value={currentReport.territory.name}
            id="endDate"
            label={t("app_reports_view_territory")}
            inputType="textField"
            readonly={true}
            className={classes.selectStyle}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <div className={classes.title}>
          <span> {t("app_reports_view_active_mode")}</span>
        </div>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6} className={classes.grid}>
          <InputForm
            id="activeModeModeKM"
            value={displayNumber(currentReport.activeMode.kmNumber)}
            label={t("app_reports_view_nb_Kilometers")}
            inputType="textField"
            className={classes.inputClassName}
            readonly={true}
            inputStyle={{
              border:
                ReportStatusUtils.isInvalid(currentReport.status.value) &&
                isInvalidValue(incident?.activeModeIncident?.kmState?.value) &&
                !isCarrier
                  ? "1px solid #ff9900"
                  : "transparent",
              borderRadius: 5,
            }}
          />
          <InputForm
            id="activeModeModeColis"
            value={displayNumber(currentReport.activeMode.packNumber)}
            label={t("app_reports_view_nb_of_parcels")}
            inputType="textField"
            className={classes.inputClassName}
            readonly={true}
            inputStyle={{
              border:
                ReportStatusUtils.isInvalid(currentReport.status.value) &&
                isInvalidValue(
                  incident?.activeModeIncident?.packState?.value
                ) &&
                !isCarrier
                  ? "1px solid #ff9900"
                  : "transparent",
              borderRadius: 5,
            }}
          />
        </Grid>
        {incident &&
          !isCarrier &&
          isOneInvalidValue(
            incident.activeModeIncident.kmState.value,
            incident.activeModeIncident.packState.value
          ) && (
            <Grid item xs={12}>
              <WarningMessage
                message={incidentMessage(
                  incident.activeModeIncident.kmState,
                  incident.activeModeIncident.packState,
                  currentReport.period.startDate,
                  "actives"
                )}
              />
            </Grid>
          )}
      </Grid>
      <Grid container direction="row">
        <div className={classes.title}>
          <span> {t("app_reports_view_electric_vehicle")}</span>
        </div>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6} className={classes.grid}>
          <InputForm
            id="electricalModeKM"
            value={displayNumber(currentReport.electricMode.kmNumber)}
            label={t("app_reports_view_Nb_Kilometers")}
            inputType="textField"
            className={classes.inputClassName}
            readonly={true}
            inputStyle={{
              border:
                ReportStatusUtils.isInvalid(currentReport.status.value) &&
                !isCarrier &&
                isInvalidValue(incident?.electricModeIncident?.kmState?.value)
                  ? "1px solid #ff9900"
                  : "transparent",
              borderRadius: 5,
            }}
          />
          <InputForm
            id="electricalModeColis"
            value={displayNumber(currentReport.electricMode.packNumber)}
            label={t("app_reports_view_Nb_of_parcels")}
            inputType="textField"
            className={classes.inputClassName}
            readonly={true}
            inputStyle={{
              border:
                ReportStatusUtils.isInvalid(currentReport.status.value) &&
                !isCarrier &&
                isInvalidValue(incident?.electricModeIncident?.packState?.value)
                  ? "1px solid #ff9900"
                  : "transparent",
              borderRadius: 5,
            }}
          />
        </Grid>
        {incident &&
          !isCarrier &&
          isOneInvalidValue(
            incident.electricModeIncident.kmState.value,
            incident.electricModeIncident.packState.value
          ) && (
            <Grid item xs={12}>
              <WarningMessage
                message={incidentMessage(
                  incident.electricModeIncident.kmState,
                  incident.electricModeIncident.packState,
                  currentReport.period.startDate,
                  "electriques"
                )}
              />
            </Grid>
          )}
      </Grid>

      <Grid container direction="row">
        <div className={classes.title}>
          <span> {t("app_reports_view_thermal_vehicle")}</span>
        </div>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6} className={classes.grid}>
          <InputForm
            id="thermalModeKM"
            value={displayNumber(currentReport.thermalMode.kmNumber)}
            label={t("app_reports_view_Nb_Kilometers")}
            inputType="textField"
            className={classes.inputClassName}
            readonly={true}
            inputStyle={{
              border:
                ReportStatusUtils.isInvalid(currentReport.status.value) &&
                !isCarrier &&
                isInvalidValue(incident?.thermalModeIncident?.kmState?.value)
                  ? "1px solid #ff9900"
                  : "transparent",
              borderRadius: 5,
            }}
          />
          <InputForm
            id="thermalModeColis"
            value={displayNumber(currentReport.thermalMode.packNumber)}
            label={t("app_reports_view_Nb_of_parcels")}
            inputType="textField"
            className={classes.inputClassName}
            readonly={true}
            inputStyle={{
              border:
                ReportStatusUtils.isInvalid(currentReport.status.value) &&
                !isCarrier &&
                isInvalidValue(incident?.thermalModeIncident?.packState?.value)
                  ? "1px solid #ff9900"
                  : "transparent",
              borderRadius: 5,
            }}
          />
        </Grid>
        {incident &&
          !isCarrier &&
          isOneInvalidValue(
            incident.thermalModeIncident.kmState.value,
            incident.thermalModeIncident.packState.value
          ) && (
            <Grid item xs={12}>
              <WarningMessage
                message={incidentMessage(
                  incident.thermalModeIncident.kmState,
                  incident.thermalModeIncident.packState,
                  currentReport.period.startDate,
                  "thermiques"
                )}
              />
            </Grid>
          )}
      </Grid>

      {displayValidationButtons() &&
        <>
        {
          currentReport.status.value == "APPROVED"
            ?
              <Button className={classes.button} onClick={handleCarrierReportInvalidation}>
                {t("app_reports_edit_invalidation")}
              </Button>
            :
              <Button className={classes.button} onClick={handleCarrierReportValidation}>
                {t("app_reports_edit_validation")}
              </Button>
        }
        </>
      }
    </SectionForm>
  );
};

export default withConnect(ReportView);
