import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import Backend from "i18next-xhr-backend";

export type LanguageType = "fr";

export const getLanguage = (): LanguageType => {
  return i18n.language as LanguageType;
};

export const changeLanguage = (lang: LanguageType) => {
  i18n.changeLanguage(lang);
};

i18n
  .use(Backend)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: ["fr"],
    lng: "fr",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      customHeaders: {
        "Cache-Control": 'no-cache',
      },
    },
  });

export default i18n;
