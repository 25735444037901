import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";

export interface PrepareSignConventionCommand
  extends city.colisactiv.organization.model.features.convention
    .PrepareSignConventionCommand {}

export interface ConventionSignPreparedEvent
  extends city.colisactiv.organization.model.features.convention
    .ConventionSignPreparedEvent {}

export const prepareSignConvention = (
  organizationId: string,
  redirectUrl: string
): Promise<ConventionSignPreparedEvent> => {
  const command: PrepareSignConventionCommand = {
    type: "PREPARE_SIGN_CONVENTION",
    payload: {
      organizationId: organizationId,
      redirectUrl: redirectUrl,
    },
  };
  return http.post(
    buildOrganizationApiUrl(`/organizations/${organizationId}/convention/sign`),
    JSON.stringify(command)
  );
};
