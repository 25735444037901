import {Territory} from "api/geoZone";
import {Campaign, CreateProjectCommandDTO, ProjectRef, ProjectStats,} from "api/project/model";
import {createActionCreator} from "deox";

export interface ProjectCampaignIds {
  projectId: string;
  campaignId: string;
}
const createProject = createActionCreator(
  "PROJECT_CREATE",
  (resolve) => (project: CreateProjectCommandDTO) => resolve({ project })
);

const createdProject = createActionCreator(
  "PROJECT_CREATED",
  (resolve) => (project: ProjectStats) => resolve({ project: project })
);

const resetProject = createActionCreator("PROJECT_CREATED");

const fetchProject = createActionCreator(
  "PROJECT_FETCH",
  (resolve) => (projectId: string) => resolve({ projectId: projectId })
);
const fetchedProject = createActionCreator(
  "PROJECT_FETCHED",
  (resolve) => (project: ProjectStats) => resolve({ project: project })
);

const fetchTerritories = createActionCreator("PROJECT_AREAS_FETCH");
const fetchedTerritories = createActionCreator(
  "PROJECT_AREAS_FETCHED",
  (resolve) => (territories: Territory[]) =>
    resolve({ territories: territories })
);

const fetchCampaign = createActionCreator(
  "CAMPAIGN_FETCH",
  (resolve) => (projectId: string, campaignId: string) =>
    resolve({ projectId: projectId, campaignId: campaignId })
);
const fetchedCampaign = createActionCreator(
  "CAMPAIGN_FETCHED",
  (resolve) => (project: ProjectStats, campaignId: string) =>
    resolve({ project: project, campaignId: campaignId })
);

const addCampaignToProject = createActionCreator(
  "ADD_CAMPAIGN_TO_PROJECT",
  (resolve) => (campaign: Campaign) => resolve({ campaign })
);

const updatedCampaign = createActionCreator(
  "UPDATED_CAMPAIGN_TO_PROJECT",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const updatedProject = createActionCreator(
  "PROJECT_UPDATE",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const fetchProjectsRefs = createActionCreator("PROJECT_REFS_FETCH");
const fetchedProjectRefs = createActionCreator(
  "PROJECT_REFS_FETCHED",
  (resolve) => (projectRefs: ProjectRef[]) =>
    resolve({ projectRefs: projectRefs })
);
const deletedCampaign = createActionCreator(
  "REMOVED_CAMPAIGN_FROM_PROJECT",
  (resolve) => (project: ProjectStats) => resolve({ project })
);
const duplicatedCampaign = createActionCreator(
  "DUPLICATED_CAMPAIGN_FROM_PROJECT",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const publishedCampaign = createActionCreator(
  "PUBLISHED_CAMPAIGN",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const archivedCampaign = createActionCreator(
  "ARCHIVED_CAMPAIGN",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const pausedCampaign = createActionCreator(
  "PAUSED_CAMPAIGN",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const startedCampaign = createActionCreator(
  "STARTED_CAMPAIGN",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

const updatedCampaignNotification = createActionCreator(
  "UPDATED_CAMPAIGN_NOTIFICATION",
  (resolve) => (project: ProjectStats) => resolve({ project })
);

export const actions = {
  createProject: createProject,
  createdProject: createdProject,

  resetProject: resetProject,

  fetchProjectRefs: fetchProjectsRefs,
  fetchedProjectRefs: fetchedProjectRefs,

  fetchProject: fetchProject,
  fetchedProject: fetchedProject,

  fetchTerritories: fetchTerritories,
  fetchedTerritories: fetchedTerritories,

  fetchCampaign: fetchCampaign,
  fetchedCampaign: fetchedCampaign,

  addCampaignToProject: addCampaignToProject,
  updatedCampaign: updatedCampaign,
  updatedProject: updatedProject,
  deletedCampaign: deletedCampaign,
  duplicatedCampaign: duplicatedCampaign,

  publishedCampaign: publishedCampaign,
  archivedCampaign: archivedCampaign,
  pausedCampaign: pausedCampaign,
  startedCampaign: startedCampaign,
  updatededCampaignNotification: updatedCampaignNotification,
};
