import { Box } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputForm } from "components";
import { useTranslation } from "react-i18next";
import { PaymentInfoFormState } from "./model";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    marginTop: "30px",
  },
  input: {
    width: "70%",
  },
  text: {
    width: "550px !important",
    alignContent: "center",
    marginTop: "5px",
  },
  inputForm: {
    marginTop: "30px",
    width: "550px !important",
  },
  reminderRuleIcon: {
    color: "#f44336",
    alignItems: "center",
    display: "flex",
  },
  reminderRuleMessage: {
    color: "#f44336",
    fontWeight: "bold",
  },
  reminderIcon: {
    color: "#ff9900",
    alignItems: "center",
    display: "flex",
  },
  reminderMessage: {
    color: "#ff9900",
    fontWeight: "bold",
    padding: "8px",
  },
  reminderContainer: {
    display: "flex",
    gap: "15px",
    background: "white",
    width: "100%",
    padding: "5px",
    marginBottom: "20px",
  },
  severityBar: {
    width: "3px",
    minWidth: "3px",
    borderRadius: "30px",
    marginRight: "5px",
  },
}));

interface PaymentFromProps {
  payment: PaymentInfoFormState;
  onDetailsChange?: (payment: PaymentInfoFormState) => void;
  readonly: boolean;
}
export const NO_ERROR = undefined;

export const PaymentInfoForm = (props: PaymentFromProps) => {
  const { payment, onDetailsChange, readonly } = props;

  const onIbanChange = (iban: string) => {
    if (onDetailsChange) {
      onDetailsChange({
        ...payment,
        iban: iban,
        validity: { ...payment.validity, iban: NO_ERROR },
      });
    }
  };

  const onBicChange = (bic: string) => {
    if (onDetailsChange) {
      onDetailsChange({
        ...payment,
        bic: bic,
        validity: { ...payment.validity, bic: NO_ERROR },
      });
    }
  };

  const onTvaChange = (tva: string) => {
    if (onDetailsChange) {
      onDetailsChange({
        ...payment,
        tva: tva,
        validity: { ...payment.validity, tva: NO_ERROR },
      });
    }
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.reminderContainer}>
        <div
          className={classes.severityBar}
          style={{ background: "rgb(244, 67, 54)" }}
        />
        <div className={classes.reminderRuleIcon}>
          <ReportProblemOutlinedIcon className={classes.reminderRuleIcon} />
        </div>
        <div className={classes.reminderRuleMessage}>
          {t("app_organization_payment_rule_reminder")}
        </div>
      </Box>
      <InputForm
        value={
          payment.iban === undefined && readonly
            ? `${t("app_payment_info_form_no_information_recorded")} `
            : payment.iban
        }
        onChange={onIbanChange}
        id="organisation-add-payment-iban"
        error={payment.validity.iban !== NO_ERROR}
        errorMessage={payment.validity.iban}
        inputType="textField"
        label={t("app_payment_info_form_IBAN")}
        placeHolder="Ex: FR76 4007 7654 0000 6578 3245 378"
        inputClassName={readonly ? classes.text : classes.input}
        className={classes.inputForm}
        readonly={readonly}
      />
      <InputForm
        value={
          payment.bic === undefined && readonly
            ? `${t("app_payment_info_form_no_information_recorded")} `
            : payment.bic
        }
        onChange={onBicChange}
        id="organisation-add-payment-bic"
        error={payment.validity.bic !== NO_ERROR}
        errorMessage={payment.validity.bic}
        inputType="textField"
        label={t("app_payment_info_form_BIC")}
        placeHolder="Ex: AGRIMQMX"
        inputClassName={readonly ? classes.text : classes.input}
        className={classes.inputForm}
        readonly={readonly}
      />
      <InputForm
        value={
          payment.tva === undefined && readonly
            ? `${t("app_payment_info_form_no_information_recorded")} `
            : payment.tva
        }
        onChange={onTvaChange}
        id="organisation-add-payment-tva"
        error={payment.validity.tva !== NO_ERROR}
        errorMessage={payment.validity.tva}
        inputType="textField"
        label={t("app_payment_info_form_TVA")}
        placeHolder="Ex: FR 00 449565217"
        inputClassName={readonly ? classes.text : classes.input}
        className={classes.inputForm}
        readonly={readonly}
      />
    </Box>
  );
};
