import { makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { OrganizationRef } from "api/organization";
import "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BonusPrice, ProjectStats } from "../../../api/project/model";
import BonusPriceRow from "./BonusPriceRow";
import { BonusPriceFormValidity, CampaignFormModel } from "./Model";
import {
  CampaignValidation,
  defaultBonusPriceFormValidity,
} from "./validation";

const useStyles = makeStyles(() => ({
  addBonusRule: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    color: "#007DCE",
    fontSize: "14px",
  },
  addCircleIcon: {
    marginRight: 5,
    width: "14px",
    height: "29px",
  },
}));

interface BonusPriceRowsProps {
  bonus: BonusPrice[];
  validity: BonusPriceFormValidity[];
  onBonusPriceChange: (
    Bonus: BonusPrice[],
    validation: BonusPriceFormValidity[]
  ) => void;
  readonly: boolean;
  carrierRefs: OrganizationRef[];
  operatorRefs: OrganizationRef[];
  project: ProjectStats | null;
  gotoGeoZoneCreate: () => void;
  isColisActivAdmin: boolean;
}

const BonusPriceRows = (props: BonusPriceRowsProps) => {
  const {
    bonus,
    onBonusPriceChange,
    validity,
    readonly,
    carrierRefs,
    operatorRefs,
    project,
    gotoGeoZoneCreate,
    isColisActivAdmin
  } = props;
  const classes = useStyles();
  const [validityState, setValidity] = useState(validity);
  const { t } = useTranslation();

  const addBonus = useCallback(() => {
    let [isValid, validation] = CampaignValidation.bonusPriceValidation(bonus);
    if (!isValid) {
      return setValidity(validation);
    }
    onBonusPriceChange(
      [...bonus, CampaignFormModel.defaultBonusPrice],
      [...validation, defaultBonusPriceFormValidity]
    );
  }, [bonus, onBonusPriceChange]);

  useEffect(() => {
    setValidity(validity);
  }, [validity]);

  const onChangeBonus = useCallback(
    (typeInput: keyof BonusPrice, position: number, value: any) => {
      const bonusCopy = [...bonus];
      bonusCopy[position] = { ...bonusCopy[position], [typeInput]: value };
      onBonusPriceChange(bonusCopy, validityState);
    },
    [bonus, bonus, onBonusPriceChange]
  );

  const onBonusDeleted = useCallback(
    (position: number): void => {
      const bonusCopy = [...bonus];
      const bonusValidationCopy = [...validityState];
      bonusCopy.splice(position, 1);
      bonusValidationCopy.splice(position, 1);
      onBonusPriceChange(bonusCopy, bonusValidationCopy);
    },
    [bonus, onBonusPriceChange]
  );

  const bonusPriceRows = useMemo(
    () =>
      bonus.map((el, index) => (
        <BonusPriceRow
          onChangeBonus={onChangeBonus}
          onBonusDeleted={onBonusDeleted}
          bonus={el}
          bonusValidation={
            validityState[index] || defaultBonusPriceFormValidity
          }
          key={"bonusPrice: " + index}
          position={index}
          bonusLength={bonus.length}
          operatorRefs={operatorRefs}
          readOnly={readonly}
          project={project}
          carrierRefs={carrierRefs}
          gotoGeoZoneCreate={gotoGeoZoneCreate}
          isColisActivAdmin={isColisActivAdmin}
        />
      )),
    [
      bonus,
      validityState,
      operatorRefs,
      project,
      carrierRefs,
      readonly,
      onBonusDeleted,
      onChangeBonus,
    ]
  );

  return (
    <>
      {bonusPriceRows}
      {!readonly && (
        <div className={classes.addBonusRule} onClick={addBonus}>
          <AddCircleIcon className={classes.addCircleIcon} />
          {t("app_campaign_form_bonus_price_rows_new_tariff")}
        </div>
      )}
    </>
  );
};

export default BonusPriceRows;
