import { makeStyles } from '@material-ui/core'
import 'date-fns'
import React, { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoComplete } from '../../Component/AutoComplete'
import { InputForm } from '../../Component/InputForm'
import { Period, PeriodPicker } from '../../Component/PeriodPicker'
import { city as cityGeo } from 'plateform-geozone-domain'
import { city as cityPlat } from 'plateform-plateform-commons'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px'
      },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  },
  container: {
    display: 'flex'
  }
}))

interface GeoZone extends cityGeo.colisactiv.platform.geozone.model.GeoZone {}
interface GeoZonePage
  extends cityPlat.colisactiv.platform.commons.Page<GeoZone> {}

export type Option = {
  label: string
  value: string
}

export interface CampaignDeliveryFiltersModel {
  period?: Period
  operatorId?: string
  area?: GeoZone
  minPackage?: number
  maxPackage?: number
}

export type OperatorOption = {
  label: string
  value: string
}

interface CampaignDeliveryFiltersProps {
  operators: Option[]
  filterValues: CampaignDeliveryFiltersModel
  getGeoZones: (
    page: number,
    size: number,
    name: string
  ) => Promise<GeoZonePage>
  onFilterChange: (values: CampaignDeliveryFiltersModel) => void
}

export const CampaignDeliveryFilters = (
  props: CampaignDeliveryFiltersProps
) => {
  const { filterValues, getGeoZones, onFilterChange, operators } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(
    filterValues?.period?.startDate == null
      ? undefined
      : {
          startDate: filterValues.period.startDate!,
          endDate: filterValues.period.startDate!
        }
  )

  const handlePeriodChange = useCallback(
    (period: Period | undefined) => {
      setSelectedPeriod(period)

      onFilterChange({
        period: period,
        operatorId: filterValues.operatorId,
        area: filterValues.area,
        minPackage: filterValues.minPackage,
        maxPackage: filterValues.maxPackage
      })
    },
    [onFilterChange, filterValues]
  )

  const handleOperatorChange = useCallback(
    (operator?: string) => {
      onFilterChange({
        period: selectedPeriod,
        operatorId: operator,
        area: filterValues.area,
        minPackage: filterValues.minPackage,
        maxPackage: filterValues.maxPackage
      })
    },
    [onFilterChange, filterValues]
  )

  const handleAreaChange = useCallback(
    (area: GeoZone | GeoZone[]) => {
      const singleArea = Array.isArray(area) ? area[0] : area

      onFilterChange({
        period: selectedPeriod,
        operatorId: filterValues.operatorId,
        area: singleArea,
        minPackage: filterValues.minPackage,
        maxPackage: filterValues.maxPackage
      })
    },
    [onFilterChange, filterValues]
  )

  const handleMinPackageChange = useCallback(
    (amount?: string) => {
      onFilterChange({
        period: selectedPeriod,
        operatorId: filterValues.operatorId,
        area: filterValues.area,
        minPackage: amount ? +amount : undefined,
        maxPackage: filterValues.maxPackage
      })
    },
    [onFilterChange, filterValues]
  )

  const handleMaxPackageChange = useCallback(
    (amount?: string) => {
      onFilterChange({
        period: selectedPeriod,
        operatorId: filterValues.operatorId,
        area: filterValues.area,
        minPackage: filterValues.minPackage,
        maxPackage: amount ? +amount : undefined
      })
    },
    [onFilterChange, filterValues]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher une ville'
  )
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<GeoZone[]>([])

  const handleSearchArea = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([])
      setAutocompleteText('Rechercher une ville')
      return
    }

    try {
      const response = await getGeoZones(0, 5, search.trim())
      setAutoCompleteOptions(response.list)
      if (response.list.length === 0) {
        setAutocompleteText('Aucune ville trouvée')
      }
    } catch (err) {
      console.error(err)
      setAutoCompleteOptions([])
      setAutocompleteText('Une erreur est survenue, veuillez réessayer')
    }
  }, [])

  const getAreaName = useCallback((area: GeoZone) => {
    return area.name
  }, [])

  return (
    <Fragment>
      <InputForm
        textFieldType={'number'}
        inputType={'textField'}
        label={''}
        placeHolder={'Minimum colis'}
        className={`${classes.filter} project-filters`}
        onChange={handleMinPackageChange}
        value={filterValues.minPackage}
        onRemoveValue={() => handleMinPackageChange(undefined)}
        id='CampaignDelivery-filters-min-package'
      />
      <InputForm
        textFieldType={'number'}
        inputType={'textField'}
        label={''}
        placeHolder={'Maximum colis'}
        className={`${classes.filter} project-filters`}
        onChange={handleMaxPackageChange}
        value={filterValues.maxPackage}
        onRemoveValue={() => handleMaxPackageChange(undefined)}
        id='CampaignDelivery-filters-max-package'
      />
      <PeriodPicker
        id='CampaignDelivery-filters-period'
        placeholder={t('app_domain_campaign_delivery_period')}
        period={selectedPeriod}
        onDateChange={handlePeriodChange}
        inputClassName={classes.filter}
        clearable
      />
      <InputForm
        inputType={'select'}
        label={''}
        placeHolder={t('app_domain_campaign_delivery_operator')}
        className={`${classes.filter} project-filters`}
        onChange={handleOperatorChange}
        selectOptions={operators}
        value={filterValues.operatorId}
        onRemoveValue={() => handleOperatorChange(undefined)}
        id='CampaignDelivery-filters-operators'
      />
      <AutoComplete<GeoZone>
        id='CampaignDelivery-filters-areacode'
        onChangeSelectedElement={handleAreaChange}
        options={autoCompleteOptions}
        defaultValue={filterValues.area}
        label=''
        onSearch={handleSearchArea}
        noOptionsText={autocompleteText}
        getOptionLabel={getAreaName}
        placeholder={t('app_domain_campaign_delivery_areacode')}
        className={classes.filter}
      />
    </Fragment>
  )
}
