import React, { useContext } from 'react'
import {
  TextField as MuiTextField,
  InputAdornment,
  InputLabel,
  makeStyles
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { themeContext } from '@smartb/archetypes-ui-components'
import { useInputStyles } from '../../Theme'

interface TextFieldProps {
  id?: string
  value?: string | number
  textFieldType?: 'number' | 'text' | 'email' | 'password'
  onChange?: (value: string) => void
  placeHolder?: string
  error?: boolean
  errorMessage?: string
  defaultValue?: string | number
  className?: string
  label?: string
  disabled?: boolean
  style?: React.CSSProperties
  textFieldClassName?: string
  baseClassName?: string
  helperTextStyle?: React.CSSProperties
  inputIcon?: React.ReactNode
  onRemoveValue?: () => void
  iconPosition?: 'start' | 'end'
  onClick?: (event: React.MouseEvent) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  autocomplete?: string
}

const useStyles = makeStyles(() => ({
  withIconStart: {
    '& .MuiInputBase-input': {
      paddingLeft: '0px !important',
      paddingRight: '8px !important'
    }
  },
  withIconEnd: {
    '& .MuiInputBase-input': {
      paddingLeft: '5px !important'
    }
  }
}))

export const TextField = React.forwardRef((props: TextFieldProps, ref) => {
  const {
    className,
    error = false,
    errorMessage = '',
    label = '',
    textFieldClassName,
    helperTextStyle,
    id = '',
    onChange,
    placeHolder = '',
    style,
    textFieldType,
    baseClassName = '',
    defaultValue,
    value = '',
    disabled = false,
    inputIcon,
    onRemoveValue,
    iconPosition = 'start',
    onClick,
    onBlur,
    autocomplete
  } = props

  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, disabled)()
  const classesLocal = useStyles()
  return (
    <div
      className={`${className}`}
      style={{ width: '100%', position: 'relative', ...style }}
    >
      {label ? (
        <InputLabel htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      ) : null}
      <MuiTextField
        id={id}
        value={defaultValue != undefined ? undefined : value}
        onChange={(e) => onChange && onChange(e.target.value)}
        onBlur={onBlur}
        onClick={onClick}
        placeholder={placeHolder}
        type={textFieldType}
        defaultValue={defaultValue}
        className={`${textFieldClassName} ${classes.input} Colisactiv-TextFieldInput`}
        variant='filled'
        error={error}
        disabled={disabled}
        helperText={error ? errorMessage : ''}
        color={'primary'}
        InputProps={{
          ref: ref,
          startAdornment: !!inputIcon && iconPosition === 'start' && (
            <InputAdornment component='div' position='start'>
              {inputIcon}
            </InputAdornment>
          ),
          endAdornment: !!inputIcon && iconPosition === 'end' && (
            <InputAdornment component='div' position='end'>
              {inputIcon}
            </InputAdornment>
          ),
          disableUnderline: true,
          style: { paddingRight: onRemoveValue ? '22px' : '' },
          inputProps: {
            className: baseClassName,
            min: 0
          },
          className:
            inputIcon && iconPosition === 'start'
              ? classesLocal.withIconStart
              : inputIcon && iconPosition === 'end'
              ? classesLocal.withIconEnd
              : '',
          autoComplete: autocomplete
        }}
        FormHelperTextProps={{
          className: classes.helperText,
          style: helperTextStyle
        }}
      />
      {value !== '' && onRemoveValue && (
        <Clear onClick={onRemoveValue} className={classes.clear} />
      )}
    </div>
  )
})
