import { city } from "ca-plateform-notification-domain";
import { buildNotificationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface NotificationPinnedEvent
  extends city.colisactiv.platform.notification.domain.features
    .NotificationPinnedEvent {}

export const pinNotification = (
  notificationId: string
): Promise<NotificationPinnedEvent> => {
  return http.put(
    buildNotificationApiUrl(`/notifications/${notificationId}/pin`),
    null,
    "La notification a été épinglé"
  );
};
