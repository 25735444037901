import ApiPage from "app/pages/API";
import BonusView from "app/pages/Bonus/View";
import Notifications from "app/pages/Notifications";
import Projects from "app/pages/Projects/List";
import UsersGeneral from "app/pages/Users";
import { HeadBarProps } from "components";
import { ConnectedRouter } from "connected-react-router";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../../auth/PrivateRoute";
import { routesAccessRights } from "../../auth/RoutesAccessRightsMap";
import { history } from "../../store";
import BonusCreatePage from "../pages/Bonus/Create";
import BonusEditPage from "../pages/Bonus/Edit";
import BonusDetailsPage from "../pages/Bonus/Details";
import BonusListPage from "../pages/Bonus/List";
import Regulations from "../pages/BonusPayment/List";
import CampaignDelivery from "../pages/CampaignDelivery/List";
import DashboardPage from "../pages/Dashboard";
import NotificationRouter from "../pages/Notifications/Router";
import OrganizationCreatePage from "../pages/Organizations/Create";
import OrganizationListPage from "../pages/Organizations/List";
import OrganizationProfile from "../pages/Organizations/View";
import OrganizationUsersSectionPage from "../pages/Organizations/View/Users";
import CampaignCreatePage from "../pages/Projects/Campaign/Create";
import CampaignEditPage from "../pages/Projects/Campaign/Edit";
import CampaignViewPage from "../pages/Projects/Campaign/View";
import ProjectCreatPage from "../pages/Projects/Create";
import ProjectEditPage from "../pages/Projects/Edit";
import ProjectViewPage from "../pages/Projects/View";
import ReportCreatePage from "../pages/Reports/Create";
import ReportEditView from "../pages/Reports/Edit";
import Reports from "../pages/Reports/List";
import ReportView from "../pages/Reports/View";
import GeoZones from "../pages/GeoZones/List";
import GeoZoneCreatePage from "../pages/GeoZones/Create";
import withConnect from "./withConnect";
import withConnectNoMatch from "./withConnectNoMatch";
import FluxPage from "../pages/Flux";
import LandingPageRouter from "app/pages/LandingPageRouter";
import DeliveryExportPage from "../pages/DeliveryExport";
import ImportTours from "app/pages/ImportTours";

interface NoMatchPageProps {
  setHeadBar: (title: HeadBarProps) => void;
}

const NoMatchPage = withConnectNoMatch((props: NoMatchPageProps) => {
  const { setHeadBar } = props;
  useEffect(() => {
    setHeadBar({ title: "Page introuvable" });
  }, [setHeadBar]);
  return <h3>Cette page n'existe pas</h3>;
});

interface AppRouterProps {}

export const AppRouter = withConnect((props: AppRouterProps) => {
  return (
    <>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={LandingPageRouter} />
          <PrivateRoute
            exact
            roles={routesAccessRights["/dashboard"]}
            path="/dashboard"
            component={DashboardPage}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/flux"]}
            path="/flux"
            component={FluxPage}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/organizations"]}
            path="/organizations"
            render={() => <OrganizationListPage />}
            key="listOrganization"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/organizations/add"]}
            path="/organizations/add"
            render={() => <OrganizationCreatePage />}
            key="addOrganization"
          />
          <Route
            exact
            path="/organizations/:organizationId/users/:mode/:objectId?"
            key="showUser"
            render={() => <OrganizationUsersSectionPage />}
          />
          <Route
            exact
            path="/organizations/:organizationId/:section/:mode/:objectId?"
            key="showOrganization"
            render={() => <OrganizationProfile />}
          />
          <Route
            exact
            path="/notifications"
            key="showNotifications"
            render={() => <Notifications />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/api"]}
            path="/api"
            render={() => <ApiPage />}
            key="api"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/bonus"]}
            path="/bonus"
            render={() => <BonusListPage />}
            key="bonus"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/bonus"]}
            path="/bonus/add"
            component={BonusCreatePage}
            key="bonusAdd"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/bonus"]}
            path="/bonus/:bonusId/view"
            component={BonusView}
            key="bonusView"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/bonus"]}
            path="/bonus/:bonusId/details"
            component={BonusDetailsPage}
            key="bonusView"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/bonus"]}
            path="/bonus/:bonusId/edit"
            component={BonusEditPage}
            key="bonusEdit"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects"]}
            path="/projects"
            render={() => <Projects />}
            key="listProjects"
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects/edit"]}
            path="/projects/add"
            key="addProject"
            render={() => <ProjectCreatPage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects"]}
            path="/projects/:projectId/view"
            key="viewProject"
            render={() => <ProjectViewPage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects/edit"]}
            path="/projects/:projectId/edit"
            key="editProject"
            render={() => <ProjectEditPage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects"]}
            path="/projects/:projectId/general/view"
            key="showProjectInformations"
            render={() => <ProjectViewPage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects/edit"]}
            path="/projects/:projectId/campaign/add"
            key="addCampaign"
            render={() => <CampaignCreatePage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects"]}
            path="/projects/:projectId/campaign/view/:campaignId"
            key="viewCampaign"
            render={() => <CampaignViewPage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/projects/edit"]}
            path="/projects/:projectId/campaign/:mode/:campaignId?"
            key="editCampaign"
            render={() => <CampaignEditPage />}
          />
          <PrivateRoute
            exact
            roles={[]}
            path="/users/:userId/:section/:mode"
            key="showUser"
            render={() => <UsersGeneral />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/reports"]}
            path="/reports"
            key="reportsList"
            render={() => <Reports />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/reports"]}
            path="/report/:reportId/view"
            key="reportView"
            render={() => <ReportView />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/reports"]}
            path="/report/:reportId/edit"
            key="reportEdit"
            render={() => <ReportEditView />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/reports"]}
            path="/report/add"
            key="reportsList"
            render={() => <ReportCreatePage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/bonusPayment"]}
            path="/bonusPayments"
            key="bonusPaymentsList"
            render={() => <Regulations />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/campaignDelivery"]}
            path="/campaignDelivery"
            key="campaignDelivery"
            render={() => <CampaignDelivery />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/notificationRouter"]}
            path="/notificationRouter/:notificationId"
            key="notificationRouter"
            render={() => <NotificationRouter />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/geozones"]}
            path="/geozones"
            key="viewGeoZones"
            render={() => <GeoZones />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/geozones/add"]}
            path="/geozones/add"
            key="addGeoZone"
            render={() => <GeoZoneCreatePage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/deliveryexport"]}
            path="/deliveryexport/:path"
            key="deliveryExport"
            render={() => <DeliveryExportPage />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/tours/import"]}
            path="/tours/import"
            key="import"
            render={() => <ImportTours />}
          />
          <PrivateRoute
            exact
            roles={routesAccessRights["/deliveryexport"]}
            path="/deliveryexport/:path"
            key="deliveryExport"
            render={() => <DeliveryExportPage />}
          />
          <Route path="*">
            <NoMatchPage />
          </Route>
        </Switch>
      </ConnectedRouter>
    </>
  );
});
