import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../../configuration";
import { http } from "../../../http";

export interface EnabledUserEvent
  extends city.colisactiv.organization.model.features.EnabledUserEvent {}

export const enableUser = (
  organizationId: string,
  userId: string
): Promise<EnabledUserEvent> => {
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/users/${userId}/enable`
    ),
    "L'utilisateur a bien été activé"
  );
};
