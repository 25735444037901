import { city } from "ca-plateform-project-domain";
import { buildProjectApiUrl } from "api/configuration";
import i18n from "i18n";
import { http } from "../../http";

export interface RemovedCampaignEvent
  extends city.colisactiv.project.model.features.RemovedCampaignEvent {}
export interface RemoveCampaignCommand
  extends city.colisactiv.project.model.features.RemoveCampaignCommand {}

export const campaignRemoveCommand = (
  projectId: string,
  campaignId: string
): RemoveCampaignCommand => {
  return {
    type: "DELETE_CAMPAIGN",
    payload: {
      projectId: projectId,
      campaignId: campaignId,
    },
  };
};

export const removeCampaignCommand = (
  projectId: string,
  campaignId: string
): Promise<RemovedCampaignEvent> => {
  return http.delete(
    buildProjectApiUrl(`/projects/${projectId}/campaigns/${campaignId}`),
    i18n.t("app_project_campaign_delete_success")
  );
};
