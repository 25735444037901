import { TFunction } from "i18next";
import { Option } from "components";
import { buildOrganizationApiUrl } from "../configuration";

export const organizationTypeOptions = (t: TFunction): Option[] => [
  { value: "Operator", label: `${t("app_organization_form_Operateur")} ` },
  { value: "Funder", label: `${t("app_organization_form_co_funder")} ` },
  { value: "Carrier", label: `${t("app_organization_form_Carrier")} ` },
  { value: "Tms", label: `${t("app_organization_form_tms")} ` },
  { value: "Visitor", label: `${t("app_organization_form_visitor")} ` },
];

export const getOrganizationLogoUrl = (organizationId: string) => {
  return buildOrganizationApiUrl(`/organizations/${organizationId}/logo`);
};
