import { city } from "ca-plateform-geozone-domain";
import { buildProjectApiUrl } from "api/configuration";
import i18n from "i18n";
import { http } from "api/http";
import { GeoZoneFormDetailsState } from "app/components/GeoZoneForm/model";

export interface GeoZoneCreatedEvent
  extends city.colisactiv.platform.geozone.model.features.GeoZoneCreatedEvent {}

export interface AddTerritoryZoneCommand
  extends city.colisactiv.platform.geozone.model.features.AddTerritoryZoneCommand {}


export const GeoZoneCreateCommand = (
  details: GeoZoneFormDetailsState
): AddTerritoryZoneCommand => {
  return {
    type: "CREATE_GEO_ZONE",
    payload: {
      name: details.geoZoneName,
      territory: details.territory.code,
      geojson: details.geoJson,
      isCustom: true
    },
  };
};

export const createGeoZoneCommand = (
  details: GeoZoneFormDetailsState
): Promise<GeoZoneCreatedEvent> => {
  return http.post(
    buildProjectApiUrl(`/geozones`),
    JSON.stringify(GeoZoneCreateCommand(details)),
    i18n.t("app_geozone_create_success")
  );
};
