import { makeStyles } from '@material-ui/core'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import { Download } from '../../Icons'
import { TextButton } from '../TextButton'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    icon: {
      width: '16px',
      height: '16px',
      marginRight: '5px'
    },
    root: {
      '& span': {
        color: theme.secondaryColor
      }
    }
  }))

interface DownloadButtonProps {
  onClick: (event: React.ChangeEvent<{}>) => void
  label?: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
}

export const DownloadButton = (props: DownloadButtonProps) => {
  const { label, onClick, className, style, disabled = false } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()
  return (
    <TextButton
      onClick={onClick}
      className={`${className ?? ''} ${classes.root}`}
      style={style}
      disabled={disabled}
    >
      <Download color={theme.secondaryColor} className={classes.icon} />
      {label}
    </TextButton>
  )
}
