import { buildProjectApiUrl } from "api/configuration";
import { http } from "api/http";
import { GeoLocation } from "api/geoLocation";

export const getGeoZoneData = (geoZoneCode: string): Promise<GeoLocation[]> => {
    return http.get(
        buildProjectApiUrl(`/geozones/geodata/${geoZoneCode}`),
        "Une erreur est survenue"
    );
};
