import { User } from "api/organization/users";

export type Roles =
  | "user"
  | "admin"
  | "super_admin"
  | "carrier_admin"
  | "carrier_user"
  | "funder_user"
  | "funder_admin"
  | "operator_admin"
  | "operator_user"
  | "operator_admin_onboarding"
  | "operator_user_onboarding"
  | "tms_admin"
  | "tms_user"
  | "visitor_admin"
  | "visitor_user"
  | "read_dashboard_active_deliveries"
  | "read_dashboard_active_pack_count"
  | "read_dashboard_average_distance_per_delivery"
  | "read_dashboard_bonus_per_package"
  | "read_dashboard_bonus_total"
  | "read_dashboard_co2_avoided"
  | "read_dashboard_delivery_time"
  | "read_dashboard_distance_total"
  | "read_dashboard_hub_count"
  | "read_dashboard_nox_ppm_avoided"
  | "read_dashboard_thermal_km_avoided"
  | "read_dashboard_tour_amount"
  | "read_flux_map"
  | "read_heatmap"
  | "tms_user";

const colisActivOwners = [
  "admin@colisactiv.fr",
  "amauric.guinard@sofub.fr",
  "m.mermet@sofub.fr",
];

const isOrganizationAdmin = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(
    [
      "super_admin",
      "tms_admin",
      "operator_admin",
      "carrier_admin",
      "funder_admin",
    ],
    keycloak
  );
};

const isColisActivOwner = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return (
    isAuthorized(["admin", "super_admin"], keycloak) &&
    colisActivOwners.includes(user.details.email)
  );
};

const isColisActivAdmin = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["admin", "super_admin"], keycloak);
};

const isAuthenticated = (keycloak?: Keycloak.KeycloakInstance): boolean => {
  if (!keycloak) {
    return false;
  }
  return !!keycloak.authenticated && !!keycloak.idToken;
};

const isAdminOrFunderOrOperator = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return (
    isOperator(user, keycloak) ||
    isFunder(user, keycloak) ||
    isColisActivAdmin(user, keycloak)
  );
};

const isOperator = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["operator_admin", "operator_user"], keycloak);
};

const isFunder = (user: User | null, keycloak?: Keycloak.KeycloakInstance) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["funder_admin", "funder_user"], keycloak);
};

const isCarrier = (user: User | null, keycloak?: Keycloak.KeycloakInstance) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["carrier_admin", "carrier_user"], keycloak);
};

const isTms = (user: User | null, keycloak?: Keycloak.KeycloakInstance) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["tms_admin", "tms_user"], keycloak);
};

const isVisitor = (user: User | null, keycloak?: Keycloak.KeycloakInstance) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["visitor_admin", "visitor_user"], keycloak);
};

const hasRoles = (
  user: User,
  roles: Roles[] | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(roles, keycloak);
};

const isAuthorized = (
  roles: Roles[] | null,
  keycloak?: Keycloak.KeycloakInstance
): boolean => {
  if (!keycloak) {
    return false;
  }
  let authorization = false;
  if (!roles || roles.length === 0) authorization = true;
  if (roles) {
    roles.forEach((r) => {
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r);
      if (realm || resource) authorization = true;
    });
  }
  return authorization;
};

export interface AuthService {
  isAuthenticated: (keycloak?: Keycloak.KeycloakInstance) => boolean;
  isColisActivOwner: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isColisActivAdmin: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isOrganizationAdmin: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isAdminOrFunderOrOperator: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isOperator: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isFunder: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isCarrier: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isTms: (user: User | null, keycloak?: Keycloak.KeycloakInstance) => boolean;
  isVisitor: (user: User | null, keycloak?: Keycloak.KeycloakInstance) => boolean;
  hasRoles: (
    user: User,
    roles: Roles[] | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isAuthorized: (
    roles: Roles[] | null,
    keycloak?: Keycloak.KeycloakInstance,
    organizationId?: string,
    userId?: string
  ) => boolean;
}

export const authService: AuthService = {
  isAuthenticated,
  isColisActivOwner,
  isColisActivAdmin,
  isOrganizationAdmin,
  isOperator,
  isFunder,
  isAdminOrFunderOrOperator,
  hasRoles,
  isAuthorized,
  isCarrier,
  isTms,
  isVisitor,
};
