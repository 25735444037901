import {Dispatch} from "redux";
import {actions} from "./report.actions";
import {CarrierReportFormState} from "../../app/components/ReportForm/model";
import {CarrierReport, carrierReportClient, CarrierReportStatus} from "../../api/report";
import {router} from "../router";

const createCarrierReport = (form: CarrierReportFormState) => {
  return async (dispatch: Dispatch) => {
    const { carrierReport } = form;
    const {
      carrierId,
      territory,
      period,
      activeMode,
      thermalMode,
      electricMode,
      status,
    } = carrierReport;
    const createEvent = await carrierReportClient.createCarrierReport(
      carrierId,
      period,
      territory.code,
      activeMode,
      thermalMode,
      electricMode,
      status
    );
    if (createEvent && createEvent.payload.id) {
      const report: CarrierReport = {
        id: createEvent.payload.id,
        carrierId: carrierId,
        territory: territory,
        period: period,
        activeMode: activeMode,
        thermalMode: thermalMode,
        electricMode: electricMode,
        status: status,
        incidents: [],
        creationDate: "",
      };
      dispatch(actions.createCarrierReport(report));
    }
  };
};

const updateCarrierReport = (form: CarrierReportFormState) => {
  return async (dispatch: Dispatch) => {
    const { carrierReport } = form;
    const {
      id,
      carrierId,
      territory,
      period,
      activeMode,
      thermalMode,
      electricMode,
      status,
    } = carrierReport;

    const updateEvent = await carrierReportClient.updateCarrierReport(
      id,
      carrierId,
      period,
      territory.code,
      activeMode,
      thermalMode,
      electricMode,
      status
    );
    if (updateEvent && updateEvent.payload.carrierReportId) {
      const report: CarrierReport = {
        id: updateEvent.payload.carrierReportId,
        carrierId: carrierId,
        territory: territory,
        period: period,
        activeMode: activeMode,
        thermalMode: thermalMode,
        electricMode: electricMode,
        status: status,
        incidents: [],
        creationDate: "",
      };
      dispatch(actions.updateCarrierReport(report));
      dispatch(router.goto.report.reportsList(0, 10));
    }
  };
};

const updateCarrierReportStatus = (carrierReport: CarrierReport, newStatus: CarrierReportStatus) => {
  return async (dispatch: Dispatch) => {
    const {
      id,
      carrierId,
      territory,
      period,
      activeMode,
      thermalMode,
      electricMode,
      status,
    } = carrierReport;

    const updateEvent = await carrierReportClient.updateCarrierReportStatus(
      id,
      newStatus
    );
    if (updateEvent && updateEvent.payload.carrierReportId) {
      const report: CarrierReport = {
        id: updateEvent.payload.carrierReportId,
        carrierId: carrierId,
        territory: territory,
        period: period,
        activeMode: activeMode,
        thermalMode: thermalMode,
        electricMode: electricMode,
        status: status,
        incidents: [],
        creationDate: "",
      };
      dispatch(actions.updateCarrierReportStatus(report));
      dispatch(router.goto.report.reportsList(0, 10));
    }
  };
};

const resetReport = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetCarrierReport());
  };
};

export const commands = {
  createCarrierReport: createCarrierReport,
  resetReport: resetReport,
  updateCarrierReport: updateCarrierReport,
  updateCarrierReportStatus: updateCarrierReportStatus
};
