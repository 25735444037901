import { city } from "ca-plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";

export interface EditBonusGroupLogCommentCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .EditBonusGroupLogCommentCommand {}

export interface BonusGroupLogCommentEditedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupLogCommentEditedEvent {}

export const editBonusGroupLogCommentCommand = (
  bonusGroupId: string,
  bonusGroupLogId: string,
  comment: string
): EditBonusGroupLogCommentCommand => {
  return {
    payload: {
      bonusGroupId: bonusGroupId,
      bonusGroupLogId: bonusGroupLogId,
      comment: comment,
    },
  };
};

export const editBonusGroupLogComment = (
  bonusGroupId: string,
  bonusGroupLogId: string,
  comment: string
): Promise<BonusGroupLogCommentEditedEvent> => {
  const url = buildBonusGroupApiUrl(
    `/bonusgroup/${bonusGroupId}/log/${bonusGroupLogId}/editComment`
  );
  const body = JSON.stringify(
    editBonusGroupLogCommentCommand(bonusGroupId, bonusGroupLogId, comment)
  );
  return http.put(url, body, "Le commentaire à bien été édité");
};
