import { Box, makeStyles, Typography } from "@material-ui/core";
import { BonusGroup, bonusGroupClient } from "api/bonusGroup";
import { BonusGroupStatusUtils } from "api/bonusGroup/model/bonusStatus";
import { campaignDeliveryClient } from "api/campaignDelivery";
import { Territory } from "api/geoZone";
import { keycloakClient } from "api/keyclaok";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import { usePaginedList } from "app/hooks/usePaginedList/usePaginedList";
import {
  BonusFilters,
  BonusFiltersModel,
  BonusRow,
  BonusTable,
  Button,
  HeadBarProps,
  Option,
  Section,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, nullToUndefined, nullToUndefinedNumber } from "utils";
import { useAuth } from "../../../../auth/useAuth";
import { BonusesNotificationBox } from "../../../components/Bonus/BonusesNotification/BonusesNotificationBox";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  button: {
    float: "right",
    height: "48px !important",
  },
  redDot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "#E32628",
    marginRight: "10px",
  },
}));

interface BonusListPageProps {
  organizationRefs: Map<string, OrganizationRef>;
  carrierRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  territories: Territory[];
  gotoBonusAdd: () => void;
  gotoBonusView: (bonusId: string) => void;
  gotoBonusList: (
    page: number,
    size: number,
    startDate?: number,
    endDate?: number,
    operatorId?: string,
    carrierId?: string,
    territory?: string,
    status?: string
  ) => void;
  restBonus: () => void;
  currentUser: User | null;
  gotoCampaignDelivery: () => void;
}

const BonusListPage = (props: BonusListPageProps) => {
  const {
    gotoBonusAdd,
    gotoBonusView,
    gotoBonusList,
    organizationRefs,
    carrierRefs,
    operatorRefs,
    restBonus,
    currentUser,
    territories,
    gotoCampaignDelivery,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [authService, keycloak] = useAuth();
  const authResult = new URLSearchParams(window.location.search);
  const [filterValues, setFiltersValue] = useState<BonusFiltersModel>({
    startDate: formatDate(authResult.get("startDate")),
    endDate: formatDate(authResult.get("endDate")),
    operatorId: nullToUndefined(authResult.get("operatorId")),
    carrierId: nullToUndefined(authResult.get("carrierId")),
    status: nullToUndefined(authResult.get("status")),
    territoryId: nullToUndefined(authResult.get("territory")),
  });

  const [isAllowedUser, setIsAllowedUser] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const getTerritories: Option[] = useMemo(
    () =>
      territories?.map((el) => {
        return {
          label: el.name,
          value: el.code,
        };
      }),
    [territories]
  );

  const getCarriers = useMemo(
    () =>
      Array.from(carrierRefs.values()).map((el) => {
        return {
          label: el.displayName,
          value: el.organizationId,
        };
      }),
    [carrierRefs]
  );

  const getOperators = useMemo(
    () =>
      Array.from(operatorRefs.values()).map((el) => {
        return {
          label: el.displayName,
          value: el.organizationId,
        };
      }),
    [operatorRefs]
  );

  const isOperator = useMemo(
    () =>
      authService.isOperator(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  useEffect(() => {
    if (currentUser) {
      setIsAllowedUser(
        authService.hasRoles(currentUser, ["admin", "user"], keycloak)
      );
    }
  }, [currentUser]);

  const fetchBonuses = useCallback(
    (page: number, size: number) => {
      return bonusGroupClient.query.fetchBonusGroupPage(
        page,
        size,
        nullToUndefinedNumber(filterValues.startDate),
        nullToUndefinedNumber(filterValues.endDate),
        filterValues.operatorId,
        filterValues.carrierId,
        filterValues.territoryId,
        filterValues.status
      );
    },
    [filterValues]
  );

  const gotoListCallback = useCallback(
    (page: number, size: number) => {
      gotoBonusList(
        page,
        size,
        nullToUndefinedNumber(filterValues.startDate),
        nullToUndefinedNumber(filterValues.endDate),
        filterValues.operatorId,
        filterValues.carrierId,
        filterValues.territoryId,
        filterValues.status
      );
    },
    [gotoBonusList, filterValues]
  );

  const [items, isLoading, handlePageChange] = usePaginedList<BonusGroup>(
    [],
    fetchBonuses,
    gotoListCallback,
    [fetchBonuses, gotoListCallback]
  );

  const onBonusFiltersChange = (values: BonusFiltersModel) => {
    gotoBonusList(
      0,
      items.perPage,
      nullToUndefinedNumber(values.startDate),
      nullToUndefinedNumber(values.endDate),
      values.operatorId,
      values.carrierId,
      values.territoryId,
      values.status
    );
    setFiltersValue({
      startDate: values.startDate,
      endDate: values.endDate,
      operatorId: values.operatorId,
      carrierId: values.carrierId,
      territoryId: values.territoryId,
      status: values.status,
    });
  };
  const handleRowClicked = useCallback(
    (row: BonusRow) => {
      gotoBonusView(`${row.id}`);
    },
    [gotoBonusView]
  );

  const handleBonusAdd = useCallback(() => {
    restBonus();
    gotoBonusAdd();
  }, [restBonus, gotoBonusAdd]);

  const getOrganizationRefs = useCallback(
    (id: string): OrganizationRef | undefined => {
      return organizationRefs.get(id);
    },
    [organizationRefs]
  );

  const headBar: HeadBarProps = { title: `${t("app_bonus_list_premiums")}` };
  const actions = [
    <BonusFilters
      operators={getOperators}
      carriers={getCarriers}
      onFilterChange={onBonusFiltersChange}
      filterValues={filterValues}
      territories={getTerritories}
      columnSwitchWidth={1400}
      isOperator={isOperator}
    />,
  ];

  const hasRejectedDeliveries = async () => {
    const isColisactiv = keycloakClient.instance.hasRealmRole("admin");

    if (!isColisactiv) return;

    const rejectedDelivery =
      await campaignDeliveryClient.query.getRejectedDeliveryTours(0, 1);
    setShowNotification(rejectedDelivery.total > 0);
  };

  useEffect(() => {
    hasRejectedDeliveries();
  }, []);

  if (isAllowedUser) {
    actions.push(
      <Button className={classes.button} onClick={handleBonusAdd}>
        {t("app_bonus_list_add_premiums")}
      </Button>
    );
  }
  return (
    <PageWithTitle
      headBar={headBar}
      header={<>{actions.map((action) => action)}</>}
      columnSwitchWidth={1300}
      switchedHeaderHeight={350}
    >
      <Section actions={[]}>
        <Box display="flex" width="100%" justifyContent="space-between">
          {showNotification && (
            <BonusesNotificationBox
              gotoCampaignDelivery={gotoCampaignDelivery}
            />
          )}
          <Box
            display="flex"
            alignItems="center"
            flex="1"
            justifyContent="flex-end"
          >
            <div className={classes.redDot} />
            <Typography variant="body2" style={{ marginRight: "10px" }}>
              {t("app_bonus_wrong_data_legend")}
            </Typography>
          </Box>
        </Box>
        <BonusTable
          bonus={items.list}
          onRowClicked={handleRowClicked}
          isLoading={isLoading}
          totalPages={Math.ceil(items.totalRows / items.perPage)}
          page={items.page + 1}
          handlePageChange={handlePageChange}
          getOrganizationRef={getOrganizationRefs}
          getStatus={BonusGroupStatusUtils.getStatusFromRow}
        />
      </Section>
    </PageWithTitle>
  );
};

export default withConnect(BonusListPage);
