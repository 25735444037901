import { connect } from "react-redux";
import { geoZone } from "store/geozone";
import { profile } from "store/profile";
import { router } from "store/router";
import {State} from "../../../../store";

const mapStateToProps = (state: State) => ({
    currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
    archiveGeoZone: geoZone.commands.archiveGeoZone,
    gotoGeoZoneCreate: router.goto.geoZone.geoZoneAdd,
    gotoGeoZoneList: router.goto.geoZone.geoZonesList
};

export default connect(mapStateToProps, mapDispatchToProps);
