import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "ca-plateform-bonus-group-domain";
import { http } from "../../http";

export interface BonusGroupStatusUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupStatusUpdatedEvent {
  type: string;
}

export interface RejectBonusGroupCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .RejectBonusGroupCommand {
  type: string;
}

export const rejectBonusGroupCommand = (
  bonusGroupId: string,
  reasons: string
): RejectBonusGroupCommand => ({
  type: "REJECT_BONUS_GROUP",
  payload: {
    bonusGroupId: bonusGroupId,
    reasons: reasons,
  },
});

export const rejectBonusGroup = async (
  bonusGroupId: string,
  reasons: string
): Promise<BonusGroupStatusUpdatedEvent> => {
  return http.put(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/reject`),
    JSON.stringify(rejectBonusGroupCommand(bonusGroupId, reasons)),
    "La prime a bien été rejetée"
  );
};
