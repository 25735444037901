import { city } from "ca-plateform-project-domain";
import { buildProjectApiUrl } from "../../configuration";
import { http } from "../../http";

export interface UpdatedCampaignNotificationEvent {
  payload: {
    projectId: string;
    campaignId: string;
  };
}
export interface UpdateCampaignNotificationCommand
  extends city.colisactiv.project.model.features.UpdateCampaignNotificationCommand {}

export const campaignNotificationUpdateCommand = (
  projectId: string,
  campaignId: string,
  sendNotification: boolean
): UpdateCampaignNotificationCommand => {
  return {
    type: "UPDATE_CAMPAIGN_NOTIFICATION",
    payload: {
      projectId: projectId,
      campaignId: campaignId,
      sendExpiringNotification: sendNotification
    },
  };
};

export const updateCampaignNotificationCommand = (
  projectId: string,
  campaignId: string,
  sendNotification: boolean
): Promise<UpdatedCampaignNotificationEvent> => {
  return http.post(
    buildProjectApiUrl(`/projects/${projectId}/campaigns/${campaignId}/notification`),
    JSON.stringify(
      campaignNotificationUpdateCommand(
        projectId,
        campaignId,
        sendNotification
      ).payload
    ),
    "Les notifications de la campagne ont été modifiées avec succès"
  );
};
