import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { OrganizationRef } from "api/organization";
import { PrimaryTypography } from "components";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

const useStyles = (theme: Theme) =>
  makeStyles({
    copyIcon: {
      position: "absolute",
      right: 0,
      "&:hover svg": {
        color: theme.secondaryColor,
      },
    },
    icon: {
      color: "#9a9a9a",
      width: 30,
      height: 30,
    },
  });

interface ApiOrganizationsCellIdProps {
  org: OrganizationRef;
  onCopyToClipboard: (org: OrganizationRef) => void;
}

export const ApiOrganizationsCellId = (props: ApiOrganizationsCellIdProps) => {
  const { org, onCopyToClipboard } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();
  const { t } = useTranslation();

  const handleCopyToClipboard = useCallback(() => onCopyToClipboard(org), [
    org,
    onCopyToClipboard,
  ]);

  return (
    <>
      <PrimaryTypography
        data-tag="___react-data-table-allow-propagation___"
        align="left"
      >
        {org.friendlyId}
      </PrimaryTypography>
      <Tooltip title="Copier l'identifiant">
        <IconButton
          onClick={handleCopyToClipboard}
          className={classes.copyIcon}
        >
          <FileCopyOutlined className={classes.icon} />
        </IconButton>
      </Tooltip>
    </>
  );
};
