import { city } from "ca-plateform-carrier-report-domain";
import { ReportRow, StatusTagsFormProps } from "components";
export interface ReportStatus
  extends city.colisactiv.platform.carrier.report.domain.CarrierReportStatus {}

class CarrierReportStatusValues extends city.colisactiv.platform.carrier.report
  .domain.CarrierReportStatusValues {}

class CarrierReportIncidentStatusValues extends city.colisactiv.platform.carrier
  .report.domain.CarrierReportIncidentStatusValues {}

export const carrierReportStatusValues = new CarrierReportStatusValues();
export const carrierReportIncidentStatusValues = new CarrierReportIncidentStatusValues();

const getLabelStyleStatusTags = (
  reportStatus: ReportStatus,
  isCarrier: boolean = false
): StatusTagsFormProps => {
  if (
    reportStatus.value === carrierReportStatusValues.INVALID.value &&
    isCarrier
  ) {
    return {
      label: "En traitement",
      color: "blue",
    };
  }
  switch (reportStatus.value) {
    case carrierReportStatusValues.CREATED.value:
      return {
        label: "En attente de remplissage",
        color: "grey",
      };
    case carrierReportStatusValues.PUBLISHED.value:
      return {
        label: "Complété",
        color: "yellow",
      };
    case carrierReportStatusValues.APPROVED.value:
      return {
        label: "Validé",
        color: "green",
      };
    case carrierReportStatusValues.INVALID.value:
      return {
        label: "Incident",
        color: "orange",
      };
    default:
      return {
        label: "",
        color: "grey",
      };
  }
};
const getStatusFromRow = (
  row: ReportRow,
  isCarrier?: boolean
): StatusTagsFormProps => {
  const status = { value: row.status };
  return getLabelStyleStatusTags(status, isCarrier);
};

const isCreated = (status: string) => {
  return status === carrierReportStatusValues.CREATED.value;
};
const isPublished = (status: string) => {
  return status === carrierReportStatusValues.PUBLISHED.value;
};
const isApproved = (status: string) => {
  return status === carrierReportStatusValues.APPROVED.value;
};
const isInvalid = (status: string) => {
  return status === carrierReportStatusValues.INVALID.value;
};

export const ReportStatusUtils = {
  getLabelStyleStatusTags: getLabelStyleStatusTags,
  getStatusFromRow: getStatusFromRow,
  isCreated: isCreated,
  isPublished: isPublished,
  isApproved: isApproved,
  isInvalid: isInvalid,
};
