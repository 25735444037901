import { makeStyles } from '@material-ui/core'
import React, { Fragment, useCallback, useState } from 'react'
import { InputForm } from '../../Component/InputForm'
import { AutoComplete } from '../../Component/AutoComplete'
import { PeriodPicker } from '../../Component/PeriodPicker'
import { city as cityGeo } from 'ca-plateform-geozone-domain'
import { city as cityPlat } from 'ca-plateform-plateform-commons'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px'
      },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  }
}))

export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}
export interface TerritoryPage
  extends cityPlat.colisactiv.platform.commons.Page<Territory> {}
export interface DateInterval
  extends cityPlat.colisactiv.platform.commons.DateInterval {}

export interface DashboardFiltersModel {
  startPeriod?: DateInterval
  endPeriod?: DateInterval
  status?: string
  operatorId?: string
  carrierId?: string
  territory?: Territory
}

export type Option = {
  label: string
  value: string
}

interface DashboardFiltersProps {
  operators: Option[]
  carriers: Option[]
  allowedTerritories?: Nullable<string[]>
  filterValues: DashboardFiltersModel
  getTerritories: (
    page: number,
    size: number,
    name: string
  ) => Promise<TerritoryPage>
  onFilterChange: (values: DashboardFiltersModel) => void
  canSeeOperatorFilter: boolean
  canSeeCarrierFilter: boolean
  canSeeTerritoryFilter: boolean
}

export const DashboardFilters = (props: DashboardFiltersProps) => {
  const {
    filterValues,
    onFilterChange,
    carriers,
    operators,
    allowedTerritories = null,
    getTerritories,
    canSeeCarrierFilter,
    canSeeOperatorFilter,
    canSeeTerritoryFilter
  } = props
  const classes = useStyles()
  const [selectedStartPeriod, setStartSelectedPeriod] = useState<
    DateInterval | undefined
  >(
    filterValues?.startPeriod?.startDate == null
      ? undefined
      : {
          startDate: filterValues.startPeriod.startDate!,
          endDate: filterValues.startPeriod.endDate!
        }
  )

  const [selectedEndPeriod, setEndSelectedPeriod] = useState<
    DateInterval | undefined
  >(
    filterValues?.endPeriod?.startDate == null
      ? undefined
      : {
          startDate: filterValues.endPeriod.startDate!,
          endDate: filterValues.endPeriod.endDate!
        }
  )

  const handleStartPeriodChange = useCallback(
    (period: DateInterval | undefined) => {
      setStartSelectedPeriod(period)

      onFilterChange({
        startPeriod: period,
        endPeriod: filterValues.endPeriod,
        operatorId: filterValues.operatorId,
        carrierId: filterValues.carrierId,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleEndPeriodChange = useCallback(
    (period: DateInterval | undefined) => {
      setEndSelectedPeriod(period)

      onFilterChange({
        startPeriod: filterValues.startPeriod,
        endPeriod: period,
        operatorId: filterValues.operatorId,
        carrierId: filterValues.carrierId,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleOperatorChange = useCallback(
    (operator?: string) => {
      onFilterChange({
        startPeriod: filterValues.startPeriod,
        endPeriod: filterValues.endPeriod,
        operatorId: operator,
        carrierId: filterValues.carrierId,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleCarrierChange = useCallback(
    (carrier?: string) => {
      onFilterChange({
        startPeriod: filterValues.startPeriod,
        endPeriod: filterValues.endPeriod,
        operatorId: filterValues.operatorId,
        carrierId: carrier,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const singleTerritory = Array.isArray(territory)
        ? territory[0]
        : territory

      if (
        !allowedTerritories ||
        (singleTerritory && allowedTerritories.includes(singleTerritory.code))
      ) {
        onFilterChange({
          startPeriod: filterValues.startPeriod,
          endPeriod: filterValues.endPeriod,
          operatorId: filterValues.operatorId,
          carrierId: filterValues.carrierId,
          status: filterValues.status,
          territory: singleTerritory
        })
      }
    },
    [onFilterChange, filterValues, allowedTerritories]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher un territoire'
  )
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  )

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([])
      setAutocompleteText('Rechercher un territoire')
      return
    }

    try {
      const response = await getTerritories(0, 5, search.trim())
      let territories = response.list
      if (allowedTerritories) {
        territories = response.list.filter((territory: Territory) =>
          allowedTerritories.includes(territory.code)
        )
      }
      setAutoCompleteOptions(territories)
      if (response.list.length === 0) {
        setAutocompleteText('Aucun territoire trouvé')
      }
    } catch (err) {
      console.error(err)
      setAutoCompleteOptions([])
      setAutocompleteText('Une erreur est survenue, veuillez réessayer')
    }
  }, [])

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name
  }, [])

  return (
    <Fragment>
      <PeriodPicker
        id='filterPeriod'
        placeholder={'Période de début'}
        period={selectedStartPeriod}
        onDateChange={handleStartPeriodChange}
        inputClassName={classes.filter}
        clearable
      />
      <PeriodPicker
        id='filterPeriod'
        placeholder={'Période de fin'}
        period={selectedEndPeriod}
        onDateChange={handleEndPeriodChange}
        inputClassName={classes.filter}
        clearable
      />
      {canSeeOperatorFilter && (
        <InputForm
          inputType={'select'}
          onChange={handleOperatorChange}
          selectOptions={operators}
          value={filterValues.operatorId}
          onRemoveValue={() => handleOperatorChange(undefined)}
          label={''}
          placeHolder={'Opérateur'}
          className={`${classes.filter} project-filters`}
          id='dashboard-filters-operator'
        />
      )}
      {canSeeCarrierFilter && (
        <InputForm
          inputType={'select'}
          onChange={handleCarrierChange}
          selectOptions={carriers}
          value={filterValues.carrierId}
          onRemoveValue={() => handleCarrierChange(undefined)}
          label={''}
          placeHolder={'Transporteur'}
          className={`${classes.filter} project-filters`}
          id='dashboard-filters-carrier'
        />
      )}
      {canSeeTerritoryFilter && (
        <AutoComplete<Territory>
          id='dashboard-filters-territory'
          onChangeSelectedElement={handleTerritoryChange}
          options={autoCompleteOptions}
          defaultValue={filterValues.territory}
          label=''
          onSearch={handleSearchTerritory}
          noOptionsText={autocompleteText}
          getOptionLabel={getTerritoryName}
          placeholder='Territoire'
          className={classes.filter}
          readonly={allowedTerritories ? allowedTerritories.length == 1 : false}
        />
      )}
    </Fragment>
  )
}
