import { Bonus, BonusDeliveryTour } from "api/bonus";
import { BonusGroup } from "api/bonusGroup";
import { Territory } from "api/geoZone";
import { DateInterval } from "api/report";
import { v4 as uuidv4 } from "uuid";

export interface BonusFormState {
  details: BonusFormDetailsState;
  validity: BonusFormValidity;
}
export interface BonusFormValidity {
  details: BonusFormDetailsValidity;
  bonuses: BonusFormStatsValidity[];
}

export interface BonusFormDetailsState {
  id?: string;
  carrierId: string;
  operatorId: string;
  territory: Territory;
  bonuses: BonusFormStatsState[];
  period: DateInterval;
}
export interface BonusFormDetailsValidity {
  operatorId: boolean;
  carrierId: boolean;
  territory: boolean;
}

export interface BonusFormStatsState {
  campaignId: string;
  areaCode: string;
  manualData: BonusFormStatsRowState[];
  automaticData: BonusFormStatsRowState;
  isNew: boolean;
}
export interface BonusFormStatsValidity {
  campaignId: boolean;
  areaCode: boolean;
  manualData: BonusFormStatsRowValidity[];
  automaticData: BonusFormStatsRowValidity;
}

export interface BonusFormStatsRowState {
  id: string;
  activeDeliveryCount: number;
  packCount: number;
  duration: number;
  manuallyDeclaredDate?: number;
}

export interface BonusFormStatsRowValidity {
  id: boolean;
  activeDeliveryCount: boolean;
  packCount: boolean;
  duration: boolean;
}

export const defaultBonusFormStatsRow: () => BonusFormStatsRowState = () => ({
  id: uuidv4(),
  //@ts-ignore
  activeDeliveryCount: undefined,
  //@ts-ignore
  packCount: undefined,
  //@ts-ignore
  duration: undefined,
});

export const defaultBonusFormStats: () => BonusFormStatsState = () => ({
  //@ts-ignore
  areaCode: undefined,
  //@ts-ignore
  campaignId: undefined,
  manualData: [defaultBonusFormStatsRow()],
  automaticData: defaultBonusFormStatsRow(),
  isNew: true,
});

export const defaultBonusFormStatsRowValidity: BonusFormStatsRowValidity = {
  id: true,
  activeDeliveryCount: true,
  packCount: true,
  duration: true,
};

export const defaultBonusFormStatsValidity: BonusFormStatsValidity = {
  campaignId: true,
  areaCode: true,
  manualData: [defaultBonusFormStatsRowValidity],
  automaticData: defaultBonusFormStatsRowValidity,
};

export const defaultBonusFormValidity = (amount: number): BonusFormValidity => {
  const bonusesValidity = []
  for (let i = 0; i < amount; i++) {
    bonusesValidity.push(defaultBonusFormStatsValidity)
  }
  return {
    details: {
      operatorId: true,
      carrierId: true,
      territory: true,
    },
    bonuses: bonusesValidity
  }
};

const date = new Date();

export const defaultBonusFormState: () => BonusFormState = () => ({
  details: {
    id: "",
    carrierId: "",
    operatorId: "",
    //@ts-ignore
    period: undefined,
    //@ts-ignore
    territory: undefined,
    bonuses: [defaultBonusFormStats()],
  },
  validity: defaultBonusFormValidity(1),
});

export const isBonusGroupNotTotallyManual = (bonusGroup: BonusGroup): boolean => {
  return bonusGroup.bonuses.findIndex(
    (bonus: Bonus) => bonus.activeDeliveryTours.concat(bonus.nonActiveDeliveryTours).findIndex(
      (tour: BonusDeliveryTour) => tour.manuallyDeclaredDate === null
    ) >= 0
  ) >= 0
}

const isManual = (tour: BonusDeliveryTour) => tour.manuallyDeclaredDate != null;

const toFormStatsRowManual = (bonus: Bonus): BonusFormStatsRowState[] => {
  return bonus.activeDeliveryTours
    .filter((tour) => isManual(tour))
    .map((tour) => ({
      id: tour.tourId,
      activeDeliveryCount: tour.deliveryCount,
      packCount: tour.packCount,
      duration: tour.duration,
      manuallyDeclaredDate: tour.manuallyDeclaredDate,
    }));
};

const toFormStatsRowAutomatic = (bonus: Bonus): BonusFormStatsRowState => {
  const tours = bonus.activeDeliveryTours.filter((tour) => !isManual(tour));
  return {
    id: tours[0]?.tourId ?? uuidv4(),
    activeDeliveryCount: tours.reduce(
      (acc, tour) => acc + tour.deliveryCount,
      0
    ),
    packCount: tours.reduce((acc, tour) => acc + tour.packCount, 0),
    duration: tours.reduce((acc, tour) => acc + tour.duration, 0),
    manuallyDeclaredDate: undefined,
  };
};

const toFormStats = (bonus: Bonus): BonusFormStatsState => ({
  areaCode: bonus.area.code,
  campaignId: bonus.campaignId,
  manualData: toFormStatsRowManual(bonus),
  automaticData: toFormStatsRowAutomatic(bonus),
  isNew: false,
});

export const toForm = (bonusGroup: BonusGroup): BonusFormState => {
  return {
    details: {
      ...bonusGroup,
      id: bonusGroup.id,
      carrierId: bonusGroup.carrierId,
      operatorId: bonusGroup.operatorId,
      period: bonusGroup.period,
      territory: bonusGroup.territory,
      bonuses: bonusGroup.bonuses.map(toFormStats),
    },
    validity: defaultBonusFormValidity(bonusGroup.bonuses.length),
  };
};
