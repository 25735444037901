import React, { useEffect } from "react";
import { connect } from "react-redux";
import { profile } from "store/profile";
import { global } from "store/global";
import { useAuth } from "../../auth/useAuth";

interface StoreInitProps {
  fetchOrganizationRefs: (force: boolean) => void;
  fetchCampaigns: (force: boolean) => void;
  fetchAllTerritoriesProject: (force: boolean) => void;
  fetchProfile: () => void;
  children: React.ReactElement;
}

const StoreInit = (props: StoreInitProps) => {
  const {
    children,
    fetchOrganizationRefs,
    fetchProfile,
    fetchCampaigns,
    fetchAllTerritoriesProject: fetchAllTerritoriesProject,
  } = props;
  const [authService, keycloak] = useAuth();

  if (!!authService.isAuthenticated(keycloak)) {
    if (authService.isAuthorized(["admin", "operator_user"], keycloak)) {
      fetchCampaigns(false);
      fetchAllTerritoriesProject(false);
    }   

    if (authService.isAuthorized(["carrier_user"], keycloak)) {
      fetchAllTerritoriesProject(false);
    }

    fetchOrganizationRefs(false);
  }
  useEffect(() => {
    authService.isAuthenticated(keycloak) && fetchProfile();
  }, [keycloak?.authenticated]);

  return children;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  fetchCampaigns: global.queries.fetchCampaigns,
  fetchOrganizationRefs: global.queries.fetchOrganizationRefs,
  fetchProfile: profile.queries.fetchProfile,
  fetchAllTerritoriesProject: global.queries.fetchProjectTerritories,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreInit);
