import { Dispatch } from "redux";
import { router } from "store/router";
import {geoZoneClient} from "../../api/geoZone";
import { GeoZoneFormDetailsState } from "app/components/GeoZoneForm/model";

const createGeoZone = (details: GeoZoneFormDetailsState) => {
  return async (dispatch: Dispatch) => {
    await geoZoneClient.createGeoZone(details);
    dispatch(router.goto.geoZone.geoZonesList());
  };
};

const archiveGeoZone = (geoZoneId: string) => {
  return async (dispatch: Dispatch) => {
    await geoZoneClient.archiveGeoZone(geoZoneId);
    dispatch(router.goto.geoZone.geoZonesList());
  };
};

export const commands = {
  createGeoZone: createGeoZone,
  archiveGeoZone: archiveGeoZone
};
