import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface ExportAuthorizationUpdateCommand
  extends city.colisactiv.organization.model.features
    .ExportAuthorizationUpdateCommand {}

export interface ExportAuthorizationUpdatedEvent
  extends city.colisactiv.organization.model.features
    .ExportAuthorizationUpdatedEvent {}

export interface ExportAuthorization
  extends city.colisactiv.organization.model.ExportAuthorization {}

export const updateExportAuthorization = (
  organizationId: string,
  exportAuthorization: ExportAuthorization
): Promise<ExportAuthorizationUpdatedEvent | null> => {
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/exportAuthorization`
    ),
    JSON.stringify(exportAuthorization)
  );
};
