import {buildDashboardPoiApiUrl} from "api/configuration";
import {DateInterval} from "api/report";
import {http} from "../../http";
import {PoiDashboardStats} from "../index";

export interface PoiDashboardFilter {
  operatorId?: string;
  carrierId?: string;
  startPeriod?: DateInterval;
  endPeriod?: DateInterval;
  territoryCode?: string;
}

export const fetchPoiDashboardStats = (
  filter: PoiDashboardFilter
): Promise<PoiDashboardStats> => {
  const {
    territoryCode,
    carrierId,
    operatorId,
    startPeriod,
    endPeriod
  } = filter;
  return http.post(
    buildDashboardPoiApiUrl("/poidashboard"),
    JSON.stringify({
      payload: {
        operatorId: operatorId,
        carrierId: carrierId,
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        territoryCode: territoryCode
      },
    })
  );
};
