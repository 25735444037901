import { city as cityOrga, city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "api/http";

export interface UpdateApplicationSecretCommand
  extends cityOrga.colisactiv.organization.model.features
    .UpdateApplicationSecretCommand {}

export interface UpdateApplicationSecretCommandDTO
  extends city.colisactiv.organization.model.features
    .UpdateApplicationSecretCommandDTO {}

export interface ApplicationSecretUpdatedEvent
  extends city.colisactiv.organization.model.features
    .ApplicationSecretUpdatedEvent {
  type: string;
}

export const updateApplicationSecretCommand = (
  keycloackId: string
): UpdateApplicationSecretCommand => {
  return {
    type: "UPDATE_APPLICATION_SECRET",
    payload: {
      keycloackId: keycloackId,
    },
  };
};

export const updateApplicationSecret = (
  organizationId: string,
  keycloackId: string
): Promise<ApplicationSecretUpdatedEvent> => {
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/applications/${keycloackId}`
    ),
    JSON.stringify(updateApplicationSecretCommand(keycloackId))
  );
};
