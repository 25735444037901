import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Button as SbButton,
  Theme,
  themeContext
} from '@smartb/archetypes-ui-components'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { Check, Close, Delete, ReportProblemOutlined } from '@material-ui/icons'

const containedUseStyles = (theme: Theme) =>
  makeStyles(() => ({
    root: {
      textTransform: 'none',
      border: 'none !important',
      minWidth: '100px !important',
      background: theme.secondaryColor,
      backgroundImage: 'none !important',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.16) !important',
      height: '44px',
      '& span': {
        color: 'white'
      },
      '&:hover': {
        opacity: '0.9',
        background: theme.secondaryColor
      }
    },
    loading: {
      background: '#77b8a1',
      opacity: '0.3 !important'
    },
    success: {
      background: '#4caf50',
      '&:hover': {
        opacity: '0.9',
        background: '#4caf50'
      }
    },
    fail: {
      background: '#EC8A90',
      '&:hover': {
        opacity: '0.9',
        background: '#EC8A90'
      }
    },
    advertissement: {
      background: '#FFB26B',
      '&:hover': {
        opacity: '0.9',
        background: '#FFB26B'
      }
    },
    deletion: {
      background: '#BBBBBB',
      '&:hover': {
        opacity: '0.9',
        background: '#BBBBBB'
      }
    },
    buttonProgress: {
      color: 'white',
      marginRight: 5
    },
    icon: {
      marginRight: 5
    }
  }))

const outlinedUseStyles = (theme: Theme) =>
  makeStyles(() => ({
    root: {
      padding: '5px 9px',
      border: `1px solid ${theme.secondaryColor}`,
      minWidth: '0px !important',
      boxShadow: 'none !important',
      fontSize: '12px',
      borderRadius: '4px',
      textTransform: 'none',
      '& span': {
        color: theme.secondaryColor
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
      }
    },
    loading: {},
    success: {
      border: '1px solid #4caf50 !important',
      color: '#4caf50 !important',
      '& span': {
        color: '#4caf50 !important'
      }
    },
    fail: {
      border: '1px solid #EC8A90 !important',
      color: '#EC8A90 !important',
      '& span': {
        color: '#EC8A90 !important'
      }
    },
    advertissement: {
      border: '1px solid #FFB26B !important',
      color: '#FFB26B !important',
      '& span': {
        color: '#FFB26B !important'
      }
    },
    deletion: {
      border: '1px solid #BBBBBB !important',
      color: '#BBBBBB !important',
      '& span': {
        color: '#BBBBBB !important'
      }
    },
    buttonProgress: {
      color: theme.secondaryColor,
      marginRight: 5
    },
    icon: {
      marginRight: 5
    }
  }))

interface ButtonProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClick?: (event: React.ChangeEvent<{}>) => void
  disabled?: boolean
  advertissement?: boolean
  deletion?: boolean
  success?: boolean
  fail?: boolean
  icon?: React.ReactNode
  noIcon?: boolean
  isLoading?: boolean
  variant?: 'outlined' | 'contained'
}

export const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    style,
    onClick,
    disabled = !onClick,
    success = false,
    fail = false,
    advertissement = false,
    deletion = false,
    icon,
    noIcon,
    isLoading = false,
    variant = 'contained'
  } = props
  const theme = useContext(themeContext)
  const classes =
    variant === 'contained'
      ? containedUseStyles(theme)()
      : outlinedUseStyles(theme)()

  const forcedLoading = isLoading
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (success || fail) setloading(false)
  }, [success, fail])

  const onClickMemoisied = useCallback(
    async (event: React.ChangeEvent<{}>) => {
      if (!!onClick) {
        setloading(true)
        await onClick(event)
        setloading(false)
      }
    },
    [onClick]
  )

  return (
    <SbButton
      onClick={onClickMemoisied}
      className={`
        ${classes.root} ${className}
        ${success ? classes.success : ''}
        ${fail ? classes.fail : ''}
        ${ advertissement ? classes.advertissement : ''}
        ${ deletion ? classes.deletion : ''}
      `}
      style={{
        borderRadius: variant === 'contained' ? '5px' : '4px',
        padding: variant === 'contained' ? '7px 10px' : '5px 9px',
        ...style
      }}
      disabled={loading || disabled || forcedLoading}
      variant={variant}
    >
      {!noIcon &&
        (loading || forcedLoading ? (
          <CircularProgress
            size={variant === 'contained' ? 26 : 20}
            className={classes.buttonProgress}
          />
        ) : success ? (
          <Check className={classes.icon} />
        ) : fail ? (
          <Close className={classes.icon} />
        ) : advertissement ? (
          <ReportProblemOutlined className={classes.icon} />
        ) :  deletion ? (
          <Delete className={classes.icon} />
        ) : icon ? (
          icon
        ) : (
          ''
        ))}
      {children}
    </SbButton>
  )
}
