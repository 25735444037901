import { city } from "ca-plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";

export interface UpdateBonusGroupInvoiceCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .UpdateBonusGroupInvoiceCommand {}

export interface UpdateBonusGroupInvoiceCommandDetails
  extends city.colisactiv.plateform.bonus.group.model.features
    .UpdateBonusGroupInvoiceCommandDetails {}

export interface BonusGroupInvoiceUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupInvoiceUpdatedEvent {
  type: string;
}

export const updateBonusGroupInvoiceCommand = (
  bonusGroupId: string,
  invoiceId: string,
  amount: number
): UpdateBonusGroupInvoiceCommand => {
  return {
    type: "UPDATE_BONUS_GROUP_INVOICE",
    payload: {
      bonusGroupId: bonusGroupId,
      invoiceId: invoiceId,
      amount: amount,
    },
  };
};

export const updateInvoice = (
  bonusGroupId: string,
  invoiceId: string,
  amount: number
): Promise<BonusGroupInvoiceUpdatedEvent> => {
  return http.put(
    buildBonusGroupApiUrl(`/bonusgroup/invoice`),
    JSON.stringify(
      updateBonusGroupInvoiceCommand(bonusGroupId, invoiceId, amount)
    ),
    "La facture a bien été modfiée"
  );
};
