import { city } from "ca-plateform-bonus-payment-domain";
import { city as cityPlat } from "ca-plateform-plateform-commons";
import { fetchBonusPaymentQuery } from "./features/query/fetchBonusPaymentQuery";
import { fetchBonusPaymentPage } from "./features/query/fetchBonusPaymentPageQuery";

export const BonusPaymentStatusValues = new city.colisactiv.platform.bonus.payment.domain.model.BonusPaymentStatusValues();

export interface BonusPaymentStatus
  extends city.colisactiv.platform.bonus.payment.domain.model
    .BonusPaymentStatus {}

export interface BonusPayment
  extends city.colisactiv.platform.bonus.payment.domain.model.BonusPayment {}

export type BonusPaymentId = string;

export interface BonusPaymentPage
  extends cityPlat.colisactiv.platform.commons.Page<BonusPayment> {}

export const bonusPaymentClient = {
  query: {
    fetchPage: fetchBonusPaymentPage,
    fetchBonusPaymentQuery: fetchBonusPaymentQuery,
  },
};
