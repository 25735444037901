import React from "react";
import { Box, makeStyles, Typography, Paper } from "@material-ui/core";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    width: "750px",
    minHeight: "800px",
    marginBottom: "20px",
    height: "fit-content",
  },
  text: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "#353945",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

interface BonusPdfEmptyProps {
  bonusHasDocuments: boolean;
}

const BonusPdfEmpty = (props: BonusPdfEmptyProps) => {
  const { bonusHasDocuments } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Box
        display="flex"
        justifyContent="center"
        height={300}
        alignItems="flex-end"
      >
        <Typography className={classes.text} variant="body1">
          {bonusHasDocuments
            ? "Cliquez sur l'un des documents de la prime pour le visualiser"
            : "Aucun document à afficher"}
        </Typography>
      </Box>
    </Paper>
  );
};

export default BonusPdfEmpty;
