import { city as cityPlat } from "ca-plateform-poi-dashboard-domain";
import { city as cityDash } from "ca-plateform-dashboard-api-domain";
import { fetchPoiDashboardStats } from "./query/fetchPoiDashboardStats";
import { generateDashboardToken } from "./command/generateDashboardToken";
import {getDashboardsQuery} from "./query/getDashboardsQuery";
import {getHeatmapData} from "./query/getHeatmapData";

export interface PoiDashboardStats
  extends cityPlat.colisactiv.plateform.poi.dashboard.domain.PoiDashboardStats {}

export interface DeliveryLocation
  extends cityPlat.colisactiv.plateform.poi.dashboard.domain.DeliveryLocation {}

export interface DashboardToken
  extends cityDash.colisactiv.dashboard.api.model.DashboardToken {}

export interface Dashboard
  extends cityDash.colisactiv.dashboard.api.model.Dashboard {}

export interface HeatmapData
  extends cityPlat.colisactiv.plateform.poi.dashboard.domain.HeatmapData {}

export const dashboardClient = {
  generateDashboardToken: generateDashboardToken,
  query: {
    fetchPoiDashboardStats: fetchPoiDashboardStats,
    getHeatmapData: getHeatmapData,
    getDashboardsQuery: getDashboardsQuery
  },
};
