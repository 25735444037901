import {buildBonusGroupApiUrl} from "api/configuration";
import {city} from "ca-plateform-carrier-report-domain";
import {http} from "../../http";

export interface ExportCarrierReportQueryBase
    extends city.colisactiv.platform.carrier.report.domain.features
        .ExportCarrierReportQueryBase {}

export const exportReport = async (
  carrierReportId: string
): Promise<string | null> => {
  const query: ExportCarrierReportQueryBase = {
    type: "EXPORT_CARRIER_REPORT",
    payload: {
      carrierReportId: carrierReportId
    }
  }
  return http.fetchDownloadableFile(
    buildBonusGroupApiUrl(`/carrierReports/export`),
    "Une erreur est survenue lors de l'export du rapport",
    "post",
    JSON.stringify(query)
  );
};
