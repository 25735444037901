import { StatusTagsFormProps } from "components";
import { city } from "ca-plateform-project-domain";
import { ProjectStats } from "./index";
import i18n from "i18n";
export type ProjectStatus = "CREATED" | "STARTED" | "DONE" | "ARCHIVED";

export interface ProjectStatusBase
  extends city.colisactiv.project.model.ProjectStatus {}
const isCreated = (project: ProjectStats) => {
  return hasStatus(project, "CREATED");
};

const isStarted = (project: ProjectStats) => {
  return hasStatus(project, "STARTED");
};

const isDone = (project: ProjectStats) => {
  return hasStatus(project, "DONE");
};

const isArchived = (project: ProjectStats) => {
  return hasStatus(project, "ARCHIVED");
};

const hasStatus = (project: ProjectStats, status: ProjectStatus): boolean => {
  return project.status.status === status;
};
const getStatusFromRow = (row: ProjectStats): StatusTagsFormProps => {
  return getLabelStyleStatusTags(row.status);
};
const getLabelStyleStatusTags = (
  projectStatus: ProjectStatusBase
): StatusTagsFormProps => {
  switch (projectStatus.status) {
    case "CREATED":
      return {
        label: i18n.t("app_project_created_status"),
        color: "purple",
      };
    case "STARTED":
      return {
        label: i18n.t("app_project_started_status"),
        color: "green",
      };
    case "DONE":
      return {
        label: i18n.t("app_project_done_status"),
        color: "grey",
      };
    case "ARCHIVED":
      return {
        label: i18n.t("app_project_archived_status"),
        color: "light_grey",
      };
    default:
      return {
        label: projectStatus.status,
        color: "grey",
      };
  }
};

export const ProjectStatusUtils = {
  isCreated: isCreated,
  isStarted: isStarted,
  isDone: isDone,
  isArchived: isArchived,
  getStatusFromRow: getStatusFromRow,
  getLabelStyleStatusTags: getLabelStyleStatusTags,
};
