import { Territory } from "api/geoZone";
import { EMPTY_GEO_JSON } from "components";

export interface GeoZoneFormState {
  details: GeoZoneFormDetailsState,
  validity: GeoZoneFormValidity
}

export interface GeoZoneFormDetailsState {
  geoZoneName: string;
  territory: Territory;
  geoJson: string;
}

export interface GeoZoneFormValidity {
  details: GeoZoneFormDetailsValidity
}

export interface GeoZoneFormDetailsValidity {
  geoZoneName: boolean;
  territory: boolean;
  geoJson: boolean;
}

export const defaultGeoZoneFormValidity: GeoZoneFormValidity = {
  details: {
    geoZoneName: true,
    territory: true,
    geoJson: true
  },
};

export const defaultGeoZoneFormState: GeoZoneFormState = {
  details: {
    id: "",
    geoZoneName: "",
    //@ts-ignore
    territory: undefined,
    geoJson: EMPTY_GEO_JSON
  },
  validity: defaultGeoZoneFormValidity,
};
