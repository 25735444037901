import React, { useContext } from 'react'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import { Fab, makeStyles, Tooltip } from '@material-ui/core'
import { city } from 'ca-plateform-carrier-report-domain'
import { PrimaryTypography, Table } from '../../Component/Table'
import { city as cityOrgRef } from 'ca-plateform-organization-ref-domain'
import { useTranslation } from 'react-i18next'
import { StatusTags, StatusTagsFormProps } from '../../Component/StatusTags'
import { displayNumber } from '../../utils'
import { MonthDate } from '../../Component/MonthDate'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { carrierReportStatusValues } from '.'

interface CarrierReport
  extends city.colisactiv.platform.carrier.report.domain.CarrierReport {}
interface OrganizationRef
  extends cityOrgRef.colisactiv.plateform.organization.ref.model.OrganizationRef{}

interface TitleFomProps {
  title: string
  subTitle: string
}

const titleUseStyles = (theme: Theme) =>
  makeStyles(() => ({
    customTitleContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center'
    },
    title: {
      marginBottom: 5
    }
  }))

const CustomTitle = (props: TitleFomProps) => {
  const { title, subTitle } = props
  const theme = useContext(themeContext)
  const classes = titleUseStyles(theme)()
  return (
    <div className={classes.customTitleContainer}>
      <div className={classes.title}>{title}</div>
      <div>{subTitle}</div>
    </div>
  )
}

export interface ReportRow {
  id: string
  carrierId: string
  carrierName: string | undefined
  period: number
  territoryName: string
  status: string
  activeMode: string
  electricMode: string
  thermalMode: string
}
interface ReportsTableProps {
  carrierReports: CarrierReport[]
  carriers: Map<string, OrganizationRef>
  omitCarrier: boolean
  onRowClicked?: (row: ReportRow) => void
  totalPages: number
  page: number
  handlePageChange: (page: number) => void
  isLoading?: boolean
  getStatus: (row: ReportRow) => StatusTagsFormProps
  onDownload: (carrierReportId: string) => void
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '200px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '200px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '100px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '240px',
      justifyContent: 'right'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '240px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '160px',
      maxWidth: '160px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '160px',
      maxWidth: '160px'
    }
  },
  fab: {
    width: 40,
    height: 40,
    backgroundColor: '#00000000',
    boxShadow: '0 0 black'
  },
  icon: {
    color: '#007DCE'
  }
}))

export const ReportsTable = (props: ReportsTableProps) => {
  const {
    carrierReports,
    carriers,
    onRowClicked,
    handlePageChange,
    page,
    totalPages,
    isLoading,
    omitCarrier,
    getStatus,
    onDownload
  } = props
  const classes = useStyles()
  const returnCarrierName = (carrierId: string) => {
    const carrier = carriers.get(carrierId)
    return carrier?.displayName
  }

  const { t } = useTranslation()

  const data: ReportRow[] = carrierReports.map(
    (carrierReport): ReportRow => {
      return {
        id: carrierReport.id,
        carrierId: carrierReport.carrierId,
        carrierName: returnCarrierName(carrierReport.carrierId),
        period: carrierReport.period.startDate,
        territoryName: carrierReport.territory.name,
        activeMode: `${displayNumber(
          carrierReport.activeMode.kmNumber
        )} / ${displayNumber(carrierReport.activeMode.packNumber)} `,
        thermalMode: `${displayNumber(
          carrierReport.thermalMode.kmNumber
        )} / ${displayNumber(carrierReport.thermalMode.packNumber)} `,
        electricMode: `${displayNumber(
          carrierReport.electricMode.kmNumber
        )} / ${displayNumber(carrierReport.electricMode.packNumber)} `,
        status: carrierReport.status.value
      }
    }
  )

  const columns = [
    {
      name: `${t('app_domain_report_period')} `,
      cell: (row: ReportRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          <MonthDate date={row.period} />
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_project_Territory')} `,
      cell: (row: ReportRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.territoryName}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_report_Chargeur')} `,
      cell: (row: ReportRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.carrierName}
        </PrimaryTypography>
      ),
      omit: omitCarrier
    },
    {
      name: <CustomTitle title='Mode Actif' subTitle='Nb.Km/Nb.Colis' />,
      cell: (row: ReportRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='center'
        >
          {row.activeMode}
        </PrimaryTypography>
      )
    },
    {
      name: <CustomTitle title='Mode électrique' subTitle='Nb.Km/Nb.Colis' />,
      cell: (row: ReportRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.electricMode}
        </PrimaryTypography>
      )
    },
    {
      name: <CustomTitle title='Mode thermique' subTitle='Nb.Km/Nb.Colis' />,
      cell: (row: ReportRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.thermalMode}
        </PrimaryTypography>
      )
    },
    {
      name: `${t('app_domain_status')} `,
      cell: (row: ReportRow) => {
        const status = getStatus(row)
        return (
          <StatusTags
            data-tag='___react-data-table-allow-propagation___'
            {...status}
          />
        )
      }
    },
    {
      name: '',
      cell: (row: ReportRow) => {
        return carrierReportStatusValues.isCreated(row.status) ? null : (
        <Tooltip title={t("app_domain_report_download")} arrow onClick={() => onDownload(row.id)}>
          <Fab className={classes.fab}>
            <SystemUpdateAltIcon className={classes.icon} />
          </Fab>
        </Tooltip>
      )}
    }
  ]
  return (
    <Table
      data={data}
      className={classes.container}
      columns={columns}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      page={page}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_report_table')}
    />
  )
}
