import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "ca-plateform-bonus-payment-domain";
import { DateInterval } from 'components/src/Domain/BonusPayment'
import { http } from "../../http";

export interface ExportBonusGroupInvoiceQueryBase
  extends city.colisactiv.plateform.bonus.payment.domain.features.query.ExportBonusGroupInvoiceQueryBase {}

export const exportBonusGroupInvoices = async (
  period: DateInterval
): Promise<string | null> => {
  const query: ExportBonusGroupInvoiceQueryBase = {
    payload: { period }
  }
  return http.fetchDownloadableFile(
    buildBonusGroupApiUrl(`/bonuspayments/export`),
    "Une erreur est survenue lors de l'export des factures",
    "post",
    JSON.stringify(query)
  );
};

