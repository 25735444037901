import { connect } from "react-redux";
import { global } from "store/global";
import { organization } from "store/organization";
import { profile } from "store/profile";
import { State } from "../../../store";
import { title } from "../../../store/title";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  currentUser: profile.selectors.User(state),
  carrierRefs: global.selectors.carrierRefs(state),
  operatorRefs: global.selectors.operatorRefs(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  fetchOrganization: organization.queries.fetchOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps);
