import { makeStyles } from "@material-ui/core";
import { OrganizationRef } from "api/organization";
import { projectClient } from "api/project";
import { Campaign } from "api/project/model";
import { CampaignStatusValues } from "api/project/model/CampaignStatus";
import "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import BonusFormGeneralDetails from "./BonusFormGeneralDetails";
import BonusFormStatsList, { CampaignRef } from "./BonusFormStatsList";
import {
  BonusFormDetailsState,
  BonusFormState,
  BonusFormStatsState,
  BonusFormStatsValidity
} from "./model";

const useStyles = makeStyles(() => ({
  bonusStatsContainer: {
    borderTop: `solid 1px #007DCE30`,
    flex: 1,
  },
}));
interface BonusFormPops {
  carriers: Map<string, OrganizationRef>;
  operators: Map<string, OrganizationRef>;
  bonusState: BonusFormState;
  mode: "create" | "update" | "readonly";
  onBonusChange: (bonusState: BonusFormState) => void;
}

const BonusForm = (props: BonusFormPops) => {
  const { bonusState, carriers, mode, operators, onBonusChange } = props;
  const classes = useStyles();

  const [availableCampaigns, setAvailableCampaigns] = useState<CampaignRef[]>(
    []
  );

  const areaCodes = useCallback(
    (campaign: Campaign): string[] => {
      return campaign.bonusPrices
        .filter((bonusPrice) =>
          bonusPrice.operatorIds.some(
            (operator) => operator === bonusState.details.operatorId
          )
        )
        .flatMap((bonusPrice) => bonusPrice.areas.map((area) => area.code));
    },
    [bonusState.details.operatorId]
  );

  useEffect(() => {
    if (
      !bonusState.details.territory?.code ||
      !bonusState.details.operatorId ||
      !bonusState.details.period
    ) {
      setAvailableCampaigns([]);
      return;
    }

    projectClient.query
      .fetchCampaignsFiltered(
        bonusState.details.operatorId,
        bonusState.details.territory.code,
        bonusState.details.period,
        { value: CampaignStatusValues.STARTED.value }
      )
      .then((campaigns) => {
        const campaignRefs: CampaignRef[] = campaigns.map((campaign) => ({
          id: campaign.id,
          name: campaign.details.name,
          areaCodes: areaCodes(campaign),
        }));
        setAvailableCampaigns(campaignRefs);
      });
  }, [
    bonusState.details.operatorId,
    bonusState.details.territory,
    bonusState.details.period,
    areaCodes,
  ]);

  const carrierName = (carrierId: string): string => {
    const name = carriers.get(carrierId)?.displayName;
    return name || "";
  };

  const handleBonusDetailsChange = useCallback(
    (type: keyof BonusFormDetailsState, value: any) => {
      onBonusChange({
        ...bonusState,
        details: {
          ...bonusState.details,
          [type]: value,
        },
        validity: {
          ...bonusState.validity,
          details: { ...bonusState.validity.details, [type]: true },
        },
      });
    },
    [bonusState, onBonusChange]
  );

  const handleBonusStatsChange = useCallback(
    (
      statList: BonusFormStatsState[],
      validationList: BonusFormStatsValidity[]
    ) => {
      onBonusChange({
        ...bonusState,
        details: {
          ...bonusState.details,
          bonuses: statList,
        },
        validity: {
          ...bonusState.validity,
          bonuses: validationList,
        },
      });
    },
    [onBonusChange, bonusState]
  );

  return (
    <>
      <BonusFormGeneralDetails
        bonusDetails={bonusState.details}
        validity={bonusState.validity.details}
        onChange={handleBonusDetailsChange}
        carriers={carriers}
        operators={operators}
        readonly={mode === "update" || mode === "readonly"}
      />
      <div className={classes.bonusStatsContainer}>
        <BonusFormStatsList
          statList={bonusState.details.bonuses}
          validationList={bonusState.validity.bonuses}
          carrier={carrierName(bonusState.details.carrierId)}
          onChange={handleBonusStatsChange}
          availableCampaigns={availableCampaigns}
          availableAreas={bonusState.details.territory?.zones ?? []}
          readonly={mode === "readonly"}
        />
      </div>
    </>
  );
};

export default BonusForm;
