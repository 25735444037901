import { Box, Grid, makeStyles } from "@material-ui/core";
import GeoZoneForm from "app/components/GeoZoneForm";
import { defaultGeoZoneFormState, GeoZoneFormDetailsState, GeoZoneFormState } from "app/components/GeoZoneForm/model";
import { GeoZoneValidation } from "app/components/GeoZoneForm/validation";
import {
  BackButton,
  Button,
  GeoZoneMap,
  HeadBarProps,
  PageForm,
} from "components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  buttons: {
    justifyContent: "right",
    display: "flex",
    width: "100%"
  },
  mapContainer: {
    paddingRight: '20px'
  },
  form: {
    display: "flex",
    flexDirection: "column"
  }
}));

interface GeoZoneCreatePageProps {
  setHeadBar: (headBar: HeadBarProps) => void;
  createGeoZone: (form: GeoZoneFormDetailsState) => void;
}

const GeoZoneCreatePage = (props: GeoZoneCreatePageProps) => {
  const { setHeadBar, createGeoZone } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [geoZoneState, setGeoZone] = useState<GeoZoneFormState>(
    defaultGeoZoneFormState
  );

  const handleGeoZoneDetailsChange = useCallback(
    (type: keyof GeoZoneFormDetailsState, value: any) => {
      setGeoZone(geoZoneOldState => ({
        ...geoZoneOldState,
        details: {
          ...geoZoneOldState.details,
          [type]: value,
        },
        validity: {
          ...geoZoneOldState.validity,
          details: { ...geoZoneOldState.validity.details, [type]: true },
        },
      }));
    },
    []
  );

  const handleGeoZoneCreation = useCallback(async () => {
    const [isValid, validation] = GeoZoneValidation.validateForm(
      geoZoneState
    );
    if (!isValid) {
      return setGeoZone({ ...geoZoneState, validity: validation });
    }
    await createGeoZone(geoZoneState.details);
  }, [createGeoZone, geoZoneState]);

  return (
    <PageForm headBar={{ title: "Créer une zone personnalisée" }} setHeadBar={setHeadBar}>
      <>
      <BackButton />
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box className={classes.form}>
              <GeoZoneForm
                geoZoneDetails={geoZoneState.details}
                validity={geoZoneState.validity.details}
                onChange={handleGeoZoneDetailsChange}
              />
              <Box className={classes.buttons}>
                <Button onClick={handleGeoZoneCreation}>
                  {t("app_geozone_create_create")}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <GeoZoneMap            
              mapContainerClassName={classes.mapContainer}
              geoJson={geoZoneState.details.geoJson}
            />
          </Grid>
        </Grid>
      </Box>
      </>
    </PageForm>
  );
};

export default withConnect(GeoZoneCreatePage);
