import { city } from "ca-plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";
import { BonusGroupFunderInvoiceDetails } from "..";

export interface UpdateBonusGroupInvoiceDetailsCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .UpdateBonusGroupInvoiceDetailsCommand {}

export interface UpdateBonusGroupInvoiceDetailsCommandDetails
  extends city.colisactiv.plateform.bonus.group.model.features
    .UpdateBonusGroupInvoiceDetailsCommandDetails {}

export interface BonusGroupInvoiceDetailsUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupInvoiceDetailsUpdatedEvent {
  type: string;
}

export const updateBonusGroupInvoiceDetailsCommand = (
  bonusGroupId: string,
  invoiceId: string,
  details: BonusGroupFunderInvoiceDetails
): UpdateBonusGroupInvoiceDetailsCommand => {
  return {
    type: "UPDATE_BONUS_GROUP_INVOICE_DETAILS",
    payload: {
      bonusGroupId: Number(bonusGroupId),
      invoiceId: invoiceId,
      details: details,
    },
  };
};

export const updateInvoiceDetails = (
  bonusGroupId: string,
  invoiceId: string,
  details: BonusGroupFunderInvoiceDetails
): Promise<BonusGroupInvoiceDetailsUpdatedEvent> => {
  return http.put(
    buildBonusGroupApiUrl("/bonusgroup/invoice/details"),
    JSON.stringify(
      updateBonusGroupInvoiceDetailsCommand(bonusGroupId, invoiceId, details)
    ),
    "La facture a bien été modifiée"
  );
};
