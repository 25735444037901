import { geoZoneClient, Territory } from "api/geoZone";
import { AutoComplete } from "components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { classicNameResolver } from "typescript";

interface GeoZoneFormTerritoryInputProps {
  label: string;
  className: string;
  inputClassName: string;
  value: Territory;
  error: boolean;
  errorMessage: string;
  readonly?: boolean;
  onChange: (territory: Territory | Territory[]) => void;
  helperTextClassName?: string;
}

const GeoZoneFormTerritoryInput = (props: GeoZoneFormTerritoryInputProps) => {
  const {
    label,
    className,
    error,
    errorMessage,
    inputClassName,
    readonly,
    value,
    onChange,
    helperTextClassName,
  } = props;
  const { t } = useTranslation();

  const [autocompleteText, setAutocompleteText] = useState(
    "Rechercher un territoire"
  );
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  );

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([]);
      setAutocompleteText("Rechercher un territoire");
      return;
    }

    try {
      const response = await geoZoneClient.query.getTerritories(
        0,
        5,
        search.trim()
      );
      setAutoCompleteOptions(response.list);
      if (response.list.length === 0) {
        setAutocompleteText("Aucun territoire trouvé");
      }
    } catch (err) {
      console.error(err);
      setAutoCompleteOptions([]);
      setAutocompleteText("Une erreur est survenue, veuillez réessayer");
    }
  }, []);

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory?.name ?? "";
  }, []);

  return (
    <AutoComplete<Territory>
      id="geozone-territory"
      label={label}
      className={className}
      textFieldClassName={inputClassName}
      options={autoCompleteOptions}
      defaultValue={value}
      noOptionsText={autocompleteText}
      onChangeSelectedElement={onChange}
      onSearch={handleSearchTerritory}
      getOptionLabel={getTerritoryName}
      readonly={readonly}
      error={error}
      errorMessage={errorMessage}
      helperTextClassName={helperTextClassName}
    />
  );
};

export default GeoZoneFormTerritoryInput;
