import React, { useContext, useEffect, useState } from "react";
import withConnect from "../../pages/Bonus/View/withConnect";
import { makeStyles } from "@material-ui/core";
import { themeContext, Theme } from "@smartb/archetypes-ui-components";
import { PageSelector, ComponentLoading } from "components";
import { Document, Page, pdfjs } from "react-pdf";
import BonusPdfEmpty from "./BonusPdfEmpty";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    text: {
      fontSize: "13px",
      fontWeight: 600,
      lineHeight: "20px",
      color: "#353945",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    pdfViewer: {
      boxShadow: theme.shadows[1],
      "& span": {
        color: "transparent",
      },
      "& canvas": {
        borderRadius: "5px",
      },
    },
    document: {
      position: "relative",
      width: "750px",
      minHeight: "800px",
      marginBottom: "20px",
    },
    loading: {
      marginTop: "50%",
    },
  }));

interface BonusViewProps {
  pdf?: string;
  bonusHasDocuments: boolean;
}

const BonusPdfViewer = (props: BonusViewProps) => {
  const { pdf, bonusHasDocuments } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    setPageNumber(1);
  }, [pdf]);

  function onDocumentLoadSuccess(pdf: any) {
    setTotalPages(pdf.numPages);
  }

  if (!pdf) {
    return <BonusPdfEmpty bonusHasDocuments={bonusHasDocuments} />;
  }

  return (
    <Document
      file={pdf}
      onLoadSuccess={onDocumentLoadSuccess}
      className={classes.document}
      loading={<ComponentLoading className={classes.loading} />}
    >
      <PageSelector
        pageNumber={pageNumber}
        setPageNumber={(newPageNumber) => setPageNumber(newPageNumber)}
        totalPages={totalPages}
        style={{ position: "absolute", top: "10px", left: "10px" }}
      />
      <Page
        pageNumber={pageNumber}
        width={750}
        renderMode="canvas"
        className={classes.pdfViewer}
        renderAnnotationLayer={false}
        loading={<ComponentLoading className={classes.loading} />}
      />
    </Document>
  );
};

export default withConnect(BonusPdfViewer);
