import { city } from "ca-plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";

export interface EditBonusGroupCommentCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .EditBonusGroupCommentCommand {}

export interface BonusGroupCommentEditedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupCommentEditedEvent {}

export const editBonusGroupCommentCommand = (
  bonusGroupId: string,
  comment: string
): EditBonusGroupCommentCommand => {
  return {
    payload: {
      bonusGroupId: bonusGroupId,
      comment: comment,
    },
  };
};

export const editBonusGroupComment = (
  bonusGroupId: string,
  comment: string
): Promise<BonusGroupCommentEditedEvent> => {
  const url = buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/editComment`);
  const body = JSON.stringify(
    editBonusGroupCommentCommand(bonusGroupId, comment)
  );
  return http.put(url, body, "Le commentaire à bien été édité");
};
