import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Button } from "components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  button: {
    marginRight: "10px",
    marginTop: "3px",
  },
  exportContainer: {
    marginTop: 10,
  },
  exportForms: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    width: "100%",
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "10px",
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
  exportSection: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    marginRight: 50,
    marginTop: 10,
  },
  sectionTitle: {
    padding: "20px 0",
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
  },
  period: {
    display: "flex",
    justifyContent: "left",
  },
  leftPeriodInput: {
    paddingRight: "10px",
  },
  exportButton: {
    alignSelf: "end",
    marginTop: "10px",
  },
  reminderRuleIcon: {
    color: "#007DCE",
    alignItems: "center",
    display: "flex",
  },
  reminderIcon: {
    alignItems: "center",
    display: "flex",
  },
  reminderMessage: {
    padding: "8px",
  },
  reminderContainer: {
    width: "fit-content",
    display: "flex",
    gap: "15px",
    background: "white",
    padding: "5px",
    paddingRight: "10px",
    marginTop: "10px",
  },
  severityBar: {
    width: "3px",
    minWidth: "3px",
    borderRadius: "30px",
    marginRight: "5px",
  },
}));

interface DashboardAdminExportFormProps {
  handleDeliveryExportAll: () => void;
  handleTraceExportAll: () => void;
}

export const DashboardAdminExportForm = (
  props: DashboardAdminExportFormProps
) => {
  const { handleDeliveryExportAll, handleTraceExportAll } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.exportContainer}>
      <div className={classes.title}>
        <span>{t("app_dashboard_admin_export_title")}</span>
      </div>
      <Box className={classes.reminderContainer}>
        <div
          className={classes.severityBar}
          style={{ background: "rgb(33, 150, 243)" }}
        />
        <div className={classes.reminderRuleIcon}>
          <Info className={classes.reminderRuleIcon} />
        </div>
        <div>{t("app_dashboard_admin_export_info")}</div>
      </Box>
      <Box className={classes.exportForms}>
        <Box className={classes.exportSection}>
          <Button
            className={classes.exportButton}
            onClick={handleDeliveryExportAll}
          >
            {t("app_dashboard_admin_export_delivery_button")}
          </Button>
        </Box>
        <Box className={classes.exportSection}>
          <Button
            className={classes.exportButton}
            onClick={handleTraceExportAll}
          >
            {t("app_dashboard_admin_export_trace_button")}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
