import { Box, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { OrganizationRef } from "api/organization";
import { InputForm, PrimaryTypography, Table } from "components";
import {
  CsvExtractor,
  CsvExtractorHeader,
} from "components/src/Component/CsvExtractor";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiOrganizationsCellId } from "./ApiOrganizationsCellId";

const organizationIdColumn = {
  position: "last-child",
  style: {
    maxWidth: 150,
  },
};

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  filterName: {
    width: 250,
    marginLeft: 20,
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
  },
  table: {
    margin: "0 -20px",
    maxWidth: 500,
    [`& .rdt_TableRow .rdt_TableCell:${organizationIdColumn.position}`]: organizationIdColumn.style,
    [`& .rdt_TableCol:${organizationIdColumn.position}`]: organizationIdColumn.style,
  },
});

interface ApiOrganizationsProps {
  organizationRefs: Map<string, OrganizationRef>;
}

export const ApiOrganizations = (props: ApiOrganizationsProps) => {
  const { organizationRefs } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [filterName, setFilterName] = useState("");

  const organizations = useMemo(() => {
    return Array.from(organizationRefs.values()).filter((org) =>
      org.displayName.toLowerCase().includes(filterName)
    );
  }, [organizationRefs, filterName]);

  const columns = [
    {
      name: `${t("app_api_organization_table_name")} `,
      cell: (org: OrganizationRef) => (
        <PrimaryTypography
          data-tag="___react-data-table-allow-propagation___"
          align="left"
        >
          {org.displayName}
        </PrimaryTypography>
      ),
    },
    {
      name: `${t("app_api_organization_table_id")} `,
      cell: (org: OrganizationRef) => (
        <ApiOrganizationsCellId
          org={org}
          onCopyToClipboard={copyIdToClipboard}
        />
      ),
    },
  ];

  const copyIdToClipboard = useCallback((org: OrganizationRef) => {
    navigator.clipboard.writeText(org.friendlyId);
  }, []);

  const handleFilterNameChange = useCallback((search: string) => {
    setFilterName(search.toLowerCase());
  }, []);

  const handleFilterNameClear = useCallback(() => {
    setFilterName("");
  }, []);

  const csvHeaders: CsvExtractorHeader<OrganizationRef>[] = [
    { key: "displayName", label: t("app_api_organization_table_name") },
    { key: "friendlyId", label: t("app_api_organization_table_id") },
  ];

  return (
    <>
      <Box className={classes.actionBar}>
        <CsvExtractor
          data={organizations}
          headers={csvHeaders}
          filename={"ID_Transporteurs.csv"} //-> TODO update
          tooltipText={"Exporter"}
        />
        <InputForm
          inputType="textField"
          id="api-organizations-filters-name"
          onChange={handleFilterNameChange}
          value={filterName}
          placeHolder={t("app_api_organization_filter_name")}
          className={classes.filterName}
          inputIcon={<Search className={classes.icon} />}
          onRemoveValue={handleFilterNameClear}
        />
      </Box>
      <Table
        className={classes.table}
        data={organizations}
        columns={columns}
        onRowClicked={copyIdToClipboard}
      />
    </>
  );
};
