import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";

export interface ConventionCreatedEvent
  extends city.colisactiv.organization.model.features.convention
    .ConventionCreatedEvent {}

export const resetConvention = (
  organizationId: string
): Promise<ConventionCreatedEvent> => {
  return http.post(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/convention/reset`
    ),
    undefined,
    "La convention a bien été réinitialisée"
  );
};
