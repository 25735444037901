import React, {useEffect} from "react";
import {ComponentLoading} from "components";
import withConnect from "./withConnect";
import {keycloakClient} from "api/keyclaok";
import {User} from "api/organization/users";

interface LandingPageRouterProps {
  goto: (url: string) => void;
  gotoOrganization: (orgId: string) => void;
  currentUser: User | null;
}

const LandingPageRouter = (props: LandingPageRouterProps) => {
  const { goto, gotoOrganization, currentUser } = props;

  useEffect(() => {
    redirectLandingPage();
  }, [currentUser]);

  const redirectLandingPage = async () => {
    if (currentUser === null) return;
    if (keycloakClient.instance.hasRealmRole("operator_admin_onboarding") ||
      keycloakClient.instance.hasRealmRole("operator_user_onboarding")) {
      gotoOrganization(currentUser!!.details.organizationId);
    } else if (keycloakClient.instance.hasRealmRole("tms_admin") ||
      keycloakClient.instance.hasRealmRole("tms_user")) {
      goto("/api");
    } else goto("/dashboard");
  };

  return <ComponentLoading />;
};

export default withConnect(LandingPageRouter);
