import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";
import i18n from "i18n";

export interface DeleteOrganizationCommand
  extends city.colisactiv.organization.model.features
    .DeleteOrganizationCommand {}

export interface DeletedOrganizationEvent
  extends city.colisactiv.organization.model.features
    .DeletedOrganizationEvent {}

export const organizationCreateCommand = (
  organizationId: string
): DeleteOrganizationCommand => {
  return {
    type: "DELETE_ORGANIZATION",
    payload: {
      organizationId: organizationId,
    },
  };
};

export const deleteOrganizationCommand = (
  organizationId: string
): Promise<DeletedOrganizationEvent> => {
  return http.delete(
    buildOrganizationApiUrl(`/organizations/${organizationId}`),
    i18n.t("app_organization_api_delete")
  );
};
