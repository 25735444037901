import { connect } from "react-redux";
import { router } from "store/router";
import { State } from "../../../../store";
import { project } from "store/project";
import { profile } from "store/profile";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  projectGeneralView: router.goto.project.projectView,
  gotoProjectCreate: router.goto.project.projectAdd,
  resetProject: project.commands.resetProject,
  gotoProjectsList: router.goto.project.projectsList,
};

export default connect(mapStateToProps, mapDispatchToProps);
