import {city} from "ca-plateform-carrier-report-domain";
import {city as cityPlat} from "ca-plateform-plateform-commons";
import {fetchReportsPage} from "./query/getReportsPageQuery";
import {getReport} from "./query/getReportQuery";
import {createCarrierReportCommand} from "./commande/createCarrierReportCommand";
import {updateCarrierReportCommand} from "./commande/updateCarrierReportCommand";
import {exportReport} from "./query/exportReport";
import {updateCarrierReportStatusCommand} from "./commande/updateCarrierReportStatusCommand";

export interface CarrierReport
  extends city.colisactiv.platform.carrier.report.domain.CarrierReport {}

export interface Page<T> extends cityPlat.colisactiv.platform.commons.Page<T> {}

export interface Mode
  extends city.colisactiv.platform.carrier.report.domain.Mode {}

export interface DateInterval
  extends cityPlat.colisactiv.platform.commons.DateInterval {}

export interface CarrierReportStatus
  extends city.colisactiv.platform.carrier.report.domain.CarrierReportStatus {}

export interface CarrierReportStatusValues
  extends city.colisactiv.platform.carrier.report.domain.CarrierReportStatusValues {}

export const carrierReportClient = {
  createCarrierReport: createCarrierReportCommand,
  updateCarrierReport: updateCarrierReportCommand,
  updateCarrierReportStatus: updateCarrierReportStatusCommand,
  query: {
    getCarrierReportsPage: fetchReportsPage,
    getReport: getReport,
    exportReport: exportReport
  },
};
