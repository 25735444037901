import { ClickAwayListener, makeStyles, Popper } from '@material-ui/core'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import Calendar from 'react-calendar'
import { DateInterval } from '../../Domain/BonusPayment'
import {
  UTCToTimedZone,
  getFirstDayOfTheMonthUTC,
  getLastDayOfTheMonthUTC
} from '../../utils'
import { InputForm } from '../InputForm'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    container: {
      boxShadow: theme.shadows[1],
      border: 'none',
      borderRadius: '5px',
      width: '350px',
      maxWidth: '100%',
      background: 'white',
      '& button': {
        borderRadius: '5px',
        margin: 0,
        border: 0,
        outline: 'none'
      },
      '& button:enabled:hover': {
        cursor: 'pointer'
      },
      '& .react-calendar__navigation button': {
        minWidth: '44px',
        background: 'none'
      },
      '& .react-calendar__year-view .react-calendar__tile,.react-calendar__decade-view .react-calendar__tile,.react-calendar__century-view .react-calendar__tile': {
        padding: '2em 0.5em'
      },
      '& .react-calendar__tile': {
        maxWidth: '100%',
        textAlign: 'center',
        padding: '0.75em 0.5em',
        background: 'none'
      },
      '& button:disabled': {
        backgroundColor: 'transparent !important',
        opacity: '0.5'
      },
      '& button:hover': {
        background: 'rgba(0, 0, 0, 0.05)'
      },
      '& .react-calendar__tile--hasActive': {
        background: 'rgba(0, 0, 0, 0.05) !important'
      },
      '& .react-calendar__navigation': {
        height: '44px',
        background: '#61D4A6',
        borderRadius: '5px'
      }
    },
    popper: {
      marginBottom: '5px',
      marginTop: '5px',
      zIndex: 2000
    }
  }))

interface PeriodPickerProps {
  id: string
  label?: string
  error?: boolean
  errorMessage?: string
  minDate?: number
  maxDate?: number
  period?: DateInterval
  placeholder?: string
  inputClassName?: string
  displayEnd?: boolean
  clearable?: boolean
  readonly?: boolean
  onDateChange?: (newPeriod: DateInterval | undefined) => void
}

export const PeriodPicker = (props: PeriodPickerProps) => {
  const {
    id,
    label,
    error,
    errorMessage,
    period,
    maxDate,
    minDate,
    onDateChange,
    placeholder,
    inputClassName,
    clearable,
    displayEnd = false,
    readonly = false
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  const [min, setMin] = useState<Date | undefined>(undefined)
  const [max, setMax] = useState<Date | undefined>(undefined)
  const [date, setDate] = useState<Date | undefined>(undefined)

  useEffect(() => {
    setDate(
      period == null
        ? undefined
        : UTCToTimedZone(displayEnd ? period.endDate : period.startDate)
    )
  }, [period])

  useEffect(() => {
    setMin(minDate == null ? undefined : UTCToTimedZone(minDate))
  }, [minDate])

  useEffect(() => {
    setMax(maxDate == null ? undefined : UTCToTimedZone(maxDate))
  }, [maxDate])

  const inputRef = useRef<HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const options = { year: 'numeric', month: 'long' }

  const handleClickMonth = useCallback(
    (newDate: Date) => {
      onDateChange &&
        onDateChange({
          startDate: getFirstDayOfTheMonthUTC(newDate),
          endDate: getLastDayOfTheMonthUTC(newDate)
        })
    },
    [onDateChange]
  )

  const handleClear = useCallback(() => {
    onDateChange && onDateChange(undefined)
  }, [onDateChange])

  return (
    <Fragment>
      <InputForm
        id={id}
        ref={inputRef}
        inputType='textField'
        label={label}
        value={date ? date.toLocaleDateString('fr-FR', options) : ''}
        error={error}
        errorMessage={errorMessage}
        onClick={() => !readonly && setOpen(!open)}
        placeHolder={placeholder}
        className={inputClassName}
        onRemoveValue={clearable ? handleClear : undefined}
        readonly={readonly}
        autocomplete={'off'}
      />
      <Popper
        open={open}
        anchorEl={inputRef.current}
        placement='bottom'
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Calendar
            view='year'
            value={date}
            locale='fr-FR'
            onClickMonth={handleClickMonth}
            maxDate={max}
            minDate={min}
            className={classes.container}
            next2Label={null}
            prev2Label={null}
          />
        </ClickAwayListener>
      </Popper>
    </Fragment>
  )
}
