import { makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Campaign, CampaignStats } from "api/project/model";
import {
  CampaignActionsUtils,
  CampaignsActionProps,
} from "api/project/model/CampaignActions";
import { CampaignStatusUtils } from "api/project/model/CampaignStatus";
import {
  Button,
  CampaignFilters,
  CampaignRow,
  CampaignTable,
} from "components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  filter: {
    width: "200px !important",
    marginRight: "20px",
    "@media (max-width: 900px)": {
      width: "100% !important",
      marginRight: "0 !important",
    },
    "& .MuiOutlinedInput-root": {
      height: "44px !important",
    },
    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
      marginTop: "0px",
    },
  },
  rightStyle: {
    position: "sticky",
    top: 0,
    background: "transparent",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 40,
    alignItems: "center",
    paddingBottom: 10,
  },
  rightStyleContainer: {
    position: "relative",
    width: "100%",
  },
  campaignsListing: {
    width: "100%",
    marginTop: 20,
    height: "auto",
  },
  icon: {
    color: "#9a9a9a",
    cursor: "pointer",
    marginTop: "0px !important",
  },
}));

interface CampaignsInformationsProps extends CampaignsActionProps {
  campaigns: Array<CampaignStats>;
  addCampaign: () => void;
  onClickCampaign: (campaignId: string) => void;
  isColisactivAdmin: boolean;
}

const CampaignsInformations = (props: CampaignsInformationsProps) => {
  const { campaigns, addCampaign, onClickCampaign, isColisactivAdmin } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchCampaign, setSearchCampaign] = useState<string>("");
  const [campaignStatus, setCampaignStatus] = useState<string>("");
  const [campaignToShow, setCampaignToShow] = useState<CampaignStats[]>([]);

  useEffect(() => {
    setCampaignToShow(campaigns);
  }, [campaigns]);

  const stringLowerCase = (value: string): string => {
    return value.trim().toLowerCase();
  };

  const onSearchCampaign = async (campaignName: string) => {
    await setSearchCampaign(campaignName);
    const allCampaign = [...campaigns];
    const searchedCampaign = allCampaign.filter((campaign: CampaignStats) => {
      if (campaignStatus !== "") {
        return (
          stringLowerCase(campaign.details.name).includes(
            stringLowerCase(campaignName)
          ) && campaign.status.value === campaignStatus
        );
      } else {
        return stringLowerCase(campaign.details.name).includes(
          stringLowerCase(campaignName)
        );
      }
    });
    setCampaignToShow(searchedCampaign);
  };

  const onSelectCampaignStatus = async (selectedcampaignStatus: string) => {
    await setCampaignStatus(selectedcampaignStatus);
    const allCampaign = [...campaigns];
    const searchedCampaign = allCampaign.filter((campaign: CampaignStats) => {
      if (selectedcampaignStatus !== "") {
        return (
          stringLowerCase(campaign.details.name).includes(
            stringLowerCase(searchCampaign)
          ) && campaign.status.value === selectedcampaignStatus
        );
      } else {
        return stringLowerCase(campaign.details.name).includes(
          stringLowerCase(searchCampaign)
        );
      }
    });
    setCampaignToShow(searchedCampaign);
  };

  return (
    <>
      <div className={classes.rightStyleContainer}>
        <div className={classes.rightStyle}>
          <CampaignFilters
            searchCampaign={searchCampaign}
            setSearchCampaign={onSearchCampaign}
            campaignStatus={campaignStatus}
            setCampaignStatus={onSelectCampaignStatus}
          />
          {isColisactivAdmin && (
            <Button onClick={() => addCampaign()}>
              <AddCircleIcon />
              {t("app_campaign_details_form_action_create")}
            </Button>
          )}
        </div>

        <div className={classes.campaignsListing}>
          <CampaignTable
            campaigns={campaignToShow}
            onRowClicked={(row: CampaignRow) => onClickCampaign(row.id)}
            getStatus={(campaign: CampaignStats) =>
              //@ts-ignore
              CampaignStatusUtils.getStatusFromRow(campaign as Campaign)
            }
            getActions={(campaign: CampaignStats) =>
              CampaignActionsUtils.getMenuItems(
                //@ts-ignore
                campaign as Campaign,
                props,
                isColisactivAdmin
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default CampaignsInformations;
