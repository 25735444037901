import {Box, makeStyles} from '@material-ui/core'
import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import {lastDayOfMonth, max} from "date-fns";

const useStyles = makeStyles(() => ({
  reminder: {
    marginBottom: "15px",
    marginTop: "5px",
  },
  reminderRuleIcon: {
    color: "#f44336",
    alignItems: "center",
    display: "flex"
  },
  reminderRuleMessage: {
    color: "#f44336",
    fontWeight: "bold"
  },
  reminderIcon: {
    color: "#ff9900",
    alignItems: "center",
    display: "flex"
  },
  reminderMessage: {
    color: "#ff9900",
    fontWeight: "bold",
    padding: '8px'
  },
  reminderContainer: {
    display: 'flex',
    gap: '15px',
    background: 'white',
    width: '100%',
    padding: '5px',
    marginBottom: '20px'
  },
  severityBar: {
    width: '3px',
    minWidth: '3px',
    borderRadius: '30px',
    marginRight: '5px'
  }
}));

interface ReportReminderProps {
  carrierReportCreationDate: number | null | undefined
}

export const ReportReminder = (props: ReportReminderProps) => {
  const { carrierReportCreationDate } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const creationDate = new Date(
    carrierReportCreationDate + 14 * 24 * 3600 * 1000
  );

  const limitDate = lastDayOfMonth(max([creationDate, new Date()]));

  const reminderMessageParams = {
    deadline: limitDate.toLocaleString("fr-fr", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }),
    month: limitDate.toLocaleString("fr-fr", { month: "long" }),
  };

  return (
    <Fragment>
      <div className={classes.reminder}>
        <Box className={classes.reminderContainer}>
          <div
            className={classes.severityBar}
            style={{background: 'rgb(244, 67, 54)'}}
          />
          <div className={classes.reminderRuleIcon}>
            <ReportProblemOutlinedIcon className={classes.reminderRuleIcon} />
          </div>
          <div className={classes.reminderRuleMessage}>
            {t("app_reports_view_rule_reminder")}
          </div>
        </Box>
        <Box className={classes.reminderContainer}>
          <div
            className={classes.severityBar}
            style={{background: 'rgb(255, 152, 0)'}}
          />
          <div className={classes.reminderIcon}>
            <ReportProblemOutlinedIcon className={classes.reminderIcon} />
          </div>
          <div className={classes.reminderMessage}>
            {t("app_reports_view_reminder", reminderMessageParams)}
          </div>
        </Box>
      </div>
    </Fragment>
  )
}

export default ReportReminder
