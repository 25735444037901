import { OrganizationDetails, OrganizationType, PackageType } from "../index";
import { buildOrganizationApiUrl } from "../../configuration";
import { city } from "ca-plateform-organization-domain";
import { http } from "../../http";

export interface OrganizationUpdateCommand
  extends city.colisactiv.organization.model.features
    .OrganizationUpdateCommand {}

export interface OrganizationUpdatedEvent
  extends city.colisactiv.organization.model.features
    .OrganizationUpdatedEvent {}

export const organizationUpdateCommand = (
  type: OrganizationType,
  details: OrganizationDetails,
  organizationId: string,
  packageType?: PackageType
): OrganizationUpdateCommand => {
  return {
    type: "UPDATE_ORGANIZATION",
    payload: {
      details: details,
      organizationId: organizationId,
      type: type,
      packageType: packageType,
    },
  };
};

export const updateOrganizationCommand = (
  type: OrganizationType,
  details: OrganizationDetails,
  organizationId: string,
  packageType?: PackageType
): Promise<OrganizationUpdatedEvent> => {
  return http.put(
    buildOrganizationApiUrl(`/organizations/${organizationId}`),
    JSON.stringify(
      organizationUpdateCommand(type, details, organizationId, packageType)
    )
  );
};
