import {Box, makeStyles} from "@material-ui/core"
import {exportBonusGroupInvoices} from "api/bonusGroup/query/exportBonusGroupInvoices"
import {bonusPaymentClient, BonusPaymentId} from "api/bonusPayment"
import {BonusPaymentStatusUtils} from "api/bonusPayment/model/BonusPaymentStatusUtils"
import {OrganizationRef} from "api/organization"
import {getDefaultOrganizationLogo} from "app/components/OrganizationDetailsForm/OrganizationLogo"
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle"
import {
  BonusPayment,
  BonusPaymentFilters,
  BonusPaymentFiltersModel,
  BonusPaymentTable,
  Button,
  HeadlineBox,
  MonthDate,
  OperatorOption,
  PeriodPickerPopup,
  SelectedItem,
  SelectedItemsWithActions
} from "components"
import {DateInterval} from "components/src/Domain/BonusPayment"
import React, {useCallback, useMemo, useState} from "react"
import {useTranslation} from 'react-i18next'
import {formatDate, nullToUndefined, nullToUndefinedNumber} from "utils"
import {usePaginedList} from "../../../hooks/usePaginedList/usePaginedList"
import withConnect from "./withConnect"

const useStyles = makeStyles(() => ({
  headline1: {
    width: "60%",
  },
  headline2: {
    width: "35%",
  },
  topContainer: {
    display: "flex",
    justifyContent: "right",
    paddingRight: 30
  },
  fab: {
    width: 40,
    height: 40,
    backgroundColor: "#00000000",
    boxShadow: "0 0 black",
  },
  icon: {
    color: "#007DCE",
  },
}));

interface BonusPaymentProps {
  organizationRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  gotoBonus: (bonusId: string) => void;
  payBonusPayment: (ids: BonusPaymentId[]) => void;
  gotoBonusPaymentList: (
    page: number,
    size: number,
    operatorId?: string,
    startDate?: number,
    endDate?: number,
    status?: string
  ) => void;
}

interface BonusPaymentsState {
  checkedBonus: (SelectedItem & BonusPayment)[];
  checkedStatus: boolean;
}

const BonusPayments = (props: BonusPaymentProps) => {
  const {
    gotoBonusPaymentList,
    gotoBonus,
    organizationRefs,
    operatorRefs,
    payBonusPayment,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation()

  const [checkedBonusInformations, setCheckedBonus] = useState<BonusPaymentsState>({
    checkedBonus: [],
    checkedStatus: false,
  });

  const [openInvoiceExportPopup, setOpenInvoiceExportPopup] = useState(false)

  const getOperators = useCallback((): OperatorOption[] => {
    return Array.from(operatorRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [organizationRefs]);

  const formatOperator = useCallback(
    (operatorId: string | null): OperatorOption | undefined => {
      if (!operatorId) {
        return {
          label: "",
          value: "",
        };
      }
      const allOperators = getOperators();
      return allOperators.find((el) => el.value === operatorId);
    },
    [getOperators]
  );

  const authResult = new URLSearchParams(window.location.search);
  const [filterValues, setFilterValues] = useState<BonusPaymentFiltersModel>({
    startDate: formatDate(authResult.get("startDate")),
    endDate: formatDate(authResult.get("endDate")),
    operator:
      formatOperator(authResult.get("operatorId")) !== undefined
        ? formatOperator(authResult.get("operatorId"))
        : {
            label: "",
            value: "",
          },
    status: nullToUndefined(authResult.get("status")),
  });

  const getOperatorId = (
    operator: OperatorOption | undefined
  ): string | undefined => {
    if (!operator || operator?.value === "") {
      return undefined;
    }
    return operator.value;
  };
  const fetchPage = useCallback(
    (page, size) => {
      return bonusPaymentClient.query.fetchPage(
        page,
        size,
        getOperatorId(filterValues.operator),
        nullToUndefinedNumber(filterValues.startDate),
        nullToUndefinedNumber(filterValues.endDate),
        filterValues.status
      );
    },
    [filterValues]
  );

  const gotoListCallback = useCallback(
    (page, size) =>
      gotoBonusPaymentList(
        page,
        size,
        getOperatorId(filterValues.operator),
        nullToUndefinedNumber(filterValues.startDate),
        nullToUndefinedNumber(filterValues.endDate),
        filterValues.status
      ),
    [
      gotoBonusPaymentList,
      filterValues.operator,
      filterValues.startDate,
      filterValues.endDate,
      filterValues.status,
    ]
  );

  const [items, isLoading, handlePageChange] = usePaginedList<BonusPayment>(
    [],
    fetchPage,
    gotoListCallback,
    [fetchPage]
  );

  const unCheckBonus = useCallback(
    (bonusId: string) => {
      let checkedBonusIdCopy = [...checkedBonusInformations.checkedBonus];
      const filtered = checkedBonusIdCopy.filter((it) => it.id != bonusId);
      checkedBonusIdCopy = filtered;
      setCheckedBonus({
        ...checkedBonusInformations,
        checkedBonus: checkedBonusIdCopy,
      });
    },
    [checkedBonusInformations]
  );

  const selectDeselectBonus = useCallback(
    (checked: boolean, bonus: BonusPayment) => {
      if (checked) {
        let checkedBonusIdCopy = [...checkedBonusInformations.checkedBonus];
        const operator = operatorRefs.get(bonus.operator.id);
        checkedBonusIdCopy.push({
          ...bonus,
          informations: (
            <Box display="flex" justifyContent="space-between">
              <HeadlineBox
                className={classes.headline1}
                header={operator?.displayName}
              >
                <MonthDate date={bonus.period.startDate} />
              </HeadlineBox>
              <HeadlineBox
                className={classes.headline2}
                header={`${bonus.budget.toLocaleString()}€`}
              />
            </Box>
          ),
        });
        setCheckedBonus({
          ...checkedBonusInformations,
          checkedBonus: checkedBonusIdCopy,
        });
      } else {
        unCheckBonus(bonus.id);
      }
    },
    [checkedBonusInformations, unCheckBonus, operatorRefs, classes]
  );

  const onChangeGlobalCheck = useCallback(
    (globalChecked: boolean) => {
      if (!globalChecked) {
        let checkBonusCopy = [...checkedBonusInformations.checkedBonus];
        const currentItemsIds = items.list.map((el) => el.id);
        checkBonusCopy = checkBonusCopy.filter(
          (el) => !currentItemsIds.includes(el.id)
        );
        setCheckedBonus({
          checkedStatus: false,
          checkedBonus: checkBonusCopy,
        });
        return;
      }
      const checkedBonusIds = checkedBonusInformations.checkedBonus.map(
        (el) => el.id
      );
      const unPaidBonus = items.list.filter(
        (el) => el.status.value !== "PAID" && !checkedBonusIds.includes(el.id)
      );
      const unPaidCheckedBonus: (SelectedItem &
        BonusPayment)[] = unPaidBonus.map((bonusPayment) => {
        const operator = operatorRefs.get(bonusPayment.operator.id);
        return {
          ...bonusPayment,
          informations: (
            <Box display="flex" justifyContent="space-between">
              <HeadlineBox
                className={classes.headline1}
                header={operator?.displayName}
              >
                <MonthDate date={bonusPayment.period.startDate} />
              </HeadlineBox>
              <HeadlineBox
                className={classes.headline2}
                header={`${bonusPayment.budget.toLocaleString()}€`}
              />
            </Box>
          ),
        };
      });
      setCheckedBonus({
        checkedStatus: true,
        checkedBonus: [
          ...checkedBonusInformations.checkedBonus,
          ...unPaidCheckedBonus,
        ],
      });
    },
    [checkedBonusInformations.checkedBonus, items.list, classes, operatorRefs]
  );

  const onBonusPaymentFiltersChange = useCallback(
    (values: BonusPaymentFiltersModel) => {
      gotoBonusPaymentList(
        0,
        items.perPage,
        getOperatorId(values.operator),
        nullToUndefinedNumber(values.startDate),
        nullToUndefinedNumber(values.endDate),
        values.status
      );
      setFilterValues({
        startDate: values.startDate,
        endDate: values.endDate,
        operator: values.operator,
        status: values.status,
      });
    },
    [gotoBonusPaymentList, items.perPage]
  );

  const onPayBonus = useCallback(async () => {
    const bonusPaymentIds = checkedBonusInformations.checkedBonus.map(
      (checkedBonus) => checkedBonus.id
    );
    await payBonusPayment(bonusPaymentIds);
    gotoBonusPaymentList(
      items.page,
      items.perPage,
      getOperatorId(filterValues.operator),
      nullToUndefinedNumber(filterValues.startDate),
      nullToUndefinedNumber(filterValues.endDate),
      filterValues.status
    );
  }, [
    payBonusPayment,
    gotoBonusPaymentList,
    items.page,
    items.perPage,
    filterValues,
    checkedBonusInformations.checkedBonus,
  ]);

  const onPayOneBonus = useCallback(
    async (bonusPaymentId) => {
      await payBonusPayment([bonusPaymentId]);
      gotoBonusPaymentList(
        items.page,
        items.perPage,
        getOperatorId(filterValues.operator),
        nullToUndefinedNumber(filterValues.startDate),
        nullToUndefinedNumber(filterValues.endDate),
        filterValues.status
      );
    },
    [
      payBonusPayment,
      gotoBonusPaymentList,
      items.page,
      items.perPage,
      filterValues,
    ]
  );

  const getOrganizationRef = useCallback((id: string) => organizationRefs.get(id), [organizationRefs]);

  const checkedBonusIds = useMemo(
    () => checkedBonusInformations.checkedBonus.map((it) => it.id),
    [checkedBonusInformations.checkedBonus]
  );

  const handleInvoiceExport = useCallback(async (period: DateInterval) => {
    const objectUrl = await exportBonusGroupInvoices(period)
    if (objectUrl == null) {
      return;
    }
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "export_dolibarr_factures.zip";
    a.click();
    setOpenInvoiceExportPopup(false)
  }, []);

  const handleOpenInvoiceExportPopup = useCallback(() => setOpenInvoiceExportPopup(true), [])
  const handleCloseInvoiceExportPopup = useCallback(() => setOpenInvoiceExportPopup(false), [])

  return (
    <PageWithTitle
      headBar={{ title: "Règlement" }}
      header={
        <>
          <BonusPaymentFilters
            onFilterChange={onBonusPaymentFiltersChange}
            filterValues={filterValues}
            operators={getOperators()}
          />
          <Box className={classes.topContainer}>
            <Button onClick={handleOpenInvoiceExportPopup}>
              {t("app_bonuspayment_export_popup_button")}
            </Button>
          </Box>
        </>
      }
      columnSwitchWidth={800}
      switchedHeaderHeight={150}
    >
      <PeriodPickerPopup 
        open={openInvoiceExportPopup}
        title={t("app_bonuspayment_export_popup_title")}
        body={t("app_bonuspayment_export_popup_body")}
        onClose={handleCloseInvoiceExportPopup}
        onConfirm={handleInvoiceExport}
      />
      <BonusPaymentTable
        handlePageChange={handlePageChange}
        onPayClick={onPayOneBonus}
        page={items.page + 1}
        totalPages={Math.ceil(items.totalRows / items.perPage)}
        bonusPayments={items.list}
        isLoading={isLoading}
        getStatus={BonusPaymentStatusUtils.getStatusFromRow}
        checkedBonus={checkedBonusIds}
        onClickCheckBox={selectDeselectBonus}
        onChangeGlobalCheck={onChangeGlobalCheck}
        onClickBonus={gotoBonus}
        getOrganizationRef={getOrganizationRef}
        getDefaultOrganizationLogo={getDefaultOrganizationLogo}
        showPaidstatus={filterValues.status === "PAID"}
      />
      {checkedBonusInformations.checkedBonus.length > 0 && (
        <SelectedItemsWithActions
          displayList={true}
          items={checkedBonusInformations.checkedBonus}
          onRemoveItem={unCheckBonus}
          actions={
            <Box display="flex" alignItems="center">
              <Button
                style={{ marginLeft: "15px", marginRight: "15px" }}
                onClick={onPayBonus}
              >
                Payer
              </Button>
            </Box>
          }
        />
      )}
    </PageWithTitle>
  );
};

export default withConnect(BonusPayments);
