// @ts-ignore
export const apiUrl = window._env_.API_URL;
// @ts-ignore
export const deliveryApiUrl = window._env_.DELIVERY_API_URL;
// @ts-ignore
export const dashboardApiUrl = window._env_.LOCAL_DASHBOARD_API;
// @ts-ignore
export const apiConfigUrlPath = window._env_.API_URL_CONFIG;
// @ts-ignore
export const apiOrganizationUrlPath = window._env_.API_URL_ORGANIZATION;
// @ts-ignore
export const apiProjectUrlPath = window._env_.API_URL_PROJECT;
// @ts-ignore
export const apiCarrierReportUrlPath = window._env_.API_URL_CARRIER_REPORT;
// @ts-ignore
export const apiBonusUrlPath = window._env_.API_URL_BONUS;
// @ts-ignore
export const apiBonusGroupUrlPath = window._env_.API_URL_BONUS_GROUP;
// @ts-ignore
export const apiBonusGroupStatsUrlPath = window._env_.API_URL_BONUS_GROUP_STATS;
// @ts-ignore
export const apiPolylineUrlPath = window._env_.API_URL_POLYLINE;
// @ts-ignore
export const apiBonusPaymentUrlPath = window._env_.API_URL_BONUS_PAYMENT;
// @ts-ignore
export const apiDashboardPoiUrlPath = window._env_.API_URL_DASHBOARD_POI;
// @ts-ignore
export const apiNotificationUrlPath = window._env_.API_URL_NOTIFICATION;
// @ts-ignore
export const apiDashboardUrlPath = window._env_.API_URL_DASHBOARD;
// @ts-ignore
export const deliveryApiTourUrlPath = window._env_.DELIVERY_API_URL_TOUR;
// @ts-ignore
export const apiInseeBearerToken = window._env_.API_INSEE_BEARER_TOKEN;
// @ts-ignore
export const apiMapboxAccessToken = window._env_.API_MAPBOX_ACCESS_TOKEN;

export const buildConfigApiUrl = (path: String) => {
  return `${apiUrl}${apiConfigUrlPath}${path}`;
};

export const buildOrganizationApiUrl = (path: String) => {
  return `${apiUrl}${apiOrganizationUrlPath}${path}`;
};

export const buildBonusApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusUrlPath}${path}`;
};

export const buildBonusGroupApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusGroupUrlPath}${path}`;
};

export const buildBonusGroupStatsApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusGroupStatsUrlPath}${path}`;
};

export const buildBonusPaymentApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusPaymentUrlPath}${path}`;
};

export const buildProjectApiUrl = (path: String) => {
  return `${apiUrl}${apiProjectUrlPath}${path}`;
};
export const buildCarrierReportApiUrl = (path: String) => {
  return `${apiUrl}${apiCarrierReportUrlPath}${path}`;
};
export const buildDashboardPoiApiUrl = (path: String) => {
  return `${apiUrl}${apiDashboardPoiUrlPath}${path}`;
};
export const buildNotificationApiUrl = (path: String) => {
  return `${apiUrl}${apiNotificationUrlPath}${path}`;
};
export const buildDashboardApiUrl = (path: String) => {
  return `${dashboardApiUrl ?? apiUrl}${apiDashboardUrlPath}${path}`;
};
export const buildTourApiUrl = (path: String) => {
  return `${deliveryApiUrl}${deliveryApiTourUrlPath}${path}`;
};
export const buildPolylineApiUrl = (path: String) => {
  return `${apiUrl}${apiPolylineUrlPath}${path}`;
};

export const getURLParameter = (name: string): string => {
  const parameter = (RegExp(name + "=" + "(.+?)(&|$)").exec(
    window.location.search
  ) || [, null])[1];
  return decodeURI(parameter ? parameter : "");
};
