import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Button, Section } from "components";
import withConnect from "./withConnect";
import UserDetailsForm, {
  UserFormState,
} from "../../../../../components/UserForm";
import {
  User,
  UserDetails,
  usersClient,
} from "../../../../../../api/organization/users";
import { useParams } from "react-router-dom";
import { UserValidation } from "../../../../../components/UserForm/validation";
import { OrganizationRef } from "../../../../../../api/organization";
import { useTranslation } from "react-i18next";
import { Roles, authService } from "auth";
import { useAuth } from "auth/useAuth";
const useStyles = makeStyles(() => ({
  button: {
    position: "absolute",
    right: "30px",
    top: "10px",
  },
}));

interface AddUserProps {
  gotoOrganizationUsers: (organizationId: string) => void;
  organizationRefs: Map<string, OrganizationRef>;
  currentUser: User | null;
}

const AddUser = (props: AddUserProps) => {
  const { gotoOrganizationUsers, organizationRefs, currentUser } = props;

  const classes = useStyles();
  const { organizationId } = useParams();
  const [user, setUser] = useState<UserFormState>(
    UserValidation.defaultUserFormState(organizationId)
  );

  const [authService, keycloak] = useAuth();

  const onAddUser = async () => {
    const validation = UserValidation.validation(user.details);
    if (!UserValidation.isFormValid(validation)) {
      return setUser({
        ...user,
        validity: validation,
      });
    }

    const userDetails: UserDetails = {
      ...user.details,
      firstname: user.details.firstname.trim(),
      lastname: user.details.lastname.trim(),
      email: user.details.email.trim(),
    };
    const response = await usersClient.createUser(organizationId, userDetails);

    if (response.type == "emailAlreadyUsed") {
      return setUser({
        ...user,
        validity: {
          ...validation,
          email: t("app_organizations_profil_users_add_error_email_used"),
        },
      });
    }

    // add logo

    if (user.logo && user.logo.file) {
      const blobLogo = new Blob([user.logo.file], {
        type: user.logo.file.type,
      });
      usersClient.uploadUserLogo(
        response.payload.organizationId,
        response.payload.userId,
        blobLogo,
        user.logo.file.name
      );
    }

    gotoOrganizationUsers(organizationId);
  };

  const currentUserHasRoles = (roles: Roles[]) => currentUser ? authService.hasRoles(currentUser, roles, keycloak) : false

  const { t } = useTranslation();
  return (
    <Section
      actions={[
        <Button className={classes.button} onClick={onAddUser}>
          {t("app_organizations_profil_users_add_save")}
        </Button>,
      ]}
    >
      <UserDetailsForm
        currentUserHasRoles={currentUserHasRoles}
        user={user}
        organizationRefs={organizationRefs}
        onDetailsChange={setUser}
        readonly={false}
      />
    </Section>
  );
};

export default withConnect(AddUser);
