import {city} from "ca-plateform-project-domain";
import {http} from "api/http";
import {buildProjectApiUrl} from "api/configuration";

export interface StartedCampaignEvent
  extends city.colisactiv.project.model.features.StartedCampaignEvent {}

export const startCampaignCommand = (
  projectId: string,
  campaignId: string
): Promise<StartedCampaignEvent> =>
  http.put(
    buildProjectApiUrl(
      `/projects/${projectId}/campaigns/${campaignId}/status/start`
    )
  );
