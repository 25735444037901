import {
  ExportAuthorization,
  Organization,
  OrganizationDetails,
  OrganizationType,
  PackageType,
  PaymentDetails,
} from "../../../api/organization";
import { LogoType } from "../../../api/organization/service";
import { buildOrganizationApiUrl } from "../../../api/configuration";
import { OrganizationDetailsValidation } from "./validation";

export interface OrganizationDetailsFormState {
  friendlyId: string;
  details: OrganizationDetails;
  type: OrganizationType;
  validity: OrganizationDetailsFormValidity;
  packageType?: PackageType;
  logo?: LogoType;
  payment?: PaymentDetails;
}

export interface OrganizationDetailsFormValidity {
  name?: string;
  siret?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  type?: string;
  packageType?: string;
}

export const buildOrganizationDetailsForm = (
  organization: Organization
): OrganizationDetailsFormState => ({
  friendlyId: organization.friendlyId,
  details: organization.details,
  logo: {
    src: buildOrganizationApiUrl(`/organizations/${organization.id}/logo`),
  },
  validity: OrganizationDetailsValidation.validation(
    organization.details,
    organization.organizationType,
    organization.packageType
  ),
  type: organization.organizationType,
  packageType: organization.packageType,
});
