import React, { useEffect, useState } from "react";
import { Button, EditButton, FormButtons, Section } from "components";
import withConnect from "./withConnect";
import { useParams } from "react-router-dom";
import UserForm, { UserFormState } from "app/components/UserForm";
import { UserValidation } from "app/components/UserForm/validation";
import { buildOrganizationApiUrl } from "api/configuration";
import { Organization, OrganizationRef } from "api/organization";
import { User, UserDetails, usersClient } from "api/organization/users";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth/useAuth";
import { Roles } from "auth";

interface GeneralInformationsProps {
  organizationRefs: Map<string, OrganizationRef>;
  organization: Organization | null;
  user: User | null;
  gotoUserEdit: (userId: string) => void;
  gotoUserView: (userId: string) => void;
  fetchOrganization: (organizationId: string) => void;
  fetchUser: () => void;
}

const buildUserForm = (user: User): UserFormState => ({
  details: user.details,
  logo: {
    src: buildOrganizationApiUrl(
      `/organizations/${user.details.organizationId}/users/${user.userId}/logo`
    ),
  },
  validity: UserValidation.validation(user.details),
});

const GeneralInformations = (props: GeneralInformationsProps) => {
  const {
    organizationRefs,
    organization,
    user,
    gotoUserEdit,
    gotoUserView,
    fetchOrganization,
    fetchUser,
  } = props;
  const { mode } = useParams();
  const [userEditState, setUserEditState] = useState<UserFormState>(
    UserValidation.defaultUserFormState
  );
  const [authService, keycloak] = useAuth();

  useEffect(() => {
    if (!organization && user) fetchOrganization(user.details.organizationId);
    else if (
      organization &&
      user &&
      organization.id !== user.details.organizationId
    )
      fetchOrganization(user.details.organizationId);
  }, [organization, user, fetchOrganization]);

  useEffect(() => {
    if (user) {
      const userForm = buildUserForm(user);
      setUserEditState(userForm);
    }
  }, [user]);

  const { t } = useTranslation();

  if (user == null) {
    return <div> {t("app_users_general_information_no_users")}</div>;
  }

  const onSaveUser = async () => {
    const { details } = userEditState;
    const validation = UserValidation.validation(details);
    if (!UserValidation.isFormValid(validation)) {
      return setUserEditState({
        ...userEditState,
        validity: validation,
      });
    }
    if (organization && user) {
      const userDetails: UserDetails = {
        ...userEditState.details,
        firstname: userEditState.details.firstname.trim(),
        lastname: userEditState.details.lastname.trim(),
        email: userEditState.details.email.trim(),
      };
      const response = await usersClient.updateUser(
        organization.id,
        userDetails,
        user.userId
      );

      if (userEditState.logo && userEditState.logo.file) {
        const blobLogo = new Blob([userEditState.logo.file], {
          type: userEditState.logo.file.type,
        });
        await usersClient.uploadUserLogo(
          response.payload.organizationId,
          response.payload.userId,
          blobLogo,
          userEditState.logo.file.name
        );
      }
      fetchUser();
      gotoUserView(user?.userId);
    }
  };
  const isViewMode = mode === "view";

  const currentUserHasRoles = (roles: Roles[]) =>
    user ? authService.hasRoles(user, roles, keycloak) : false;

  return (
    <Section withGoBack>
      <UserForm
        currentUserHasRoles={currentUserHasRoles}
        organization={!!organization ? organization : undefined}
        organizationRefs={organizationRefs}
        user={isViewMode ? buildUserForm(user) : userEditState}
        onDetailsChange={isViewMode ? () => {} : setUserEditState}
        readonly={isViewMode}
      />
      <FormButtons>
        <Button
          key={"users-general-information-change-password-button"}
          onClick={() =>
            usersClient.changeUserPassword(
              user.details.organizationId,
              user.userId
            )
          }
        >
          {t("app_users_general_information_change_my_password")}
        </Button>
        {isViewMode ? (
          <EditButton
            key={"users-general-information-edit-button"}
            onClick={() => gotoUserEdit(user?.userId)}
            label={t("app_users_general_information_edit")}
          />
        ) : (
          <Button 
            key={"users-general-information-validate-button"}
            onClick={onSaveUser}
          >
            {t("app_users_general_information_validate")}
          </Button>
        )}
      </FormButtons>
    </Section>
  );
};

export default withConnect(GeneralInformations);
