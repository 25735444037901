import { NO_ERROR, UserFormState, UserFormValidity } from "./index";
import * as R from "ramda";
import { UserDetails } from "../../../api/organization/users";
import i18n from "i18n";

const FRENCH_PHONE_NUMBER_VALIDATION_REGEX =
  /(^(?![\s\S])|^((\+)33|0)[1-9](\d{2}){4}$)/;
const EMAIL_VALIDATION_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getValidation = (details: UserDetails): UserFormValidity => {
  return {
    firstname: getFirstnameError(details.firstname),
    lastname: getLastnameError(details.lastname),
    email: getEmailError(details.email),
    organization: getOrganizationError(details.organizationId),
    phoneNumber: getPhoneNumberError(details.phoneNumber),
  };
};

function getFirstnameError(firstname: string) {
  if (isEmpty(firstname)) {
    return i18n.t("app_user_form_please_insert_a_first_name");
  }
  return NO_ERROR;
}

function getLastnameError(lastname: string) {
  if (isEmpty(lastname)) {
    return i18n.t("app_user_form_please_insert_a_last_name");
  }
  return NO_ERROR;
}

function getOrganizationError(organizationId: string) {
  if (isEmpty(organizationId)) {
    return i18n.t("app_user_form_please_insert_an_organization");
  }
  return NO_ERROR;
}

function getEmailError(email: string) {
  if (isEmpty(email)) {
    return i18n.t("app_user_form_please_insert_an_email");
  }
  if (!EMAIL_VALIDATION_REGEX.test(String(email).toLowerCase())) {
    return i18n.t("app_user_form_please_insert_a_correct_email");
  }
  return NO_ERROR;
}

function getPhoneNumberError(phoneNumber: string) {
  if (
    !FRENCH_PHONE_NUMBER_VALIDATION_REGEX.test(
      String(phoneNumber).toLowerCase()
    )
  ) {
    return i18n.t("app_user_form_please_insert_a_correct_phone_number");
  }
  return NO_ERROR;
}

function isEmpty(string: string) {
  return string === undefined || string === "";
}

export const isFormValid = (newValidity: UserFormValidity): boolean => {
  return R.values(newValidity)
    .map((value) => value == NO_ERROR)
    .reduce((previousValue, currentValue) => previousValue && currentValue);
};

export const defaultUserFormValidity: UserFormValidity = {
  firstname: NO_ERROR,
  lastname: NO_ERROR,
  email: NO_ERROR,
  organization: NO_ERROR,
  phoneNumber: NO_ERROR,
};

export const defaultUserFormState: (
  organizationId?: string
) => UserFormState = (organizationId?: string) => ({
  details: {
    organizationId: !!organizationId ? organizationId : "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    fonction: "",
    role: "user",
    status: false,
    territories: [],
  },
  validity: defaultUserFormValidity,
});

export const UserValidation = {
  validation: getValidation,
  isFormValid: isFormValid,
  defaultUserFormValidity: defaultUserFormValidity,
  defaultUserFormState: defaultUserFormState,
};
