import {Grid, makeStyles} from "@material-ui/core";
import {DeleteButton, InputForm} from "components";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {BonusFormStatsRowState, BonusFormStatsRowValidity} from "./model";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    justifyItems: "center",
    justifyContent: "space-between",
    margin: "21px 0",
    position: "relative",
  },
  inputClassName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 250,
    justifyContent: "center",
  },
  deletebutton: {
    marginTop: "52px",
  },
  inputStyle: {
    width: "100%",
  },
  trashIcon: {
    position: "absolute",
    right: 0,
    top: -13,
    width: "fit-content",
    height: "fit-content",
    cursor: "pointer",
  },
  delete: {
    width: "19px",
    height: "19px",
  },
}));

interface BonusFormStatsRowProps {
  stats: BonusFormStatsRowState;
  validation: BonusFormStatsRowValidity;
  readonly?: boolean;
  withDeleteButton?: boolean;
  onChange: (
    id: string,
    property: keyof BonusFormStatsRowState,
    value: any
  ) => void;
  onDelete?: (id: string) => void;
}

const BonusFormStatsRow = (props: BonusFormStatsRowProps) => {
  const classes = useStyles();
  const {
    readonly,
    stats,
    validation,
    withDeleteButton,
    onChange,
    onDelete,
  } = props;

  const handleDeliveryCountChange = useCallback(
    (value: string) => {
      onChange(stats.id, "activeDeliveryCount", parseInt(value));
    },
    [onChange, stats.id]
  );

  const handlePackCountChange = useCallback(
    (value: string) => {
      onChange(stats.id, "packCount", parseInt(value));
    },
    [onChange, stats.id]
  );

  const handleDurationChange = useCallback(
    (value: string) => {
      const durationInMs = parseFloat(value) * 60 * 60 * 1000;
      onChange(stats.id, "duration", durationInMs);
    },
    [onChange, stats.id]
  );

  const handleDelete = useCallback(() => {
    onDelete && onDelete(stats.id);
  }, [onDelete, stats.id]);

  const { t } = useTranslation();
  return (
    <Grid container direction="row">
      <Grid item xs={12} md={9} className={classes.grid}>
        <InputForm
          id={`activeDeliveryCount-${stats.id}`}
          value={stats.activeDeliveryCount}
          onChange={handleDeliveryCountChange}
          inputType="textField"
          textFieldType="number"
          label={t(
            "app_components_bonus_bonusform_Number_of_active_deliveries"
          )}
          error={!validation.activeDeliveryCount}
          errorMessage={
            stats.activeDeliveryCount === undefined
              ? t(
                  "app_components_bonus_bonusform_Please_enter_the_delivery_number"
                )
              : t("app_bonus_packCount_greater_than_delivery_number")
          }
          className={classes.inputClassName}
          inputClassName={classes.inputStyle}
          readonly={readonly}
        />
        <InputForm
          id={`packCount-${stats.id}`}
          value={stats.packCount}
          onChange={handlePackCountChange}
          inputType="textField"
          textFieldType="number"
          label={t("app_components_bonus_bonusform_Number_of_packages")}
          error={!validation.packCount}
          errorMessage={t(
            "app_components_bonus_bonusform_Please_enter_the_number_of_packages"
          )}
          className={classes.inputClassName}
          inputClassName={classes.inputStyle}
          readonly={readonly}
        />
        <InputForm
          id={`duration-${stats.id}`}
          value={stats.duration / 1000 / 60 / 60}
          onChange={handleDurationChange}
          inputType="textField"
          textFieldType="number"
          label={t("app_components_bonus_bonusform_deliverie_duration")}
          error={!validation.duration}
          errorMessage={t(
            "app_components_bonus_bonusform_Please_enter_the_deliverie_duration"
          )}
          className={classes.inputClassName}
          inputClassName={classes.inputStyle}
          readonly={readonly}
        />
      </Grid>
      {withDeleteButton && (
        <DeleteButton className={classes.deletebutton} onClick={handleDelete} />
      )}
    </Grid>
  );
};
export default BonusFormStatsRow;
