import { OrganizationDetails } from "../index";
import { apiInseeBearerToken } from "../../configuration";

export const getInformationsBySiret = async (
  siret: string
): Promise<OrganizationDetails | null> => {
  try {
    const resp = await fetch(
      `https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ` + apiInseeBearerToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await resp.json();

    return toOrganizationDetails(data, siret);
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const toOrganizationDetails = (
  data: any,
  siret: string
): OrganizationDetails => {
  const establishment = data.etablissement;
  const address = data.etablissement.adresseEtablissement;

  const streetNumber =
    address.numeroVoieEtablissement !== null
      ? address.numeroVoieEtablissement
      : "";
  const streetType =
    address.typeVoieEtablissement !== null ? address.typeVoieEtablissement : "";
  const streetName =
    address.libelleVoieEtablissement !== null
      ? address.libelleVoieEtablissement
      : "";

  return {
    siret: siret,
    name:
      establishment.uniteLegale.denominationUniteLegale ||
      establishment.uniteLegale.denominationUsuelle1UniteLegale ||
      establishment.uniteLegale.denominationUsuelle2UniteLegale ||
      establishment.uniteLegale.denominationUsuelle3UniteLegale,
    address: {
      city: address.libelleCommuneEtablissement,
      zipCode: address.codePostalEtablissement,
      street: streetNumber + " " + streetType + " " + streetName,
    },
  };
};
