import {Box, makeStyles, Paper} from "@material-ui/core";
import {OrganizationRef} from "api/organization";
import {User} from "api/organization/users";
import {ProjectFunder, ProjectStats} from "api/project/model";
import {CampaignsActionProps} from "api/project/model/CampaignActions";
import {ProjectStatusUtils} from "api/project/model/ProjectStatus";
import {useAuth} from "auth/useAuth";
import {BackButton, HeadBarProps, HeadBarView, HeadlineBox, Section, StatusTags,} from "components";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {displayNumber} from "utils";
import CampaignsInformations from "../../../components/ProjectForm/CampaignsInformations";
import withConnect from "./withConnect";

const useStyles = () =>
  makeStyles(() => ({
    projectFunder: {
      width: 350,
    },
    paper: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "20px",
      width: "800px",
      boxSizing: "border-box",
      padding: "10px",
      marginBottom: "30px",
    },
    headline: {
      margin: "10px",
      "& .colisactivHeadlineBox-content": {
        fontSize: "16px",
      },
    },
    fundersHeadline: {
      margin: "10px",
      "& .colisactivHeadlineBox-header": {
        overflow: "unset",
      },
    },
    tags: {
      margin: "2px",
    },
  }));

interface GeneralInformationsProjectProps extends CampaignsActionProps {
  project: ProjectStats | null;
  funderRefs: Map<string, OrganizationRef>;
  setHeadBar: (title: HeadBarProps) => void;
  fetchProject: (projectId: string) => void;
  gotoProjectEdit: (projectId: string) => void;
  gotoCreateCampaign: (projectId: string) => void;
  gotoCampaignView: (projectId: string, campaignId: string) => void;
  currentUser: User | null;
}

const ProjectViewPage = (props: GeneralInformationsProjectProps) => {
  const {
    project,
    funderRefs,
    setHeadBar,
    fetchProject,
    gotoCreateCampaign,
    gotoCampaignView,
    gotoProjectEdit,
    currentUser,
  } = props;
  const { projectId } = useParams();
  const { t } = useTranslation();
  const classes = useStyles()();
  const [authService, keycloak] = useAuth();
  useEffect(() => {
    fetchProject(projectId);
  }, [projectId]);

  useEffect(() => {
    if (project) {
      setHeadBar({
        title: project.details.name,
        component: (
          <Box display="flex" alignItems="center">
            <HeadBarView
              title={project.details.name}
              status={ProjectStatusUtils.getLabelStyleStatusTags(
                project.status
              )}
              onEdit={
                authService.isColisActivAdmin(currentUser, keycloak) &&
                (ProjectStatusUtils.isCreated(project) ||
                  ProjectStatusUtils.isStarted(project))
                  ? () => gotoProjectEdit(project.id)
                  : undefined
              }
            />
          </Box>
        ),
      });
    }
  }, [project]);

  const getFundersInfo: {
    display: React.ReactNode;
    globalBudget: number;
  } = useMemo(() => {
    let globalBudget = 0;
    const display = project ? (
      project.funders.map(
        (funder: ProjectFunder, index: number): React.ReactNode => {
          globalBudget += Number(funder.budget);
          const name = funderRefs.get(funder.funderId);
          if (!name) return <> </>;
          return (
            <StatusTags
              key={`${funder.funderId}-tag-${name.displayName}-${index}`}
              className={classes.tags}
              label={name.displayName}
              color="blue"
            />
          );
        }
      )
    ) : (
      <> </>
    );

    return {
      display: display,
      globalBudget: globalBudget,
    };
  }, [project?.funders, funderRefs]);

  if (project == null) {
    return <div />;
  }
  const addCampaign = () => {
    gotoCreateCampaign(projectId);
  };

  const onClickCampaign = (campaignId: string) => {
    gotoCampaignView(projectId, campaignId);
  };

  return (
    <Section actions={[]}>
      <BackButton />
      <Paper className={classes.paper} elevation={2}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <HeadlineBox
            aligned
            className={classes.headline}
            header={t("app_project_view_global_budget")}
          >
            {`${displayNumber(getFundersInfo.globalBudget)} €`}
          </HeadlineBox>
          <HeadlineBox
            aligned
            className={classes.fundersHeadline}
            header={t("app_project_view_cofunder")}
            nodeChildren
          >
            <Box
              display="flex"
              marginLeft="5px"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              {getFundersInfo.display}
            </Box>
          </HeadlineBox>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <HeadlineBox
            aligned
            className={classes.headline}
            header={t("app_project_view_consumed_budget")}
          >
            {`${displayNumber(project.totalBonusAmount)} €`}
          </HeadlineBox>
          <HeadlineBox
            aligned
            className={classes.headline}
            header={t("app_project_view_active_delivery")}
          >
            {displayNumber(project.activeDeliveryCount)}
          </HeadlineBox>
        </Box>
      </Paper>
      <CampaignsInformations
        isColisactivAdmin={authService.isColisActivAdmin(currentUser, keycloak)}
        addCampaign={addCampaign}
        campaigns={project.campaigns}
        onClickCampaign={onClickCampaign}
        duplicateCampaign={props.duplicateCampaign}
        gotoCampaignView={props.gotoCampaignView}
        publishCampaign={props.publishCampaign}
        archiveCampaign={props.archiveCampaign}
        deleteCampaign={props.deleteCampaign}
        pauseCampaign={props.pauseCampaign}
        startCampaign={props.startCampaign}
        updateCampaignNotification={props.updateCampaignNotification}
        gotoCampaignEdit={props.gotoCampaignEdit}
        gotoProjectView={props.gotoProjectView}
      />
    </Section>
  );
};

export default withConnect(ProjectViewPage);
