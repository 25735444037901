import React, { useCallback, useEffect, useMemo, useState } from "react";
import withConnect from "./withConnect";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import { Box, makeStyles } from "@material-ui/core";
import { Territory, geoZoneClient } from "../../../api/geoZone";
import { dateOrUndefined, nullToUndefined } from "../../../utils";
import { Organization, OrganizationRef } from "../../../api/organization";
import { FluxFilters } from "components/src/Domain/Flux";
import { useAuth } from "../../../auth/useAuth";
import { User } from "../../../api/organization/users";
import { bonusGroupClient } from "api/bonusGroup";
import { addMonths } from "date-fns";
import { useAsyncResponse } from "utils/useAsync";
import { KibanaFilters } from "components/src/utils/kibanaQuery";
import { GetPolylineListQueryDTO, polylineClient } from "../../../api/polyline";
import { Fluxmap } from "../../components/Fluxmap";

const useStyles = makeStyles(() => ({
  iframe: {
    border: 0,
  },
  filterGrid: {
    backgroundColor: "white",
  },
  content: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "stretch",
  },
}));

interface FluxPageProps {
  operatorRefs: Map<string, OrganizationRef>;
  organization: Organization | null;
  goToFlux: (
    operatorId: string[],
    days: string[],
    startDate?: number,
    endDate?: number,
    startTime?: number,
    endTime?: number,
    territoryName?: string,
    territoryId?: string,
    territoryCode?: string,
    dashboardId?: string
  ) => void;
  currentUser: User | null;
  fetchOrganization: (organizationId: string) => void;
}

const FluxPage = (props: FluxPageProps) => {
  const {
    goToFlux,
    operatorRefs,
    currentUser,
    organization,
    fetchOrganization,
  } = props;

  const authResult = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const [authService, keycloak] = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const territoryName = nullToUndefined(authResult.get("territoryName"));
  const territoryId = nullToUndefined(authResult.get("territoryId"));
  const territoryCode = nullToUndefined(authResult.get("territoryCode"));
  const dashboardId = nullToUndefined(authResult.get("dashboardId"));

  const startDate = dateOrUndefined(authResult.get("startDate"));
  const endDate = dateOrUndefined(authResult.get("endDate"));

  const startTime = dateOrUndefined(authResult.get("startTime"));
  const endTime = dateOrUndefined(authResult.get("endTime"));

  const [polylines, setPolylines] = useState<string[]>([]);

  const operatorIds = nullToUndefined(authResult.get("operatorIds"));
  const operatorsArray =
    operatorIds === undefined ? [] : operatorIds.split(",");

  const days = nullToUndefined(authResult.get("days"));
  const daysArray = days === undefined ? [] : days.split(",");

  const [filterValues, setFiltersValue] = useState<KibanaFilters>({
    startDate: startDate ? startDate : addMonths(new Date(), -3),
    endDate: endDate ? endDate : new Date(),
    startTime: startTime ? startTime : undefined,
    endTime: endTime ? endTime : undefined,
    days: daysArray,
    operatorIds: operatorsArray,
    territory:
      territoryName && territoryId && territoryCode
        ? {
          code: territoryCode,
          id: territoryId,
          name: territoryName,
          zones: [],
        }
        : undefined,
    dashboardId: dashboardId ? dashboardId : undefined,
  });

  const isAdmin = useMemo(
    () => authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const isFunder = useMemo(
    () => !isAdmin && authService.isFunder(currentUser, keycloak),
    [isAdmin, authService, currentUser, keycloak]
  );

  const isVisitor = useMemo(
    () => !isAdmin && authService.isVisitor(currentUser, keycloak),
    [isAdmin, authService, currentUser, keycloak]
  );

  useEffect(() => {
    if (organization === null && currentUser !== null) {
      fetchOrganization(currentUser!!.details.organizationId!!);
    }
  }, [organization, currentUser, fetchOrganization]);

  useEffect(() => {
    if (organization && isLoading) {
      if (isVisitor) {
        setFiltersValue({
          ...filterValues,
          territory:
            organization?.territories && organization.territories.length > 0
              ? organization?.territories[0]
              : undefined,
          operatorIds: organization?.visitorAuthorization?.watchedOperatorId
            ? [organization?.visitorAuthorization?.watchedOperatorId]
            : operatorsArray,
        });
        setIsLoading(false)
      }
    }
  }, [filterValues, isLoading, isVisitor, operatorsArray, organization]);

  const allowedTerritories = useMemo((): string[] | undefined => {
    if (isVisitor && organization?.territories) {
      return organization.territories.length > 0
        ? organization.territories.map((territory: Territory) => territory.code)
        : undefined;
    }
    return undefined;
  }, [isVisitor, organization]);

  const extractTime = useCallback((date: Date | undefined) => {
    if (!date) {
      return;
    }
    return date.getHours() * 100 + date.getMinutes();
  }, []);

  const onFiltersChange = useCallback(
    async (values: KibanaFilters) => {
      goToFlux(
        values.operatorIds,
        values.days,
        values.startDate?.getTime(),
        values.endDate?.getTime(),
        values.startTime?.getTime(),
        values.endTime?.getTime(),
        values.territory?.name,
        values.territory?.id,
        values.territory?.code,
        values.dashboardId
      );
      setFiltersValue({ ...values });

      const dto: GetPolylineListQueryDTO = {
        operatorIds: values.operatorIds,
        territoryCodes: values.territory ? [values.territory.code] : [],
        from: values.startDate?.getTime(),
        to: values.endDate?.getTime(),
        startTime: extractTime(values.startTime),
        endTime: extractTime(values.endTime),
        dayOfWeek: values.days.length === 0 ? null : values.days,
      };

      const result = await polylineClient.query.fetchPolylineListQuery(dto);

      setPolylines(result);
    },
    [extractTime, goToFlux]
  );

  const operatorsByTerritory =
    useAsyncResponse(bonusGroupClient.query.fetchOperatorsByTerritories)
      .result ?? {};

  const headBar = useMemo(() => ({ title: "Flux des livraisons" }), []);

  return (
    <PageWithTitle
      headBar={headBar}
      header={<span />}
      columnSwitchWidth={800}
      switchedHeaderHeight={150}
    >
      <Box className={classes.content}>
        <FluxFilters
          isFunder={isFunder}
          isVisitor={isVisitor}
          days={daysOptions}
          operatorByTerritory={operatorsByTerritory}
          operatorRefs={operatorRefs}
          onFilterChange={onFiltersChange}
          getTerritories={geoZoneClient.query.getTerritories}
          filterValues={filterValues}
          allowedTerritories={allowedTerritories}
        />
        <Fluxmap polylines={polylines} />
      </Box>
    </PageWithTitle>
  );
};

export default withConnect(FluxPage);

const daysOptions = [
  {
    label: "Lundi",
    value: "1",
  },
  {
    label: "Mardi",
    value: "2",
  },
  {
    label: "Mercredi",
    value: "3",
  },
  {
    label: "Jeudi",
    value: "4",
  },
  {
    label: "Vendredi",
    value: "5",
  },
  {
    label: "Samedi",
    value: "6",
  },
  {
    label: "Dimanche",
    value: "7",
  },
];
