import { Box, TextareaAutosize, makeStyles } from "@material-ui/core";
import {
  BonusGroup,
  BonusGroupLogDetail,
  bonusGroupClient,
} from "api/bonusGroup";
import { BonusGroupLogUtils } from "api/bonusGroup/model/bonusGroupLogType";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { UTCToTimedZone } from "utils";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PopUp, theme } from "components";

const useStyles = makeStyles(() => ({
  popup: {
    "& .MuiDialog-paper": {
      maxWidth: "1000px",
    },
  },
  logTable: {
    fontSize: 12,
  },
  logCommentContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  commentTextField: {
    width: "170px",
    minHeight: "30px",
    margin: "5px",
    fontSize: 11,
    fontFamily: '"Montserrat", Arial',
    display: "inline-block",
    verticalAlign: "middle",
    border: "none",
    resize: "none",
    "&:focus": {
      outline: "ridge thin",
      outlineColor: theme.secondaryColor,
      borderRadius: "3px",
    },
  },
  iconButton: {
    width: "40px",
    height: "40px",
    "&:hover svg": {
      color: theme.secondaryColor,
    },
  },
  icon: {
    color: "#9a9a9a",
    width: "22px",
    height: "22px",
  },
}));

export interface BonusLogTableProps {
  bonus: BonusGroup;
  logPopUpIsOpen: boolean;
  setLogPopUpIsOpen: (isOpen: boolean) => void;
  fetchBonus: (bonusId: string, force?: boolean) => void;
}

export const BonusLogTable = (props: BonusLogTableProps) => {
  const { bonus, logPopUpIsOpen, setLogPopUpIsOpen, fetchBonus } = props;
  const { t } = useTranslation();
  const { bonusId } = useParams();

  const classes = useStyles();

  const onEditBonusGroupLogComment = useCallback(
    (bonusGroupLogId: string, comment: string) =>
      bonusGroupClient.editBonusGroupLogComment(
        bonusId,
        bonusGroupLogId,
        comment
      ),
    [bonusId]
  );

  const onClose = useCallback(() => {
    setLogPopUpIsOpen(false);
    fetchBonus(bonusId);
  }, [setLogPopUpIsOpen, fetchBonus]);

  const columns = useMemo(() => {
    return [
      {
        name: t("app_bonus_view_log_date"),
        width: "130px",
        cell: (log: BonusGroupLogDetail) =>
          UTCToTimedZone(log.date).toLocaleDateString(),
      },
      {
        name: t("app_bonus_view_log_type"),
        width: "200px",
        cell: (log: BonusGroupLogDetail) =>
          BonusGroupLogUtils.getLogTypeLabel(log.type, log.status),
      },
      {
        name: t("app_bonus_view_log_user"),
        width: "200px",
        cell: (log: BonusGroupLogDetail) => log.userEmail || "",
      },
      {
        name: t("app_bonus_view_log_organization"),
        width: "200px",
        cell: (log: BonusGroupLogDetail) => log.organizationName || "",
      },
      {
        name: t("app_bonus_view_log_comment"),
        width: "200px",
        cell: (log: BonusGroupLogDetail) => (
          <Box className={classes.logCommentContainer} key={log.id}>
            <TextareaAutosize
              defaultValue={log.comment}
              onBlur={(e) =>
                e.target.value !== log.comment
                  ? onEditBonusGroupLogComment(log.id, e.target.value)
                  : ""
              }
              className={classes.commentTextField}
            />
          </Box>
        ),
      },
    ];
  }, [onEditBonusGroupLogComment]);

  return (
    <PopUp
      title={t("app_bonus_view_log_title")}
      onClose={onClose}
      open={logPopUpIsOpen}
      className={classes.popup}
    >
      <DataTable
        columns={columns}
        data={bonus.logs}
        noDataComponent={"Aucun élément à afficher"}
        noHeader
        theme="colisactiv"
        className={classes.logTable}
      />
    </PopUp>
  );
};
