import { keycloakClient } from "api/keyclaok";
import {
  Convention,
  ConventionActions,
  ConventionStatusValues,
  Organization,
  organizationClient,
} from "api/organization";
import { conventionTemplateImageUrl } from "api/organization/query/conventionTemplateImageUrl";
import { ConventionViewer } from "components";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import withConnect from "./withConnect";

interface OrganizationDocumentsProps {
  organization: Organization | null;
  convention: Convention | null;
  conventionActions: ConventionActions;
  prepareSignConvention: (organizationId: string) => void;
  resetConvention: (organizationId: string) => Promise<void>;
  fetchConvention: (organizationId: string) => void;
  docusignEvent?: string;
}

const OrganizationDocuments = (props: OrganizationDocumentsProps) => {
  const {
    organization,
    conventionActions,
    convention,
    prepareSignConvention,
    resetConvention,
    fetchConvention,
    docusignEvent,
  } = props;
  const { organizationId } = useParams();
  const [localPdfUrl, setLocalPdfUrl] = useState<string>("");

  useEffect(() => {
    if (convention == null) {
      fetchConvention(organizationId);
    }
    if (convention !== null) {
      organizationClient.query
        .fetchConventionFile(organizationId)
        .then((document) => {
          if (document != undefined) {
            setLocalPdfUrl(URL.createObjectURL(document));
          }
        });
    }
  }, [organization, organizationId, convention]);
  const { t } = useTranslation();

  const onSignCLick = useCallback(async () => {
    await prepareSignConvention(organizationId);
  }, [prepareSignConvention, organizationId]);

  const isSigned = () => {
    if (!convention) return false;

    if (convention.status.value === ConventionStatusValues.FINALIZED.value)
      return true;

    if (
      convention.status.value === ConventionStatusValues.SIGNED_BY_ORG.value &&
      (keycloakClient.instance.hasRealmRole("operator_admin") ||
        keycloakClient.instance.hasRealmRole("operator_admin_onboarding")) &&
      !keycloakClient.instance.hasRealmRole("admin")
    )
      return true;

    return false;
  };

  const isPrefilled = () => {
    return (
      convention != null &&
      convention.status.value === ConventionStatusValues.PREFILLED.value &&
      keycloakClient.instance.hasRealmRole("admin")
    );
  };

  const createOnView = () => {
    return localPdfUrl !== ""
      ? () => window.open(localPdfUrl, "_blank")
      : undefined;
  };

  const handleReset = useCallback(async () => {
    await resetConvention(organizationId);
    fetchConvention(organizationId);
  }, [resetConvention, organizationId]);

  const isColisactiv = keycloakClient.instance.hasRealmRole("admin");

  return (
    <ConventionViewer
      imageSrc={`${conventionTemplateImageUrl(organizationId)}`}
      title={`${t("app_organizations_documents_CA_Convention", {
        operatorName: organization?.details.name,
      })}.pdf`}
      signed={isSigned()}
      prefilled={isPrefilled()}
      onPrefill={conventionActions.canPrefill ? onSignCLick : undefined}
      onSign={conventionActions.canSign ? onSignCLick : undefined}
      onView={createOnView()}
      onReset={isColisactiv ? handleReset : undefined}
      conventionLoading={!!convention && localPdfUrl === ""}
    />
  );
};

export default withConnect(OrganizationDocuments);
