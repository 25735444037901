import React, { useCallback } from "react";
import withConnect from "./withConnect";
import { Button } from "components";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { deliveryExportClient } from "api/deliveryExport";

const useStyles = makeStyles(() => ({
  exportContainer: {
    marginTop: 10,
    marginLeft: "10px",
  },
  exportForms: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    width: "100%",
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "10px",
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
}));

const DeliveryExportPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDownloadExport = useCallback(async () => {
    const objectUrl = await deliveryExportClient.query.downloadExport(
      window.location.search
    );
    if (objectUrl == null) {
      return;
    }
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "export.zip";
    a.click();
  }, []);

  const getDataType = useCallback(() => {
    const filePath = window.location.search;
    if (filePath.includes("DELIVERY")) {
      return "données de livraison";
    } else if (filePath.includes("TRACE")) {
      return "traces gps";
    }
    return "File type not found";
  }, []);

  return (
    <div className={classes.exportContainer}>
      <div className={classes.title}>
        <span>{t("app_delivery_export_title") + getDataType()}</span>
      </div>
      <Box className={classes.exportForms}>
        <Button onClick={handleDownloadExport}>
          {t("app_delivery_export_download_button")}
        </Button>
      </Box>
    </div>
  );
};

export default withConnect(DeliveryExportPage);
