import {DateInterval} from "api/report";
import {http} from "../../http";
import {city} from "ca-plateform-organization-domain";
import {buildBonusGroupApiUrl} from "api/configuration";

export interface ExportOrganizationsQueryBase
    extends city.colisactiv.organization.model.features
        .ExportOrganizationsQueryBase {}

export const exportOrganizationsDolibarr = async (
  period: DateInterval,
  organizationType: string
): Promise<string | null> => {
  const query: ExportOrganizationsQueryBase = {
    type: "EXPORT_ORGANIZATIONS",
    payload: {
      period: {
        startDate: period.startDate,
        endDate: period.endDate
      },
      type: {
        type: organizationType
      }
    }
  }
  return http.fetchDownloadableFile(
    buildBonusGroupApiUrl(`/organizations/export`),
    "Une erreur est survenue lors de l'export des organisations",
    "post",
    JSON.stringify(query)
  );
};
