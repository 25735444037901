import { InputLabel, makeStyles } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import { useInputStyles } from '../../Theme'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { TimeIcon } from '@material-ui/pickers/_shared/icons/TimeIcon'

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiInputBase-input': {
      paddingLeft: 10,
      color: '#98A5AE !important',
      minHeight: 50,
      padding: 0
    }
  },
  noIcon: {
    '& .MuiIconButton-root.Mui-disabled': {
      display: 'none'
    }
  },
  toolbar: {
    '& .MuiPickersToolbar-toolbar': {}
  }
}))

type viewsFormat = 'hours' | 'minutes'
interface TimePickerProps {
  value?: Date
  onChangeDate: (date: Date) => void
  className?: string
  id: string
  dateLabel?: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  views?: Array<viewsFormat>
  openTo?: viewsFormat
  notUseformat?: boolean
  clearable?: boolean
  placeholder?: string
  disableToolBar?: boolean
  ampm?: boolean
}

export const TimePicker = (props: TimePickerProps) => {
  const {
    value,
    onChangeDate,
    className,
    id,
    dateLabel,
    disabled = false,
    views,
    openTo,
    clearable = false,
    placeholder = '',
    disableToolBar = true,
    ampm = false
  } = props
  const localClasses = useStyles()
  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, disabled)()

  return (
    <div className={`${className}`}>
      {dateLabel ? (
        <InputLabel htmlFor={id} className={classes.label}>
          {dateLabel}
        </InputLabel>
      ) : null}
      <KeyboardTimePicker
        autoOk
        ampm={ampm}
        views={views ? views : ['hours', 'minutes']}
        openTo={openTo ? openTo : 'hours'}
        disableToolbar={disableToolBar}
        clearable={clearable}
        variant={clearable ? 'dialog' : 'inline'}
        cancelLabel={''}
        clearLabel='Vider'
        okLabel=''
        id={id}
        placeholder={placeholder}
        className={
          disabled
            ? `${classes.input} ${localClasses.input} ${localClasses.noIcon} `
            : `${classes.input} ${localClasses.input} `
        }
        InputProps={{
          disableUnderline: true
        }}
        disabled={disabled ? disabled : false}
        value={value ? value : null}
        onChange={onChangeDate}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        keyboardIcon={
          <TimeIcon
            style={{
              width: 15,
              height: 15,
              color: '#98A5AE'
            }}
          />
        }
        minDateMessage={''}
      />
    </div>
  )
}
