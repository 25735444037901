import * as R from "ramda";
import {
  ExportAuthorizationFormState,
  ExportAuthorizationFormValidity,
} from "./model";
import { NO_ERROR } from "../SiretAutocomplete";

export const getValidation = (
  form: ExportAuthorizationFormState
): ExportAuthorizationFormValidity => {
  return {
    canExportDelivery: NO_ERROR,
    deliveryPeriodStartMonth: NO_ERROR,
    deliveryPeriodEndMonth: NO_ERROR,
    canExportTrace: NO_ERROR,
    tracePeriodStartMonth: NO_ERROR,
    tracePeriodEndMonth: NO_ERROR,
  };
};

export const isFormValid = (
  newValidity: ExportAuthorizationFormValidity
): boolean => {
  return R.values(newValidity)
    .map((value) => value == NO_ERROR)
    .reduce((previousValue, currentValue) => previousValue && currentValue);
};

export const defaultExportAuthorizationFormValidity: ExportAuthorizationFormValidity =
  {
    canExportDelivery: NO_ERROR,
    deliveryPeriodStartMonth: NO_ERROR,
    deliveryPeriodEndMonth: NO_ERROR,
    canExportTrace: NO_ERROR,
    tracePeriodStartMonth: NO_ERROR,
    tracePeriodEndMonth: NO_ERROR,
  };

export const defaultExportAuthorizationFormState: ExportAuthorizationFormState =
  {
    canExportDelivery: false,
    deliveryPeriodStartMonth: undefined,
    deliveryPeriodEndMonth: undefined,
    canExportTrace: false,
    tracePeriodStartMonth: undefined,
    tracePeriodEndMonth: undefined,
    validity: defaultExportAuthorizationFormValidity,
  };

export const ExportAuthorizationValidation = {
  validation: getValidation,
  isFormValid: isFormValid,
  defaultExportAuthorizationFormValidity:
    defaultExportAuthorizationFormValidity,
  defaultExportAuthorizationFormState: defaultExportAuthorizationFormState,
};
