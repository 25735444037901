import { Box, makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { AutoComplete } from '../../Component/AutoComplete'
import { city as cityPlat } from 'ca-plateform-plateform-commons'
import { Territory } from './index'
import { TextField } from '../..'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginLeft: '20px',
    maxWidth: '200px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px'
      },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left'
  }
}))

export interface TerritoryPage
  extends cityPlat.colisactiv.platform.commons.Page<Territory> {}

export interface GeoZoneFiltersModel {
  territory?: Territory
  geoZoneName: string
}

interface GeoZoneFiltersProps {
  filterValues: GeoZoneFiltersModel
  getTerritories: (
    page: number,
    size: number,
    name: string
  ) => Promise<TerritoryPage>
  onFilterChange: (values: GeoZoneFiltersModel) => void
}

export const GeoZoneFilters = (props: GeoZoneFiltersProps) => {
  const { filterValues, onFilterChange, getTerritories } = props
  const classes = useStyles()

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const singleTerritory = Array.isArray(territory)
        ? territory[0]
        : territory

      onFilterChange({
        territory: singleTerritory,
        geoZoneName: filterValues.geoZoneName
      })
    },
    [onFilterChange, filterValues]
  )

  const handleGeoZoneNameChange = useCallback(
    (geoZoneName: string) => {
      onFilterChange({
        ...filterValues,
        geoZoneName: geoZoneName
      })
    },
    [onFilterChange, filterValues]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher un territoire'
  )
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  )

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([])
      setAutocompleteText('Rechercher un territoire')
      return
    }

    try {
      const response = await getTerritories(0, 5, search.trim())
      setAutoCompleteOptions(response.list)
      if (response.list.length === 0) {
        setAutocompleteText('Aucun territoire trouvé')
      }
    } catch (err) {
      console.error(err)
      setAutoCompleteOptions([])
      setAutocompleteText('Une erreur est survenue, veuillez réessayer')
    }
  }, [])

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name
  }, [])

  const optionSelectedValidator = useCallback(
    (option: Territory, value: Territory) => {
      if (option.id == value.id) return true
      return false
    },
    []
  )

  return (
    <Box className={classes.container}>
      <AutoComplete<Territory>
        id='geozone-filters-territory'
        onChangeSelectedElement={handleTerritoryChange}
        options={autoCompleteOptions}
        defaultValue={filterValues.territory}
        label=''
        onSearch={handleSearchTerritory}
        noOptionsText={autocompleteText}
        getOptionLabel={getTerritoryName}
        placeholder='Territoire'
        className={classes.filter}
        getOptionSelected={optionSelectedValidator}
      />
      <TextField
        className={classes.filter}
        placeHolder='Zone'
        value={filterValues.geoZoneName}
        onChange={handleGeoZoneNameChange}
      />
    </Box>
  )
}
