import { FullscreenControl, GeoJSONSourceRaw, Popup } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import ReactMapboxGl, { Layer, Source } from "react-mapbox-gl";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import { apiMapboxAccessToken } from "../../../api/configuration";
import { toGeoJSON } from "@mapbox/polyline";
import { makeStyles } from "@material-ui/core";
import { centerMedian } from "@turf/turf";
import { useMemo } from "react";
import { FitBounds } from "react-mapbox-gl/lib/map";

const styles = {
  londonCycle: "mapbox://styles/mapbox/light-v9",
  light: "mapbox://styles/mapbox/light-v9",
  dark: "mapbox://styles/mapbox/dark-v10",
  basic: "mapbox://styles/mapbox/basic-v9",
  outdoor: "mapbox://styles/mapbox/outdoors-v10",
  street: "mapbox://styles/mapbox/streets-v11",
};

const Map = ReactMapboxGl({
  accessToken: apiMapboxAccessToken,
});

const mapStyle = {
  width: "100%",
  height: "600px",
  marginLeft: "30px",
};

const clickPadding = 20 // in px

const useStyles = makeStyles(() => ({
  popup: {
    '& .mapboxgl-popup-content': {
      background: 'none',
      color: 'rgb(236,222,239)',
      backgroundColor: 'rgba(14,28,11,0.8)',
      padding: '1px 6px',
      margin: '2px',
      boxSizing: 'border-box',
      borderRadius: '10%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .mapboxgl-popup-tip': {
      backgroundColor: 'rgba(236,222,239,0.2)',
      border: '1px solid rgba(236,222,239,0.8)',
      boxSizing: 'border-box',
      width: `${clickPadding * 2}px`,
      height: `${clickPadding * 2}px`,
      padding: 0,
    }
  },
}))

interface FluxmapProps {
  onStyleLoad?: (map: any) => any;
  polylines: string[]
}

export const Fluxmap = (props: FluxmapProps) => {
  const { polylines } = props;
  const classes = useStyles()

  const geoJsonSource: GeoJSONSourceRaw = useMemo(() => {
    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: polylines.map((polyline) => {
          return {
            type: "Feature",
            geometry: toGeoJSON(polyline)
          }
        })
      }
    }
  }, [polylines]);

  const mapBounds: FitBounds = useMemo(() => {
    if (polylines.length > 0) {
      const center = centerMedian(geoJsonSource.data).geometry.coordinates
      const offset = 0.05
      return [[center[0] - offset, center[1] - offset], [center[0] + offset, center[1] + offset]]
    } else {
      return [[-12.973238, 40.968836], [18.667387, 51.183932]]
    }
  }, [geoJsonSource.data, polylines.length]);

  const layerPaint: any = useMemo(() => {
    return {
      'line-color': '#f5b042',
      'line-width': 1.2,
      'line-opacity': (1 / (polylines.length / 200 + 1)),
    }
  }, [polylines.length]);

  const onStyleLoad: MapEvent = (map) => {
    const { onStyleLoad } = props;
    map.addControl(new FullscreenControl());
    map.on('click', (e) => {
      const bbox = [
        [e.point.x - clickPadding, e.point.y - clickPadding],
        [e.point.x + clickPadding, e.point.y + clickPadding]
      ]
      const features = map.queryRenderedFeatures(
        bbox,
        { layers: ['polylines'] }
      );
      // Display a tooltip with the count of intersecting polylines
      new Popup({ closeOnMove: true, closeButton: false, className: classes.popup })
        .setLngLat(e.lngLat)
        .setText(`${features.length} passage${features.length > 1 ? 's' : ''}`)
        .setOffset(-clickPadding)
        .addTo(map);
    });
    return onStyleLoad && onStyleLoad(map);
  };

  return (
    <Map
      style={styles.dark}
      containerStyle={mapStyle}
      onStyleLoad={onStyleLoad}
      fitBounds={mapBounds}
    >
      <Source id="polylines" geoJsonSource={geoJsonSource} />
      <Layer
        type="line"
        id="polylines"
        sourceId="polylines"
        paint={layerPaint}
      />
    </Map>
  );
};