import React from 'react'
import { ReactComponent } from './directions_bike_24px_rounded.svg'
import { MergeReactElementProps } from '../types'

interface TruckProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', TruckProps>

export const BikeIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#9a9a9a' } = props
    return <ReactComponent stroke={color} fill={color} ref={ref} {...props} />
  }
)
