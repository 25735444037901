import React, { useEffect, useState } from "react";
import {
  VpnKey,
  ExitToApp,
  PersonOutlineOutlined,
  LocationOnOutlined,
} from "@material-ui/icons";
import {
  AppLayout,
  ProfileMenu,
  Dashboard,
  CreditCard,
  BusinessCenter,
  ApartmentIcon,
  NotificationsIcon,
  theme,
  Assignment,
  ColisactivLogoSvg,
  Pay,
  ProfileLogo,
  ShipmentDelivered,
  GeoZoneIcon,
  SaveCSV,
} from "components";
import { Badge, makeStyles } from "@material-ui/core";
import withConnect from "./withConnect";
import { Menu } from "@smartb/archetypes-ui-layout";
import { User, usersClient } from "api/organization/users";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth/useAuth";
import { routesAccessRights } from "auth/RoutesAccessRightsMap";
import { ColisActivLogoInner } from "app/components/ColisActivLogo";
import Loading from "app/components/Loading";

const useStyles = makeStyles(() => ({
  iconsLogo: {
    marginBottom: "30px",
  },
  iconsSvg: {
    marginTop: "28px",
    marginBottom: "30px",
  },
  notificationsMenu: {
    maxHeight: "500px",
    maxWidth: "350px",
    top: "60px !important",
    "& ul": {
      paddingTop: "0px",
    },
    "& .notification-bodyContainer": {
      padding: "5px",
    },
    "& .notification-mainContainer": {
      minHeight: "60px",
    },
    "& .MuiSpeedDial-root": {
      top: "4px",
    },
  },
  icon: {
    width: "26px",
    height: "26px",
  },
  profileIcon: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
    color: "#353945",
  },
  profileImg: {
    width: "40px",
    marginRight: "10px",
  },
}));

export interface AppProps {
  navBarContent: JSX.Element;
  children: JSX.Element;
  unSeenNumber: number;
  gotoDashboard: () => void;
  gotoApi: () => void;
  gotoReports: (page: number, size: number) => void;
  gotoBonus: (page: number, size: number) => void;
  gotoNotifications: () => void;
  gotoProjectsList: (page: number, size: number) => void;
  gotoOrganizationsList: (page: number, size: number) => void;
  gotoOrganization: (organizationId: string) => void;
  onRemoveAlert: (index: number) => void;
  currentUser: User | null;
  usersView: (userId: string) => void;
  gotoBonusPayments: (page: number, size: number) => void;
  gotoCampaignDelivery: () => void;
  gotoFlux: () => void;
  gotoZonesList: () => void;
  gotoImportTours: () => void;
}

const AdminAppLayout = (props: AppProps) => {
  const {
    navBarContent,
    gotoDashboard,
    gotoApi,
    gotoBonus,
    gotoNotifications,
    gotoProjectsList,
    gotoOrganizationsList,
    gotoOrganization,
    gotoZonesList,
    gotoImportTours,
    currentUser,
    usersView,
    gotoReports,
    children,
    gotoBonusPayments,
    gotoCampaignDelivery,
    unSeenNumber,
    gotoFlux,
  } = props;
  const [authService, keycloak] = useAuth();
  const [open, setOpen] = useState(window.innerWidth > 768);
  const classes = useStyles();
  const { t } = useTranslation();
  const [profileImage, setprofileImage] = useState<JSX.Element>(
    <PersonOutlineOutlined className={classes.profileIcon} />
  );

  useEffect(() => {
    if (currentUser && currentUser.hasLogo) {
      setprofileImage(
        <ProfileLogo
          alt="Votre photo"
          src={usersClient.queries.userLogoUrl(currentUser.userId)}
          className={classes.profileImg}
        />
      );
    } else {
      setprofileImage(
        <PersonOutlineOutlined className={classes.profileIcon} />
      );
    }
  }, [currentUser]);

  const accountMenu = {
    label: `${t("app_admin_layout_profile_menu")} `,
    key: "profileMenu",
    icon: (
      <ProfileMenu
        name={currentUser ? currentUser.details.firstname : ""}
        role={currentUser ? getFrenchRole(currentUser.details.role) : ""}
        img={profileImage}
      />
    ),
    items: [
      {
        label: `${t("app_admin_layout_main")} `,
        key: "main",
        items: [
          {
            key: "profile",
            label: `${t("app_admin_layout_my_profile")} `,
            goto: () => {
              currentUser && usersView(currentUser.userId);
            },
            icon: <PersonOutlineOutlined />,
          },
          {
            key: "myOrganization",
            label: `${t("app_admin_layout_my_organization")} `,
            goto: () => {
              currentUser &&
                gotoOrganization(currentUser.details.organizationId);
            },
            icon: (
              <ApartmentIcon
                color="rgba(0, 0, 0, 0.54)"
                strokeWidth="1.5px"
                style={{ width: "24px", height: "24px" }}
              />
            ),
          },
          ...(authService.isAuthorized(routesAccessRights["/api"], keycloak)
            ? [
                {
                  key: "ApiKey",
                  label: `${t("app_admin_layout_API")} `,
                  goto: () => gotoApi(),
                  icon: <VpnKey />,
                },
              ]
            : []),
          {
            key: "logout",
            label: `${t("app_admin_layout_logout")} `,
            goto: () => !!keycloak && keycloak.logout(),
            icon: <ExitToApp />,
          },
        ],
      },
    ],
  };

  const iconColor = theme.nav.color.icon;
  const logoColor = theme.nav.color.logo;

  const drawerMenu: Menu[] = [
    {
      key: "logo",
      label: "",
      icon: open ? (
        <ColisActivLogoInner color={logoColor} className={classes.iconsLogo} />
      ) : (
        <ColisactivLogoSvg className={classes.iconsSvg} />
      ),
      goto: () => gotoDashboard(),
    },
    ...(authService.isAuthorized(routesAccessRights["/dashboard"], keycloak)
      ? [
          {
            key: "board",
            label: open ? `${t("app_admin_layout_board")} ` : "",
            icon: <Dashboard color={iconColor} className={classes.icon} />,
            goto: () => gotoDashboard(),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/bonus"], keycloak)
      ? [
          {
            key: "primes",
            label: open ? `${t("app_admin_layout_premiums")} ` : "",
            icon: <CreditCard color={iconColor} className={classes.icon} />,
            goto: () => gotoBonus(0, 10),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/bonusPayment"], keycloak)
      ? [
          {
            key: "bonusPayments",
            label: open ? `${t("app_admin_layout_bonusPayments")} ` : "",
            icon: (
              <Pay
                color={iconColor}
                className={classes.icon}
                style={{ strokeWidth: "1.5px" }}
              />
            ),
            goto: () => gotoBonusPayments(0, 10),
          },
        ]
      : []),
    ...(authService.isAuthorized(
      routesAccessRights["/campaignDelivery"],
      keycloak
    )
      ? [
          {
            key: "campaignDelivery",
            label: open ? `${t("app_admin_layout_campaignDelivery")} ` : "",
            icon: (
              <ShipmentDelivered
                color={iconColor}
                className={classes.icon}
                style={{ width: "28px", height: "28px", strokeWidth: "2" }}
              />
            ),
            goto: () => gotoCampaignDelivery(),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/projects"], keycloak)
      ? [
          {
            key: "Projects",
            label: open ? `${t("app_admin_layout_projects")} ` : "",
            icon: <BusinessCenter color={iconColor} className={classes.icon} />,
            goto: () => gotoProjectsList(0, 10),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/organizations"], keycloak)
      ? [
          {
            key: "organizations",
            label: open ? `${t("app_admin_layout_organizations")} ` : "",
            icon: <ApartmentIcon color={iconColor} className={classes.icon} />,
            goto: () => gotoOrganizationsList(0, 10),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/reports"], keycloak)
      ? [
          {
            key: "reports",
            label: open ? `${t("app_admin_layout_reports")} ` : "",
            icon: (
              <Assignment
                color={iconColor}
                className={classes.icon}
                strokeWidth="1.5px"
              />
            ),
            goto: () => gotoReports(0, 10),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/flux"], keycloak)
      ? [
          {
            key: "flux",
            label: open ? `${t("app_admin_layout_flux")} ` : "",
            icon: (
              <LocationOnOutlined
                htmlColor={iconColor}
                className={classes.icon}
              />
            ),
            goto: () => gotoFlux(),
          },
        ]
      : []),
    ...(authService.isAuthorized(routesAccessRights["/geozones"], keycloak)
      ? [
          {
            key: "zones",
            label: open ? `${t("app_admin_layout_zones")} ` : "",
            icon: <GeoZoneIcon color={iconColor} className={classes.icon} />,
            goto: () => gotoZonesList(),
          },
        ]
      : []),
    {
      key: "notifications",
      label: open ? `${t("app_admin_layout_notifications")} ` : "",
      icon: (
        <Badge badgeContent={unSeenNumber} color="error">
          <NotificationsIcon color={iconColor} className={classes.icon} />
        </Badge>
      ),
      goto: () => gotoNotifications(),
    },
    ...(authService.isAuthorized(routesAccessRights["/tours/import"], keycloak)
      ? [
          {
            key: "import",
            label: open ? `${t("app_admin_layout_import")} ` : "",
            icon: <SaveCSV color={iconColor} className={classes.icon} />,
            goto: () => gotoImportTours(),
          },
        ]
      : []),
  ];

  if (!authService.isAuthenticated(keycloak)) {
    return <Loading />;
  }

  return (
    <AppLayout
      open={open}
      onToggle={() => setOpen(!open)}
      accountMenu={accountMenu}
      drawerMenu={drawerMenu}
      navBarContent={navBarContent}
    >
      {children}
    </AppLayout>
  );
};

export default withConnect(AdminAppLayout);

const getFrenchRole = (role: string): string => {
  switch (role) {
    case "user":
      return "Utilisateur";
    case "admin":
      return "Administrateur";
    default:
      return "";
  }
};
