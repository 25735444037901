import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../../configuration";
import { http } from "../../../http";

export interface DisabledUserEvent
  extends city.colisactiv.organization.model.features.DisabledUserEvent {}

export const disableUser = (
  organizationId: string,
  userId: string
): Promise<DisabledUserEvent> => {
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/users/${userId}/disable`
    ),
    "L'utilisateur a bien été désactivé"
  );
};
