import * as R from "ramda";
import { GeoZoneFormDetailsValidity, GeoZoneFormState, GeoZoneFormValidity } from "./model";
import gjv from "geojson-validation"

const validateGeoZoneForm = (
  geoZoneForm: GeoZoneFormState
): [boolean, GeoZoneFormValidity] => {
  const geoZoneFormValidation: GeoZoneFormValidity = {
    details: validateGeoZoneFormDetails(geoZoneForm)[1],
  };
  const isValid = isValidGeoZoneFormDetails(geoZoneFormValidation.details);
  return [isValid, geoZoneFormValidation];
};

const validateGeoZoneFormDetails = (
  geoZone: GeoZoneFormState
): [boolean, GeoZoneFormDetailsValidity] => {
  const validity: GeoZoneFormDetailsValidity = {
    geoZoneName: geoZone.details.geoZoneName !== "",
    territory: geoZone.details.territory && geoZone.details.territory.code !== "",  
    geoJson: isGeoJsonValid(geoZone.details.geoJson),
  };

  const isValid = isValidGeoZoneFormDetails(validity);
  return [isValid, validity];
};

const isValidGeoZoneFormDetails = (
  validity: GeoZoneFormDetailsValidity
): boolean => {
  return R.values(validity).every(R.identity);
};

const isGeoJsonValid = (geoJson: string): boolean => {  
  // only validate "Polygon" and "MultiPolygon"
  gjv.define("LineString", () => ["error"])
  gjv.define("MultiLineString", () => ["error"])
  gjv.define("MultiPoint", () => ["error"])

  try {
    return gjv.isFeature(JSON.parse(geoJson), false) || 
      gjv.isFeatureCollection(JSON.parse(geoJson), false)
  } catch {
    return false
  }
}

export const GeoZoneValidation = {
  validateDetails: validateGeoZoneFormDetails,
  validateForm: validateGeoZoneForm
};
