import { connect } from "react-redux";
import { State } from "store";
import { title } from "store/title";
import { project } from "store/project";
import { router } from "store/router";
import { global } from "store/global";
import { profile } from "store/profile";

const mapStateToProps = (state: State) => ({
  carrierRefs: global.selectors.carrierRefs(state),
  operatorRefs: global.selectors.operatorRefs(state),
  funderRefs: global.selectors.funderRefs(state),
  project: project.selectors.project(state),
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  gotoProjectGeneralView: router.goto.project.projectView,
  createCampaign: project.commands.creatCampaign,
  setHeadBar: title.actions.setHeadBar,
  fetchProject: project.queries.fetchProject,
  gotoGeoZoneCreate: router.goto.geoZone.geoZoneAdd,
};

export default connect(mapStateToProps, mapDispatchToProps);
