import { city } from "ca-plateform-notification-domain";
import { buildNotificationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface NotificationUnpinnedEvent
  extends city.colisactiv.platform.notification.domain.features
    .NotificationUnpinnedEvent {}

export const unpinNotification = (
  notificationId: string
): Promise<NotificationUnpinnedEvent> => {
  return http.put(
    buildNotificationApiUrl(`/notifications/${notificationId}/unpin`),
    null,
    "La notification a été désépinglé"
  );
};
