import { connect } from "react-redux";
import { router } from "store/router";
import { State } from "store";
import { profile } from "store/profile";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  goto: router.goto.push,
  gotoOrganization: router.goto.organization.organizationView,
};

export default connect(mapStateToProps, mapDispatchToProps);
