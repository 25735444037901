import { push } from "connected-react-router";

const geoZonesList = (
  territoryName?: string,
  territoryId?: string,
  territoryCode?: string,
  geoZoneName?: string
) => {
  let filters: string[] = [];
  if (territoryName) filters.push(`territoryName=${territoryName}`);
  if (territoryId) filters.push(`territoryId=${territoryId}`);
  if (territoryCode) filters.push(`territoryCode=${territoryCode}`);
  if (geoZoneName) filters.push(`geoZoneName=${geoZoneName}`);
  return push(`/geoZones?${filters.join("&")}`);
};

const geoZoneAdd = () => push(`/geoZones/add`);

export const gotoGeoZone = {
  geoZonesList: geoZonesList,
  geoZoneAdd: geoZoneAdd
};
