import { city } from "ca-plateform-project-domain";
import { http } from "../../http";
import { ArchivedCampaignEvent } from "./archiveCampaignCommand";
import { buildProjectApiUrl } from "../../configuration";

export interface PublishedCampaignEvent
  extends city.colisactiv.project.model.features.PublishedCampaignEvent {}

export const publishCampaignCommand = (
  projectId: string,
  campaignId: string
): Promise<ArchivedCampaignEvent> =>
  http.put<PublishedCampaignEvent>(
    buildProjectApiUrl(
      `/projects/${projectId}/campaigns/${campaignId}/status/publish`
    )
  );
