import { http } from "../../http";
import {Dashboard} from "../index";
import {buildDashboardApiUrl} from "../../configuration";

export const getDashboardsQuery = (
): Promise<Dashboard[]> => {
    return http.get(
        buildDashboardApiUrl("/dashboards"),
        "la récupération des tableaux de bord n'a pas pu être effectuée"
    );
};
