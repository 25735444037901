import {
  downloadBonusGroupImports,
  downloadExport,
  exportAllDelivery,
  exportAllTrace,
  exportDelivery,
  exportTrace,
} from "./query/exportDelivery";

export const deliveryExportClient = {
  query: {
    exportDelivery: exportDelivery,
    exportTrace: exportTrace,
    exportAllDelivery: exportAllDelivery,
    exportAllTrace: exportAllTrace,
    downloadExport: downloadExport,
    downloadBonusGroupImports: downloadBonusGroupImports,
  },
};
