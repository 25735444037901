import { Grid, makeStyles } from "@material-ui/core";
import { GeoZone } from "api/geoZone";
import { InputForm, Option } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignRef } from "./BonusFormStatsList";
import BonusFormStatsRow from "./BonusFormStatsRow";
import {
  BonusFormStatsRowState,
  BonusFormStatsState,
  BonusFormStatsValidity,
  defaultBonusFormStatsRow,
  defaultBonusFormStatsRowValidity,
} from "./model";
import { BonusValidation } from "./validation";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    justifyItems: "center",
    justifyContent: "space-between",
    margin: "21px 0",
    position: "relative",
  },
  inputClassName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 250,
    justifyContent: "center",
  },
  deletebutton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 250,
    justifyContent: "center",
    marginTop: "25px",
  },
  inputStyle: {
    width: "100%",
  },
  selectStyle: {
    width: "100%",
    minWidth: 250,
    marginTop: 10,
    "& .MuiInput-underline:before": {
      content: "none",
    },
    display: "block",
  },
  trashIcon: {
    position: "absolute",
    right: 0,
    top: -13,
    width: "fit-content",
    height: "fit-content",
    cursor: "pointer",
  },
  delete: {
    width: "19px",
    height: "19px",
  },
  addStatsLine: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    color: "#007DCE",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "5px",
  },
}));

interface BonusFormStatsProps {
  position: number;
  stats: BonusFormStatsState;
  validation: BonusFormStatsValidity;
  availableAreas: GeoZone[];
  availableCampaigns: CampaignRef[];
  isUpdate: boolean;
  readonly: boolean;
  onChange: (
    position: number,
    property: keyof BonusFormStatsState,
    value: any,
    newValidation?: BonusFormStatsValidity
  ) => void;
}

const BonusFormStats = (props: BonusFormStatsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    position,
    stats,
    validation,
    availableAreas,
    availableCampaigns,
    isUpdate,
    readonly,
    onChange,
  } = props;

  const [areaOptions, setAreaOptions] = useState<Option[]>([]);
  const [campaignOptions, setCampaignOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!stats.campaignId || isUpdate) {
      return;
    }

    const selectedCampaign = availableCampaigns.find(
      (campaign) => campaign.id === stats.campaignId
    );
    if (!selectedCampaign) {
      handleCampaignClear();
    }
  }, [availableCampaigns, stats.campaignId]);

  useEffect(() => {
    if (!stats.areaCode || isUpdate) {
      return;
    }

    const selectedArea = availableAreas.find(
      (area) => area.code === stats.areaCode
    );
    if (!selectedArea) {
      handleAreaClear();
    }
  }, [availableAreas, stats.areaCode]);

  useEffect(() => {
    let areas = availableAreas;

    if (stats.campaignId) {
      const campaignAreaCodes = availableCampaigns.find(
        (campaign) => campaign.id === stats.campaignId
      )?.areaCodes;

      const areaSet = new Set(campaignAreaCodes);
      areas = areas.filter((area) => areaSet.has(area.code));
    }

    setAreaOptions(
      areas.map((area) => ({
        label: area.name,
        value: area.code,
      }))
    );
  }, [availableAreas, availableCampaigns, stats.campaignId]);

  useEffect(() => {
    let campaigns = availableCampaigns;

    if (stats.areaCode) {
      campaigns = campaigns.filter((campaign) => {
        return campaign.areaCodes.includes(stats.areaCode);
      });
    }

    setCampaignOptions(
      campaigns.map((campaign) => ({
        label: campaign.name,
        value: campaign.id,
      }))
    );
  }, [availableAreas, availableCampaigns, stats.areaCode]);

  const handleAreaChange = useCallback(
    (value?: string) => {
      onChange(position, "areaCode", value);
    },
    [onChange]
  );

  const handleAreaClear = useCallback(() => {
    handleAreaChange(undefined);
  }, [handleAreaChange]);

  const handleCampaignChange = useCallback(
    (value?: string) => {
      onChange(position, "campaignId", value);
    },
    [onChange]
  );

  const handleCampaignClear = useCallback(() => {
    handleCampaignChange(undefined);
  }, [handleCampaignChange]);

  const handleManualDataChange = useCallback(
    (id: string, property: keyof BonusFormStatsRowState, value: any) => {
      const newData = stats.manualData.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            [property]: value,
          };
        }
        return data;
      });
      onChange(position, "manualData", newData);
    },
    [onChange, stats, position]
  );

  const handleAutomaticDataChange = useCallback(
    (id: string, property: keyof BonusFormStatsRowState, value: any) => {
      const newData: BonusFormStatsRowState = {
        ...stats.automaticData,
        [property]: value,
      };
      onChange(position, "automaticData", newData);
    },
    [onChange, stats, position]
  );

  const addStatsLine = useCallback(() => {
    let [isValid, validation] = BonusValidation.validateStats([stats]);

    if (!isValid) {
      return onChange(position, "manualData", stats.manualData, validation[0]);
    }

    const newData = [...stats.manualData, defaultBonusFormStatsRow()];
    const newValidation: BonusFormStatsValidity = {
      ...validation[0],
      manualData: [
        ...validation[0].manualData,
        defaultBonusFormStatsRowValidity,
      ],
    };
    onChange(position, "manualData", newData, newValidation);
  }, [onChange, stats, position]);

  const handleDeleteStatsLine = useCallback(
    (id: string) => {
      const newData = stats.manualData.filter((data) => data.id !== id);
      onChange(position, "manualData", newData);
    },
    [onChange, stats]
  );

  const shouldDisplayAutomaticData =
    stats.automaticData.activeDeliveryCount > 0;

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} md={3} className={classes.grid}>
          <div>
            <InputForm
              id={`campaign-${position}`}
              value={stats.campaignId}
              onChange={handleCampaignChange}
              onRemoveValue={handleCampaignClear}
              inputType="select"
              label={t("app_components_bonus_bonusform_select_campaign")}
              error={!validation.campaignId}
              errorMessage={t(
                "app_components_bonus_bonusform_select_campaign_error"
              )}
              className={classes.selectStyle}
              selectOptions={campaignOptions}
              readonly={isUpdate || readonly}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3} className={classes.grid}>
          <div className={classes.inputClassName}>
            <InputForm
              id={`area-${position}`}
              value={stats.areaCode}
              onChange={handleAreaChange}
              onRemoveValue={handleAreaClear}
              inputType="select"
              label={t("app_components_bonus_form_area_code")}
              error={!validation.areaCode}
              errorMessage={t("app_components_bonus_form_area_code_error")}
              className={classes.selectStyle}
              selectOptions={areaOptions}
              readonly={isUpdate || readonly}
            />
          </div>
        </Grid>
      </Grid>
      {shouldDisplayAutomaticData && (
        <BonusFormStatsRow
          stats={stats.automaticData}
          validation={validation.automaticData}
          onChange={handleAutomaticDataChange}
          readonly
        />
      )}
      {stats.manualData
        .slice(0, validation.manualData.length)
        .map((row, iRow) => (
          <BonusFormStatsRow
            key={`bonusFormStatsRow-${iRow}`}
            stats={row}
            validation={validation.manualData[iRow]}
            onChange={handleManualDataChange}
            onDelete={handleDeleteStatsLine}
            readonly={readonly}
            withDeleteButton={
              !readonly && (shouldDisplayAutomaticData || stats.manualData.length > 1)
            }
          />
        ))}
      {!readonly &&
        <div className={classes.addStatsLine} onClick={addStatsLine}>
          Ajouter une ligne
        </div>
      }
    </>
  );
};
export default BonusFormStats;
