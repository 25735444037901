import { Grid, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { GeoZone } from "api/geoZone";
import React, { useCallback, useEffect, useState } from "react";
import BonusFormStats from "./BonusFormStats";
import {
  BonusFormStatsState,
  BonusFormStatsValidity,
  defaultBonusFormStats as defaultBonusFormStatsState,
  defaultBonusFormStatsValidity,
} from "./model";
import { BonusValidation } from "./validation";

const useStyles = makeStyles(() => ({
  addDeliveryTourStyle: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    color: "#007DCE",
    fontSize: "14px",
    marginTop: "20px",
  },
  nestedDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  addCircleIcon: {
    marginRight: 5,
    width: "14px",
    height: "29px",
  },
  container: {
    borderBottom: `solid 1px #007DCE30`,
    flex: 1,
  },
}));

export interface CampaignRef {
  id: string;
  name: string;
  areaCodes: string[];
}

interface BonusFormStatsListProps {
  statList: BonusFormStatsState[];
  validationList: BonusFormStatsValidity[];
  onChange: (
    statList: BonusFormStatsState[],
    validationList: BonusFormStatsValidity[]
  ) => void;
  carrier: string;
  availableCampaigns: CampaignRef[];
  availableAreas: GeoZone[];
  readonly: boolean;
}

const BonusFormStatsList = (props: BonusFormStatsListProps) => {
  const classes = useStyles();
  const {
    statList,
    carrier,
    validationList,
    onChange,
    availableAreas,
    availableCampaigns,
    readonly
  } = props;

  const [validation, setValidation] = useState(validationList);

  const addStatsBlock = useCallback(() => {
    let [isValid, validation] = BonusValidation.validateStats(statList);

    if (!isValid) {
      return setValidation(validation);
    }

    onChange(
      [...statList, defaultBonusFormStatsState()],
      [...validation, defaultBonusFormStatsValidity]
    );
  }, [onChange, statList]);

  const handleBonusFormStatsChange = useCallback(
    (
      position: number,
      property: keyof BonusFormStatsState,
      value: any,
      newValidation?: BonusFormStatsValidity
    ) => {
      const newStats = statList.map((stats, iStats) => {
        if (iStats === position) {
          return {
            ...stats,
            [property]: value,
          };
        }
        return stats;
      });

      let updatedValidation = validation;
      if (newValidation) {
        updatedValidation = validation.map((v, iValidation) => {
          if (iValidation === position) {
            return newValidation;
          }
          return v;
        });
      }

      onChange(newStats, updatedValidation);
    },
    [onChange, statList, validation]
  );

  useEffect(() => {
    setValidation(validationList);
  }, [validationList]);

  return (
    <Grid container direction="row">
      {statList.slice(0, validation.length).map((stats, iStats) => {
        return (
          <div className={classes.container} key={`bonusFormStats-${iStats}`}>
            <BonusFormStats
              position={iStats}
              stats={stats}
              validation={validation[iStats]}
              onChange={handleBonusFormStatsChange}
              availableAreas={availableAreas}
              availableCampaigns={availableCampaigns}
              isUpdate={!stats.isNew}
              readonly={readonly}
            />
          </div>
        );
      })}
      {!readonly &&
        <Grid item xs={12} md={9} className={classes.addDeliveryTourStyle}>
          <div className={classes.nestedDiv} onClick={addStatsBlock}>
            <AddCircleIcon className={classes.addCircleIcon} />
            {`Ajouter une prime pour ${carrier} `}
          </div>
        </Grid>
      }
    </Grid>
  );
};

export default BonusFormStatsList;
