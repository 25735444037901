import { Grid, makeStyles } from '@material-ui/core'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { AutoComplete } from '../../Component/AutoComplete'
import { city as cityGeo } from 'ca-plateform-geozone-domain'
import { city as cityPlat } from 'ca-plateform-plateform-commons'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from 'date-fns/locale/fr'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MultiSelectBox } from '../../Component/MultiSelect'
import { DatePicker } from '../../Component/DatePicker'
import { TimePicker } from '../../Component/TimePicker'
import { Button } from '../../Component/Button'
import { OrganizationRef } from 'web-admin/src/api/organization'
import { endOfDay, startOfDay } from 'date-fns'
import { KibanaFilters } from '../../utils/kibanaQuery'

const useStyles = makeStyles(() => ({
  filter: {
    width: '100%'
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  },
  container: {
    display: 'flex'
  }
}))

export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}
export interface TerritoryPage
  extends cityPlat.colisactiv.platform.commons.Page<Territory> {}

export type Option = {
  label: string
  value: string
}

interface FluxFiltersProps {
  days: Option[]
  filterValues: KibanaFilters
  getTerritories: (
    page: number,
    size: number,
    name: string
  ) => Promise<TerritoryPage>
  onFilterChange: (values: KibanaFilters) => void
  isFunder: boolean
  isVisitor: boolean
  operatorByTerritory: {
    [territoryId: string]: string[]
  }
  operatorRefs: Map<string, OrganizationRef>
  allowedTerritories?: string[]
}

export const FluxFilters = (props: FluxFiltersProps) => {
  const {
    filterValues,
    onFilterChange,
    getTerritories,
    isFunder,
    isVisitor,
    operatorByTerritory,
    days,
    operatorRefs,
    allowedTerritories
  } = props
  const classes = useStyles()

  const [startDate, setStartDate] = useState<Date | undefined>(
    !filterValues?.startDate ? undefined : filterValues.startDate
  )

  const [endDate, setEndDate] = useState<Date | undefined>(
    !filterValues?.endDate ? undefined : filterValues.endDate
  )

  const [startTime, setStartTime] = useState<Date | undefined>(
    !filterValues?.startTime ? undefined : filterValues.startTime
  )

  const [endTime, setEndTime] = useState<Date | undefined>(
    !filterValues?.endTime ? undefined : filterValues.endTime
  )
  const [territory, setTerritory] = useState<Territory | undefined>(
    !filterValues?.territory ? undefined : filterValues.territory
  )

  const [operatorsFilter, setOperatorsFilter] = useState<string[]>([])

  const [daysFilter, setDaysFilter] = useState<string[]>([])

  const [errors, setErrors] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    if (isVisitor) {
      setTerritory(filterValues.territory)
      if (filterValues.operatorIds.length === 1) {
        setOperatorsFilter(filterValues.operatorIds)
      }
    }
  }, [filterValues])

  const handleStartDateChange = useCallback(
    (startDate: Date | undefined) => {
      setStartDate(startDate && startOfDay(startDate))
      if (startDate) {
        setEndDate(endOfDay(startDate))
      }
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.delete('startDate')
        return map
      })
    },
    [startDate, endDate]
  )

  const handleEndDateChange = useCallback(
    (endDate: Date | undefined) => {
      setEndDate(endDate && endOfDay(endDate))
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.delete('endDate')
        return map
      })
    },
    [endDate]
  )

  const handleStartTimeChange = useCallback(
    (startTime: Date | undefined) => {
      setStartTime(startTime)
    },
    [startTime]
  )

  const handleEndTimeChange = useCallback(
    (endTime: Date | undefined) => {
      setEndTime(endTime)
    },
    [endTime]
  )

  const handleOperatorChange = useCallback(
    (operators: string | string[]) => {
      const operatorsArray = Array.isArray(operators) ? operators : [operators]
      setOperatorsFilter(operatorsArray)
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.delete('operators')
        return map
      })
    },
    [operatorsFilter]
  )

  const handleDaysChange = useCallback(
    (days: string | string[]) => {
      const daysArray = Array.isArray(days) ? days : [days]
      setDaysFilter(daysArray)
    },
    [daysFilter]
  )

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const singleTerritory = Array.isArray(territory)
        ? territory[0]
        : territory
      if (
        !allowedTerritories ||
        (singleTerritory && allowedTerritories.includes(singleTerritory.code))
      ) {
        setTerritory(singleTerritory)
        setErrors((oldMap) => {
          const map = new Map(oldMap.entries())
          map.delete('territory')
          return map
        })
      }
    },
    [daysFilter]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher un territoire'
  )
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  )

  const handleSearchTerritory = useCallback(
    async (search: string) => {
      if (search.trim().length === 0) {
        setAutoCompleteOptions([])
        setAutocompleteText('Rechercher un territoire')
        return
      }

      try {
        const response = await getTerritories(0, 5, search.trim())
        let territories = response.list
        if (allowedTerritories) {
          territories = response.list.filter((territory: Territory) =>
            allowedTerritories.includes(territory.code)
          )
        }
        setAutoCompleteOptions(territories)
        if (response.list.length === 0) {
          setAutocompleteText('Aucun territoire trouvé')
        }
      } catch (err) {
        console.error(err)
        setAutoCompleteOptions([])
        setAutocompleteText('Une erreur est survenue, veuillez réessayer')
      }
    },
    [allowedTerritories]
  )

  const operatorsOptions = useMemo((): Option[] => {
    if (territory) {
      const options: Option[] = []
      operatorByTerritory[territory.code]?.forEach((operatorId) => {
        const el = operatorRefs.get(operatorId)
        if (el) {
          options.push({
            label: el.displayName,
            value: el.organizationId
          })
        }
      })
      return options
    }

    return Array.from(operatorRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId
      }
    })
  }, [operatorRefs, territory, operatorByTerritory])

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name
  }, [])

  const searchTripsOnclick = () => {
    let hasError = false
    if (!startDate) {
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.set('startDate', 'Veuillez sélectionner une date de début')
        return map
      })
      hasError = true
    }
    if (!endDate) {
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.set('endDate', 'Veuillez sélectionner une date de fin')
        return map
      })
      hasError = true
    }
    if (!territory) {
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.set('territory', 'Veuillez sélectionner un territoire ')
        return map
      })
      hasError = true
    }
    if (
      (!isVisitor || operatorRefs.size !== 0) &&
      operatorsFilter.length === 0
    ) {
      setErrors((oldMap) => {
        const map = new Map(oldMap.entries())
        map.set('operators', 'Veuillez sélectionner un ou plusieurs opérateurs')
        return map
      })
      hasError = true
    }
    if (errors.size === 0 && !hasError) {
      onFilterChange({
        ...filterValues,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        operatorIds: operatorsFilter,
        days: daysFilter,
        territory: territory
      })
    }
  }

  return (
    <Fragment>
      <Grid container spacing={3} style={{ width: '600px' }}>
        {!isFunder && (
          <Grid item xs={12}>
            <AutoComplete<Territory>
              id='dashboard-filters-territory'
              onChangeSelectedElement={handleTerritoryChange}
              options={autoCompleteOptions}
              defaultValue={territory}
              onSearch={handleSearchTerritory}
              noOptionsText={autocompleteText}
              getOptionLabel={getTerritoryName}
              label='Territoire'
              multiple={false}
              style={{ width: '100%' }}
              error={!!errors.get('territory')}
              errorMessage={errors.get('territory')}
              readonly={
                allowedTerritories ? allowedTerritories.length == 1 : false
              }
            />
          </Grid>
        )}
        {(!isVisitor || operatorRefs.size != 0) && (
          <Grid item xs={12}>
            <MultiSelectBox
              id='multi-select-operators'
              options={operatorsOptions}
              onChange={handleOperatorChange}
              values={operatorsFilter}
              className={classes.filter}
              label='Opérateurs'
              placeHolder='Opérateurs'
              error={!!errors.get('operators')}
              errorMessage={errors.get('operators')}
              readonly={operatorRefs.size == 1}
            />
          </Grid>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <Grid item xs={6}>
            <DatePicker
              id='filterStartDate'
              value={startDate}
              onChangeDate={handleStartDateChange}
              dateLabel='Entre les dates'
              placeholder='De'
              className={classes.filter}
              clearable={true}
              error={!!errors.get('startDate')}
              errorMessage={errors.get('startDate')}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id='filterEndDate'
              value={endDate}
              onChangeDate={handleEndDateChange}
              dateLabel='&nbsp;'
              placeholder='A'
              className={classes.filter}
              clearable={true}
              error={!!errors.get('endDate')}
              errorMessage={errors.get('endDate')}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              onChangeDate={handleStartTimeChange}
              id='filterStartTime'
              dateLabel='Dans la plage horaire'
              placeholder='De'
              clearable={true}
              value={startTime}
              className={classes.filter}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              onChangeDate={handleEndTimeChange}
              id='filterEndTime'
              dateLabel='&nbsp;'
              placeholder='A'
              clearable={true}
              value={endTime}
              className={classes.filter}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectBox
              id='multi-select-days'
              options={days}
              onChange={handleDaysChange}
              values={daysFilter}
              className={classes.filter}
              label='Les jours'
              placeHolder='Tous les jours'
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Button style={{ width: '100%' }} onClick={searchTripsOnclick}>
            Voir itinéraires
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  )
}
