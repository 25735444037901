import { Box, InputLabel } from '@material-ui/core'
import React, { useContext } from 'react'
import { Option, Select } from '../Select'
import { TextField } from '../TextField'
import { useInputStyles } from '../../Theme'
import { themeContext } from '@smartb/archetypes-ui-components'

interface InputFormProps {
  label?: string
  inputType: 'select' | 'textField'
  textFieldType?: 'number' | 'text' | 'email' | 'password'
  selectOptions?: Option[]
  value?: string | number
  defaultValue?: string | number
  onChange?: (value: string) => void
  className?: string
  style?: React.CSSProperties
  inputClassName?: string
  baseClassName?: string
  inputStyle?: React.CSSProperties
  id: string
  placeHolder?: string
  readonly?: boolean
  error?: boolean
  errorMessage?: string
  onRemoveValue?: () => void
  inputIcon?: React.ReactNode
  iconPosition?: 'start' | 'end'
  onClick?: (event: React.MouseEvent) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  autocomplete?: string
}

export const InputForm = React.forwardRef((props: InputFormProps, ref) => {
  const {
    inputType,
    label,
    onChange,
    className,
    inputClassName,
    baseClassName,
    inputStyle,
    selectOptions = [],
    style,
    textFieldType = 'text',
    value = '',
    id,
    placeHolder = '',
    readonly = false,
    error = false,
    errorMessage = '',
    defaultValue,
    onRemoveValue,
    onBlur,
    inputIcon,
    iconPosition,
    onClick,
    autocomplete
  } = props
  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, readonly)()
  return (
    <Box className={className} style={style}>
      {label ? (
        <InputLabel htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      ) : null}
      {readonly ? (
        <TextField
          ref={ref}
          onClick={onClick}
          id={id}
          value={
            inputType === 'select'
              ? selectOptions.find((el) => el.value === value)?.label
              : value
          }
          placeHolder={placeHolder}
          textFieldType={textFieldType}
          className={`${inputClassName}`}
          defaultValue={defaultValue}
          style={inputStyle}
          disabled={true}
          baseClassName={baseClassName}
          inputIcon={inputIcon}
          iconPosition={iconPosition}
          onBlur={onBlur}
          autocomplete={autocomplete}
        />
      ) : inputType === 'textField' ? (
        <TextField
          ref={ref}
          onClick={onClick}
          id={id}
          value={value}
          onChange={(value: string) => onChange && onChange(value)}
          placeHolder={placeHolder}
          textFieldType={textFieldType}
          className={`${inputClassName}`}
          style={inputStyle}
          error={error}
          baseClassName={baseClassName}
          defaultValue={defaultValue}
          errorMessage={errorMessage}
          inputIcon={inputIcon}
          iconPosition={iconPosition}
          onRemoveValue={onRemoveValue}
          onBlur={onBlur}
          autocomplete={autocomplete}
        />
      ) : (
        <Select
          ref={ref}
          onClick={onClick}
          id={id}
          value={value}
          onChange={(value: string) => {
            onChange && onChange(value)
          }}
          options={selectOptions}
          className={inputClassName}
          style={inputStyle}
          placeHolder={placeHolder}
          baseClassName={baseClassName}
          error={error}
          onRemoveValue={onRemoveValue}
          errorMessage={error ? errorMessage : undefined}
          onBlur={onBlur}
        />
      )}
    </Box>
  )
})
