import { Box, makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { deliveryExportClient } from "api/deliveryExport";
import { geoZoneClient } from "api/geoZone";
import { Organization } from "api/organization";
import { AutoComplete, Button, PeriodPicker } from "components";
import { DateInterval } from "components/src/Domain/BonusPayment";
import { Territory } from "components/src/Domain/GeoZone";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { wholeMonthOfOrUndefined } from "utils";

const useStyles = makeStyles(() => ({
  button: {
    marginRight: "10px",
    marginTop: "3px",
  },
  exportContainer: {
    marginTop: 10,
  },
  exportForms: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    width: "100%",
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "10px",
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
  exportSection: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    marginRight: 50,
    marginTop: 10,
  },
  sectionTitle: {
    padding: "20px 0",
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
  },
  filter: {
    minWidth: "200px",
    marginRight: "10px",
    "@media (max-width: 800px)": {
      width: "100% !important",
      marginRight: "0 !important",
    },
    "& .MuiOutlinedInput-root": {
      height: "44px !important",
    },
    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)":
      {
        marginTop: "0px",
      },
    "& .MuiInputLabel-filled ": {
      transform: " translate(12px, 25px) scale(1)",
    },
  },
  period: {
    display: "flex",
    justifyContent: "left",
  },
  leftPeriodInput: {
    paddingRight: "10px",
  },
  exportButton: {
    alignSelf: "end",
    marginTop: "10px",
  },
  reminderRuleIcon: {
    color: "#007DCE",
    alignItems: "center",
    display: "flex",
  },
  reminderIcon: {
    alignItems: "center",
    display: "flex",
  },
  reminderMessage: {
    padding: "8px",
  },
  reminderContainer: {
    width: "fit-content",
    display: "flex",
    gap: "15px",
    background: "white",
    padding: "5px",
    paddingRight: "10px",
    marginTop: "10px",
  },
  severityBar: {
    width: "3px",
    minWidth: "3px",
    borderRadius: "30px",
    marginRight: "5px",
  },
}));

export interface DashboardExportFormProps {
  organization: Organization | null;
  isFunder: boolean;
  isAdmin: boolean;
  isVisitor: boolean;
  canExportDelivery: boolean;
  canExportTrace: boolean;
  allowedTerritories?: Nullable<string[]>;
}

export const DashboardExportForm = (props: DashboardExportFormProps) => {
  const {
    organization,
    isFunder,
    isAdmin,
    isVisitor,
    canExportDelivery,
    canExportTrace,
    allowedTerritories,
  } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  const [territory, setTerritory] = useState<Territory | undefined>(undefined);

  const [exportDeliveryPeriodStartMonth, setExportDeliveryPeriodStartMonth] =
    useState<DateInterval | undefined>(
      wholeMonthOfOrUndefined(
        organization?.exportAuthorization?.deliveryPeriod?.startDate ??
          undefined
      )
    );
  const [exportDeliveryPeriodEndMonth, setExportDeliveryPeriodEndMonth] =
    useState<DateInterval | undefined>(
      wholeMonthOfOrUndefined(
        organization?.exportAuthorization?.deliveryPeriod?.endDate ?? undefined
      )
    );
  const [exportTracePeriodStartMonth, setExportTracePeriodStartMonth] =
    useState<DateInterval | undefined>(
      wholeMonthOfOrUndefined(
        organization?.exportAuthorization?.tracePeriod?.startDate ?? undefined
      )
    );
  const [exportTracePeriodEndMonth, setExportTracePeriodEndMonth] = useState<
    DateInterval | undefined
  >(
    wholeMonthOfOrUndefined(
      organization?.exportAuthorization?.tracePeriod?.endDate ?? undefined
    )
  );

  const exportDeliveryPeriodLimit = useMemo(
    (): DateInterval | undefined =>
      organization?.exportAuthorization?.deliveryPeriod ?? undefined,
    [organization]
  );
  const exportTracePeriodLimit = useMemo(
    (): DateInterval | undefined =>
      organization?.exportAuthorization?.tracePeriod ?? undefined,
    [organization]
  );
  const getLimitDate = (limitDate: number | undefined) =>
    limitDate == undefined || limitDate <= 0 ? undefined : limitDate;
  const getLocaleDateString = (limitDate: number) =>
    new Date(limitDate).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
    });

  const handleDeliveryExport = useCallback(async () => {
    if (
      !(
        exportDeliveryPeriodStartMonth &&
        exportDeliveryPeriodEndMonth &&
        (!isAdmin || territory)
      )
    ) {
      return;
    }
    const objectUrl = await deliveryExportClient.query.exportDelivery(
      {
        startDate: exportDeliveryPeriodStartMonth.startDate,
        endDate: exportDeliveryPeriodEndMonth.endDate,
      },
      territory?.code
    );
    if (objectUrl == null) {
      return;
    }

    const from = getLocaleDateString(exportDeliveryPeriodStartMonth.startDate);
    const to = getLocaleDateString(exportDeliveryPeriodEndMonth.startDate);

    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `Export des points de livraisons de ${from} à ${to}.csv`;
    a.click();
  }, [
    exportDeliveryPeriodStartMonth,
    exportDeliveryPeriodEndMonth,
    isAdmin,
    territory,
  ]);

  const handleTraceExport = useCallback(async () => {
    if (
      !(
        exportTracePeriodStartMonth &&
        exportTracePeriodEndMonth &&
        (!isAdmin || territory)
      )
    ) {
      return;
    }
    const objectUrl = await deliveryExportClient.query.exportTrace(
      {
        startDate: exportTracePeriodStartMonth.startDate,
        endDate: exportTracePeriodEndMonth.endDate,
      },
      territory?.code
    );
    if (objectUrl == null) {
      return;
    }

    const from = getLocaleDateString(exportTracePeriodStartMonth.startDate);
    const to = getLocaleDateString(exportTracePeriodEndMonth.startDate);

    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `Export des traces GPS de ${from} à ${to}.json`;
    a.click();
  }, [
    exportTracePeriodStartMonth,
    exportTracePeriodEndMonth,
    isAdmin,
    territory,
  ]);

  const [autocompleteText, setAutocompleteText] = useState(
    "Rechercher un territoire"
  );
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  );

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([]);
      setAutocompleteText("Rechercher un territoire");
      return;
    }

    try {
      const response = await geoZoneClient.query.getTerritories(
        0,
        5,
        search.trim()
      );
      let territories = response.list;
      if (allowedTerritories) {
        territories = response.list.filter((territory: Territory) =>
          allowedTerritories.includes(territory.code)
        );
      }
      setAutoCompleteOptions(territories);
      if (response.list.length === 0) {
        setAutocompleteText("Aucun territoire trouvé");
      }
    } catch (err) {
      console.error(err);
      setAutoCompleteOptions([]);
      setAutocompleteText("Une erreur est survenue, veuillez réessayer");
    }
  }, []);

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name;
  }, []);

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const singleTerritory = Array.isArray(territory)
        ? territory[0]
        : territory;

      if (
        !allowedTerritories ||
        (singleTerritory && allowedTerritories.includes(singleTerritory.code))
      ) {
        setTerritory(singleTerritory);
      }
    },
    []
  );

  return (
    <div className={classes.exportContainer}>
      <div className={classes.title}>
        <span>{t("app_dashboard_export_title")}</span>
      </div>
      <Box className={classes.reminderContainer}>
        <div
          className={classes.severityBar}
          style={{ background: "rgb(33, 150, 243)" }}
        />
        <div className={classes.reminderRuleIcon}>
          <Info className={classes.reminderRuleIcon} />
        </div>
        <div>{t("app_dashboard_export_reminder")}</div>
      </Box>
      {(isAdmin || (isVisitor && organization?.territories?.length !== 1)) && (
        <Box className={classes.exportSection}>
          <div className={classes.sectionTitle}>
            <span>{t("app_dashboard_export_territory")}</span>
          </div>
          <AutoComplete<Territory>
            id="dashboard-filters-territory"
            onChangeSelectedElement={handleTerritoryChange}
            options={autoCompleteOptions}
            defaultValue={territory}
            label=""
            onSearch={handleSearchTerritory}
            noOptionsText={autocompleteText}
            getOptionLabel={getTerritoryName}
            placeholder="Territoire"
            className={classes.filter}
          />
        </Box>
      )}
      <Box className={classes.exportForms}>
        {canExportDelivery && (
          <Box className={classes.exportSection}>
            <div className={classes.sectionTitle}>
              <span>{t("app_dashboard_export_delivery")}</span>
            </div>
            <div className={classes.period}>
              <PeriodPicker
                id="organization-form-export-trace-periodPicker-left"
                label={t("app_campaign_form_start_date")}
                period={exportDeliveryPeriodStartMonth}
                minDate={getLimitDate(exportDeliveryPeriodLimit?.startDate)}
                maxDate={
                  exportDeliveryPeriodEndMonth?.startDate ??
                  getLimitDate(exportDeliveryPeriodLimit?.endDate)
                }
                onDateChange={setExportDeliveryPeriodStartMonth}
                inputClassName={classes.leftPeriodInput}
                clearable
              />
              <PeriodPicker
                id="organization-form-export-trace-periodPicker-right"
                label={t("app_campaign_form_end_date")}
                period={exportDeliveryPeriodEndMonth}
                minDate={
                  exportDeliveryPeriodStartMonth?.endDate ??
                  getLimitDate(exportDeliveryPeriodLimit?.startDate)
                }
                maxDate={getLimitDate(exportDeliveryPeriodLimit?.endDate)}
                onDateChange={setExportDeliveryPeriodEndMonth}
                clearable
                displayEnd
              />
            </div>
            <Button
              className={classes.exportButton}
              onClick={handleDeliveryExport}
              disabled={
                !(
                  exportDeliveryPeriodStartMonth &&
                  exportDeliveryPeriodEndMonth &&
                  (!isAdmin || territory)
                )
              }
            >
              {t("app_dashboard_export")}
            </Button>
          </Box>
        )}
        {canExportTrace && (
          <Box className={classes.exportSection}>
            <div className={classes.sectionTitle}>
              <span>{t("app_dashboard_export_trace")}</span>
            </div>
            <div className={classes.period}>
              <PeriodPicker
                id="organization-form-export-trace-periodPicker-left"
                label={t("app_campaign_form_start_date")}
                period={exportTracePeriodStartMonth}
                minDate={getLimitDate(exportTracePeriodLimit?.startDate)}
                maxDate={
                  exportTracePeriodEndMonth?.startDate ??
                  getLimitDate(exportTracePeriodLimit?.endDate)
                }
                onDateChange={setExportTracePeriodStartMonth}
                inputClassName={classes.leftPeriodInput}
                clearable
              />
              <PeriodPicker
                id="organization-form-export-trace-periodPicker-right"
                label={t("app_campaign_form_end_date")}
                period={exportTracePeriodEndMonth}
                minDate={
                  exportTracePeriodStartMonth?.endDate ??
                  getLimitDate(exportTracePeriodLimit?.startDate)
                }
                maxDate={getLimitDate(exportTracePeriodLimit?.endDate)}
                onDateChange={setExportTracePeriodEndMonth}
                clearable
                displayEnd
              />
            </div>
            <Button
              className={classes.exportButton}
              onClick={handleTraceExport}
              disabled={
                !(
                  exportTracePeriodStartMonth &&
                  exportTracePeriodEndMonth &&
                  (!isAdmin || territory)
                )
              }
            >
              {t("app_dashboard_export")}
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};
