import { GeoLocation } from "api/geoLocation";
import pickupIcon from "components/src/Icons/Pickup/icon-pickup.svg";
import { FullscreenControl, GeoJSONSourceRaw } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import ReactMapboxGl, {
  Layer,
  MapContext,
  Popup,
  Source,
} from "react-mapbox-gl";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import { apiMapboxAccessToken } from "../../../api/configuration";
import { useContext, useLayoutEffect, useState } from "react";

const styles = {
  londonCycle: "mapbox://styles/mapbox/light-v9",
  light: "mapbox://styles/mapbox/light-v9",
  dark: "mapbox://styles/mapbox/dark-v10",
  basic: "mapbox://styles/mapbox/basic-v9",
  outdoor: "mapbox://styles/mapbox/outdoors-v10",
  street: "mapbox://styles/mapbox/streets-v11",
};

const Map = ReactMapboxGl({
  accessToken: apiMapboxAccessToken,
});

const mapStyle = {
  width: "500px",
  height: "282px",
  marginLeft: "30px",
};

interface HeatmapProps {
  onStyleLoad?: (map: any) => any;
  pickupPoints: any;
  deliveryPoints: any;
  southWestBound: GeoLocation;
  northEastBound: GeoLocation;
}

export const Heatmap = (props: HeatmapProps) => {
  const { pickupPoints, deliveryPoints, southWestBound, northEastBound } =
    props;

  const pickupPointsSource: GeoJSONSourceRaw = {
    data: JSON.parse(pickupPoints),
    type: "geojson",
  };

  const deliveryPointsSource: GeoJSONSourceRaw = {
    data: JSON.parse(deliveryPoints),
    type: "geojson",
  };

  const pickupImg = new Image(25, 25);
  pickupImg.src = pickupIcon;

  const onStyleLoad: MapEvent = (map) => {
    const { onStyleLoad } = props;

    map.addImage("pickup-point", pickupImg);
    map.addControl(new FullscreenControl());

    return onStyleLoad && onStyleLoad(map);
  };

  const heatmapLayer = {
    // increase weight as diameter breast height increases
    "heatmap-weight": {
      property: "nbColis",
      type: "exponential",
      stops: [
        [1, 0],
        [800, 0.8],
        [1000, 1],
      ],
    },
    // assign color values be applied to points depending on their density
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(255,255,178,0)",
      0.02,
      "rgb(0,40,169)",
      0.05,
      "rgb(37,162,204)",
      0.1,
      "rgb(49,210,141)",
      0.2,
      "rgba(177,225,5,0.7)",
      0.4,
      "rgba(253,141,60,0.8)",
      0.6,
      "rgba(240,59,32,0.9)",
      0.8,
      "rgba(189,0,38,1)",
    ],
    "heatmap-radius": {
      stops: [
        [5, 5],
        [7, 15],
        [10, 17],
        [12, 20],
      ],
    },
    "heatmap-intensity": {
      stops: [
        [5, 1],
        [7, 2],
        [10, 4],
        [12, 6],
      ],
    },
  };

  const markerLayer = {
    "circle-radius": {
      property: "nbColis",
      type: "exponential",
      stops: [
        [{ zoom: 22, value: 10 }, 4],
        [{ zoom: 22, value: 20 }, 5],
        [{ zoom: 22, value: 50 }, 6],
        [{ zoom: 22, value: 100 }, 7],
        [{ zoom: 22, value: 300 }, 8],
        [{ zoom: 22, value: 600 }, 9],
        [{ zoom: 22, value: 800 }, 10],
        [{ zoom: 22, value: 1000 }, 12],
      ],
    },
    "circle-color": {
      property: "nbColis",
      type: "exponential",
      stops: [
        [0, "rgba(236,222,239,0)"],
        [10, "rgb(0,40,169)"],
        [20, "rgb(37,162,204)"],
        [50, "rgb(49,210,141)"],
        [100, "rgba(177,225,5,0.7)"],
        [200, "rgba(253,141,60,0.8)"],
        [300, "rgba(240,59,32,0.9)"],
        [600, "rgba(189,0,38,1)"],
      ],
    },
    "circle-stroke-color": "white",
    "circle-stroke-width": 1,
    "circle-opacity": {
      stops: [
        [7, 0],
        [8, 1],
      ],
    },
  };

  return (
    <Map
      style={styles.street}
      fitBounds={[
        [southWestBound.lon, southWestBound.lat],
        [northEastBound.lon, northEastBound.lat],
      ]}
      fitBoundsOptions={{ padding: 15 }}
      containerStyle={mapStyle}
      onStyleLoad={onStyleLoad}
    >
      <Source id="heatmap" geoJsonSource={deliveryPointsSource} />
      <Layer
        id="marker"
        type="circle"
        sourceId="heatmap"
        paint={markerLayer}
        minZoom={13}
      />
      <Layer
        type="heatmap"
        sourceId="heatmap"
        paint={heatmapLayer}
        maxZoom={13}
      />
      <Source id="pickup" geoJsonSource={pickupPointsSource} />
      <Layer
        type="symbol"
        layout={{ "icon-image": "pickup-point" }}
        sourceId="pickup"
      />
      {<MarkerPopup />}
    </Map>
  );
};

const MarkerPopup = () => {
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const map = useContext(MapContext);

  useLayoutEffect(() => {
    map?.on("click", "marker", (event) => {
      setSelectedDelivery(null);
      setSelectedDelivery(event);
    });
  }, [map]);

  return (
    selectedDelivery && (
      <Popup
        coordinates={selectedDelivery.lngLat}
        onClick={() => {
          setSelectedDelivery(null);
        }}
      >
        <p>
          Nombre de colis livrés:{" "}
          {selectedDelivery.features[0].properties["nbColis"]}
        </p>
      </Popup>
    )
  );
};
