import { city } from "ca-plateform-notification-domain";
import { buildNotificationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface NotificationArchivedEvent
  extends city.colisactiv.platform.notification.domain.features
    .NotificationArchivedEvent {}

export const archiveNotification = (
  notificationId: string
): Promise<NotificationArchivedEvent> => {
  return http.delete(
    buildNotificationApiUrl(`/notifications/${notificationId}/archive`),
    "La notification a été archivé"
  );
};
