import { connect } from "react-redux";
import { State } from "store";
import { title } from "store/title";
import { geoZone } from "store/geozone";
import { router } from "store/router";

const mapStateToProps = (state: State) => ({
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  createGeoZone: geoZone.commands.createGeoZone,
  gotoGeoZonesView: router.goto.geoZone.geoZonesList,
};

export default connect(mapStateToProps, mapDispatchToProps);
