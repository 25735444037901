import { buildOrganizationApiUrl } from "api/configuration";
import { DeliveryTourPage } from "..";
import { http } from "../../http";
import { city as citydel } from "ca-plateform-campaign-delivery-domain";

interface RejectDeliveryToursCommand
    extends citydel.colisactiv.platform.campaign.delivery.model.features
        .RejectDeliveryToursCommand { }

interface DeliveryTourRejectedEvent
    extends citydel.colisactiv.platform.campaign.delivery.model.features
        .DeliveryTourRejectedEvent { }

const rejectDeliveryToursCommand = (
    tourIds: string[]
): RejectDeliveryToursCommand => {
    return {
        type: "REJECT_DELIVERY_TOURS",
        payload: {
            tourIds: tourIds,
        },
    };
};

export const rejectDeliveryTours = (
    tourIds: string[]
): Promise<DeliveryTourRejectedEvent[]> => {
    return http.post(
        buildOrganizationApiUrl(`/campaignDelivery/tours/reject`),
        JSON.stringify(rejectDeliveryToursCommand(tourIds)),
        `${tourIds.length > 1 ? "les" : "la"} livraison${tourIds.length > 1 ? "s" : ""} ${tourIds.length > 1 ? "ont" : "a"} bien été rejeté${tourIds.length > 1 ? "s" : ""}`
    );
};
